var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DashboardCard',[_c('edit-table',{attrs:{"endpoint":"programnew","keys":['id', 'name', 'description'],"ignoredKeys":['phases'],"name":"Programa","object":"program","requiresPassword":""}}),_c('edit-table',{attrs:{"requiresPassword":"","endpoint":"phasenew","externalSearchableField":"program","label":"Filtrar por programa","getParams":{ program: this.program.id },"keys":[
      'id',
      'available',
      'program',
      'name',
      'number',
      'title',
      'description',
      'summary',
      'start_date',
      'end_message' ],"ignoredKeys":['stages', 'lectionaries', 'objectives', 'transitions'],"name":"Etapa","object":"phase"}}),_c('edit-table',{attrs:{"requiresPassword":"","endpoint":"stagenew","externalSearchableField":"phase","label":"Filtrar por Etapa","getParams":{ phase__program: this.program.id },"keys":[
      'id',
      'phase',
      'name',
      'number',
      'title',
      'description',
      'has_feedback',
      'is_start',
      'is_end',
      'format',
      'label' ],"ignoredKeys":['activities'],"name":"Sub-Etapa (Pantalla)","object":"stage"}}),_c('edit-table',{attrs:{"requiresPassword":"","endpoint":"activitynew","externalSearchableField":"stage","label":"Filtrar por Sub-Etapa","keys":[
      'id',
      'stage',
      'title',
      'number',
      'description',
      'media',
      'type',
      'format',
      'btn_link',
      'btn_text',
      'expandable',
      'background' ],"ignoredKeys":['questions'],"name":"Actividades","object":"activity"}}),_c('edit-table',{attrs:{"requiresPassword":"","getParams":{ activity__stage__phase__program: this.program.id },"endpoint":"questionnew","externalSearchableField":"activity","label":"Filtrar por Actividad","keys":[
      'id',
      'activity',
      'question',
      'number',
      'media',
      'type',
      'text',
      'expected',
      'format',
      'maximum' ],"ignoredKeys":['answers'],"name":"Preguntas","object":"question"}}),_c('edit-table',{attrs:{"requiresPassword":"","endpoint":"answernew","externalSearchableField":"question","label":"Filtrar por Pregunta","getParams":{
      question__activity__stage__phase__program: this.program.id,
    },"keys":[
      'id',
      'question',
      'text',
      'number',
      'media',
      '_max',
      '_min',
      'btn_link',
      'btn_text',
      'unique',
      'extra_text' ],"name":"Respuestas","object":"answer"}}),_c('v-divider'),_c('edit-table',{attrs:{"requiresPassword":"","endpoint":"lectionarynew","externalSearchableField":"phase","label":"Filtrar por etapa","getParams":{
      phase__program: this.program.id,
    },"keys":['id', 'number', 'phase', 'name', 'summary'],"name":"Leccionarios","object":"lectionary"}}),_c('edit-table',{attrs:{"requiresPassword":"","endpoint":"objectivenew","externalSearchableField":"phase","label":"Filtrar por etapa","getParams":{
      phase__program: this.program.id,
    },"keys":['id', 'phase', 'number', 'text', 'type'],"name":"Objetivos por etapa","object":"objective"}}),_c('v-divider'),_c('edit-table',{attrs:{"requiresPassword":"","endpoint":"tutorial","externalSearchableField":"id","label":"Filtrar por id","keys":['id', 'name', 'user_type', 'video_link', 'description'],"name":"Tutoriales","object":"tutorial"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }