<template>
  <div
    :style="{
      transform: rotated ? 'rotate(45deg)' : '',
      width: `${size}px`,
      height: `${size}px`,
    }"
    style="transform-origin:center center;width:40px;height:40px"
  >
    <svg
      :width="size"
      :height="size"
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40 27.7807V40H14.1818H0V0H0.727276H40V12.3238V27.7807Z"
        fill="currentCollor"
      />
    </svg>
  </div>
</template>
<script>
export default {
  name: "Square",
  props: {
    rotated: {
      default: false,
      type: Boolean,
      required: false,
    },
    size: {
      default: 40,
      type: Number,
      required: false,
    },
  },
};
</script>
