<template>
  <b-overlay>
    <transition name="fade">
      <div
        class="absolute text-left text-small"
        v-if="visible"
        :style="{ 'background-color': success ? '#bdffb7' : '#fae150' }"
      >
        {{ this.text }}
      </div>
    </transition>
  </b-overlay>
</template>
<script>
export default {
  name: "ToastNotification",
  data() {
    return {
      text: "",
      visible: false,
      success: false,
    };
  },

  watch: {
    "$store.state.layout.toasts": function() {
      this.text = this.$store.state.layout.toasts.slice(-1)[0];
      if (this.text != "" && this.text != undefined) {
        this.success = this.$store.state.layout.success;
        this.visible = true;
        setTimeout(() => (this.visible = false), 3000);
      }
    },
  },
};
</script>
<style scoped>
.absolute {
  position: fixed;
  bottom: 30px;
  padding: 15px;
  border-radius: 10px;
  right: 30px;
  width: 300px;
  z-index: 5000;
  background-color: #f7f4f9;
}

@media (max-width: 768px) {
  .absolute {
    position: fixed;
    bottom: 30px;
    padding: 10px;
    border-radius: 10px;
    right: 30px;
    width: 200px;
    z-index: 5000;
    background-color: #f7f4f9;
  }
}
.fade-enter-active {
  transition: opacity 0.1s;
}
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
