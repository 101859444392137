var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('responsive-div',{staticClass:"d-flex flex-column ms-auto me-auto",class:[
    _vm.activity.format.includes('rounded')
      ? 'rounded-lg ' + _vm.activity.format
      : '',
    _vm.activity.background != '' ? ' p-4' : '' ],staticStyle:{"max-width":"744px"},style:(_vm.activity.background != ''
      ? ("background:" + (_vm.activity.background) + " !important")
      : '')},[_c('custom-dialog',{attrs:{"width":"100% !important","extraStyles":"margin: 0 !important padding: 0 !important;position:absolute;height:100vh;width:100vw;top:0;left:0","fullScreen":true,"closeIconStyles":"padding-right:1px;position:absolute;right:5px;top:5px;width:26px;height:26px;background:white;border-radius:13px;max-width:26px","height":"100vh !important"},on:{"close":_vm.postCompletedActivity},model:{value:(_vm.iframeActive),callback:function ($$v) {_vm.iframeActive=$$v},expression:"iframeActive"}},[_c('iframe',{staticStyle:{"width":"100%","height":"100%"},attrs:{"id":"iframeId","src":_vm.buttonLink,"title":"resource"}})]),_c('responsive-div',{staticClass:"mb-2",class:_vm.stage.format == 'inversed-background' ? 'color--neutral-100-t' : ''},[(!_vm.readOnly)?_c('custom-text',{class:_vm.readOnly ? '' : 'text-title mb-2',staticStyle:{"color":"#212529"},attrs:{"text":_vm.activity.title,"ignoreStyles":false}}):_vm._e(),(!_vm.readOnly)?_c('custom-text',{class:_vm.readOnly ? '' : 'text-body',attrs:{"text":_vm.activity.description,"ignoreStyles":false}}):_vm._e()],1),_c('responsive-div',[(_vm.activity.media != '' && !_vm.readOnly)?_c('responsive-div',{staticClass:"bg--primary-70-t rounded",attrs:{"mobileStyle":"margin-left: -16px !important;min-width:100vw !important;","desktopStyle":""}},[_c('vimeo-player',{ref:"activityPlayer",attrs:{"options":{
          title: false,
          responsive: true,
          webkitallowfullscreen: true,
          mozallowfullscreen: true,
          allowfullscreen: true,
        },"video-id":_vm.activity.media},on:{"timeupdate":_vm.playing}})],1):_vm._e(),(_vm.activity.btn_link)?_c('div',{staticClass:"text-center h-100 d-flex align-items-center justify-content-center",staticStyle:{"min-height":"100px"}},[_c('common-button',{attrs:{"secondaryColor":"var(--tertiary)","text":_vm.activity.btn_text},on:{"click":_vm.openLink}})],1):_vm._e(),_vm._l((_vm.activity.questions),function(question,index){return _c('Question',{key:question,staticClass:"mt-2",attrs:{"readOnly":_vm.readOnly,"activity":_vm.computedActivity,"stage":_vm.stage,"questionId":index,"question":question}})})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }