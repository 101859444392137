<template>
  <!-- mixpanel on -->
  <grid gridTemplateRows="70px minmax(0, 1fr)" gridTemplateColumns="1fr">
    <grid-element
      rowStart="1"
      rowEnd="2"
      colStart="1"
      colEnd="3"
      :elevation="false"
    >
      <div class="w-100 text-left d-flex">
        <div class="d-flex flex-column">
          <span class="text-label ">Seleccione la etapa</span>
          <common-dropdown
            :outlined="false"
            class="phase-template-select rounded mt-1"
            hide-details
            dense
            filled
            dark
            v-model="currentPhase"
            style="max-width:200px;color:white !important;"
            :items="sortedPhases"
            item-text="name"
            item-value="id"
            @input="setCurrentPhaseById"
          ></common-dropdown>
        </div>
      </div>
    </grid-element>
    <grid-element
      :elevation="false"
      rowStart="2"
      rowEnd="3"
      colStart="1"
      colEnd="3"
      ><div class="h-100 w-100">
        <slot></slot></div
    ></grid-element>
  </grid>
</template>
<script>
import Grid from "../../components/layout/Grid.vue";
import GridElement from "../../components/layout/GridElement.vue";
// import DashboardCard from "./DashboardCard.vue";
// import Building from "../../components/dashboard/DashboardBuilding.vue";

import { mapActions, mapGetters } from "vuex";
export default {
  name: "DashboardPhaseTemplate",
  mounted() {
    this.currentPhase = this.storeCurrentPhase;
  },
  components: {
    // DashboardCard,
    Grid,
    GridElement,
    // Building,
  },
  props: {
    overflow: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  methods: {
    setCurrentPhaseById(id) {
      let phase = this.phases.find((x) => x.id == id);
      this.track({
        event: "dashboard-set-phase",
        data: { phase: phase.number },
      });

      if (phase.available) {
        this.setCurrentPhase({ phaseId: id });
      } else {
        this.setUnavailableDialog({ dialog: true });
      }
    },
    ...mapActions({
      track: "authentication/track",
      setCurrentPhase: "program/setCurrentPhase",
      setUnavailableDialog: "authentication/setUnavailableDialog",
    }),
    getPhaseButtonClass(index) {
      if (index === this.currentPhase) {
        return "dashboard-phase-button-selected";
      }
      return "dashboard-phase-button";
    },
  },
  computed: {
    sortedPhases() {
      return [...this.phases].sort(({ number: a }, { number: b }) => a - b);
    },
    ...mapGetters({
      phases: "program/phases",
      storeCurrentPhase: "program/currentPhase",
      dashboardSection: "layout/dashboardSection",
      mobile: "layout/isMobile",
      lang: "layout/lang",
    }),
  },
  data() {
    return {
      currentPhase: 0,
    };
  },
};
</script>
<style>
.dashboard-phase-button-disabled {
  font-family: "Karla";
  font-style: normal;
  font-size: 13.6646px;
  line-height: 20px;
  letter-spacing: 0.085404px;
  color: rgb(121, 121, 121) !important;
  background-color: rgb(177, 177, 177) !important;
  border-radius: 14px !important;
  height: 32px !important;
  outline: 0 !important;
  border: 0 !important;
}

.dashboard-phase-button {
  font-family: "Karla";
  font-style: normal;
  font-size: 13.6646px;
  line-height: 20px;
  letter-spacing: 0.085404px;
  color: #9aa5fd !important;
  border-radius: 14px !important;
  height: 32px !important;
  outline: 0 !important;
  border: 0 !important;
}
.dashboard-phase-button-selected {
  font-family: "Karla";
  font-style: normal;
  font-size: 13.6646px;
  line-height: 20px;
  letter-spacing: 0.085404px;
  color: #ffffff !important;
  border-radius: 14px !important;
  height: 32px !important;
  outline: 0 !important;
  border: 0 !important;
}
.phase-template-select {
  background: #ff7497 !important;
}
.phase-template-select .v-select__selections {
  background-color: transparent; /* set background to transparent */
  border: none; /* remove border */
}
</style>
