<template>
  <!-- mixpanel on -->
  <div>
    <DashboardCard
      class="h-100"
      style="min-height:100% !important;height: 100% !important;"
    >
      <div class="w-100 mt-3">
        <v-btn-toggle
          v-model="currentButton"
          class="w-100 text-left d-flex flex-row"
          style="background-color:transparent"
          mandatory
        >
          <!--<v-btn
              rounded
              dense
              active-class="no-active"
              hideOverlay


              class="no-capitalize me-2 mb-2"
              :class="getPhaseButtonClass(index)"
              elevation="0"
              >{{ button }}</v-btn
            >-->
        </v-btn-toggle>
        <div class="w-100" style="overflow-y:overlay"></div>
      </div>
      <div class="w-100" style="overflow-y:overlay;height:100%;">
        <div class="me-5">
          <div class="dashboard-tutorial-container">
            <div v-for="(tut, index) in tutorials" :key="index" class="mb-5">
              <div>
                <div style="max-width:100%;width:100%;" class="pb-2">
                  <vimeo-player
                    class="p-3"
                    :options="{ responsive: true }"
                    ref="player"
                    :video-id="tut.video_link"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardCard>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import DashboardCard from "../DashboardCard.vue";
export default {
  name: "DashboardTutorial",
  components: { DashboardCard },

  data() {
    return {
      index: 0,
      currentButton: 0,
    };
  },
  methods: {
    ...mapActions({
      track: "authentication/track",
      getTutorials: "authentication/getTutorials",
    }),
    goTo(route, otherSite) {
      if (otherSite) {
        window.open(route, "_blank");
      } else {
        this.$router.push(route);
      }
      this.track({
        event: "dashboard-tutorial-goto",
        data: { route: route },
      });
    },
    getPhaseButtonClass(index) {
      if (index === this.currentButton) {
        return "dashboard-phase-button-selected";
      } else {
        return "dashboard-phase-button";
      }
    },
  },
  computed: {
    ...mapGetters({
      phase: "program/currentPhase",
      tutorials: "authentication/tutorials",
    }),
    computedButtons() {
      return ["Tutoriales"];
    },
    videoActivities() {
      let videoActivities = [];
      this.phase.stages.forEach((stage) => {
        videoActivities = videoActivities.concat(
          stage.activities.filter((x) => x.type == "media")
        );
      });

      return videoActivities;
    },
    linkActivities() {
      let linkActivities = [];
      this.phase.stages.forEach((stage) => {
        linkActivities = linkActivities.concat(
          stage.activities.filter((x) => x.btn_link != "")
        );
      });
      return linkActivities;
    },
  },
};
</script>

<style>
.dashboard-tutorial-container {
  height: 100%;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
}
.dashboard-tutorial-container > div {
  width: 50%;
  height: 200px;
}
@media only screen and (max-width: 1000px) {
  .dashboard-tutorial-container > div {
    width: 100%;
  }
}
</style>
