<template>
  <div class="relative vh-100 bg--primary-100-t" :class="mobile ? '' : 'p-3'">
    <!-- blocking dialog -->
    <v-dialog width="300px" height="200px" persistent v-model="blockingDialog">
      <v-card style="width:300px;height:200px" class="d-flex text-center">
        <div class="m-auto text-subtitle">
          {{ blockingDialogText }}
        </div> </v-card
      >user.type == 'admin'
    </v-dialog>
    <!-- information card -->
    <DataCard
      :showDataCardButton="
        (lang == 'es-CL' || lang == 'es-CO') &&
          user.type == 'student' &&
          formLoaded &&
          !showDataCard &&
          sentCellphone == '' &&
          !baselineDialog
      "
      :showDataCard="showDataCardComputed"
      :showDataCardSent="
        (lang == 'es-CL' || lang == 'es-CO') &&
          formLoaded &&
          user.type == 'student'
      "
      :sentCellphone="sentCellphone"
      @setSentCellphone="setSentCellphone"
    />

    <!-- consent dialog -->
    <custom-dialog
      :width="mobile ? '90%' : '70%'"
      height="90vh"
      v-model="baselineDialog"
      extraStyles="background: #EEF0FF; "
      closeIconStyles="display: none"
    >
      <v-title><b>Asentimiento Informado</b> </v-title>
      <v-card
        class="text-left p-2 pe-4"
        style="max-height: 50vh;height:50vh; overflow-y:auto"
        v-html="$t('consent-text')"
      >
      </v-card>
      <div class="text-left bold pt-4">
        Luego de leer, por favor, indique si desea participar:
      </div>
      <v-radio-group class="ms-2" v-model="isParticipant">
        <v-radio color="#576aff" label="Sí, deseo participar" :value="true" />
        <v-radio
          color="#576aff"
          label="No, no quiero participar"
          :value="false"
        />
      </v-radio-group>
      <common-button
        :disabled="isParticipant === null"
        size="x-large"
        @click="closeBaseLineDialog"
        >Siguiente</common-button
      >
    </custom-dialog>
    <!-- end consent dialog -->
    <!-- no particiapte dialog -->
    <custom-dialog
      width="50%"
      height="240"
      v-model="showAreYouSure"
      class="bg--primary"
      extraStyles="padding: 7%;position:relative;"
      closeIconStyles="display: none"
    >
      <b> ¿Estás seguro que no quieres participar?</b><br />
      <br />
      Responder “No” implica que tus credenciales de acceso quedarán
      desactivadas a partir de este momento y no podrás participar de las
      actividades del programa.
      <responsive-div mobileClass="d-flex flex-column">
        <common-button
          style=""
          class="yes-button"
          text="Si, quiero participar"
          size="x-large"
          @click="clickAreYouSure(true)"
        />
        <common-button
          style=""
          class="no-button"
          borderStyle="2px solid #5669FB !important"
          primaryColor="#5669FB"
          secondaryColor="transparent"
          text="No, quiero participar"
          size="x-large"
          @click="clickAreYouSure(false)"
        />
      </responsive-div>
    </custom-dialog>
    <!-- unavailable dialog -->
    <custom-dialog
      v-if="unavailableDialog"
      v-model="unavailableDialog"
      @close="setUnavailableDialog({ dialog: false })"
    >
      La fecha límite para el desarrollo de las etapas ha finalizado. Por el
      momento no podrás acceder al contenido de estas, pero puedes revisar
      {{
        user.type == "teacher" ? "el avance de tu institución" : "tu avance"
      }}
      o comunicarte si tienes alguna duda ¡Muchas gracias por participar!
      <br />

      <common-button
        text="OK"
        @click="setUnavailableDialog({ dialog: false })"
      ></common-button>
    </custom-dialog>
    <!-- tutorial dialog
    v-if="dashboardTutorialDialog && !showDataCardComputed"

    -->
    <custom-dialog
      extraStyles="padding: 0 !important;"
      v-model="dashboardTutorialDialog"
      class="bg--primary-70-s "
      height="70vh"
      @close="setTutorialDialog({ dialog: false, dialogSeen: true })"
      width="640px"
      closeIconStyles="display:none"
    >
      <div
        class="ma-auto text-title h-100 text-align-middle d-flex flex-column ms-5 me-5"
      >
        <v-icon
          class="me-auto mt-5"
          @click="setTutorialDialog({ dialog: false, dialogSeen: true })"
          >mdi-close</v-icon
        >
        <div class="mt-auto mb-auto">
          Te invitamos a ver los tutoriales en la sección de tutoriales.
        </div>
      </div>
      <!-- all tutorials -->
      <!--<v-carousel
        v-if="isNaN(tutorials)"
        height="400px"
        v-model="tutorial"
        hide-delimiter-background
        class="bg--primary-70-s"
        style="height:100%;max-height:100%;min-height:350px;"
      >
        <v-carousel-item v-for="(tut, i) in tutorials" :key="i">
          <v-sheet height="100%" tile>
            <v-row class="fill-height" align="center" justify="center">
              <div
                ref="tutorialVideo"
                :style="
                  mobile
                    ? 'height:auto;min-height:auto'
                    : 'height:70%;min-height:70%;'
                "
              >
                <div>
                  <vimeo-player
                    :player-height="mobile ? '100px' : '350px'"
                    :video-id="tut.video_link"
                    :options="{
                      muted: true,
                      responsive: mobile ? true : false,
                    }"
                    :loop="true"
                    :autoplay="true"
                    :controls="false"
                  />
                </div>
              </div>
              <div
                class="bg--primary-70-s color--neutral-100-t p-2 ps-5 pe-5 dashboard-scrollbar"
                :class="mobile ? 'fill-height' : ''"
                :style="mobile ? '' : 'height:30%;max-height:30%;'"
                style="overflow-y:scroll !important;--scrollbar-color:red;--scrollbar-bg-color:white;"
              >
                {{ tut.description }}
              </div>
            </v-row>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>-->
      <!-- end all tutorials -->
      <!-- single tutorial -->
      <!--<div v-else>
        <div
          style="height:60%;max-height:60%;background:black"
          ref="tutorialVideo"
        >
          <vimeo-player
            v-if="tutorialHeight() > 0"
            :player-height="tutorialHeight()"
            video-id="753782440"
            :loop="true"
            :autoplay="true"
            :controls="false"
            :options="{}"
            @timeupdate="playing"
          />
        </div>
        <div style="height:40%;max-height:40%"></div>
      </div>-->

      <!-- end single tutorial -->
    </custom-dialog>
    <!-- end tutorial dialog -->
    <!-- grid -->
    <grid
      nCols="2"
      nRows="2"
      class="h-100"
      style="max-height:100vh"
      gridTemplateColumns="min-content auto"
      :gridTemplateRows="
        showTools ? 'auto auto minmax(0, 1fr)' : 'auto 0 minmax(0, 1fr)'
      "
    >
      <!-- left button panel -->
      <grid-element
        backgroundColor="white"
        rowStart="1"
        rowEnd="4"
        colStart="1"
        colEnd="2"
      >
        <div :class="mobile ? '' : 'h-100'">
          <DashboardButtonPanel
            v-model="showPanel"
            :minimized="minimized"
            @setMinimized="setMinimized"
            @setPanel="setPanel"
            :currentPanel="currentPanel"
          />
        </div>
      </grid-element>
      <!-- top information panel -->
      <grid-element
        rowStart="1"
        rowEnd="2"
        colStart="2"
        colEnd="3"
        :elevation="false"
        overflowY="hidden"
      >
        <div class="w-100 d-flex justify-content-center align-items-center">
          <v-avatar v-if="mobile" class="bg--neutral-90-t ms-2" size="36"
            >{{ getInitials(user) }}
          </v-avatar>

          <div
            class="ms-auto mr-auto d-flex justify-content-center align-items-center"
          >
            <v-icon
              v-if="!mobile && sequence.length > 0"
              link
              @click="goToPreviousPanel()"
            >
              mdi-chevron-left
            </v-icon>
            <v-toolbar-title
              class="text-title text-left bold color--neutral-30-t"
              >{{ currentPanel.name }}
            </v-toolbar-title>
            <div
              v-if="!mobile && currentClass"
              class="text-title color--neutral-30-t me-2 ms-2 bold"
            >
              |
            </div>
            <div
              v-if="!mobile && currentClass"
              class="text-title color--neutral-30-t"
            >
              {{ currentClass.name }}
            </div>
          </div>

          <v-icon
            v-if="mobile"
            class="color--primary me-2"
            @click.stop="showPanel = !showPanel"
          >
            mdi-menu
          </v-icon>
          <v-spacer v-if="!mobile"></v-spacer>

          <v-text-field
            v-if="currentPanel.tag === 'panel' && false"
            :class="mobile ? '' : 'me-5'"
            hide-details
            append-icon="mdi-magnify"
            single-line
            style="background-color:white;border-radius:30px"
            solo
            flat
            dense
            label="Buscar"
          ></v-text-field>
          <v-autocomplete
            hide-details
            style="z-index:1000"
            v-if="user.type == 'admin'"
            v-model="currentProgram"
            :items="programs"
            item-text="name"
            item-value="id"
            dense
            @change="selectProgram"
            label="Escoge tu programa"
          ></v-autocomplete>
          <!--<common-dropdown
            v-if="user.type == 'principal' && !mobile"
            style="position:relative;top:14px;max-width:400px; margin-right:30px"
            :items="schools"
            label="sede"
            dense
            v-model="currentSchool"
            item-text="name"
            item-value="rbd"
            @input="setSchool"
          >
          </common-dropdown>-->
          <!--<div v-if="!mobile" class="m-15 text-title color--primary">
            {{ currentClass ? currentClass.name : "" }}
          </div>-->
          <v-avatar v-if="!mobile" class="bg--neutral-90-t me-2" size="36"
            >{{ getInitials(user) }}
          </v-avatar>
          <div v-if="!mobile" class="d-flex flex-column text-left ms-auto">
            <span class="text-body"
              >{{ user.first_name }} {{ user.last_name }}</span
            >
            <span class="text-label">{{ user.email }}</span>
          </div>
        </div>
        <common-dropdown
          v-if="user.type == 'principal' && mobile"
          style="position:relative;top:14px;100%; margin-right:20px;margin-left:20px"
          :items="schools"
          label="sede"
          dense
          v-model="currentSchool"
          item-text="name"
          item-value="rbd"
          @input="setSchool"
        >
        </common-dropdown>
      </grid-element>
      <!-- tools panel -->
      <grid-element
        v-if="showTools"
        rowStart="2"
        rowEnd="3"
        colStart="2"
        :elevation="false"
        colEnd="3"
        overflowY="visible"
      >
        <div
          :class="mobile ? 'dashboard-tool-container' : 'd-flex h-100 gap-3'"
        >
          <dashboard-tool
            class="elevation-4"
            v-for="(tool, index) in computedTools"
            :key="index"
            :tool="tool"
            :options="tool.options"
            @selectOption="tool.action"
            @clickTool="tool.action"
          ></dashboard-tool>
        </div>
      </grid-element>
      <!-- current panel -->
      <grid-element
        :elevation="false"
        v-if="user"
        rowStart="3"
        rowEnd="4"
        colStart="2"
        colEnd="3"
        backgroundColor="transparent"
        :style="mobile ? 'flex-grow: 1' : ''"
      >
        <div
          :is="`dashboard-${currentPanel.tag}`"
          class="h-100"
          @setPanel="setPanel"
        />
      </grid-element>
    </grid>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import DataCard from "../components/DataCard.vue";
import DashboardButtonPanel from "../components/dashboard/DashboardButtonPanel.vue";
import DashboardTool from "../components/dashboard/DashboardTool.vue";
import DashboardHelp from "../components/dashboard/panels/DashboardHelp.vue";
import DashboardStudents from "../components/dashboard/panels/DashboardStudents.vue";
import DashboardLectionary from "../components/dashboard/panels/DashboardLectionary.vue";
import DashboardNotifications from "../components/dashboard/panels/DashboardNotifications.vue";
import DashboardTutorial from "../components/dashboard/panels/DashboardTutorial.vue";
import DashboardResources from "../components/dashboard/panels/DashboardResources.vue";

import DashboardPlanning from "../components/dashboard/panels/DashboardPlanning.vue";
import DashboardProgress from "../components/dashboard/panels/DashboardProgress.vue";
import DashboardPanel from "../components/dashboard/panels/DashboardPanel.vue";
import DashboardAdminProgram from "../components/dashboard/panels/DashboardAdminProgram.vue";
import DashboardAdminFeatures from "../components/dashboard/panels/DashboardAdminFeatures.vue";
import DashboardAdminProgramTable from "../components/dashboard/panels/DashboardAdminProgramTable.vue";
import DashboardAdminFlow from "../components/dashboard/panels/DashboardAdminFlow.vue";
import DashboardAdminProgress from "../components/dashboard/panels/DashboardAdminProgress.vue";

import DashboardSchools from "../components/dashboard/panels/DashboardSchools.vue";
import DashboardProfile from "../components/dashboard/panels/DashboardProfile.vue";
import DashboardAdminSupport from "../components/dashboard/panels/DashboardAdminSupport.vue";

export default {
  name: "Dashboard",
  components: {
    DataCard,
    DashboardTool,
    DashboardAdminProgramTable,
    DashboardButtonPanel,
    DashboardHelp,
    DashboardPanel,
    DashboardTutorial,
    DashboardPlanning,
    DashboardProgress,
    DashboardNotifications,
    DashboardLectionary,
    DashboardStudents,
    DashboardAdminProgram,
    DashboardAdminFeatures,
    DashboardAdminFlow,
    DashboardAdminProgress,
    DashboardSchools,
    DashboardAdminSupport,
    DashboardProfile,
    DashboardResources,
  },
  mounted() {
    if (!this.user || !this.loggedIn) {
      this.logout();
    }

    // todo: delete
    /*if (
      this.user.type == "student" &&
      process.env.VUE_APP_ENVIRONMENT == "PROD"
    ) {
      this.$router.push({ name: "form" });
    }*/

    if (
      this.lang == "es-CO" &&
      this.program == null &&
      this.user.type != "admin"
    ) {
      this.$router.push({ name: "form" });
    }
    this.setTools();
    this.track({ event: "dashboard-load", data: {} });
    // get email and cellphone
    if (this.user.type == "student") {
      // get class
      this.getForm()
        .then((response) => {
          if ("has_dfm" in response.data && !response.data.has_dfm) {
            this.$router.push({ name: "form" });
          }
          let jsonResponse = JSON.parse(response.data.answers);
          // if user has cellphone, we set it so it doesn't appear.
          if (
            "cellphone" in jsonResponse &&
            jsonResponse["cellphone"].length > 0
          ) {
            this.cellphone = jsonResponse["cellphone"];
            this.sentCellphone = jsonResponse["cellphone"];
          }
          if ("email" in jsonResponse && jsonResponse["email"].length > 0) {
            this.email = jsonResponse["email"];
          }
          this.formLoaded = true;
        })
        .catch(() => {
          this.formLoaded = true;
        });
    }

    if (this.user.type == "admin") {
      this.currentPanel = { name: "Planificación", tag: "planning" };
    }

    // get tutorials
    this.getTutorials().then(() => {
      if (
        this.user.last_login === null &&
        !this.tutorialDialogSeen &&
        !this.tutorialDialog
      ) {
        this.tutorialData = { dialog: true, dialogSeen: true };
      } else {
        this.tutorialData = { dialog: true, dialogSeen: false };
      }
      if (
        this.user.type == "student" &&
        !this.user.has_baseline &&
        this.lang == "es-CL"
      ) {
        this.baselineDialog = true;
      } else {
        this.dashboardTutorialDialog = this.tutorialData.dialogSeen;

        this.setTutorialDialog(this.tutorialData);
      }
    });

    // get program
    this.getProgram({}).then(() => {
      if (this.user.type == "admin") {
        let id = this.programs[0].id;
        this.selectProgram(id);
        this.currentProgram = id;
      }
      if (this.user.type == "principal") {
        this.currentSchool = this.schools.filter((s) => s.rbd == this.rbd)[0];
      }
      if (this.user.type == "student") {
        this.getUserIdentifier({});
        this.getRelatedVariables({});
        this.getCompleted({});
        this.getAnswers();
      } else {
        this.getStudents({ params: { _class__has_dfm: 1 } }).then(() => {
          this.getClasses({ rbd: this.rbd }).then(() => {
            this.getClassStudents();
          });
        });
      }
    });
  },
  data() {
    return {
      // showTools: false,
      tools: [],
      currentSchool: null,
      formLoaded: false,
      email: "",
      cellphone: "",
      shownProgress: {},
      sentCellphone: "",
      radio: true,
      isParticipant: null,
      tutorialData: new Object(),
      baselineDialog: false,
      currentProgram: null,
      showAreYouSure: false,
      showPanel: false,
      minimized: false,
      displayClasses: false,
      sequence: [],
      dashboardTutorialDialog: false,
      currentPanel: this.$t("dashboard.current-panel"),
    };
  },
  watch: {
    program() {
      this.setTools();
    },
  },
  methods: {
    setTools() {
      this.tools = [
        {
          icon: "mdi-apps",
          name: "app",
          title: this.$t("dashboard.tools.app.title"),
          subtitle: this.$t("dashboard.tools.app.subtitle"),
          roles: ["principal", "teacher", "student", "admin", "manager"],
          options: this.sortedPhases
            ? this.sortedPhases.map((x) => {
                return { text: `Etapa ${x.number + 1}`, id: x.id };
              })
            : [],
          action: this.goToPhase,
        },
        /*{
          icon: "mdi-face-man",
          name: "edit",
          title: this.$t("dashboard.tools.edit.title"),
          subtitle: this.$t("dashboard.tools.edit.subtitle"),
          roles: ["principal", "teacher", "admin", "manager"],
          options: null,
          action: () => {
            this.setPanel({
              name: "Estudiantes",
              tag: "students",
              icon: "mdi-account-school",
              needsClasses: true,
            });
          },
        },*/
        {
          icon: "mdi-cached",
          name: "select-school",
          title: this.$t("dashboard.tools.select-school.title"),
          subtitle: this.$t("dashboard.tools.select-school.subtitle"),
          roles: ["principal", "teacher", "admin", "manager"],
          options: this.schools
            ? this.schools.map((x) => {
                return { id: x.rbd, text: x.name };
              })
            : [],
          action: this.setSchool,
        },
        {
          icon: "mdi-cached",
          name: "select-class",
          title: this.$t("dashboard.tools.select-class.title"),
          subtitle: this.$t("dashboard.tools.select-class.subtitle"),
          roles: ["principal", "teacher", "admin", "manager"],
          options: this.classes
            ? this.classes.map((x) => {
                return { id: x.id, text: x.name };
              })
            : [],
          action: this.selectClass,
        },
        {
          icon: "mdi-bell",
          name: "notifications",
          title: this.$t("dashboard.tools.notifications.title"),
          subtitle: this.$t("dashboard.tools.notifications.subtitle", {
            unread_notifications: this.messages.filter((x) => !x.seen).length,
          }),
          roles: ["principal", "teacher", "student", "admin", "manager"],
          options: this.messages,
          action: () => {},
        },
        {
          icon: "mdi-chart-line",
          name: "simulations",
          title: this.$t("dashboard.tools.simulations.title"),
          subtitle: this.$t("dashboard.tools.simulations.subtitle"),
          roles: ["student"],
          options: null,
          action: () => {
            this.$router.push({ name: "simulator" });
          },
        },
      ];
    },
    setSchool(rbd) {
      this.setBlockingDialogText({ blockingDialogText: "Cargando sede..." });
      this.enableBlockingDialog();
      this.setRbd({ rbd });
      this.getClasses({ rbd: this.rbd }).then(() => {
        if (this.user.type == "principal") {
          // hola
          this.getStudents({
            params: { _class__school__rbd: rbd, _class__has_dfm: 1 },
          })
            .then(() => {
              this.disableBlockingDialog();
              this.setTools();
            })
            .catch(() => {
              this.disableBlockingDialog();
            });
        } else if (this.user.type == "teacher") {
          this.getClassStudents()
            .then(() => {
              this.disableBlockingDialog();
              this.setTools();
            })
            .catch(() => {
              this.disableBlockingDialog();
            });
        } else {
          this.disableBlockingDialog();
        }
      });
    },
    setSentCellphone(val) {
      this.sentCellphone = val;
    },
    validEmail(email) {
      if (email == "") {
        return true;
      }
      if (email === null) {
        return false;
      }

      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (email.match(validRegex)) {
        return true;
      } else {
        return false;
      }
    },
    clickAreYouSure(areYouSure) {
      if (areYouSure) {
        // set baseline to true, hide consent, show dialog
        this.patchUser({ body: { has_baseline: true } }).then(() => {
          this.showAreYouSure = false;
        });
        this.dashboardTutorialDialog = this.tutorialData.dialogSeen;
        this.setTutorialDialog(this.tutorialData);
      } else {
        // set has baseline to false, logout
        // patch baseline, logout
        this.patchUser({ body: { has_baseline: false } }).then(() => {
          this.showAreYouSure = false;
          this.$router.push({ name: "logout" });
        });
      }
    },
    closeBaseLineDialog() {
      this.baselineDialog = false;
      if (!this.isParticipant) {
        // show are you sure
        this.showAreYouSure = true;
      } else {
        // patch baseline to true
        this.patchUser({ body: { has_baseline: true } }).then(() => {
          this.showAreYouSure = false;
        });
        this.dashboardTutorialDialog = this.tutorialData.dialogSeen;
        this.setTutorialDialog(this.tutorialData);
      }
    },
    getInitials(user) {
      return `${user.first_name.charAt(0).toUpperCase()}${user.last_name
        .charAt(0)
        .toUpperCase()}`;
    },
    selectClass(classId) {
      this.setBlockingDialogText({
        blockingDialogText: "Cargando estudiantes...",
      });
      this.enableBlockingDialog();
      this.track({ event: "dashboard-select-class", data: { class: classId } });
      this.setCurrentClass({ classId: classId }).then(() => {
        this.getClassStudents().then(() => {
          this.disableBlockingDialog();
          this.displayClasses = false;
        });
      });
    },
    tutorialWidth() {
      if (this.$refs.tutorialVideo) {
        return this.$refs.tutorialVideo.clientWidth;
      }
    },
    tutorialHeight() {
      if (this.$refs.tutorialVideo) {
        return this.$refs.tutorialVideo.clientHeight;
      }
      return 0;
    },
    goToPhase(phaseId) {
      this.track({ event: "dashboard-go-to-phase", data: { phase: phaseId } });
      let phase = this.phases.find((x) => {
        return x.id == phaseId;
      });
      if (phase.available) {
        this.setCurrentPhase({ phaseId }).then(() => {
          this.$router.push({ name: "phase" });
        });
      } else {
        this.setUnavailableDialog({ dialog: true });
      }
    },
    selectProgram(programId) {
      this.track({
        event: "dashboard-select-program",
        data: { program: programId },
      });
      this.setProgram({
        program: this.programs.filter((p) => p.id == programId)[0],
      });
    },
    showClasses() {
      this.displayClasses = true;
    },
    ...mapActions({
      enableBlockingDialog: "authentication/enableBlockingDialog",
      disableBlockingDialog: "authentication/disableBlockingDialog",
      setBlockingDialogText: "authentication/setBlockingDialogText",
      setShowDataCard: "authentication/setShowDataCard",
      setRbd: "school/setRbd",
      getUserIdentifier: "authentication/getUserIdentifier",
      getRelatedVariables: "authentication/getRelatedVariables",
      track: "authentication/track",
      patchUser: "authentication/patchUser",
      setUnavailableDialog: "authentication/setUnavailableDialog",
      setTutorialDialog: "authentication/setTutorialDialog",
      getTutorials: "authentication/getTutorials",
      logout: "authentication/logout",
      getAnswers: "answer/getAnswers",
      getCompleted: "answer/getCompleted",
      getClassStudents: "_class/getClassStudents",
      getProgram: "program/getProgram",
      getStudents: "student/getStudents",
      getClasses: "_class/getClasses",
      setCurrentClass: "_class/setCurrentClass",
      setCurrentPhase: "program/setCurrentPhase",
      setProgram: "program/setProgram",
      getForm: "authentication/getForm",
      patchForm: "authentication/patchForm",
      get: "admin/silentGet",
    }),
    goToPreviousPanel() {
      let panel = this.sequence.pop();
      this.track({ event: "dashboard-go-to-previous-panel", data: { panel } });
      this.currentPanel = panel;
    },
    setPanel(panel) {
      if (this.currentPanel.tag != panel.tag) {
        this.track({
          event: "dashboard-change-panel",
          data: { panel: panel.tag },
        });
        this.sequence.push(this.currentPanel);
      }
      if (this.mobile) {
        this.showPanel = false;
      }
      this.currentPanel = panel;
    },

    setMinimized(minimized) {
      this.track({ event: "dashboard-toggle-minimize-panel", data: {} });
      this.minimized = minimized;
    },
  },

  computed: {
    showDataCardComputed() {
      return (
        (this.lang == "es-CL" || this.lang == "es-CO") &&
        this.user.type == "student" &&
        this.formLoaded &&
        this.sentCellphone == "" &&
        this.showDataCard &&
        !this.baselineDialog
      );
    },
    popUpDisabled() {
      return this.cellphone.length < 6 || !this.validEmail(this.email);
    },
    computedTools() {
      if (!this.user) {
        return [];
      }
      let tools = this.tools.filter((x) =>
        this.$includes(x.roles, this.user.type)
      );
      if (this.lang != "es-CL") {
        tools = tools.filter((x) => x.name != "simulations");
      }
      return tools;
    },
    sortedPhases() {
      return [...this.phases].sort(({ number: a }, { number: b }) => a - b);
    },
    ...mapGetters({
      blockingDialog: "authentication/blockingDialog",
      blockingDialogText: "authentication/blockingDialogText",
      schools: "school/schools",
      rbd: "school/rbd",
      showDataCard: "authentication/showDataCard",
      lang: "layout/lang",
      program: "program/program",
      messages: "authentication/messages",
      unavailableDialog: "authentication/unavailableDialog",
      tutorialDialogSeen: "authentication/tutorialDialog",
      tutorialDialog: "authentication/tutorialDialog",
      currentTutorial: "authentication/currentTutorial",
      tutorials: "authentication/tutorials",
      loggedIn: "authentication/loggedIn",
      students: "student/students",
      classes: "_class/classes",
      currentClass: "_class/currentClass",
      mobile: "layout/isMobile",
      currentPhase: "program/phase",
      phases: "program/phases",
      user: "authentication/user",
      programs: "program/programs",
    }),
    computedShowPanel() {
      return !this.mobile || this.showPanel;
    },
    showTools() {
      return true; // this.classes && this.classes.length > 0;
    },
  },
};
</script>
<style>
.v-carousel__controls {
  background: var(bg--primary) !important;
}
.v-carousel__controls__item {
}
</style>
