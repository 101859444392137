<template>
  <div style="width:100vw;height:100vh;min-height:100vh;background: #191f4b;">
    <!-- start error modal -->
    <b-modal v-model="showErrorModal" centered hide-footer hide-header rounded>
      <div class="m-2">
        <img
          src="@/assets/Home/close.svg"
          :class="mobile ? 'absolute-top-left m-2' : 'absolute-top-right m-2'"
          style="z-index: 101;"
          @click="showErrorModal = false"
        />
        <div v-html="errors" class="contact-form-form"></div>
      </div>
    </b-modal>
    <!-- end error modal -->
    <!-- start success modal -->
    <b-modal
      v-model="modal"
      centered
      hide-footer
      hide-header
      rounded
      @hide="goBackToMain()"
    >
      <div class="text-center">
        <img src="@/assets/ApplicationForm/CheckMarkBig.svg" />
        <p class="contact-form-modal-title">
          {{ $t("contact-form.end-form.success") }}
        </p>
        <p class="contact-form-modal-text">{{ modalText }}</p>
        <p>
          {{ $t("contact-form.end-form.question") }}
        </p>
        <v-checkbox
          class="contact-form-modal-text"
          :label="$t('contact-form.end-form.labels.socials')"
          v-model="knowDFMsocials"
        ></v-checkbox>
        <v-checkbox
          class="contact-form-modal-text"
          :label="$t('contact-form.end-form.labels.email')"
          v-model="knowDFMemail"
        ></v-checkbox>
        <v-checkbox
          class="contact-form-modal-text"
          :label="$t('contact-form.end-form.labels.contact')"
          v-model="knowDFMcontact"
        ></v-checkbox>
        <v-checkbox
          class="contact-form-modal-text"
          :label="$t('contact-form.end-form.labels.program')"
          v-model="knowDFMprogram"
        ></v-checkbox>
        <v-checkbox
          class="contact-form-modal-text"
          label="A través de una llamada telefónica"
          v-model="knowDFMcall"
        ></v-checkbox>
        <p
          class="contact-form-modal-text clickable"
          @click="goBackToMain()"
          style="text-decoration:underline"
        >
          {{ $t("contact-form.end-form.send") }}
        </p>
      </div>
    </b-modal>
    <!-- end success modal -->
    <!-- start navbar -->
    <div
      class="contact-form-navbar"
      style="position:fixed;background-color:red;top:0px;width:100%"
    >
      <div
        style="background:white;width: 100%;background: white;text-align: left;z-index:101;"
        class="text-left"
      >
        <img
          src="@/assets/Home/DFM.svg"
          class="m-3 clickable"
          @click="goTo('/', false)"
          style="height:17px"
        />
      </div>
    </div>
    <!-- end  navbar -->
    <!-- start content -->
    <!-- start main screen -->
    <div v-if="currentScreen == 'main'">
      <Welcome @setScreen="setScreen" @goTo="goTo" />
    </div>
    <!-- end main screen -->
    <!-- start form screen -->
    <responsive-div
      v-else-if="currentScreen == 'form'"
      mobileClass="contact-form-subscreen-container-mobile"
      desktopClass="contact-form-subscreen-container"
    >
      <!-- start subscreens -->
      <div class="contact-form-subscreen-subcontainer">
        <!-- start progress bar -->
        <responsive-div
          class="mb-4"
          mobileClass="mt-10"
          v-if="currentSubScreen < 3"
        >
          <StepProgressBar
            :data="[1, 2, 3]"
            primaryColor="white"
            secondaryColor="#5669FB"
            format="h"
            :current="currentSubScreen"
          />
        </responsive-div>
        <!-- end progress bar -->
        <!-- title -->
        <div class="contact-form-subscreen-title mb-4">
          {{ $t("contact-form.section-titles")[currentSubScreen] }}
        </div>
        <!-- end title -->
        <div
          style="margin-bottom:auto;--scrollbar-color:#5669FB;--scrollbar-bg-color:#505aaa"
          :style="
            mobile && currentSubScreen != 0
              ? ''
              : ';height:100%;max-height:100%;overflow: auto;'
          "
          class="mb-4"
        >
          <!-- program information -->
          <ProgramInformation
            v-if="currentSubScreen == 0"
            :termsAndConditions="termsAndConditions"
          />
          <!-- form  -->
          <Form
            v-else-if="currentSubScreen == 1"
            :external="external"
            @updateVariable="updateVariable"
          />
          <!-- Consent -->
          <Consent
            v-else-if="currentSubScreen == 2"
            :formName="formName"
            :formSchool="formSchool"
            :formPosition="formPosition"
            :externalConsent="consent"
            @updateVariable="updateVariable"
          />
          <QuitReasons
            v-else
            :external="external"
            @updateVariable="updateVariable"
          />
        </div>
        <!-- start buttons -->
        <responsive-div
          style="display:flex;flex-direction:row;margin-left:20%;margin-right:20%"
          mobileStyle="margin-left:0;margin-right:0"
          :class="mobile && currentSubScreen > 0 ? 'pb-5' : ''"
        >
          <button
            style="margin-right:auto;--background-color:#CCD2FE;--color:#2B357E;--font-size: 13.6646px"
            class="contact-form-button"
            @click="previousSubScreen()"
          >
            {{ $t("contact-form.back") }}
          </button>
          <button
            class="contact-form-button"
            :class="disabled ? 'contact-form-button-disabled' : ''"
            :style="isSendingContact ? 'cursor:wait' : ''"
            @click="disabled ? () => {} : nextSubScreen()"
          >
            {{
              currentSubScreen >= 2
                ? isSendingContact
                  ? $t("contact-form.sending")
                  : $t("contact-form.send")
                : $t("contact-form.continue")
            }}
          </button>
        </responsive-div>
        <!-- end buttons -->
      </div>
    </responsive-div>
    <!-- end form screen -->
  </div>
</template>
<script>
import mixpanel from "mixpanel-browser";
import StepProgressBar from "@/components/StepProgressBar.vue";
import { mapGetters } from "vuex";
import Welcome from "@/components/ApplicationForm/Welcome.vue";
import ProgramInformation from "../components/ApplicationForm/ProgramInformation.vue";
import Form from "@/components/ApplicationForm/Form.vue";
import Consent from "@/components/ApplicationForm/Consent.vue";
import QuitReasons from "@/components/ApplicationForm/QuitReasons.vue";
export default {
  components: {
    StepProgressBar,
    Welcome,
    ProgramInformation,
    Form,
    Consent,
    QuitReasons,
  },
  name: "ApplicationForm",
  data() {
    return {
      knowDFMsocials: false,
      knowDFMemail: false,
      knowDFMcontact: false,
      knowDFMprogram: false,
      knowDFMcall: false,
      isSendingContact: false,
      showErrorModal: false,
      otherText: "",
      quitReasons: [],
      interestedEmail: "",
      modalText: this.$t("contact-form.end-form.modal-text-default"),
      modal: false,
      valid: true,
      inCharge: false,
      errors: "",
      inChargeName: "",
      inChargeEmail: "",
      inChargePosition: "",
      inChargePhone: "",
      formName: "",
      formEmail: "",
      formPhone: "",
      formSchool: "",
      formSchoolCode: "",
      external: {},
      validFormEmail: true,
      validFormPhone: true,
      consent: null,
      formPosition: null,
      currentScreen: "main",
      currentSubScreen: 0,
      subScreen1Disabled: true,
    };
  },
  computed: {
    termsAndConditions() {
      return this.$t("contact-form.terms-and-conditions");
    },

    ...mapGetters({
      mobile: "layout/isMobile",
      lang: "layout/lang",
      country: "layout/country",
    }),
    disabled() {
      if (this.currentSubScreen == 3) {
        if (this.quitReasons.length === 0) {
          return true;
        }
        return false;
      }
      if (this.currentSubScreen == 2) {
        if (this.consent === null) {
          return true;
        }
      }
      if (this.currentSubScreen == 1) {
        if (
          this.formPosition == null ||
          this.formEmail == "" ||
          this.formName == "" ||
          this.formPhone == "" ||
          this.formSchoolCode == ""
        ) {
          return true;
        }
        if (this.inCharge) {
          if (
            this.inChargePosition == "" ||
            this.inChargeEmail == "" ||
            this.inChargeName == "" ||
            this.inChargePhone == ""
          ) {
            return true;
          }
          return false;
        }
        return false;
      }
      return false;
    },
  },
  mounted() {
    mixpanel.track(
      "contact_form_load",
      { env: process.env.VUE_APP_ENVIRONMENT },
      () => {}
    );
  },
  methods: {
    goBackToMain() {
      this.$store.dispatch("authentication/sendContact", {
        position: this.formPosition,
        email: this.formEmail,
        contact_type: "know-dfm",
        details: `socials: ${this.knowDFMsocials},email: ${this.knowDFMemail}, contact: ${this.knowDFMcontact}, program: ${this.knowDFMprogram}, call: ${this.knowDFMcall}`,
        country: this.country,
      });
      this.goTo("/", false);
    },
    sendContact() {
      if (!this.isSendingContact) {
        this.isSendingContact = true;
        this.$store
          .dispatch("authentication/sendContact", {
            position: this.formPosition,
            school_name: this.formSchool,
            school_code: this.formSchoolCode,
            email: this.formEmail,
            name: this.formName,
            phone: this.formPhone,
            contact_type: "enrollment",
            consent: this.consent,
            interested_email: this.interestedEmail,
            quit_reasons: this.quitReasons.join(),
            designated_position: this.inChargePosition,
            designated_email: this.inChargeEmail,
            designated_phone: this.inChargePhone,
            designated_name: this.inChargeName,
            other_quit_reason: this.otherText,
            country: this.country,
          })
          .then(() => {
            mixpanel.track(
              "contact_form_send_data_success",
              { env: process.env.VUE_APP_ENVIRONMENT },
              () => {}
            );
          })
          .catch(() => {
            mixpanel.track(
              "contact_form_send_data_error",
              {
                env: process.env.VUE_APP_ENVIRONMENT,
                email: this.formEmail,
                school_name: this.formSchool,
                school_code: this.formSchoolCode,
                name: this.formName,
              },
              () => {}
            );
          });
        setTimeout(() => {}, 500);
      }
      this.modal = true;
      this.isSendingContact = false;
    },
    validEmail(email) {
      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (email.match(validRegex)) {
        return true;
      } else {
        return false;
      }
    },
    validPhone(phone) {
      var validRegex = /^[\d +]*$/;
      if (phone.match(validRegex)) {
        if (phone.length < 5) {
          return false;
        }
        return true;
      } else {
        return false;
      }
    },
    scrollToErrors() {
      // var element = this.$refs["errors"].$el;
      var objDiv = document.getElementById("your_div");
      objDiv.scrollTop = objDiv.scrollHeight;
    },
    getErrors() {
      var errors = this.$t("contact-form.contact-form.errors.text");
      if (!this.validEmail(this.formEmail)) {
        errors += this.$t("contact-form.contact-form.errors.email", {
          form_position: this.formPosition,
        });
      }
      if (!this.validPhone(this.formPhone)) {
        errors += this.$t("contact-form.contact-form.errors.phone", {
          form_position: this.formPosition,
        });
      }
      if (this.inCharge && !this.validEmail(this.inChargeEmail)) {
        errors += this.$t("contact-form.contact-form.errors.in-charge-email");
      }
      if (this.inCharge && !this.validPhone(this.inChargePhone)) {
        errors += this.$t("contact-form.contact-form.errors.in-charge-phone");
      }
      errors += "</ul></div>";
      this.errors = errors;
      this.showErrorModal = true;
    },
    nextSubScreen() {
      if (this.currentSubScreen <= 1) {
        if (this.currentSubScreen != 1) {
          this.currentSubScreen += 1;
          mixpanel.track(
            "contact_form_next_stage",
            {
              env: process.env.VUE_APP_ENVIRONMENT,
              stage: this.currentSubScreen,
            },
            () => {}
          );
        } else {
          // validate input data
          if (
            this.validEmail(this.formEmail) &&
            this.validPhone(this.formPhone)
          ) {
            if (!this.inCharge) {
              this.currentSubScreen += 1;
              mixpanel.track(
                "contact_form_next_stage",
                {
                  env: process.env.VUE_APP_ENVIRONMENT,
                  stage: this.currentSubScreen,
                },
                () => {}
              );
              this.valid = true;
              this.errors = "";
              this.showErrorModal = false;
            } else if (
              this.validEmail(this.inChargeEmail) &&
              this.validPhone(this.formPhone)
            ) {
              this.currentSubScreen += 1;
              mixpanel.track(
                "contact_form_next_stage",
                {
                  env: process.env.VUE_APP_ENVIRONMENT,
                  stage: this.currentSubScreen,
                },
                () => {}
              );
              this.valid = true;
              this.errors = "";
              this.showErrorModal = false;
            } else {
              this.valid = false;
              this.getErrors();
            }
          } else {
            this.valid = false;
            this.getErrors();
          }
        }
      } else if (this.currentSubScreen === 2) {
        if (this.consent) {
          this.sendContact();
        } else {
          this.currentSubScreen += 1;
          mixpanel.track(
            "contact_form_next_stage",
            {
              env: process.env.VUE_APP_ENVIRONMENT,
              stage: this.currentSubScreen,
            },
            () => {}
          );
        }
      } else if (this.currentSubScreen === 3) {
        if (this.interestedEmail != "") {
          if (!this.validEmail(this.interestedEmail)) {
            this.errors = this.$t(
              "contact-form.contact-form.errors.interested-text"
            );
            this.showErrorModal = true;
            return;
          } else {
            this.modalText = this.$t(
              "contact-form.end-form.modal-text-interested"
            );
          }
        } else {
          this.modalText = this.$t("contact-form.end-form.modal-text-thanks");
        }
        this.sendContact();
      }
    },
    previousSubScreen() {
      if (this.currentSubScreen > 0) {
        this.currentSubScreen -= 1;
      } else {
        this.currentSubScreen = 0;
        this.currentScreen = "main";
      }
    },
    setScreen(screen) {
      this.currentScreen = screen;
    },
    goTo(route, otherSite) {
      if (otherSite) {
        window.open(route, "_blank");
      } else {
        this.$router.push(route);
      }
    },
    updateVariable(variable, value) {
      this[variable] = value;
      this.external[variable] = value;
    },
  },
};
</script>
<style>
html {
  background: #f6f8ff;
  height: 100%;
  margin: 0;
}
.contact-form-subscreen-subcontainer {
  background: #f6f8ff;
  height: 100%;
  min-height: 100%;
  border-radius: 4px;
  padding: 24px 36px 24px 36px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  text-align: left;
}
.contact-form-modal-title {
  font-size: 26px;
  line-height: 30px;
  color: #4a4b4f;
}
.contact-form-modal-text {
  font-size: 14px;
  line-height: 16px;
  color: #4a4b4f;
}
.contact-form-form {
  font-size: 12px;
  line-height: 14px;
}
.contact-form-subscreen-title {
  font-family: "Karla";
  font-style: normal;
  font-weight: 800;
  font-size: 26px;
  line-height: 30px;
  color: #4a4b4f;
}
.contact-form-button {
  font-family: "Karla";
  font-size: var(--font-size, 20px);
  line-height: 28px;
  font-family: "Karla";
  font-style: normal;
  font-weight: 500;
  padding: 14px 24px 14px 24px;
  color: var(--color, white);
  border: none;
  font: inherit;
  border-radius: 100px;
  background: var(--background-color, #5669fb);
  cursor: var(--cursor, pointer);
}
.contact-form-button-disabled {
  cursor: default !important;
  background: #eff1f6;
  color: #94979e;
}
.contact-form-input {
  border: 1px solid #bbc3fd;
  border-radius: 4px;
  font-size: 12px;
  line-height: 14px;
}
.contact-form-subscreen-container {
  background: #191f4b;
  height: 100%;
}
.contact-form-subscreen-container-mobile {
  height: 100vh;
  min-height: 100vh;
  background: #f6f8ff;
}
label.custom-control-label {
  transform: translate(3px, -3px);
}
</style>
