<template>
  <div class="w-100">
    <v-data-table
      :headers="headers"
      :items="data"
      :search="search"
      :loading="loading"
      class="elevation-1 w-100 mb-2"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <div class="mr-auto" v-if="!withPanel">{{ name }}</div>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <!-- filtrar -->
          <v-select
            :items="keys"
            style="width:200px;max-width:200px"
            class="me-1"
            v-model="searchableField"
            label="Columna a filtrar"
            solo
            single-line
            hide-details
          ></v-select>
          <!-- filtrar -->
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="`Buscar por ${searchableField}`"
            solo
            single-line
            hide-details
          ></v-text-field>
          <common-button
            class="ms-2 mr-2"
            text="Descargar"
            prependIcon="mdi-download"
            @click="downloadAll()"
          />
          <v-dialog v-model="dialog" max-width="1000px" v-if="editable">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                Agregar ítem
              </v-btn>
            </template>
            <v-form @submit.prevent="save" lazy-validation>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <!-- todo: change available options -->
                      <div
                        v-for="key in keys.filter((k) => {
                          return k != 'id' && k != 'n_students';
                        })"
                        :key="key"
                      >
                        <v-text-field
                          v-if="!(key in keyOptions)"
                          :label="key"
                          v-model="editedItem[key]"
                          hide-details
                        >
                        </v-text-field>
                        <v-select
                          v-else
                          v-model="editedItem[key]"
                          :items="keyOptions[key]"
                        ></v-select>
                      </div>
                    </v-row>
                  </v-container>
                </v-card-text>

                <div>
                  <!-- upload through json -->
                  <v-btn
                    color="primary"
                    v-if="editedIndex === -1"
                    rounded
                    dark
                    :loading="isSelecting"
                    @click="handleFileImport"
                  >
                    Cargar en formato JSON
                  </v-btn>

                  <input
                    ref="uploader"
                    class="d-none"
                    type="file"
                    @change="onFileChanged"
                  />
                  <!-- end upload through json -->
                </div>
                <v-card-actions>
                  <v-text-field
                    type="password"
                    class="ms-5"
                    v-if="requiresPassword"
                    v-model="password"
                    placeholder="password:"
                  ></v-text-field>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">
                    Cancelar
                  </v-btn>

                  <v-btn
                    :disabled="requiresPassword && !correctPassword"
                    color="blue darken-1"
                    text
                    type="submit"
                  >
                    Guardar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="600px">
            <v-card>
              <v-card-title class="text-h5"
                >¿Seguro que desea eliminar este elemento?</v-card-title
              >
              <v-card-actions>
                <v-text-field
                  type="password"
                  v-if="requiresPassword"
                  v-model="password"
                  placeholder="password:"
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-btn color="bg--neutral" text @click="closeDelete"
                  >Cancelar</v-btn
                >
                <v-btn
                  :disabled="requiresPassword && !correctPassword"
                  class="bg--error"
                  text
                  @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template #[`item.acciones`]="{ item }">
        <div class="d-flex">
          <slot name="externalactions" :item="item"></slot>
          <custom-icon
            tooltip-text="editar"
            icon="pencil"
            @click="editItem(item)"
          ></custom-icon>
          <custom-icon
            tooltip-text="eliminar"
            icon="delete"
            @click="deleteItem(item)"
          ></custom-icon>
          <custom-icon
            tooltip-text="descargar en formato json"
            icon="download"
            @click="downloadItem(item)"
          ></custom-icon>
        </div>
      </template>
      <template v-slot:no-data>
        No hay datos disponibles
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "EditTableSingle",
  mounted() {
    this.searchableField = this.externalSearchableField;
  },
  props: {
    requiresPassword: {
      // todo: move this to backend
      default: false,
      type: Boolean,
      required: false,
    },
    ignoredKeys: {
      required: false,
      default: () => [],
      type: Array,
    },
    keyOptions: {
      required: false,
      default: new Object(),
      type: Object,
    },
    withPanel: {
      required: false,
      default: true,
      type: Boolean,
    },
    editable: {
      required: false,
      default: true,
      type: Boolean,
    },

    preLoadedData: {
      required: false,
      default: null,
    },
    object: {
      required: false,
      type: String,
      default: "object",
    },
    externalSearchableField: {
      required: false,
      type: String,
      default: "",
    },
    postBody: {
      required: false,
      default: new Object(),
      type: Object,
    },
    label: {
      required: false,
      type: String,
      default: "Buscar",
    },
    endpoint: {
      required: true,
      type: String,
    },
    getParams: {
      required: false,
      type: String,
      default: new Object(),
    },
    keys: {
      required: true,
      type: Array,
    },
    name: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      dialog: false,
      password: "",
      dialogDelete: false,
      searchableField: "",
      data: [],
      search: "",
      loading: true,
      editedIndex: -1,
      editedItem: new Object(),
      defaultItem: new Object(),
      isSelecting: false,
      selectedFile: null,
    };
  },
  computed: {
    correctPassword() {
      return this.hash(this.password) == 1601183010;
    },
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo ítem" : "Editar ítem";
    },
    headers() {
      let headers = this.keys.map((k) => {
        return {
          text: k,
          value: k,
          filterable: k == this.searchableField,
        };
      });
      if (this.editable) {
        headers.push({ text: "Acciones", value: "acciones" });
      }
      return headers;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
    this.defaultItem = this.getDefaultItem();
    this.editedItem = this.getDefaultItem();
  },

  methods: {
    ...mapActions({
      adminPost: "admin/post",
      adminGet: "admin/get",
      adminPatch: "admin/patch",
      adminDelete: "admin/delete",
    }),
    hash(string) {
      var hash = 0;
      let i;
      let ch;
      if (string.length == 0) return hash;
      for (i = 0; i < string.length; i++) {
        ch = string.charCodeAt(i);
        hash = (hash << 5) - hash + ch;
        hash = hash & hash;
      }
      return hash;
    },
    downloadAll() {
      var element = document.createElement("a");
      // test
      const items = this.data;
      const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
      const header = Object.keys(items[0]);
      const csv = [
        header.join(","), // header row first
        ...items.map((row) =>
          header
            .map((fieldName) => JSON.stringify(row[fieldName], replacer))
            .join(",")
        ),
      ].join("\r\n");
      // end test
      element.setAttribute("href", "data:text/csv;charset=utf-8," + csv);
      element.setAttribute("download", `${this.object}_all.csv`);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
    handleFileImport() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );
      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      this.selectedFile = e.target.files[0];
      var file = this.selectedFile;
      if (file) {
        var reader = new FileReader();
        reader.readAsText(file, "UTF-8");
        reader.onload = (evt) => {
          let body = JSON.parse(evt.target.result);
          let finalBody = { ...body, ...this.postBody };
          this.adminPost({
            endpoint: this.endpoint,
            body: finalBody,
          })
            .then((response) => {
              this.data.push(response.data);
              this.close();
            })
            .catch(() => {
              this.selectedFile = null;
              this.$refs.uploader.value = "";
            });
        };
      }
    },
    downloadItem(item) {
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/json;charset=utf-8," +
          encodeURIComponent(JSON.stringify(item))
      );
      element.setAttribute("download", `${this.object}_${item.id}.json`);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
    getDefaultItem() {
      let object = Object();
      this.keys.forEach((k) => {
        object[k] = "";
      });
      return object;
    },
    initialize() {
      if (this.preLoadedData) {
        this.loading = false;
        this.data = this.preLoadedData;
      } else {
        this.adminGet({ endpoint: this.endpoint, params: this.getParams }).then(
          (response) => {
            this.loading = false;
            this.data = response.data;
          }
        );
      }
    },

    editItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      let itemToDelete = this.data[this.editedIndex];
      this.adminDelete({ endpoint: this.endpoint, id: itemToDelete.id }).then(
        () => {
          this.password = "";
          this.data.splice(this.editedIndex, 1);
          this.closeDelete();
        }
      );
    },

    close() {
      this.$refs.uploader.value = "";

      this.selectedFile = null;
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.dialog = false;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    fixBody(body) {
      this.ignoredKeys.forEach((k) => {
        if (k in body) {
          delete body[k];
        }
      });
      return body;
    },
    save() {
      let initialEditedIndex = this.editedIndex;
      let initialEditedItem = this.editedItem;
      if (this.editedIndex > -1) {
        let finalEditedItem = this.fixBody(this.editedItem);
        this.adminPatch({
          endpoint: this.endpoint,
          body: finalEditedItem,
        }).then(() => {
          this.close();
          this.password = "";
          Object.assign(this.data[initialEditedIndex], initialEditedItem);
          this.$emit("update");
        });
      } else {
        let body = { ...this.editedItem, ...this.postBody };
        delete body.id;
        body = this.fixBody(body);
        this.adminPost({
          endpoint: this.endpoint,
          body,
        }).then((response) => {
          this.$emit("create");
          this.data.push(response.data);
          this.close();
        });
      }
    },
  },
};
</script>
