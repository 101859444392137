var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"relative",staticStyle:{"top":"45px","margin-bottom":"45px"}},[_c('v-row',{staticClass:" ml-0 white--text text-left",class:_vm.isMobile ? 'bg--primary' : 'title vh-100',staticStyle:{"display":"flex","align-items":"flex-end","padding":"5rem 5%"}},[_c('p',{class:_vm.isMobile ? 'w-100 text-title' : 'w-50 text-heading'},[_vm._v(" Somos un equipo multidisciplinario especializado en áreas de educación, información y tecnología ")])]),_c('v-row',{staticClass:"ml-0",staticStyle:{"padding":"7% 5%"}},_vm._l(([
				{
					image: 'pi1.png',
					name: 'Christopher Neilson',
					charge: 'CEO',
					site: 'https://christopherneilson.github.io/',
					text:
						'Christopher A. Neilson, es profesor de Economía y Asuntos Públicos en la Universidad de Yale y tiene un doctorado en Economía en la Universidad de Yale. El profesor Neilson dirige la investigación en la que se basan las innovaciones de ConsiliumBots. En particular su agenda de investigación se centra especialmente en cómo los conocimientos y las creencias de los estudiantes y sus familias afectan sus decisiones y cómo las intervenciones de política pública pueden generar cambios en los diferentes actores a nivel individual, modificando estas creencias o facilitando las elecciones y la participación.',
				},
				{
					image: 'pi2.png',
					name: 'Francisco Gallego',
					charge: 'Investigador principal',
					site: 'https://sites.google.com/view/franciscoagallego',
					text:
						'Profesor titular del Instituto de Economía de la Pontificia Universidad Católica de Chile (PUC) y Director Científico de J-PAL LAC. Sus áreas de interés son la economía del desarrollo, la economía política y la economía de la educación. Ha trabajado en diversas evaluaciones educativas centradas en el impacto de la información en las decisiones de los padres, incluyendo la evaluación de DFM Perú. En los últimos años, el profesor Gallego ha establecido una sólida colaboración con el Gobierno de Chile en diversos temas, como la educación y la prevención del abandono escolar.',
				},
				{
					image: 'pi3.png',
					name: 'Oswaldo Molina',
					charge: 'Investigador principal',
					site: 'https://sites.google.com/site/oswaldomolina/home',
					text:
						'Profesor asociado en el departamento de economía Universidad del Pacífico (Lima, Perú) e investigador asociado en el Centro de Estudios de la Universidad del Pacífico. Actualmente lidera la Red de Estudios para el Desarrollo (REDES), una iniciativa para difundir y aumentar el impacto de la investigación. Además, es editor asociado de Oxford Development Studies y columnista de opinión en el periódico El Comercio. Es doctor en Economía de la Universidad de Oxford, y se interesa por estudiar la economía del desarrollo.',
				} ]),function(piColumn,index){return _c('v-col',{key:index,staticClass:"text-left",attrs:{"cols":_vm.isMobile ? 12 : 4}},[_c('img',{attrs:{"src":require('../../assets/Home/Team/' + piColumn.image),"alt":"pi"}}),_c('h2',{staticClass:"text-title color--neutral-60-t"},[_vm._v(_vm._s(piColumn.name))]),_c('h2',{staticClass:"text-body color--neutral-60-t",staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(piColumn.charge)+" ")]),_c('h4',{staticClass:"text-body color--neutral-40-t"},[_c('a',{attrs:{"href":piColumn.site,"target":"_blank"}},[_vm._v("Sitio web")])]),_c('p',{staticClass:"text-label color--neutral-40-t"},[_vm._v(_vm._s(piColumn.text))])])}),1),_c('v-row',{staticClass:"ml-0",staticStyle:{"padding":"0% 5%"}},[_c('h2',{staticClass:"text-left color--neutral-60-t",class:_vm.isMobile ? 'text-title w-100' : 'text-heading w-75'},[_vm._v(" Adicionalmente, tenemos un equipo de profesionales altamente capacitados en el desarrollo e implementación de estrategias que combinan la información, la tecnología y el mundo de la educación. ")])]),_c('v-row',{staticClass:"text-left ml-0",staticStyle:{"padding":"3% 5%"}},_vm._l(([
				{
					image: 'tm1.png',
					name: 'Josefina Lavín',
					employer: 'ConsiliumBots',
					charge: 'COO ConsiliumBots',
				},
				{
					image: 'tm2.png',
					name: 'Mauricio Cornejo',
					employer: 'ConsiliumBots',
					charge: 'Líder del programa',
				},

				{
					image: 'tm4.png',
					name: 'Anibal Guerrero',
					employer: 'JPAL',
					charge: 'Research manager en JPAL',
				},
				{
					image: 'tm5.png',
					name: 'Isidora Barría',
					employer: 'ConsiliumBots',
					charge: 'Coordinadora',
				},
				{
					image: 'tm8.png',
					name: 'Cristóbal Espinoza',
					employer: 'ConsiliumBots',
					charge: 'Ingeniero de Software',
				},
				{
					image: 'tm6.png',
					name: 'Juan Ignacio Isamitt',
					employer: 'ConsiliumBots',
					charge: 'Ingeniero de Software',
				},
				{
					image: 'tm7.png',
					name: 'Mayela Cerda',
					employer: 'ConsiliumBots',
					charge: 'Diseño',
				},
				{
					image: 'tm9.png',
					name: 'Mauricio Cáceres',
					employer: 'ConsiliumBots',
					charge: 'Diseño',
				},
				{
					image: 'tm11.png',
					name: 'Cristóbal Morgado',
					employer: 'ConsiliumBots',
					charge: 'Coordinador',
				},
				{
					image: 'tm10.png',
					name: 'M. Fernanda Ramírez',
					employer: 'ConsiliumBots',
					charge: 'Investigadora asociada',
				},
				{
					image: 'tm3.png',
					name: 'Eddie Escobar',
					employer: 'ConsiliumBots',
					charge: 'Consultor externo',
				} ]),function(teamMember,index){return _c('v-col',{key:index,attrs:{"cols":_vm.isMobile ? 6 : 3}},[_c('img',{attrs:{"src":require('../../assets/Home/Team/' + teamMember.image),"alt":"tm"}}),_c('h2',{staticClass:"text-body color--neutral-60-t",staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(teamMember.name)+" ")]),_c('h2',{staticClass:"text-body color--neutral-60-t",staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(teamMember.employer)+" ")]),_c('h4',{staticClass:"text-body color--neutral-40-t"},[_vm._v(_vm._s(teamMember.charge))])])}),1),(_vm.isMobile)?_c('v-row',{staticClass:"mobile-picture ml-0",staticStyle:{"height":"75vh"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }