<template>
  <v-btn
    :disabled="disabled"
    rounded
    :style="{
      background: selected ? secondaryColor : primaryColor,
      color: selected ? primaryColor : secondaryColor,
      'border-radius': borderRadius,
      'min-height': textWrap ? '36px' : '',
      height: textWrap ? 'auto' : '',
      'pointer-events': readOnly ? 'none' : 'auto',
      border: borderStyle,
    }"
    :class="selected ? primaryClass : secondaryClass"
    no-elevation
    dense
    active-class="no-active"
    hideOverlay
    class="no-capitalize"
    content-class="elevation-0"
    elevation="0"
    :type="type"
    @click="$emit('click')"
    v-bind="{ ...extraProps }"
    :icon="icon != null"
  >
    <v-icon v-if="prependIcon" class="me-1">{{ prependIcon }}</v-icon>

    <custom-text
      :text="text"
      :class="[textClass, textWrap ? 'text-wrap' : '']"
      :ignoreStyles="ignoreStyles"
    ></custom-text>
    <v-icon v-if="icon">{{ icon }}</v-icon>
    <v-icon v-if="appendIcon" class="ms-1">{{ appendIcon }}</v-icon>
  </v-btn>
</template>
<script>
export default {
  name: "CustomButton",
  props: {
    borderStyle: {
      required: false,
      default: "",
    },
    textWrap: {
      required: false,
      default: false,
      type: Boolean,
    },
    ignoreStyles: {
      required: false,
      default: false,
      type: Boolean,
    },
    primaryClass: {
      required: false,
      default: "",
      type: String,
    },
    secondaryClass: {
      required: false,
      default: "",
      type: String,
    },
    selected: {
      default: true,
      type: Boolean,
      required: false,
    },
    textClass: {
      required: false,
      default: "",
    },
    primaryColor: {
      required: false,
      default: "white",
    },
    borderRadius: {
      required: false,
      default: "",
    },
    readOnly: {
      required: false,
      default: false,
      type: Boolean,
    },
    disabled: {
      required: false,
      default: false,
      type: Boolean,
    },
    secondaryColor: {
      required: false,
      default: "var(--primary)",
    },
    text: {
      required: false,
      default: "",
    },
    size: {
      required: false,
      default: null,
    },
    type: {
      required: false,
      default: "button",
      type: String,
    },
    icon: {
      // same as vuetify format ('mdi-icon')
      required: false,
      default: null,
    },
    appendIcon: {
      // same as vuetify format ('mdi-icon')
      required: false,
      default: null,
    },
    prependIcon: {
      // same as vuetify format ('mdi-icon')
      required: false,
      default: null,
    },
  },
  computed: {
    extraProps() {
      if (this.size) {
        const obj = {};
        obj[this.size] = true;
        return obj;
      }
      return {};
    },
  },
};
</script>
<style>
.v-btn > span {
  width: 100%;
}
</style>
