<template>
  <div
    :style="{
      transform: rotation,
      width: `${computedWidth}px`,
      height: `${computedHeight}px`,
    }"
    style="transform-origin:center center;width:29px;height:40px"
  >
    <svg
      height="100%"
      width="100%"
      viewBox="0 0 29 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.83066 40H0V0H7.83066C19.5103 0.1849 29 9.06009 29 20.0308C29 30.9399 19.5103 39.8151 7.83066 40Z"
        fill="currentColor"
      />
    </svg>
  </div>
</template>
<script>
export default {
  name: "SemiCircle",
  props: {
    width: {
      required: false,
      default: 29,
    },
    height: {
      required: false,
      default: 40,
    },
    left: {
      required: false,
      default: false,
      type: Boolean,
    },
    right: {
      required: false,
      default: true,
      type: Boolean,
    },
    top: {
      required: false,
      default: false,
      type: Boolean,
    },
    bottom: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  computed: {
    rotation() {
      if (this.bottom) {
        return "rotate(90deg)";
      }
      if (this.left) {
        return "rotate(180deg)";
      }
      if (this.top) {
        return "rotate(270deg)";
      }
      return "rotate(0)";
    },
    computedHeight() {
      if (this.height != 40) {
        return this.height;
      }
      return (this.width * 40) / 29;
    },
    computedWidth() {
      if (this.width != 29) {
        return this.width;
      }

      return (this.height * 29) / 40;
    },
  },
};
</script>
