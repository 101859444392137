<template>
  <div>
    <div v-if="loaded" :class="question.format == 'icon' ? 'd-flex' : ''">
      <div class="text-label bold mb-2">Puedes marcar múltiples opciones.</div>
      <ChoiceAnswer
        :readOnly="readOnly"
        v-for="answer in question.answers"
        :key="answer"
        :answer="answer"
        :question="question"
        @setAnswer="setAnswer"
        :selectedAnswer="computedAnswers.has(answer.number)"
        class="mb-3"
      />
    </div>
  </div>
</template>
<script>
import ChoiceAnswer from "../answers/ChoiceAnswer.vue";
export default {
  name: "MultipleChoice",
  mounted() {
    if (this.externalAnswer) {
      this.externalAnswer.split(",").forEach((answer) => {
        this.addAnswer(parseInt(answer));
      });
      this.loaded = true;
    } else {
      this.loaded = true;
    }
  },
  props: {
    externalAnswer: {
      required: false,
    },
    question: {
      required: true,
      type: Object,
    },
    readOnly: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      answers: new Set(),
      answerList: [],
      loaded: false,
    };
  },
  methods: {
    setAnswer(answerNumber) {
      this.loaded = false;
      // remove answer
      if (this.answers.has(answerNumber) && this.answers.size > 1) {
        // emit
        this.removeAnswer(answerNumber);
        // add answer
      } else {
        // check if option is unique. if so, remove other answers.
        if (this.question.answers[answerNumber].unique) {
          this.answers = new Set();
          this.answerList = [];
        } else {
          // new: check if unique option is selected. if so, unselect.
          this.answerList.forEach((answerNumber) => {
            if (this.$includes(this.uniqueAnswerNumbers, answerNumber)) {
              this.removeAnswer(answerNumber);
            }
          });
        }
        this.addAnswer(answerNumber);
      }
      this.$emit("setAnswer", true, this.setToAnswer());
      this.loaded = true;
    },
    setToAnswer() {
      let answer = "";
      this.answers.forEach((x) => {
        answer = answer + x + ",";
      });
      answer = answer.slice(0, -1);
      return answer;
    },
    addAnswer(answerNumber) {
      // add answer to set
      this.answers.add(parseInt(answerNumber));
      // add answer to list
      if (!this.$includes(this.answerList, answerNumber)) {
        this.answerList.push(answerNumber);
      }
      // check if question has maximum amount of answers.
      if (
        this.question.maximum &&
        this.question.maximum > 0 &&
        this.answerList.length > this.question.maximum
      ) {
        this.removeAnswer(this.answerList[0]);
      }
    },
    removeAnswer(answerNumber) {
      // remove answer from set
      this.answers.delete(answerNumber);
      // remove answer from list
      if (this.$includes(this.answerList, answerNumber)) {
        this.answerList.splice(this.answerList.indexOf(answerNumber), 1);
      }
    },
  },

  computed: {
    computedAnswers() {
      return this.answers;
    },
    uniqueAnswerNumbers() {
      // returns list of answer numbers where the answer is unique.
      return this.question.answers
        .filter((answer) => {
          return answer.unique == true;
        })
        .map((answer) => {
          return answer.number;
        });
    },
  },

  components: { ChoiceAnswer },
};
</script>
