<!-- refactored? No -->
<template>
  <!-- mixpanel on -->
  <div>
    <DashboardCard
      class="text-center h-100  d-flex flex-column align-items-center text-center"
      :class="
        screen === 'help' ? 'justify-content-center' : 'bg--neutral-100-t'
      "
      v-if="$store.state.authentication.user.type != 'student'"
    >
      <div v-if="screen === 'help'">
        <div
          v-if="!sent"
          :style="mobile ? '' : 'padding-left:30%;padding-right:30%;'"
          :class="mobile ? 'text-left' : ''"
        >
          <span
            class="text-heading"
            v-t="$t('dashboard.help-panel.help-center.title')"
          />
          <p
            v-html="$t('dashboard.help-panel.help-center.subtitle')"
            class="text-title"
          />
          <div class="d-flex">
            <div style="max-width:100%;margin-right: auto; margin-left: auto;">
              <common-button
                rounded
                small
                active-class="no-active"
                v-for="(button, index) in helpOptions"
                @click="setIndex(index)"
                :key="index"
                class="no-capitalize me-2 mb-2"
                :class="getPhaseButtonClass(index)"
                content-class="elevation-0"
                elevation="0"
                v-text="button"
              />
              <a href="https://wa.me/56985051369">
                <common-button
                  style="background:#25D366 !important;color: white !important"
                  class="dashboard-phase-button"
                  >Contactar por WhatsApp</common-button
                ></a
              >
            </div>
          </div>
          <span
            v-if="problem === 3"
            v-text="$t('dashboard.help-panel.help-center.others-selected-text')"
          />
          <v-textarea
            v-if="problem === 3"
            label=""
            filled
            v-model="problemDescription"
            hide-details
            class="bg--neutral-100-t"
            flat
            auto-grow
            rows="2"
          >
          </v-textarea>
          <div class="w-100 text-center mt-5">
            <common-button
              class="bg--primary"
              :class="
                problem === null || problem === undefined
                  ? ' dashboard-phase-button'
                  : ' dashboard-phase-button-selected'
              "
              rounded
              style="padding:30px;border-radius:105px !important;height:52px !important;width:200px !important"
              dense
              elevation="0"
              @click="
                problem !== null && problem !== undefined ? setSend() : () => {}
              "
              v-text="$t('dashboard.help-panel.help-center.send')"
            />
          </div>
          <div
            v-if="lang != 'es-CO'"
            class="text-center clickable bold color--primary text-small mt-2"
            @click="setScreen('questions')"
            v-t="$t('dashboard.help-panel.help-center.go-to-faq')"
          />
        </div>
        <div v-else class="text-center d-flex flex-column h-100 w-100" style="">
          <div :style="mobile ? '' : 'margin-left:20%;margin-right:20%;'">
            <p
              v-t="$t('dashboard.help-panel.help-center.sent.message-sent')"
              class="text-heading"
            />
            <p
              v-t="$t('dashboard.help-panel.help-center.sent.team-contact')"
              class="text-subtitle"
            />
          </div>
        </div>
        <Socials
          v-if="sent"
          :width="mobile ? '100vw' : 'calc(100vw - 320px)'"
          class="ms-auto mr-auto mt-auto"
        />
      </div>
      <div v-else class="h-100">
        <div
          class="text-center text-title mb-2"
          v-t="$t('dashboard.help-panel.faq.title')"
        />
        <div
          style="height:80%;max-height:80%;overflow-y:auto;"
          :style="mobile ? '' : 'margin-left:20%;margin-right:20%'"
        >
          <div
            v-for="(questionSection, index) in questionSections"
            :key="index"
          >
            <div class="text-left text-medium mt-2">
              {{ questionSection.name }}
            </div>
            <v-expansion-panels flat accordion>
              <v-expansion-panel
                v-for="(q, i) in questionSection.questions"
                class="mt-2 bg--primary-80-t"
                :key="i"
              >
                <v-expansion-panel-header class="color--primary text-body">{{
                  q.question
                }}</v-expansion-panel-header>
                <v-expansion-panel-content class="text-left text-label">
                  <custom-text :text="q.answer" />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </div>
        <div
          class="text-center clickable bold color--primary text-medium mt-2"
          @click="setScreen('help')"
          v-t="$t('dashboard.help-panel.faq.go-to-help-center')"
        />
      </div>
    </DashboardCard>
    <DashboardCard
      class="text-center h-100  d-flex flex-column align-items-center text-center justify-content-center bg--primary-90-t"
      v-else
    >
      <div class="text-title ms-10 mr-10">
        <div class="text-heading">Centro de ayuda</div>
        <div class="text-subtitle">
          Si necesitas ayuda, favor contacta a tu orientador o profesor.
        </div>
      </div>
    </DashboardCard>
  </div>
</template>
<script>
import DashboardCard from "../DashboardCard.vue";
import Socials from "@/components/layout/Socials.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "DashboardHelp",
  components: { DashboardCard, Socials },
  computed: {
    ...mapGetters({
      mobile: "layout/isMobile",
      lang: "layout/lang",
      user: "authentication/user",
    }),
  },
  mounted() {
    if (this.lang == "es-CO") {
      this.screen = "help";
    }
  },
  methods: {
    ...mapActions({
      track: "authentication/track",
      postSupport: "authentication/postSupport",
    }),
    setScreen(screen) {
      this.screen = screen;
      this.track({ event: "dashboard-set-help-screen", data: { screen } });
    },
    setSend() {
      let body = {
        email: this.user.email,
        role: this.user.type,
        assigned: "",
        category: this.helpOptions[this.problem],
        text: this.problemDescription,
        rbd: this.user.rbd ?? "",
      };
      this.sent = true;
      this.track({
        event: "dashboard-request-help",
        data: {
          category: this.helpOptions[this.problem],
        },
      });
      this.postSupport({ body }).then(() => {
        this.helpRequested = true;
      });
    },
    setIndex(index) {
      this.problem = index;
    },
    getPhaseButtonClass(index) {
      if (index === this.problem) {
        return "bg--secondary-90-t color--secondary";
      } else {
        return "bg--primary-90-t color--primary";
      }
    },
  },
  data() {
    return {
      problem: null,
      problemDescription: "",
      sent: false,
      screen: "questions",
      questionSections: this.$t("dashboard.help-panel.faq.question-sections"),
      helpOptions: this.$t("dashboard.help-panel.help-center.options"),
    };
  },
};
</script>
<style>
.dashboard-help-title {
  font-family: "Karla";
  font-style: normal;
  font-weight: 500;
  font-size: 47.8263px;
  line-height: 72px;
}
.dashboard-help-subtitle {
  font-family: "Karla";
  font-style: normal;
  font-weight: 400;
  font-size: 23.9131px;
  line-height: 31px;
}
</style>
