<template>
  <!-- mixpanel on -->
  <div>
    <DashboardPhaseTemplate>
      <DashboardCard
        style="overflow-y:auto"
        title=""
        subtitle="En esta sección, podrás escribir las principales discusiones que tuviste con tus estudiantes.\nNo es necesario que detalles toda la conversación, sino que los puntos más importantes."
      >
        <div
          v-for="(lectionary, index) in phase.lectionaries"
          :key="index"
          class="mt-7 w-100"
        >
          <custom-text
            :text="`\\highlight{${lectionary.name}, #5669FB, white}`"
            class="text-body mb-2"
          />
          <custom-text :text="lectionary.summary" class="text-body mb-5" />
          <v-textarea
            class="w-100 "
            solo
            v-if="loaded"
            hide-details
            :value="lectionaryAnswers[lectionary.id].text"
            @change="change(...arguments, lectionary.id)"
            flat
            auto-grow
            :maxlength="500"
            label="Aquí podrás tomar apuntes sobre las diferentes actividades de la semana"
          ></v-textarea>
        </div>
        <common-button text="Guardar Respuestas" @click="save"></common-button>
      </DashboardCard>
    </DashboardPhaseTemplate>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import DashboardCard from "../DashboardCard.vue";
import DashboardPhaseTemplate from "../DashboardPhaseTemplate.vue";

export default {
  name: "DashboardLectionary",
  components: {
    DashboardPhaseTemplate,
    DashboardCard,
  },
  mounted() {
    this.initialize();
  },
  watch: {
    phase() {
      this.initialize();
    },
    currentClass() {
      this.initialize();
    },
  },
  methods: {
    ...mapActions({
      track: "authentication/track",
      getLectionaries: "program/getLectionaries",
      createLectionaries: "program/createLectionaries",
      updateLectionaries: "program/updateLectionaries",
    }),
    initialize() {
      this.loaded = false;
      this.lectionaryAnswers = new Object();
      this.phase.lectionaries.forEach((l) => {
        this.lectionaryAnswers[l.id] = { text: "" };
      });
      this.getLectionaries({
        phaseId: this.phase.id,
        classId: this.currentClass.id,
      }).then((response) => {
        response.data.forEach((lectionary) => {
          this.lectionaryAnswers[lectionary.lectionary] = {
            id: lectionary.id,
            text: lectionary.text,
          };
        });
        this.loaded = true;
      });
    },
    change(text, lectionaryId) {
      if (!(lectionaryId in this.lectionaryAnswers)) {
        // create
        this.lectionaryAnswers[lectionaryId] = new Object();
      }
      this.lectionaryAnswers[lectionaryId].text = text;
      this.track({
        event: "dashboard-update-lectionary",
        data: { lectionaryId },
      });
    },
    save() {
      this.$nextTick(() => {
        let answers = [];
        Object.keys(this.lectionaryAnswers).forEach((k) => {
          let object = this.lectionaryAnswers[k];

          let lectionary = {
            lectionary: parseInt(k),
            text: object.text,
            phase: this.phase.id,
          };
          if (object.id) {
            lectionary.id = object.id;
          }
          answers.push(lectionary);
        });
        let created = answers.filter((la) => !("id" in la));
        created = created.filter((la) => la.text != "");
        let updated = answers.filter((la) => "id" in la);
        updated = updated.filter((la) => la.text != "");

        if (updated.length) {
          this.track({ event: "dashboard-save-lectionaries", data: {} });
          this.updateLectionaries({ body: { data: updated } }).then(
            (response) => {
              response.data.data.forEach((lectionary) => {
                this.lectionaryAnswers[lectionary.lectionary].id =
                  lectionary.id;
              });
            }
          );
        }
        if (created.length) {
          created = created.map((l) => {
            l.class = this.currentClass.id;
            return l;
          });
          this.createLectionaries({ body: { data: created } }).then(
            (response) => {
              response.data.data.forEach((lectionary) => {
                this.lectionaryAnswers[lectionary.lectionary].id =
                  lectionary.id;
              });
            }
          );
        }
      });
    },
  },
  computed: {
    ...mapGetters({
      phase: "program/currentPhase",
      currentClass: "_class/currentClass",
    }),
  },
  data() {
    return {
      lectionaryAnswers: new Object(),
      loaded: false,
    };
  },
};
</script>
