import services from "@/services";

/* Useful store elements platform-wise. */
/* Sets the initial state of the app. Useful for startup and logout. */
const getDefaultState = () => ({
  currentClass: null,
  classes: [],
  currentClassStudents: null,
  classLoaded: true,
});

const state = {
  ...getDefaultState(),
};

const getters = {
  classes: ({ classes }) => classes,
  currentClass: ({ currentClass }) => currentClass,
  currentClassStudents: ({ currentClassStudents }) => currentClassStudents,
  classLoaded: ({ classLoaded }) => classLoaded,
};

const mutations = {
  setClassLoaded(state, { classLoaded }) {
    state.classLoaded = classLoaded;
  },
  setCurrentClass(state, { classId }) {
    state.currentClass = state.classes.find((x) => x.id == classId);
    state.currentClassStudents = state.currentClass.students;
  },
  setCurrentClassByObj(state, { classObj }) {
    state.currentClass = classObj;
    let index = 0;
    for (var i = 0; i < state.classes.length; i++) {
      let _class = state.classes[i];
      if (_class.id == classObj.id) {
        index = i;
      }
    }
    state.classes[index] = classObj;
  },
  setClasses(state, { classes }) {
    state.classes = classes;
  },
  setStudentsToClass(state, { classId, students }) {
    state.classes.find((x) => x.id == classId).students = students;
    state.currentClassStudents = students;
    if (state.currentClass.id == classId) {
      state.currentClass.students = students;
      state.currentClassStudents = students;
    }
  },
};

const actions = {
  reset({ state }) {
    Object.assign(state, getDefaultState());
  },
  getClasses({ commit }, { rbd }) {
    commit("setClassLoaded", { classLoaded: false });
    return services.classService.getClasses({ rbd }).then((response) => {
      const classes = response.data;
      commit("setClasses", { classes });
      if (classes.length > 0) {
        commit("setCurrentClass", { classId: classes[0].id });
      }
      commit("setClassLoaded", { classLoaded: true });
      return classes;
    });
  },
  setClasses({ commit }, { classes }) {
    commit("setClasses", { classes });
  },
  setCurrentClass({ commit }, { classId }) {
    commit("setCurrentClass", { classId });
  },
  setStudentsToClass({ commit }, { classId, students }) {
    commit("setStudentsToClass", { classId, students });
  },
  setClassLoaded({ commit }, { classLoaded }) {
    commit("setClassLoaded", { classLoaded });
  },
  getClassStudents({ commit, state }) {
    commit("setClassLoaded", { classLoaded: false });
    if (!state.currentClass) {
      commit("setClassLoaded", { classLoaded: true });
      return [];
    }
    return services.studentService
      .getStudentsFromClass(state.currentClass.id)
      .then((response) => {
        const students = response.data;
        let classObj = state.currentClass;
        classObj.students = students;
        commit("setCurrentClassByObj", { classObj });
        commit("setStudentsToClass", {
          classId: state.currentClass.id,
          students: students,
        });
        commit("setClassLoaded", { classLoaded: true });
        return students;
      })
      .catch(() => {
        commit("setClassLoaded", { classLoaded: true });
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
