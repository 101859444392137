<template>
  <div class="relative" style="z-index:2">
    <vimeo-player
      style="border-radius: 0px !important"
      :options="{ responsive: true, 'border-radius': 0 }"
      :ref="reference"
      :video-id="videoId"
      @timeupdate="playing"
    />
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "PrimerVideo",
  data() {
    return {
      videoDuration: null,
    };
  },

  methods: {
    ...mapActions({
      track: "authentication/track",
    }),
    playing() {
      const player = this.$refs[this.reference].player;
      if (this.videoDuration == null) {
        this.videoDuration = 1;
        player.getDuration().then(() => {
          this.track({
            event: "primer-click-video",
            data: { video: this.videoId, id: this.userId },
          });
        });
      }
    },
  },
  computed: {
    reference() {
      return `player-${this.videoId}`;
    },
  },
  props: {
    videoId: {
      required: true,
    },
    userId: {
      required: true,
    },
  },
};
</script>
