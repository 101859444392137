<template>
  <div>
    <!-- data v-card button -->
    <div
      class="m-2"
      style="z-index:10000"
      :style="
        mobile
          ? 'position:fixed;bottom:0;right:0;'
          : 'position:absolute;bottom:0px;right:0px;'
      "
    >
      <common-button
        @click="setShowDataCard({ showDataCard: true })"
        v-if="showDataCardButton"
        >SUSCRÍBETE AHORA</common-button
      >
    </div>
    <!-- data v-card -->
    <Transition name="slide-fade">
      <div
        style="z-index:10000"
        :style="
          mobile
            ? 'z-index:10000;position:fixed;bottom:0;right:15px;'
            : 'position:absolute;bottom:0px;right:0px'
        "
        v-if="showDataCard"
      >
        <v-card
          :style="mobile ? 'width:100%' : 'width: 402px'"
          style="border-radius:20px"
          class="p-4 m-2 text-left bg--primary-30-s color--neutral-100-t"
        >
          <div class="text-title bold">
            ¿Quieres mejorar tus probabilidades de acceder a la Educación
            Superior?
          </div>
          <div class="text-body color--neutral-100-t">
            <div class="mt-1 mb-2">
              Los alumnos que participan en el programa DFM tienen más
              probabilidad de acceder a la Eduación Superior.
            </div>
            <div class="d-flex">
              <div
                style="margin-top:21px;margin-right:2px"
                v-if="lang == 'es-CL'"
              >
                (+569)
              </div>
              <div
                style="margin-top:21px;margin-right:2px"
                v-else-if="lang == 'es-CO'"
              >
                (+57)
              </div>
              <v-text-field
                type="number"
                label="Número de celular (obligatorio)"
                flat
                dense
                hide-spin-buttons
                hide-details
                v-model="cellphone"
                class="mt-2 mb-5 p-2 pt-3 rounded"
                background-color="white"
              ></v-text-field>
            </div>
            <div>
              <v-text-field
                v-show="lang == 'es-CL'"
                label="E-mail (opcional)"
                v-model="email"
                hide-details
                class="mb-5 p-2 pt-3 rounded"
                flat
                background-color="white"
                dense
              ></v-text-field>
            </div>
          </div>

          <div class="w-100">
            <common-button
              text="Suscríbete ahora!"
              class="bg--success bold color--neutral-100-s w-100"
              @click="sendData"
              :disabled="popUpDisabled"
            ></common-button>
          </div>

          <div class="w-100 text-right">
            <common-button
              text="Cerrar"
              style="border:1px solid white !important"
              class="bg--primary-30-s color--neutral-100-t mt-3"
              @click="setShowDataCard({ showDataCard: false })"
            ></common-button>
          </div>
        </v-card>
      </div>
    </Transition>
    <!-- end data v-card -->
    <!-- data card sent -->
    <div
      style="z-index:10000"
      :style="
        mobile
          ? 'z-index:10000;position:fixed;bottom:0;right:15px;'
          : 'position:absolute;bottom:0px;right:0px'
      "
      v-if="showDataCardSent && showDataCardSentLocal"
    >
      <v-card
        class=" bg--primary-30-s color--neutral-100-t p-4 m-2"
        :style="mobile ? 'width:100%' : 'width: 402px'"
        style="border-radius:20px"
      >
        <div class="text-title bold">¡Muchas gracias!</div>
        <div class="text-body color--neutral-100-t">
          Estaremos en contacto para entregarte las mejores herramientas para tu
          eduación
        </div>
        <common-button
          class="bg--neutral-100-t color--primary w-100 mt-4"
          text="Cerrar"
          @click="showDataCardSentLocal = false"
        ></common-button>
      </v-card>
    </div>
    <!-- end data card sent -->
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "DataCard",
  data() {
    return {
      showDataCardSentLocal: false,
      email: "",
      cellphone: "",
    };
  },
  props: {
    sentCellphone: {
      required: true,
      type: String,
    },
    showDataCardButton: {
      required: true,
      type: Boolean,
    },
    showDataCard: {
      required: true,
      type: Boolean,
    },
    showDataCardSent: {
      required: true,
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters({
      mobile: "layout/isMobile",
      lang: "layout/lang",
    }),

    popUpDisabled() {
      return this.cellphone.length < 6 || !this.validEmail(this.email);
    },
  },
  methods: {
    ...mapActions({
      patchForm: "authentication/patchForm",
      setShowDataCard: "authentication/setShowDataCard",
    }),
    validEmail(email) {
      if (email == "") {
        return true;
      }
      if (email === null) {
        return false;
      }
      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (email.match(validRegex)) {
        return true;
      } else {
        return false;
      }
    },
    sendData() {
      this.patchForm({
        data: {
          answers: {
            cellphone: this.cellphone,
            email: this.email,
          },
        },
      }).then(() => {
        this.showDataCardSentLocal = true;
        this.$emit("setSentCellphone", this.cellphone);
      });
    },
  },
};
</script>
<style>
.slide-fade-enter {
  opacity: 0;
  transition: 0.3s ease-out;
}
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s ease-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  opacity: 0;
}
</style>
