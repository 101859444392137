import services from "@/services";

/* Useful store elements platform-wise. */
/* Sets the initial state of the app. Useful for startup and logout. */
const getDefaultState = () => ({
  answers: Object(),
});

const state = {
  ...getDefaultState(),
};

const getters = {
  variable: ({ variable }) => variable,
  answers: ({ answers }) => answers,
};

const mutations = {
  addAnswer(state, { questionId, answer }) {
    state.answers[questionId] = answer;
  },
  removeAnswers(state) {
    state.answers = Object();
  },
};

const actions = {
  reset({ state }) {
    Object.assign(state, getDefaultState());
  },
  // eslint-disable-next-line
  getAnswers({ commit }) {
    return services.answerService
      .getAnswers()
      .then((response) => {
        response.data.forEach((answer) => {
          commit("addAnswer", {
            questionId: answer.question,
            answer: answer.answer,
          });
        });
        return response;
      })
      .catch((error) => {
        throw error;
      });
  },
  // eslint-disable-next-line
  getStudentAnswers({ commit }, { studentId, stageId }) {
    return services.answerService
      .getAnswers(`?student__pk=${studentId}&stage__id=${stageId}`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  },
  postCompleted({ dispatch }, { body }) {
    return services.answerService
      .postCompleted({ body })
      .then((response) => {
        dispatch("addCompletedFromPost", {
          completed: response.data.completed,
        });

        return response;
      })
      .catch((error) => {
        throw error;
      });
  },
  // eslint-disable-next-line
  getCompleted({ dispatch }, { params }) {
    return services.answerService
      .getCompleted({ params })
      .then((response) => {
        response.data.forEach((completed) => {
          dispatch(
            `program/addCompleted`,
            { type: completed.related, id: completed.related_id },
            { root: true }
          );
        });
        return response;
      })
      .catch((error) => {
        throw error;
      });
  },
  addCompletedFromPost({ dispatch, commit }, { completed }) {
    // add new completed
    Object.keys(completed).forEach((type) => {
      if (type != "next") {
        completed[type].forEach((id) => {
          dispatch("program/addCompleted", { type, id }, { root: true });
        });
      }
    });
    // add next stage
    if (completed.next) {
      commit("program/appendStage", { stage: completed.next }, { root: true });
    }
  },
  removeAnswers({ commit }) {
    commit("removeAnswers");
  },
  addAnswer({ commit }, { questionId, answer }) {
    commit("addAnswer", { questionId, answer });
  },
  postAnswer({ commit, dispatch, rootGetters }, { body }) {
    // body -> question, answer
    return services.answerService
      .postAnswer({ body })
      .then((response) => {
        // add answer
        commit("addAnswer", { answer: body.answer, questionId: body.question });
        if (rootGetters["authentication/user"].type == "student") {
          dispatch("addCompletedFromPost", {
            completed: response.data.completed,
          });
        }
        return response;
      })
      .catch((error) => {
        throw error;
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
