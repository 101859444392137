<template>
  <div style="background:#f0f0f0;overflow-x:hidden;height:100%">
    <div id="primer" class="ms-auto me-auto relative">
      <div id="intro" class="text-left p-4 relative" style="z-index:2;">
        <img src="@/assets/Primer/DFMLogo.svg" style="max-width:40%" />
        <h1
          class="primer-special-title-dark bold pt-12 pb-2 primer-text-title"
          style="max-width:70%;transform-origin:top left;transform:scale(1.2)"
        >
          ¡Hola, somos DFM!
        </h1>
        <div class="primer-text-title" style="max-width:70%">
          Queremos entregarle a {{ name }} información útil sobre la importancia
          y beneficios de la educación.
        </div>
        <!-- icons -->
        <semi-circle
          class="absolute mt-6 me-6"
          style="color:#FF7497;top:0px;right:0px"
          top
        />
        <semi-circle
          class="absolute mt-6 me-6"
          style="color:#FFD166;top:29px;right:20px"
          bottom
        />
        <semi-circle
          class="absolute mb-6 me-6"
          style="color:#EE77D3;right:100px"
          :style="{ bottom: mobile ? '' : '', top: mobile ? '61px' : '102px' }"
          top
        />
        <semi-circle
          class="absolute mb-6 me-6"
          style="color:#23D7B4;right:80px"
          :style="{
            bottom: mobile ? '' : '',
            top: mobile ? '90px' : '130px',
            right: mobile ? '60px' : '80px',
          }"
          bottom
        />
        <semi-circle
          class="absolute mb-6 me-6"
          style="color:#5669FB"
          :style="{
            bottom: mobile ? '' : '',
            top: mobile ? '90px' : '130px',
            right: mobile ? '20px' : '40px',
          }"
          bottom
        />
      </div>
      <primer-section
        id="primer-state"
        icon="$mdi-app-registration"
        title="Tu estado de matrícula 2023"
      >
        <div class="ps-6 pe-6 primer-text-subtitle">
          Según los registros de la secretaría de educación,
          <b>{{ name }} no ha renovado su matrícula para el 2023*.</b>
          <br />
          <br />

          <div class="primer-text-label w-100">
            <div class="text-right">
              *Información actualizada al 23 de enero.
            </div>
          </div>
        </div>
        <div class="ms-6 me-6 mt-4 mb-4 primer-divider"></div>
        <div
          class="ps-6 pt-4 primer-text-title primer-special-title"
          style="transform:scale(0.9);transform-origin:top left"
        >
          ¿Tienes problemas para regularizar esta situación?
        </div>
        <div class="ps-6 pe-6 primer-text-subtitle">
          ¡No te preocupes, aún tienes tiempo!
        </div>
        <div class="ps-6 pe-6 primer-text-body">
          Llámanos o escríbenos al:
        </div>
        <div class="ps-6 pe-6 primer-text-heading d-flex">
          <v-btn
            @click="openLink('https://wa.me/3186418000')"
            class="rounded me-2 mt-auto mb-auto"
            icon
            style="width:30px !important;max-width:30px;height:30px;background:#34DB52"
          >
            <v-icon color="white">mdi-whatsapp</v-icon>
          </v-btn>
          <span @click="openLink('https://wa.me/3186418000')">3186418000</span>
        </div>
        <div class="ms-6 me-6 mt-4 mb-4 primer-divider"></div>
        <div class="ps-6 pe-6 primer-text-subtitle">
          <b>Explora</b> establecimientos, <b>calcula</b> tiempos de trayectos,
          <b>recorre</b> virtualmente su infraestructura ¡y mucho más!<br />
          <br />
          <div class="w-100 text-center">
            <common-button
              class="mt-4 mb-8"
              @click="openLink('https://colombia.explorador.com/welcome')"
              text="Visitar explorador escolar"
              secondary-color="#27196B"
            >
            </common-button>
          </div>
        </div>
      </primer-section>
      <!-- circles -->
      <div class="relative">
        <div
          class="absolute"
          :style="{
            height: size,
            width: size,
            bottom: mobile ? '0' : '-150px',
            left: mobile ? '0' : '-150px',
          }"
          style="background:#5669FB;border-radius:50%;z-index:1"
        ></div>
        <div
          class="absolute"
          v-if="mobile"
          style="background:#FFBAD4;width:230px;height:230px;border-radius:50%;z-index:1;right:0"
        ></div>
        <div
          class="absolute"
          v-if="mobile"
          style="background:#5669FB;width:230px;height:230px;border-radius:50%;z-index:1;right:0;top:230px"
        ></div>
      </div>
      <!-- end circles -->
      <primer-section
        id="primer-importance"
        title="La importancia de la educación"
        icon="mdi-school-outline"
      >
        <div class="ps-6 pe-6 pt-4  primer-text-subtitle">
          La educación nos prepara para tiempos de crisis:
          <b
            >nos entrega herramientas que nos permiten afrontar los desafíos.</b
          >
          <br />
          <br />
          <custom-text :text="getText()"></custom-text>
          <custom-text
            text="\highlight{tiene el poder de elegir qué tan alto quiere llegar!, #F8EFEF, #FF828F}"
          ></custom-text>
        </div>
        <br />
        <primer-video :videoId="767871788" :userId="id" />
      </primer-section>
      <!-- circles -->
      <div class="relative">
        <div
          class="absolute"
          :style="{
            width: size,
            height: `calc(${size} / 2)`,
            'border-radius': `${size} ${size} 0 0`,
            top: mobile ? '30px' : '350px',
            left: mobile ? '0' : '-200px',
          }"
          style="background:#27196B;z-index:1"
        ></div>
        <div
          class="absolute"
          :style="{
            width: size,
            height: size,
            top: mobile ? '500px' : '1000px',
            left: mobile ? '0' : '-200px',
          }"
          style="background:#27196B;border-radius:50%;z-index:1;"
        ></div>
        <div
          class="absolute"
          :style="{
            width: size,
            height: size,
            top: mobile ? '500px' : '1000px',
            right: mobile ? '-115px' : '-200px',
          }"
          style="background:#FFBAD4;border-radius:50%;z-index:1;"
        ></div>
      </div>
      <!-- end circles -->
      <primer-section
        id="primer-benefits"
        title="Los beneficios de la educación"
        icon="mdi-shimmer"
      >
        <div class="ps-6 pe-6 pt-4  primer-text-subtitle">
          ¡La educación nos entrega <b>herramientas</b> que nos permiten
          alcanzar nuestras metas,
          <b
            >mejorando nuestra calidad de vida y la de todos quienes nos
            rodean!</b
          >
          <br />
          <br />

          <custom-text
            text="\highlight{En promedio, \bold{las personas con un mayor nivel educativo acceden a mejores oportunidades, empleos, entre otros.}, #F8EFEF, #FF828F}"
          ></custom-text>
          <br />
          <div>¿Quieres conocer más?</div>
          <br />
        </div>
        <primer-video :videoId="767871845" :userId="id" />
        <div class="ms-6 me-6 mt-4 mb-4 primer-divider"></div>

        <div class="ps-6 pe-6 primer-text-subtitle">
          <b>Educarse es una de las decisiones más importantes de la vida</b>,
          nos permite aumentar las posibilidades de alcanzar nuestros sueños y
          desarrollarnos como persona.
          <br />

          <br />

          <custom-text
            text="\highlight{Por ejemplo, las personas que \bold{terminan la escuela obtienen, en promedio, un salario más alto que aquellos que no la terminaron.}, #F8EFEF, #FF828F}"
          >
          </custom-text>
          <br />
          <div>¿Quieres conocer más?</div>
          <br />
        </div>

        <primer-video :videoId="767871593" :userId="id" />
      </primer-section>
      <primer-section id="primer-end">
        <div class="ps-6 pe-6 mb-4 primer-text-subtitle">
          Antes de despedirnos, nos gustaría compartir contigo un mensaje de la
          Secretaria de Educación, Ana Janneth Ibarra.
        </div>
        <primer-video :videoId="792720215" :userId="id"></primer-video>
      </primer-section>
      <primer-section>
        <!-- icons -->
        <semi-circle
          top
          class="absolute mt-6 me-6"
          style="color:#FF7497;top:0;right:0"
        />
        <semi-circle
          bottom
          class="absolute mt-6 me-6"
          style="color:#5669FB;top:28px;right:20px"
        />
        <square
          class="absolute mt-6 me-6"
          :size="34"
          style="color:#FFBA66;top:62px;right:18px"
        />

        <semi-circle
          top
          class="absolute mb-6 me-6"
          style="color:#FF7497;bottom:32px;right:40%"
        />
        <semi-circle
          right
          class="absolute mb-6 me-6"
          style="color:#23D7B4;bottom:20px;right:20%"
        />

        <!-- end icons -->
        <div class="ps-6 pe-6 primer-text-title" style="max-width:70%">
          <b>¡La educación nos hace invencibles!</b>
        </div>
        <br />
        <div class="ps-6 pe-6 primer-text-title" style="max-width:70%">
          <b>¡Sueña tu futuro, ven a la escuela!</b>
        </div>
        <br />
        <br />

        <div class="pb-4 ps-6 pt-4">
          <img src="@/assets/DFM2.svg" style="max-width:70%;min-width:50%" />
        </div>
      </primer-section>
      <!-- circles -->
      <div class="relative">
        <div
          class="absolute"
          :style="{
            width: sizeSmall,
            height: sizeSmall,
            top: mobile ? '-170px' : '-500px',
            left: mobile ? '30%' : '30%',
          }"
          style="background:#23D7B4;border-radius:50%;z-index:1;left:30%;transform:translate(-50%, 0);"
        ></div>

        <div
          class="absolute"
          :style="{
            width: sizeSmall,
            height: sizeSmall,
            top: mobile ? '-170px' : '-500px',
            left: mobile ? 'calc(30% + 230px)' : 'calc(30% + 500px)',
          }"
          style="background:#FFBAD4;border-radius:50%;z-index:1;left:calc(30% + 230px);transform:translate(-50%, 0)"
        ></div>
      </div>
      <!-- end circles -->
      <div style="height:4px"></div>
    </div>
    <div
      class="d-flex flex-row p-4 align-items-center text-center vertical-align-middle relative"
      style="background:white;width:100%;z-index:2"
    >
      <img
        src="@/assets/Home/ConsiliumBots.svg"
        style="max-height:16px;max-width:30%"
        class="ms-auto"
      />
      <div class="ms-3 me-3 primer-text-label" style="color:#828282;">
        en colaboración con
      </div>
      <img src="@/assets/ValleDelCauca.svg" class="me-auto" />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import PrimerSection from "@/components/primer/PrimerSection.vue";
import PrimerVideo from "@/components/primer/PrimerVideo.vue";

export default {
  name: "Primer",
  components: {
    PrimerSection,
    PrimerVideo,
  },
  data() {
    return {
      id: null,
      name: "",
    };
  },
  mounted() {
    const { id } = this.$route.params;
    this.id = id;
    this.get({ endpoint: `primerinformation/${this.id}`, params: {} }).then(
      (response) => {
        this.name = this.titleCase(response.data.name);
      }
    );
    this.track({ event: "primer-access", data: { id: id } });
  },
  methods: {
    titleCase(str) {
      var splitStr = str.toLowerCase().split(" ");
      for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(" ");
    },
    getText() {
      return `\\highlight{¡${this.name}, #F8EFEF, #FF828F}`;
    },
    openLink(link) {
      this.track({
        event: "primer-open-link",
        data: { link: link, id: this.id },
      });
      window.open(link, "_blank");
    },
    ...mapActions({
      track: "authentication/track",
      get: "admin/silentGet",
    }),
  },
  computed: {
    ...mapGetters({
      mobile: "layout/isMobile",
    }),
    size() {
      if (this.mobile) {
        return "230px";
      } else {
        return "693px";
      }
    },
    sizeSmall() {
      if (this.mobile) {
        return "230px";
      } else {
        return "500px";
      }
    },
  },
};
</script>
<style>
@import url("https://fonts.cdnfonts.com/css/satoshi");
#primer {
  font-family: "Satoshi" !important;
  background: #f0f0f0 !important;
  color: #4f4f4f;
  max-width: 1000px;
}
/* text sizes */
.primer-text-heading {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
}

.primer-text-title {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
}

.primer-text-subtitle {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 400;
}

.primer-text-body {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 400;
}

.primer-text-label {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
}

/* phone text sizes */
@media (max-width: 740px) {
  .primer-text-heading {
    font-size: 34.1616px !important;
    line-height: 50px !important;
  }

  .primer-text-title {
    font-size: 20.497px !important;
    line-height: 31px !important;
  }

  .primer-text-subtitle {
    font-size: 17.0808px !important;
    line-height: 27px !important;
  }

  .primer-text-body {
    font-size: 13.6646px !important;
    line-height: 20px !important;
  }

  .primer-text-label {
    font-size: 10.2485px !important;
    line-height: 15px !important;
  }
}

/* tablet text sizes */
@media (max-width: 1024px) and (min-width: 740px) {
  .primer-text-heading {
    font-size: 40.9939px !important;
    line-height: 61px !important;
  }

  .primer-text-title {
    font-size: 23.9131px !important;
    line-height: 34px !important;
  }

  .primer-text-subtitle {
    font-size: 20.497px !important;
    line-height: 27px !important;
  }

  .primer-text-body {
    font-size: 15.3727px !important;
    line-height: 24px !important;
  }

  .primer-text-label {
    font-size: 11.9566px !important;
    line-height: 17px !important;
  }
}

/*desktop text sizes */
@media (min-width: 1024px) {
  .primer-text-heading {
    font-size: 47.8263px !important;
    line-height: 72px !important;
  }

  .primer-text-title {
    font-size: 27.3293px !important;
    line-height: 37px !important;
  }

  .primer-text-subtitle {
    font-size: 23.9131px !important;
    line-height: 42px !important;
  }

  .primer-text-body {
    font-size: 17.0808px !important;
    line-height: 27px !important;
  }

  .primer-text-label {
    font-size: 13.6646px !important;
    line-height: 20px !important;
  }
}
.primer-special-title {
  background: linear-gradient(90deg, #ff7497 2.09%, #ffbad4 90.87%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.primer-special-title-dark {
  letter-spacing: 0.34252px;
  background: linear-gradient(90deg, #ff7497 2.03%, #27196b 77.64%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.primer-divider {
  background: #4f4f4f;
  height: 1.5px !important;
}
</style>
