var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard-grid-element",class:_vm.elevation ? 'elevation-3' : '',style:({
    '--row-start': _vm.mobile ? '' : _vm.rowStart,
    '--row-end': _vm.mobile ? '' : _vm.rowEnd,
    '--col-start': _vm.mobile ? '' : _vm.colStart,
    '--col-end': _vm.mobile ? '' : _vm.colEnd,
    '--bg-color': _vm.mobile ? '' : _vm.backgroundColor,
    'overflow-y': _vm.mobile ? 'visible' : _vm.overflowY,
  })},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }