<template>
  <div style="flex: 1 1 0">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "FlexBoxItem",
};
</script>
