<template>
	<v-row class="relative" style="top:45px;margin-bottom:45px;">
		<!-- title -->
		<v-row
			class="ml-0 pr-0 color--neutral-10-t bg--primary-90-t text-left"
			:class="isMobile ? '' : 'vh-100 '"
		>
			<v-col style="padding:25% 10% 5% 5%" :cols="isMobile ? 12 : 6">
				<h3 class="text-overline bold">CONTENIDO DEL PROGRAMA</h3>
				<h3 class="text-title">
					DFM consiste en un plan de estudio digital, que combate la deserción
					escolar y promueve la continuidad de estudios superiores, mediante la
					entrega de información persuasiva y personalizada a los estudiantes.
				</h3>
				<p class="text-body">
					De esta manera, a través de diferentes actividades y herramientas, que
					se van adaptando a las necesidades de cada estudiante, estos
					reflexionan sobre la importancia de las trayectorias educativas
					completas, discuten los problemas u obstáculos que podrían enfrentar
					para acceder a la Educación Superior y elaboran estrategias y planes
					que podrían permitirles abordarlos con éxito.
				</p>
			</v-col>
			<v-col v-if="!isMobile" :cols="isMobile ? 12 : 6" class="title" />
		</v-row>

		<!-- stages bar -->
		<v-row justify="center" align="center" class="bg--neutral-100">
			<v-col class="clickable" v-if="!isMobile" cols="3" @click="scrollLeft">
				<v-icon x-large :color="leftArrowColor">$arrowleftlarge</v-icon>
			</v-col>
			<v-col :cols="isMobile ? 12 : 6">
				<h1 class="text-heading bold color--primary">
					¡Conoce las etapas del programa!
				</h1>
			</v-col>
			<v-col class="clickable" v-if="isMobile" cols="5">
				<v-icon x-large :color="leftArrowColor" @click="scrollLeft"
					>$arrowleftlarge</v-icon
				>
			</v-col>
			<v-col class="clickable" :cols="isMobile ? 5 : 3" @click="scrollRight">
				<v-icon
					x-large
					:color="rightArrowColor"
					style="transform:rotate(180deg)"
					>$arrowleftlarge</v-icon
				>
			</v-col>
		</v-row>
		<!-- stages -->
		<div class="stages pa-0" ref="stages">
			<v-col class="pa-0" :style="isMobile ? 'width: 360px' : 'width: 550px'">
				<img
					src="@/assets/Home/Content/stage1.png"
					style="height: auto;"
					:style="isMobile ? 'width: 370px' : 'width: 550px'"
				/>
				<div class="stage-information">
					<div class="text-left pl-7" style="border-left: 1px solid #ccc;">
						<h3 class="text-overline">etapa 1 | el valor de la educación</h3>
						<p class="color--neutral-40-t">
							Su objetivo es que las y los estudiantes, a través de diversas
							actividades y herramientas, entiendan y comprendan la importancia
							que juega la educación a lo largo de sus vidas.
						</p>
					</div>
				</div>
			</v-col>
			<v-col class="pa-0">
				<img
					src="@/assets/Home/Content/stage2.png"
					style="height: auto;"
					:style="isMobile ? 'width: 370px' : 'width: 550px'"
				/>
				<div class="stage-information">
					<div class="text-left pl-7" style="border-left: 1px solid #ccc;">
						<h3 class="text-overline">
							etapa 2 | Los beneficios de la educación
						</h3>
						<p class="color--neutral-40-t">
							Su objetivo es que las y los estudiantes puedan conocer y ajustar
							sus expectativas sobre los beneficios monetarios y no monetarios
							de educarse.
						</p>
					</div>
				</div>
			</v-col>
			<v-col class="pa-0">
				<img
					src="@/assets/Home/Content/stage3.png"
					style="height: auto;"
					:style="isMobile ? 'width: 370px' : 'width: 550px'"
				/>
				<div class="stage-information">
					<div class="text-left pl-7" style="border-left: 1px solid #ccc;">
						<h3 class="text-overline">
							Etapa 3 | Camino a la Educación Superior
						</h3>
						<p class="color--neutral-40-t">
							Su objetivo es dar a conocer las diversas opciones que existen
							para acceder a la Educación Superior. Se busca orientar a las y
							los estudiantes, entregándoles información sobre la oferta
							académica y sus características.
						</p>
					</div>
				</div>
			</v-col>
			<v-col class="pa-0">
				<img
					src="@/assets/Home/Content/stage4.png"
					style="height: auto;"
					:style="isMobile ? 'width: 370px' : 'width: 550px'"
				/>
				<div class="stage-information">
					<div class="text-left pl-7" style="border-left: 1px solid #ccc;">
						<h3 class="text-overline">
							Etapa 4 | Financiamiento para seguir estudiando
						</h3>
						<p class="color--neutral-40-t">
							Su objetivo es que las y los estudiantes conozcan y comprendan las
							ayudas financieras que existen para cursar estudios de Educación
							Superior.
						</p>
					</div>
				</div>
			</v-col>
			<v-col class="pa-0">
				<img
					src="@/assets/Home/Content/stage5.png"
					style="height: auto;"
					:style="isMobile ? 'width: 370px' : 'width: 550px'"
				/>
				<div class="stage-information">
					<div class="text-left pl-7" style="border-left: 1px solid #ccc;">
						<h3 class="text-overline">
							Etapa 5 | Elaborando un plan de educación.
						</h3>
						<p class="color--neutral-40-t">
							Su objetivo es ayudar a las y los estudiantes a elaborar un plan o
							estrategia que les permita acceder a la Educación Superior y
							sortear con éxito los obstáculos que se presenten.
						</p>
					</div>
				</div>
			</v-col>
		</div>
		<v-row
			class="text-left ml-0 "
			style="z-index:1"
			:style="isMobile ? 'padding: 20% 5%' : 'padding: 7% 12%'"
		>
			<v-row class="text-title ml-0 bold mb-5">
				<v-col>
					¡Revisa lo que tenemos preparado para tu comunidad educativa!
				</v-col>
			</v-row>
			<v-row class="color--neutral-40-t ml-0">
				<v-col
					class="mb-5"
					v-for="(element, index) in whyJoinInformation"
					:key="index"
					:cols="isMobile ? 12 : 4"
				>
					<v-icon class="mb-5" color="#ff7497" x-large>{{
						element.icon
					}}</v-icon>
					<div
						style="border-top: 1px solid #ffbad4"
						:class="isMobile ? 'mb-2' : 'mb-10'"
					/>
					{{ element.text }}
				</v-col>
			</v-row>
		</v-row>
	</v-row>
</template>
<script>
	import { mapActions, mapGetters } from 'vuex';
	export default {
		data() {
			return {
				startTime: Date.now(),
				leftArrowColor: 'lightgray',
				rightArrowColor: '#ff7497',
				whyJoinInformation: [
					{
						icon: 'mdi-face-woman-outline',

						text:
							'Cuentas únicas para cada usuario: creamos cuentas para todos los participantes de tu comunidad.',
					},
					{
						icon: 'mdi-clipboard-plus-outline',
						text:
							'Encuestas y reportes: entregan información del nivel de conocimiento de los estudiantes y las comunidades educativas sobre distintas materias relevantes para sortear con éxito la educación media y acceder a la educación superior. La información que resulte de la aplicación de estas encuestas es analizada y sistematizada en reportes que luego son enviados a todos los establecimientos educacionales.',
					},
					{
						icon: 'mdi-video-outline',
						text:
							'Miniserie y videos animados: entrega de información de manera interactiva, donde a través de diferentes personajes se recrean historias y situaciones cotidianas que deben enfrentar las y los estudiantes durante su etapa escolar.',
					},
					{
						icon: 'mdi-view-dashboard-edit-outline',
						text:
							'Panel interactivo: directores, docentes y estudiantes pueden monitorear el avance del programa, conocer estadísticas interesantes y conectarse con herramientas y plataformas relacionadas con el objetivo del programa.',
					},
					{
						icon: 'mdi-message-badge-outline',
						text:
							'Mensajería y notificaciones: directores y docentes, utilizando esta herramienta y la información que proporcionan los estudiantes al programa, pueden enviarles notificaciones y mensajería para darles retroalimentación o para motivarlos a reflexionar sobre algún tema en particular.',
					},
				],
			};
		},
		beforeDestroy() {
			const currentTime = Date.now();
			const timeSpent = (currentTime - this.startTime) / 1000;
			this.track({
				event: 'home-content-time',
				data: { timeSpent: timeSpent },
			});
		},
		computed: {
			...mapGetters({
				isMobile: 'layout/isMobile',
			}),
		},
		methods: {
			...mapActions({
				track: 'authentication/track',
			}),
			scrollLeft() {
				this.track({
				event: 'home-content-scroll-left',
				data: {  },
				});
				this.$refs.stages.scrollBy({
					left: this.isMobile ? -370 : -550,
					behavior: 'smooth',
				});
				setTimeout(() => {
					if (this.$refs.stages.scrollLeft === 0) {
						this.leftArrowColor = 'gray';
					} else {
						this.leftArrowColor = '#ff7497';
					}
					if (
						this.$refs.stages.scrollLeft + this.$refs.stages.clientWidth ==
						this.$refs.stages.scrollWidth
					) {
						this.rightArrowColor = 'gray';
					} else {
						this.rightArrowColor = '#ff7497';
					}
				}, 400);
			},
			scrollRight() {
				this.track({
				event: 'home-content-scroll-right',
				data: {  },
				});
				this.$refs.stages.scrollBy({
					left: this.isMobile ? 370 : 550,
					behavior: 'smooth',
				});
				setTimeout(() => {
					if (this.$refs.stages === 0) {
						this.leftArrowColor = 'gray';
					} else {
						this.leftArrowColor = '#ff7497';
					}
					if (
						this.$refs.stages.scrollLeft + this.$refs.stages.clientWidth ==
						this.$refs.stages.scrollWidth
					) {
						this.rightArrowColor = 'gray';
					} else {
						this.rightArrowColor = '#ff7497';
					}
				}, 400);
			},
		},
	};
</script>
<style scoped>
	.title {
		background-size: cover;
		background-position: center;
		background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
			url('../../assets/Home/Content/background.png');
	}
	.stages {
		background: linear-gradient(
			90deg,
			#ff7497 0.236%,
			#ffbad4 19.846%,
			#ffbad4 19.498%,
			#ff7497 40%,
			#ff7497 40%,
			#ffd166 60%,
			#ffd166 60.852%,
			#ffba66 80%,
			#ffba66 80.432%,
			#ffbad4 95.676%
		);
		display: flex;
		overflow-x: scroll;
	}
	.stages::-webkit-scrollbar {
		background: white;
		height: 7px;
	}
	.stages::-webkit-scrollbar-thumb {
		background: var(--scrollbar-color, rgb(212, 211, 211));
		border-radius: 5px;
	}
	.stage-information {
		padding: 5% 3% 5% 10%;
		background: white;
		width: 100%;
		height: 210px;
	}
	@media (max-width: 768px) {
		.stage-information {
			height: 270px;
		}
	}
</style>
