<template>
	<!-- mixpanel on -->
	<div class="w-100 h-100" style="overflow:hidden">
		<custom-dialog
			v-model="phaseFinish"
			:closeIconStyles="'display: none'"
			@close="$router.push({ name: 'dashboard' })"
			@hide="$router.push({ name: 'dashboard' })"
			extraStyles="background: #FFF8FB"
		>
			<div class="m-4  flex-column">
				<custom-text class="mb-4 color--black" :text="phase.end_message" />
				<common-button
					text="Terminar"
					secondary
					@click="$router.push({ name: 'dashboard' })"
				/>
			</div>
		</custom-dialog>
		<div
			id="phase-header"
			class="pt-3 pb-3 ps-5 pe-5 w-100 d-flex justify-content-center align-items-center  bg--neutral-100-t"
			style="position:fixed;z-index:1;max-width:100vw !important"
		>
			<v-icon
				class="color--primary me-auto"
				style="position:relative:z-index:99999"
				@click="$router.push({ name: 'dashboard' })"
				>mdi-arrow-left</v-icon
			>
			<div class="mr-2">Etapa {{ phase.number + 1 }}</div>
			<v-divider vertical class="bg--secondary ms-2 mr-2"></v-divider>
			<img src="@/assets/Home/DFM.svg" style="height:17px" />
		</div>
		<div id="phase-content" class="w-100 h-100" :class="start ? '' : ' pt-12'">
			<div
				id="phase-stages"
				class="text-left h-100 relative"
				:class="start ? '' : 'pt-3 pb-3'"
				style="overflow-y:auto;"
				:style="'background: ' + background"
			>
				<div class="w-100 text-center">
					<div class="d-flex ms-auto ps-4 pe-4 me-auto">
						<v-progress-linear
							style="max-width:min(744px, 100vw)"
							v-if="!start"
							:value="progress"
							class="mt-2 mb-4 ms-auto me-auto ps-4 pe-4"
							background-color="white"
							rounded
							height="9"
						></v-progress-linear>
					</div>
				</div>

				<Stage :stage="currentStage" @nextStage="nextStage" />
				<div
					class="w-100 mt-3 ps-4 pe-4 ms-auto me-auto"
					style="max-width:min(744px, 100vw)"
					v-if="!start"
				>
					<div class="d-flex ms-auto me-auto">
						<common-button
							prependIcon="mdi-arrow-left"
							class="bg--neutral-100-t color--neutral-80-s"
							text="Volver"
							:disabled="stage == 0"
							@click="previousStage()"
						></common-button>
						<common-button
							text="Continuar"
							class="ms-auto"
							secondary
							:disabled="!buttonEnabled"
							@click="nextStage()"
						></common-button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import Stage from '@/components/phase/Stage.vue';
	import { mapGetters, mapActions } from 'vuex';
	export default {
		name: 'Phase',
		mounted() {
			if (!this.phase) {
				this.setCurrentPhaseDefault().then(() => {});
			}
			if (!this.phase.available) {
				this.setCurrentPhaseDefault().then(() => {});
			}
			// get current Stage
			this.phase.stages.forEach((s) => {
				if (this.$includes(this.completedStages, s.id)) {
					this.stage = Math.min(s.number + 1, this.phase.stages.length - 1);
				}
			});

			this.checkStageCompletion();
		},
		components: {
			Stage,
		},
		data() {
			return {
				start: true,
				stage: 0,
				buttonEnabled: false,
				phaseFinish: false,
			};
		},
		watch: {
			completedStages() {
				this.checkStageCompletion();
			},
		},
		computed: {
			...mapGetters({
				phase: 'program/currentPhase',
				phases: 'program/phases',
				completedStages: 'program/completedStages',
				user: 'authentication/user',
			}),
			progress() {
				if (
					this.stage == this.phase.stages.length - 1 &&
					this.phase.stages[this.stage].is_end
				) {
					return 100;
				}
				return (100 * this.stage) / this.phase.total;
			},
			user() {
				return this.$store.state.authentication.user;
			},
			currentStage() {
				return this.phase.stages[this.stage];
			},
			limit() {
				if (this.phaseFinish) {
					return 100;
				}
				if (this.phase) {
					let completedStages = this.completedStages.filter((id) =>
						this.$includes(
							this.phase.stages.map((s) => s.id),
							id
						)
					);
					return (100 * completedStages.length) / this.phase.total;
				}
				return 0;
			},
			background() {
				if (this.currentStage.format == 'inversed-background')
					return '#191F4B !important';
				else return '#F9F9F9';
			},
		},
		methods: {
			...mapActions({
				track: 'authentication/track',
				setCurrentPhaseDefault: 'program/setCurrentPhaseDefault',
			}),
			checkStageCompletion() {
				if (this.$includes(this.completedStages, this.currentStage.id)) {
					this.buttonEnabled = true;
				} else {
					this.buttonEnabled = false;
				}
				if (this.stage > 0) {
					this.start = false;
				} else {
					this.start = true;
				}
			},
			previousStage() {
				this.stage = Math.max(this.stage - 1, 0);
				this.checkStageCompletion();
				this.track({
					event: 'phase-go-to-previous-stage',
					data: { phase: this.phase.id, stage: this.stage },
				});
			},
			nextStage() {
				if (this.phase.stages[this.stage].is_end) {
					// finish phase
					this.phaseFinish = true;
				}
				this.stage = Math.min(this.stage + 1, this.phase.stages.length - 1);
				this.checkStageCompletion();
				this.track({
					event: 'phase-go-to-next-stage',
					data: { phase: this.phase.id, stage: this.stage },
				});
			},
		},
	};
</script>
