import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import ArrowLeftLarge from "@/components/icons/ArrowLeftLarge";
import ArrowRightLarge from "@/components/icons/ArrowRightLarge";
Vue.use(Vuetify);

// vuetify.ts

import TwitterIcon from "@/assets/Home/Twitter.vue";
import AppRegistration from "@/components/CustomIcon/AppRegistration.vue";

export default new Vuetify({
  icons: {
    values: {
      twitter: {
        // name of our custom icon
        component: TwitterIcon, // our custom component
      },
      "mdi-app-registration": {
        component: AppRegistration,
      },
      arrowleftlarge: {
        component: ArrowLeftLarge,
      },
      arrowrightlarge: {
        component: ArrowRightLarge,
      },
    },
  },
});
