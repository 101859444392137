<template>
  <div
    class="d-flex align-items-center"
    :class="mobile && !alwaysHorizontal ? 'flex-column' : 'flex-row'"
    :style="{ gap: `${gap}px` }"
  >
    <slot></slot>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "FlexBox",
  props: {
    alwaysHorizontal: {
      required: false,
      default: false,
      type: Boolean,
    },
    gap: {
      required: false,
      default: 0,
    },
  },
  computed: {
    ...mapGetters({
      mobile: "layout/isMobile",
    }),
  },
};
</script>
