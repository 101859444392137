/* Useful store elements platform-wise. */

import services from "../services";

/* Sets the initial state of the app. Useful for startup and logout. */
const getDefaultState = () => ({});

const state = {
  ...getDefaultState(),
};

const getters = {};

const mutations = {};

const actions = {
  reset({ state }) {
    Object.assign(state, getDefaultState());
  },
  // eslint-disable-next-line
  silentGet({}, { endpoint, params }) {
    return services.adminService.get({ endpoint, params }).then((response) => {
      return response;
    });
  },
  // eslint-disable-next-line
  silentGetWithHeaders({}, { endpoint, params, headers }) {
    return services.adminService
      .getWithHeaders({ endpoint, params, headers })
      .then((response) => {
        return response;
      });
  },
  get({ dispatch }, { endpoint, params }) {
    dispatch(
      "authentication/track",
      {
        event: "admin-get",
        data: { endpoint },
      },
      { root: true }
    );
    return services.adminService
      .get({ endpoint, params })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        dispatch(
          "layout/addToast",
          {
            toastState: "error",
            text: `Error accediendo a endpoint: ${endpoint}`,
          },
          { root: true }
        );
        throw err;
      });
  },
  // eslint-disable-next-line
  silentPost({ dispatch }, { endpoint, body }) {
    return services.adminService.post({ endpoint, body }).then((response) => {
      return response;
    });
  },
  // eslint-disable-next-line
  post({ dispatch }, { endpoint, body }) {
    dispatch(
      "authentication/track",
      {
        event: "admin-post",
        data: { endpoint },
      },
      { root: true }
    );
    return services.adminService
      .post({ endpoint, body })
      .then((response) => {
        dispatch(
          "layout/addToast",
          {
            toastState: "success",
            text: `Objeto creado correctamente.`,
          },
          { root: true }
        );
        return response;
      })
      .catch((err) => {
        dispatch(
          "layout/addToast",
          {
            toastState: 0,
            text: `Error creando objeto en endpoint ${endpoint}:  ${JSON.stringify(
              err.response.data
            )}`,
          },
          { root: true }
        );
        throw err;
      });
  },
  // eslint-disable-next-line
  silentPatch({ dispatch }, { endpoint, body }) {
    return services.adminService.patch({ endpoint, body }).then((response) => {
      return response;
    });
  },
  // eslint-disable-next-line
  patch({ dispatch }, { endpoint, body }) {
    dispatch(
      "authentication/track",
      {
        event: "admin-patch",
        data: { endpoint },
      },
      { root: true }
    );
    return services.adminService
      .patch({ endpoint, body })
      .then((response) => {
        dispatch(
          "layout/addToast",
          {
            toastState: "success",
            text: `Cambios actualizados correctamente.`,
          },
          { root: true }
        );
        return response;
      })
      .catch((err) => {
        dispatch(
          "layout/addToast",
          {
            toastState: "error",
            text: `Error actualizando objeto en endpoint ${endpoint}:  ${JSON.stringify(
              err.response.data
            )}`,
          },
          { root: true }
        );
        throw err;
      });
  },
  delete({ dispatch }, { endpoint, id }) {
    dispatch(
      "authentication/track",
      {
        event: "admin-delete",
        data: { endpoint },
      },
      { root: true }
    );
    return services.adminService
      .delete({ endpoint, id })
      .then((response) => {
        dispatch(
          "layout/addToast",
          {
            toastState: "success",
            text: `Objeto eliminado correctamente.`,
          },
          { root: true }
        );
        return response;
      })
      .catch((err) => {
        dispatch(
          "layout/addToast",
          {
            toastState: "error",
            text: `Error eliminando objeto de id ${id} en endpoint ${endpoint}`,
          },
          { root: true }
        );
        throw err;
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
