<template>
	<svg
		width="66"
		height="40"
		viewBox="0 0 66 40"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g filter="url(#filter0_d_415_2981)">
			<path
				d="M61.0607 17.0607C61.6464 16.4749 61.6464 15.5251 61.0607 14.9393L51.5147 5.39339C50.9289 4.80761 49.9792 4.80761 49.3934 5.3934C48.8076 5.97918 48.8076 6.92893 49.3934 7.51472L57.8787 16L49.3934 24.4853C48.8076 25.0711 48.8076 26.0208 49.3934 26.6066C49.9792 27.1924 50.9289 27.1924 51.5147 26.6066L61.0607 17.0607ZM12 17.5L60 17.5L60 14.5L12 14.5L12 17.5Z"
				fill="#FF7497"
			/>
		</g>
		<defs>
			<filter
				id="filter0_d_415_2981"
				x="0"
				y="0.954102"
				width="65.5"
				height="38.0918"
				filterUnits="userSpaceOnUse"
				color-interpolation-filters="sRGB"
			>
				<feFlood flood-opacity="0" result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dx="-4" dy="4" />
				<feGaussianBlur stdDeviation="4" />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_415_2981"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_415_2981"
					result="shape"
				/>
			</filter>
		</defs>
	</svg>
</template>
<script>
	export default {
		name: 'ArrowRightLarge.svg',
	};
</script>
