<template>
  <div class="relative" id="map" style="background:white">
    <!-- symbology -->
    <v-dialog
      id="symbology"
      width="600px"
      style="z-index:1002"
      v-model="showInformation"
      @click:outside="showInformation = false"
    >
      <v-card style="font-family: Montserrat !important">
        <v-container>
          <v-row>
            <v-col class="d-flex">
              <div class="me-auto">Leyenda</div>

              <v-icon @click="showInformation = false" class="ms-auto"
                >mdi-close</v-icon
              >
            </v-col>
          </v-row>
          <v-row v-for="(i, index) in [0, 1, 2]" :key="index" class="text-left">
            <v-col>
              <div class="pt-1 ps-2">
                <img
                  class="me-2"
                  style="height:17px;width:24px"
                  :src="
                    require(`@/assets/maps/marker_group_${
                      ['big', 'medium', 'small'][index]
                    }.svg`)
                  "
                />
                <!-- <v-icon
                  :style="{ color: ['#EB5757', '#F2C94C', '#27AE60'][index] }"
                  >mdi-circle</v-icon
                > -->
                {{
                  [
                    "Alta concentración",
                    "Media concentración",
                    "Baja concentración",
                  ][index]
                }}
              </div>
            </v-col>
          </v-row>
          <v-divider />
          <v-row
            v-for="(i, index) in [0, 1, 2, 3]"
            :key="index"
            class="text-left"
          >
            <v-col>
              <div class="pt-1 ps-2">
                <v-icon
                  :style="{
                    color: ['#00405B', '#EB5757', '#F2C94C', '#27AE60'][index],
                  }"
                  >mdi-circle</v-icon
                >
                <!-- <v-icon
                  :style="{ color: ['#EB5757', '#F2C94C', '#27AE60'][index] }"
                  >mdi-circle</v-icon
                > -->
                {{ filterNames[index] }}
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <!-- student info -->
    <v-dialog
      @click:outside="closeDialog"
      id="studentInfo"
      v-model="showDialog"
      v-if="showDialog"
      style="z-index:1002"
      width="600px"
    >
      <v-card
        class="text-left p-3"
        style="background:white;line-height:30px;font-family: Montserrat !important;color: #00405B !important;"
      >
        <div class="w-100 d-flex">
          <h1 class="bold">
            Información
          </h1>
          <v-icon class="ms-auto mb-auto" color="#00405B" @click="closeDialog"
            >mdi-close</v-icon
          >
        </div>
        <h4>{{ studentInfo.name }}</h4>
        <h6 v-if="studentInfo.age" class="bold">Edad: {{ studentInfo.age }}</h6>
        <div v-if="studentInfo.last_school">
          Última institución educativa: {{ studentInfo.last_school }}
        </div>
        <div v-if="studentInfo.phone">
          Número telefónico: {{ studentInfo.phone }}
        </div>

        <div class="d-flex">
          <v-icon
            :style="{
              color: ['#00405B', '#EB5757', '#F2C94C', '#27AE60'][
                studentInfo.state
              ],
            }"
            >mdi-circle</v-icon
          >
          <div>{{ filterNames[studentInfo.state] }}</div>
        </div>
        <v-radio-group v-model="studentInfo.state" @change="changeState" row>
          <v-radio
            style="color:#00405B !important"
            v-for="n in [0, 1, 2]"
            :key="n"
            :label="filterNames[n]"
            :value="n"
          ></v-radio>
        </v-radio-group>
        <div class="w-100 d-flex vertical-align-middle mb-2">
          <img
            style="height:24px"
            class="mt-1 me-2"
            src="@/assets/maps/marker_blue.svg"
          />
          <div
            @click="changeAddress"
            style="color:blue;cursor:pointer;margin-top:2px"
            class="bold"
          >
            Modificar ubicación en mapa
          </div>
        </div>
        <map-input
          v-model="studentInfo.address"
          question="Dirección"
          @setValue="setValue(...arguments, 'address')"
          label=""
        />
        <map-button
          label="Ir a google maps"
          class="text-center mt-4 p-4 w-100"
          @click="openGoogleMaps(studentInfo.lat, studentInfo.lng)"
        >
          s</map-button
        >
        <map-button
          label="Ir a google forms"
          rounded
          class="text-center mt-4 p-4 w-100"
          @click="openGoogleForms()"
        >
        </map-button>
        <!--<map-input
          class="mt-2"
          v-model="studentInfo.observation"
          question="Observaciones"
          label="Escribe tus observaciones aquí..."
          @setValue="setValue(...arguments, 'observation')"
        />-->
      </v-card>
    </v-dialog>
    <v-container
      id="navbar"
      style="width:100vw"
      :style="{ 'min-height': mobile ? '' : '64px' }"
    >
      <div
        :style="{ height: mobile ? '40px !important' : '50px !important' }"
        class="d-flex mt-auto mb-auto vertical-align-middle flex-row"
      >
        <div
          :style="{ height: mobile ? '40px !important' : '50px !important' }"
          class="d-flex flex-column text-center vertical-align-middle align-items-center"
        >
          <v-img contain src="@/assets/maps/logo.svg" class="me-2" />
        </div>
        <map-selector
          v-if="!mobile"
          v-model="municipality"
          :items="municipalities"
          item-text="name"
          item-value="position"
          label="Municipio"
        />
        <map-autocomplete
          :items="students"
          label="Buscar por nombre"
          @input="selectStudent"
          :style="{
            transform: mobile ? 'scale(0.8)' : '',
            'transform-origin': mobile ? 'center center' : '',
          }"
        />
        <div
          width="48px"
          class="ms-auto rounded d-flex justify-content-center text-center"
          background="red"
          style="height:48px;width:48px !important;max-width:48px !important;background:#00405B;"
          flat
          elevation="0"
        >
          <v-icon color="white" class="ms-1" @click="performLogout"
            >mdi-logout</v-icon
          >
        </div>
      </div>
    </v-container>

    <div
      id="changeAddressInformation"
      v-if="isChangingAddress"
      class="absolute rounded"
      style="left:10px;right:10px;top:90px;z-index:3"
    >
      <v-card background="white" class="w-100 text-center">
        <div class="d-flex p-2 text-center w-100">
          Arrastra el pin a la ubicación deseada. Puedes cancelar la
          modificación presionando "Cancelar" o guardar la modificación
          presionando "Aceptar".
        </div>
      </v-card>
    </div>

    <div
      id="information"
      v-if="!isChangingAddress"
      class="absolute"
      style="top:calc(100vh - 50px);right:20px;z-index:1001"
    >
      <v-btn fab small @click="showInformation = true">
        <v-icon>mdi-help-circle</v-icon></v-btn
      >
    </div>
    <!-- new -->
    <!-- end new -->
    <div
      class="d-flex flex-column absolute"
      style="background:green"
      :style="{
        top: mobile ? '70px !important' : '90px !important',
        left: mobile ? '5px !important' : '30px !important',
      }"
    >
      <map-selector
        v-if="mobile"
        v-model="municipality"
        :items="municipalities"
        style="min-height:42px"
        :style="{
          transform: mobile ? 'scale(0.8)' : '',
          'transform-origin': mobile ? 'top left' : '',
        }"
        item-text="name"
        item-value="position"
        label="Municipio"
      />
      <div
        id="filter-button"
        v-if="!isChangingAddress && !showDialog"
        :style="{
          transform: mobile ? 'scale(0.8)' : '',
          'transform-origin': mobile ? 'top left' : '',
          'max-width': mobile ? '100px' : '',
        }"
        style="z-index:1000;"
      >
        <v-card
          @click="showFilters = !showFilters"
          style="color:white;background:#00405B;"
        >
          <div class="p-2">Filtro <v-icon color="white">mdi-tune</v-icon></div>
        </v-card>
      </div>

      <div
        id="filters"
        :style="{
          transform: mobile ? 'scale(0.8)' : '',
          'transform-origin': mobile ? 'top left' : '',
        }"
        style="z-index:1000;"
        v-if="showFilters"
      >
        <v-card v-click-outside="onClickOutside">
          <v-container>
            <v-row v-for="(i, index) in filters" :key="index">
              <v-col class="d-flex flex-row text-left">
                <div class="pt-1 ps-2 me-2" style="width:150px">
                  <v-icon
                    :style="{
                      color: ['#00405B', '#EB5757', '#F2C94C', '#27AE60'][
                        index
                      ],
                    }"
                    >mdi-circle</v-icon
                  >
                  {{ filterNames[index] }}
                </div>
                <label class="switch">
                  <input
                    type="checkbox"
                    v-model="filters[index]"
                    @change="change(...arguments, index)"
                  />
                  <span class="slider round"></span>
                </label>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </div>
    </div>

    <GmapMap
      :center="center"
      :zoom="zoom"
      ref="map"
      :options="{
        disableDefaultUI: true,
        // clickableIcons: false,
        mapTypeControl: false,
        styles: mapStyle,
        fullscreenControl: false,
        streetViewControl: false,
        // gestureHandling: 'greedy',
      }"
      style="width: 100vw; height: calc(100vh - 70px)"
    >
      <!-- the higher the max-zoom, the closer I can get without disabling clusters -->
      <gmap-cluster
        :styles="clusterStyles"
        :max-zoom="16"
        v-if="showMarkers"
        :minimumClusterSize="5"
        :zoomOnClick="true"
      >
        <gmap-marker
          :icon="getIcon(m.state)"
          :key="index"
          v-for="(m, index) in shownMarkers"
          :position="{ lat: m.lat, lng: m.lng }"
          :clickable="true"
          :draggable="isChangingAddress"
          @drag="dragMarker"
          @click="clickStudent(m)"
        />
      </gmap-cluster>
    </GmapMap>
    <div
      class="absolute"
      style="bottom:20px;right:20px"
      v-if="isChangingAddress"
    >
      <v-card background="white">
        <div class="d-flex p-2">
          <map-button
            inverted
            label="Cancelar"
            class="me-2"
            @click="setAddress(false)"
          />
          <map-button label="Aceptar" @click="setAddress(true)" />
        </div>
      </v-card>
    </div>
  </div>
</template>
<script>
// import ElementaryJSON from "@/assets/maps/departmentsColombia.json";
import mapStyle from "@/assets/maps/mapStyle.json";
// import municipalities from "@/assets/maps/municipiosColombia.json";
import locations from "@/assets/maps/locations.json";
import MapSelector from "@/components/map/MapSelector.vue";
import MapAutocomplete from "@/components/map/MapAutocomplete.vue";
import MapInput from "@/components/map/MapInput.vue";
import MapButton from "@/components/map/MapButton.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Map",
  components: { MapAutocomplete, MapInput, MapButton, MapSelector },
  data() {
    return {
      municipality: null,
      municipalities: [
        { position: { lat: 4.674994014, lng: -75.7797919013 }, name: "Alcalá" },
        {
          position: { lat: 4.1717131973, lng: -76.1679246335 },
          name: "Andalucía",
        },
        {
          position: { lat: 4.7949836716, lng: -75.9920029831 },
          name: "Ansermanuevo",
        },
        {
          position: { lat: 4.7269447056, lng: -76.1199046459 },
          name: "Argelia",
        },
        {
          position: { lat: 4.3378460768, lng: -76.1835827105 },
          name: "Bolívar",
        },
        {
          position: { lat: 3.8757075934, lng: -77.0107373308 },
          name: "Buenaventura",
        },
        {
          position: { lat: 4.2083315915, lng: -76.1568077442 },
          name: "Bugalagrande",
        },
        {
          position: { lat: 4.3347098809, lng: -75.830610628 },
          name: "Caicedonia",
        },
        { position: { lat: 3.4143724548, lng: -76.5215517909 }, name: "Cali" },
        {
          position: { lat: 3.9336642655, lng: -76.4841316048 },
          name: "Calima",
        },
        {
          position: { lat: 3.4077664654, lng: -76.3464289062 },
          name: "Candelaria",
        },
        {
          position: { lat: 4.7423360367, lng: -75.9243733888 },
          name: "Cartago",
        },
        { position: { lat: 3.6573084842, lng: -76.6888710083 }, name: "Dagua" },
        {
          position: { lat: 4.7608738372, lng: -76.2216111269 },
          name: "El Cairo",
        },
        {
          position: { lat: 3.6842899469, lng: -76.3119118335 },
          name: "El Cerrito",
        },
        {
          position: { lat: 4.5104518894, lng: -76.2370839501 },
          name: "El Dovio",
        },
        {
          position: { lat: 4.906061519, lng: -76.0427792567 },
          name: "El Águila",
        },
        {
          position: { lat: 3.323878599, lng: -76.2343495871 },
          name: "Florida",
        },
        {
          position: { lat: 3.724181147, lng: -76.2680678885 },
          name: "Ginebra",
        },
        { position: { lat: 3.762667999, lng: -76.331055021 }, name: "Guacarí" },
        {
          position: { lat: 3.9007891315, lng: -76.2989592552 },
          name: "Guadalajara De Buga",
        },
        {
          position: { lat: 3.2591723295, lng: -76.5383802797 },
          name: "Jamundí",
        },
        {
          position: { lat: 3.6492677894, lng: -76.5680500295 },
          name: "La Cumbre",
        },
        {
          position: { lat: 4.5338688039, lng: -76.09966061 },
          name: "La Unión",
        },
        {
          position: { lat: 4.5236032634, lng: -76.0365287838 },
          name: "La Victoria",
        },
        { position: { lat: 4.5757117937, lng: -75.974709975 }, name: "Obando" },
        {
          position: { lat: 3.4198470006, lng: -76.2418321776 },
          name: "Pradera",
        },
        {
          position: { lat: 3.8213506755, lng: -76.523329203 },
          name: "Restrepo",
        },
        {
          position: { lat: 4.1569076467, lng: -76.2883131302 },
          name: "Riofrío",
        },
        {
          position: { lat: 4.4136010076, lng: -76.1522790271 },
          name: "Roldanillo",
        },
        {
          position: { lat: 3.995073116, lng: -76.2286918766 },
          name: "San Pedro",
        },
        {
          position: { lat: 4.2707111891, lng: -75.9316233719 },
          name: "Sevilla",
        },
        { position: { lat: 4.6080850923, lng: -76.0768590075 }, name: "Toro" },
        {
          position: { lat: 4.2120369123, lng: -76.3188183767 },
          name: "Trujillo",
        },
        { position: { lat: 4.0852748237, lng: -76.1976614004 }, name: "Tuluá" },
        { position: { lat: 4.7036231585, lng: -75.7378080188 }, name: "Ulloa" },
        {
          position: { lat: 4.5750186819, lng: -76.1992031845 },
          name: "Versalles",
        },
        { position: { lat: 3.6986855365, lng: -76.4418043621 }, name: "Vijes" },
        {
          position: { lat: 3.8612215795, lng: -76.3824698607 },
          name: "Yotoco",
        },
        { position: { lat: 3.5400888418, lng: -76.4992327953 }, name: "Yumbo" },
        {
          position: { lat: 4.3926584805, lng: -76.0707946911 },
          name: "Zarzal",
        },
        {
          position: { lat: 3.5323548679, lng: -76.2985675175 },
          name: "Palmira",
        },
      ],
      observations: "",
      previousMarkers: [],
      isChangingAddress: false,
      isChangingAddressText: false,
      clusterStyles: [
        {
          textColor: "white",
          url: require("@/assets/maps/marker_group_small.svg"),
          height: 100,
          width: 100,
          anchorIcon: [24, 24],
          anchorText: [-22, -26],
        },
        {
          textColor: "white",

          url: require("@/assets/maps/marker_group_medium.svg"),
          height: 200,
          width: 200,
          anchorIcon: [24, 26],
          anchorText: [-70, -74],
        },
        {
          textColor: "white",

          url: require("@/assets/maps/marker_group_big.svg"),
          height: 300,
          width: 300,
          anchorIcon: [24, 24],
          anchorText: [-116, -120],
        },
      ],
      filters: [true, true, true, true], // waiting, deserter, observation, visited
      filterNames: [
        "En espera",
        "Visita sin éxito",
        " Visita exitosa",
        "Matriculado",
      ],
      currentState: null,
      showButton: true,
      showVisited: true,
      showMarkers: false,
      showInformation: false,
      student: null,
      studentInfo: { state: null },
      markers: locations["locations"],
      shownMarkers: locations["locations"],
      center: { lat: 3.359889, lng: -76.638565 },
      paths: [],
      mapStyle: mapStyle,
      zoom: 10,
      geojson: null,
      showDialog: false,
      showFilters: false,
      previousAddress: null,
      changedAddress: null,
    };
  },
  watch: {
    municipality(position) {
      this.center = position;
    },
  },
  methods: {
    ...mapActions({
      get: "admin/silentGet",
      patch: "admin/silentPatch",
      logout: "authentication/logout",
    }),
    openGoogleMaps(lat, lng) {
      window.open(`http://www.google.com/maps/place/${lat},${lng}`, "_blank");
    },
    openGoogleForms() {
      window.open(
        "https://docs.google.com/forms/d/e/1FAIpQLScXaBKapbD1AAUMRmRAYdwz30YIAVq2exLsESGhVu73hVdTiA/viewform?vc=0&c=0&w=1&flr=0",
        "_blank"
      );
    },
    performLogout() {
      this.logout()
        .then(() => {})
        .catch(() => {})
        .finally(() => {
          this.$router.push({ name: "maplogin" });
        });
    },
    setValue(value, key) {
      this.patch({
        endpoint: `studentinformation`,
        body: { [key]: value, id: this.studentInfo.id },
      });
    },
    dragMarker(event) {
      this.changedAddress = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
    },
    changeAddress() {
      this.showDialog = false;
      this.isChangingAddress = true;
      this.previousMarkers = this.shownMarkers;
      this.shownMarkers = this.shownMarkers.filter(
        (m) => m.name == this.studentInfo.name
      );
      let marker = this.shownMarkers[0];
      this.changedAddress = { lat: marker.lat, lng: marker.lng };
      this.previousAddress = this.changedAddress;
    },
    setAddress(address) {
      if (address === false) {
        this.isChangingAddress = false;
        this.studentInfo.lat = this.previousAddress.lat;
        this.studentInfo.lng = this.previousAddress.lng;
      } else {
        this.studentInfo.lat = this.changedAddress.lat;
        this.studentInfo.lng = this.changedAddress.lng;
        this.setValue(this.changedAddress.lat, "lat");
        this.setValue(this.changedAddress.lng, "lng");
      }
      this.changedAddress = null;
      this.shownMarkers = this.previousMarkers;
      this.previousMarkers = [];
      this.isChangingAddress = false;
    },
    changeState() {
      let studentInfo = this.studentInfo;
      this.studentInfo = null;
      this.$nextTick(() => {
        this.studentInfo = studentInfo;
        this.setValue(studentInfo.state, "state");
      });
    },
    closeDialog() {
      this.showDialog = false;
      // this.setStudentState(this.studentInfo.name, this.currentState);
    },
    onClickOutside() {
      this.showFilters = false;
    },
    change() {
      this.$nextTick(() => {
        let markers = this.markers;
        // set filters
        if (this.filters[0] == false) {
          // hide deserter
          markers = markers.filter((s) => s.state != 0);
        }
        if (this.filters[1] == false) {
          // hide observation
          markers = markers.filter((s) => s.state != 1);
        }
        if (this.filters[2] == false) {
          // hide visited
          markers = markers.filter((s) => s.state != 2);
        }
        if (this.filters[3] == false) {
          // hide visited
          markers = markers.filter((s) => s.state != 3);
        }
        this.shownMarkers = markers;
      });
    },
    getIcon(state) {
      if (state == 0) {
        return { url: require("@/assets/maps/marker_blue.svg") };
      } else if (state == 1) {
        return { url: require("@/assets/maps/marker_red.svg") };
      } else if (state == 2) {
        return { url: require("@/assets/maps/marker_yellow.svg") };
      } else {
        return { url: require("@/assets/maps/marker_green.svg") };
      }
    },
    getIconSingle(state) {
      if (state == 0) {
        return require("@/assets/maps/marker_blue.svg");
      } else if (state == 1) {
        return require("@/assets/maps/marker_red.svg");
      } else if (state == 2) {
        return require("@/assets/maps/marker_yellow.svg");
      } else {
        return require("@/assets/maps/marker_green.svg");
      }
    },
    clickStudent(m) {
      this.studentInfo = m;
      this.currentState = m.state;
      // this.setStudentState(m.name, 2);

      // this.center = m;
      if (m.state == 1) {
        this.showButton = false;
      } else {
        this.showButton = true;
      }
      this.showDialog = true;
    },
    selectStudent(student) {
      let marker = this.markers.filter((m) => m.name === student)[0];
      this.studentInfo = marker;
      this.center = { lat: marker.lat, lng: marker.lng };
      if (marker.state == 1) {
        this.showButton = false;
      } else {
        this.showButton = true;
      }
      this.$refs.map.$mapObject.setZoom(20);
    },
    setStudentState(studentName, state) {
      this.studentInfo.state = state;
      this.markers.find((m) => m.name === studentName).state = state;
      if (state == 1) {
        this.showButton = false;
      }
    },
  },
  mounted() {
    this.get({ endpoint: "studentinformation", params: {} })
      .then((response) => {
        this.markers = response.data.filter(
          (x) => x.lat != null && x.lng != null
        );
        this.shownMarkers = this.markers;
        this.showMarkers = true;
      })
      .catch(() => {
        this.performLogout();
      });
  },
  computed: {
    ...mapGetters({
      mobile: "layout/isMobile",
    }),
    students() {
      return this.markers.map((m) => m.name);
    },
    zoomOpacity() {
      if (this.zoom >= 7) {
        return 0;
      } else {
        return 1;
      }
    },
  },
};
</script>
<style>
/* The switch - the box around the slider */
#map {
  font-family: Montserrat;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
