<template>
  <v-dialog
    v-model="computedValue"
    :width="width"
    @click:outside="hideDialog()"
  >
    <v-card
      class="text-body"
      :class="[...extraClasses, fullScreen ? '' : ' pt-4 pb-4 ps-5 pe-5']"
      :style="extraStyles"
      :height="height"
    >
      <p
        class="w-100 text-right clickable pb-0 mb-0"
        @click="closeDialog()"
        :style="closeIconStyles"
      >
        <v-icon>mdi-close</v-icon>
      </p>

      <slot></slot>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "CustomDialog",
  props: {
    value: {
      required: true,
    },
    width: {
      required: false,
      default: "300",
      type: String,
    },
    height: {
      required: false,
      type: String,
      default: "auto",
    },
    extraStyles: {
      required: false,
      default: "",
      type: String,
    },
    closeIconStyles: {
      required: false,
      default: "",
      type: String,
    },
    fullScreen: {
      required: false,
      default: false,
    },
    extraClasses: {
      required: false,
      default: "",
      type: String,
    },
  },
  methods: {
    closeDialog() {
      this.computedValue = false;
      this.$emit("close");
    },
    hideDialog() {
      this.$emit("hide");
    },
  },
  computed: {
    ...mapGetters({
      mobile: "layout/isMobile",
    }),
    computedValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
};
</script>
