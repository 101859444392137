<template>
  <v-autocomplete
    outlined
    dense
    v-bind="$attrs"
    v-model="computedValue"
  ></v-autocomplete>
</template>
<script>
export default {
  name: "CommonDropdown",
  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
  props: {
    value: {
      required: true,
    },
  },
};
</script>
