<template>
  <div>
    <!-- header -->
    <div
      class="d-flex w-100 p-3 bg--neutral-100-t shadow-left"
      style="position:fixed;z-index: 99;top:0px"
    >
      <img
        class="clickable"
        @click="$router.push({ name: 'home' })"
        src="@/assets/Home/DFM.svg"
        style="height:20px;max-height:20px"
      />
    </div>
    <!-- end header -->
    <v-card
      style="height: 60%; width: max-content; margin: auto; margin-top: 7%; padding: 20px "
    >
      <v-col cols="12" sm="8" class="d-flex flex-column progress-content">
        <responsive-div
          desktopStyle="margin-top:20%"
          mobileStyle="margin-top: 30%"
          class="progress-heading color--primary-70-s mb-3 relative"
        >
          <div
            class="bg--tertiary absolute"
            style="height:119px;width:119px;border-radius:60px;z-index:97;top:-60px"
          ></div>
          <div
            class="relative progress-heading"
            style="z-index:98; text-align: left;"
          >
            ¿Quieres saber el avance de las respuestas de tus alumnos?
          </div>
        </responsive-div>
        <div class=" text-body schedule-line-height" style="text-align: left">
          ¡Eres parte de los más de 800 establecimientos educacionales que están
          participando de Decidiendo para un Futuro Mejor!
        </div>
        <div
        v-if="totalStudents > 0"
          class="mb-3 text-body schedule-line-height"
          style="text-align: left"
        >
          Aquí conocerás el avance de las respuestas de tus estudiantes
        </div>
        <div class="w-100 text-center" v-if="null === percentage">
          <v-progress-circular
            class="color--primary mb-15"
            :rotate="270"
            size="60"
            width="5"
            indeterminate
          />
        </div>
        <div v-else>
          <div
          v-if="totalStudents > 0"
            class="mb-3 text-body schedule-line-height"
            style="text-align: left"
          >
            <v-icon style="top:-2px" class="color--primary"
              >mdi-school-outline</v-icon
            >
            Del total* de tus estudiantes aproximadamente el:
            <h4 style="text-align:center" class="mt-5 mb-5">
              <span class="color--tertiary-20-s" style="font-weight: bolder"
                >{{ percentage }}%</span
              >
              han contestado
            </h4>
          </div>
          <div
            class="mb-3 text-body schedule-line-height"
            style="text-align: left"
          >
            <v-icon style="top:-2px" class="color--primary">mdi-human</v-icon>
            Aquí tienes la información de la cantidad de estudiantes que han
            contestado por cada curso
            <div
              style="margin-left:5%"
              v-for="(_class, index) in classes"
              :key="index"
            >
              <span class="color--tertiary-20-s"
                >{{ addCircle(_class.name) }}:
              </span>
              <span>{{ _class.total }}</span>
            </div>
          </div>
          <div
            class="mb-3 text-body schedule-line-height"
            style="text-align: left; font-style:italic"
          >
            *Información actualizada al {{ date }}
            
          </div>
          <div
            class="mb-3 text-body schedule-line-height"
            style="text-align: left"
          >
            <v-icon style="top:-2px" class="color--primary"
              >mdi-help-circle-outline</v-icon
            >
            ¿Dudas?
          </div>
          <div
            class="mb-10 text-body schedule-line-height"
            style="text-align: left"
          >
            Ante cualquier consulta, no dudes en comunicarte con nuestros
            equipos al correo
            <a href="mailto: decidiendofuturomejor@mineduc.cl"
              >decidiendofuturomejor@mineduc.cl</a
            >
          </div>
        </div>
      </v-col>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "Progress",
  data() {
    return {
      percentage: null,
      classes: null,
      date: null,
      totalStudents: null,
    };
  },
  mounted() {
    const { uuid } = this.$route.params;
    this.getQualtricsProgress({ params: { uuid } }).then((data) => {
      this.percentage = data.percentage;
      this.classes = data.classes;
      this.date = this.getDate(data.last_update);
      this.totalStudents = data.total_students
    });
  },
  methods: {
    ...mapActions({
      getQualtricsProgress: "qualtrics/getQualtricsProgress",
    }),
    addCircle(class_string) {
      return class_string.split("")[0] + "°" + class_string.split("")[1];
    },
    getDate(originalDate) {
      if (originalDate) {
        var date = new Date(originalDate);
        var formatOptions = {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
        };
        var dateString = date.toLocaleDateString("en-UK", formatOptions);
        dateString = dateString
          .replace(",", "")
          .replace("PM", "p.m.")
          .replace("AM", "a.m.");

        return dateString;
      }
      return this.$t(
        "dashboard.main-panel.individual-progress.student-progress.no-login"
      );
    },
  },
};
</script>
<style scoped>
.progress-content {
  margin: auto;
  gap: 24px;
}
.progress-heading {
  font-style: normal;
  font-weight: 900;
  font-size: 42px;
  line-height: 51px;
}
</style>
