<template>
	<div>
			
			<embed
				src="Protocolo.pdf"
				style="width: 100%; height: 100vh"
			/>
	</div>
</template>
<script>
	import mixpanel from 'mixpanel-browser';
	export default {
		name: 'PDFView',
		mounted() {
			mixpanel.track(
				'enter_pdf_protocol',
				{ env: process.env.VUE_APP_ENVIRONMENT },
				() => {}
			);
		},
	};
</script>
