<template>
  <div>
    <div
      class="p-2"
      style="background:#E0E0E0;border: solid black; border-width: 0px 0px 1px 0px;border-radius: 5px 5px 0px 0px"
    >
      {{ question }}
    </div>
    <div
      style="border-radius:0px 0px 5px 5px !important;overflow:hidden;background:#E0E0E0 !important;"
      class="d-flex"
    >
      <v-text-field
        class="map-input ps-2"
        v-model="specificValue"
        :disabled="!editing"
        :label="
          editing || (specificValue !== '' && specificValue !== null)
            ? ''
            : label
        "
        hide-details
        style="background:#E0E0E0 !important;margin-top:0px !important;"
      >
      </v-text-field>
      <v-icon class="ms-2 me-2" @click="editing = true" v-if="!editing"
        >mdi-pencil</v-icon
      >
      <v-icon class="ms-2 me-2" @click="finishEditing" v-else>mdi-check</v-icon>
    </div>
  </div>
</template>
<script>
export default {
  name: "MapInput",
  data() {
    return {
      specificValue: "",
      editing: false,
    };
  },
  mounted() {
    this.specificValue = this.value;
  },
  props: {
    value: {
      required: true,
    },
    question: {
      required: true,
    },
    label: {
      required: true,
    },
  },
  methods: {
    finishEditing() {
      this.editing = false;
      this.$emit("setValue", this.specificValue);
    },
  },
};
</script>
<style>
.map-input.v-input > .v-input__control > .v-input__slot {
  border-radius: 0px 0px 15px 15px !important;
}
.map-input.v-input > .v-input__control > .v-input__slot {
  border-style: none !important;
}
</style>
