var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contact-form-form"},[_c('div',{domProps:{"innerHTML":_vm._s(
      _vm.$t('contact-form.contact-form.declaration', {
        form_name: _vm.formName,
        form_school: _vm.formSchool,
        form_position: _vm.formPosition,
      })
    )}}),_c('b-form-group',[_c('b-form-radio-group',{attrs:{"id":"radio-group-2","name":"radio-options-2"},on:{"input":function($event){
    var i = arguments.length, argsArray = Array(i);
    while ( i-- ) argsArray[i] = arguments[i];
return _vm.sendToParent.apply(void 0, argsArray.concat( [_vm.consent], ['consent'] ))}},model:{value:(_vm.consent),callback:function ($$v) {_vm.consent=$$v},expression:"consent"}},[_c('div',{staticStyle:{"width":"10px","height":"10px"}}),_c('b-form-radio',{staticClass:"mt-1 mb-1",attrs:{"value":true}},[_vm._v(_vm._s(_vm.$t("contact-form.contact-form.accept")))]),_c('div',{staticStyle:{"width":"10px","height":"10px"}}),_c('b-form-radio',{staticClass:"mt-1 mb-1",attrs:{"value":false}},[_vm._v(_vm._s(_vm.$t("contact-form.contact-form.deny"))+" ")]),_c('div',{staticStyle:{"width":"10px","height":"10px"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }