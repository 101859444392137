import services from "@/services";

const getDefaultState = () => ({
  students: [],
});

const state = {
  ...getDefaultState(),
};

const getters = {
  students: ({ students }) => students,
};

const mutations = {
  setStudents(state, { students }) {
    state.students = students;
  },
};

const actions = {
  reset({ state }) {
    Object.assign(state, getDefaultState());
  },
  getStudents({ commit }, { params }) {
    return services.studentService.getStudents({ params }).then((response) => {
      const students = response.data;
      commit("setStudents", { students });
      return students;
    });
  },
  // eslint-disable-next-line
  uploadStudents({ dispatch }, { file }) {
    return services.studentService.uploadStudents(file).then(() => {
      dispatch("_class/getClasses", null, { root: true }).then(() => {
        dispatch("_class/getClassStudents", null, { root: true }).then(
          (students) => {
            return students;
          }
        );
      });
    });
  },
  patchStudents({ dispatch }, { students, _class }) {
    return services.studentService
      .patchStudents(students, _class)
      .then((response) => {
        // change students respective to class
        dispatch(
          "_class/setStudentsToClass",
          {
            classId: _class,
            students: response.data,
          },
          { root: true }
        );
        dispatch("getStudents", {
          params: { _class__id: _class, _class__has_dfm: 1 },
        });
        dispatch(
          "layout/addToast",
          {
            toastState: true,
            text: "Los cambios fueron registrados correctamente.",
          },
          { root: true }
        );
        return response;
      })
      .catch(() => {
        dispatch(
          "layout/addToast",
          {
            toastState: false,
            text:
              "Los cambios no pudieron ser guardados. Verifique que el email esté correcto en todos los estudiantes. Si un estudiante no tiene email, dejar el campo en blanco.",
          },
          { root: true }
        );
      });
  },

  deleteStudents({ dispatch }) {
    return services.studentService.deleteStudents().then((response) => {
      dispatch("_class/reset", null, { root: true });
      dispatch("student/reset", null, { root: true });

      return response;
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
