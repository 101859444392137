var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"relative",staticStyle:{"top":"45px","margin-bottom":"45px"}},[(_vm.isMobile)?_c('v-row',{staticClass:"ml-0 white--text text-left",class:_vm.isMobile ? 'bg--primary' : 'title vh-100 ',staticStyle:{"display":"flex","align-items":"flex-end","padding":"5rem 5%"}},[_c('p',{class:_vm.isMobile ? 'w-100 text-title' : 'w-50 text-heading'},[_vm._v(" La voz de nuestros participantes: descubre cómo DFM ha impactado a profesores y estudiantes de Chile y Colombia. ")])]):_vm._e(),_vm._l(([
			{
				mobileText: 'TESTIMONIOS',
				text: "TESTIMONIOS<br><br> La voz de nuestros participantes: descubre cómo DFM ha impactado a profesores y estudiantes de Chile y Colombia.",
				imageUrl: 'Row1.png',
				backgroundText: 'bg--primary',
				backgroundImage: 'bg--primary-90-t',
				distribution: '50-50',
				color: 'white',
			},
			{
				text:
					'“Los estudiantes que aparecen en los videos lograron sus sueños y todo fue gracias a la motivación y esfuerzo que tuvieron. El aprendizaje que me llevo es que por imposible que las cosas parezcan, siempre con paciencia y dedicación se pueden lograr.” <br/><br/> — Estudiante de segundo medio en Lebu.',
				imageUrl: 'Row2.png',
				backgroundImage: 'bg--cuaternary',
				backgroundText: 'bg--neutral-100-t',
				distribution: '50-50',
				color: '',
			},
			{
				text:
					'Lo que aprendí fue que no es necesario irse del colegio ya que si sigues y terminas una carrera tendrás mejor futuro.” <br/><br/> — Estudiante de séptimo básico en la Florida.',
				imageUrl: 'Row3.png',
				backgroundText: 'bg--primary-90-t',
				backgroundImage: 'bg--primary-90-t',
				distribution: '40-60',
				color: '',
			},
			{
				text:
					'“Acercar este tipo de proyectos a niños de séptimo y octavo básico […] ya es importante [para] que comiencen a pensar lo que van hacer y tengan orientaciones más claras de lo que quieren en su futuro.” <br/><br/>— César Gómez, Orientador, Liceo Polimodal Juan Morales ',
				imageUrl: 'Row4.png',
				backgroundImage: 'bg--primary',
				backgroundText: 'bg--neutral-100-t',
				distribution: '50-50',
				color: '',
			},
			{
				text:
					'“El programa hace al alumno tomar conciencia de cuál es su proyecto de vida.” <br/><br/>— Fahime Castillo, profesor, Escuela Ejército de Salvación Arica.',
				imageUrl: 'Row5.png',
				backgroundText: 'bg--primary-90-t',
				backgroundImage: 'bg--cuaternary',
				distribution: '40-60',
				color: '',
			} ]),function(row,index){return _c('v-row',{key:index,staticClass:" ml-0 pl-0 pr-0 relative text-left",staticStyle:{"height":"max-content!important"}},[((_vm.isMobile || index % 2 == 0) && !(_vm.isMobile && index == 0))?_c('v-col',{class:row.backgroundText + ' ' + row.color + '--text ',staticStyle:{"display":"flex","align-items":"flex-end","padding":"2% 15% 5% 5%"},style:(_vm.isMobile
					? index == 0
						? 'padding: 60% 5% 10% 5%'
						: 'padding: 30% 5% 10% 5%'
					: ''),attrs:{"cols":_vm.isMobile ? 12 : row.distribution === '50-50' ? 6 : 5},domProps:{"innerHTML":_vm._s(row.text)}}):_vm._e(),_c('v-col',{class:row.backgroundImage,staticStyle:{"padding":"0"},attrs:{"cols":_vm.isMobile ? 12 : row.distribution === '50-50' ? 6 : 7}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require('@/assets/Home/Testimonies/' + row.imageUrl),"alt":""}})]),(!_vm.isMobile && index % 2 != 0)?_c('v-col',{class:row.backgroundText + ' ' + row.color + '--text ',staticStyle:{"display":"flex","align-items":"flex-end","padding":"2% 15% 5% 5%"},style:(_vm.isMobile ? 'padding: 30% 5% 10% 5%' : ''),attrs:{"cols":_vm.isMobile ? 12 : row.distribution === '50-50' ? 6 : 5},domProps:{"innerHTML":_vm._s(row.text)}}):_vm._e()],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }