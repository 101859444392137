import api from "../api";
export default {
  postAnswer({ body }) {
    return api.post("studentanswernew/", body);
  },
  getCompleted({ params }) {
    return api.get("completed/", { params });
  },
  postCompleted({ body }) {
    return api.post("completed/", body);
  },
  getAnswers(params = "") {
    return api.get(`studentanswernew${params}`);
  },
};
