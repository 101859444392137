import api from "../api";

// import api from "../api";
export default {
  register({ data }) {
    return api.post("usernew/", data);
  },
  getForm() {
    return api.get("formanswer/1/");
  },
  patchForm({ data }) {
    return api.post("formanswer/", data);
  },
  login({ username, password, captcha }) {
    return api.post("api/token/", { username, password, captcha });
    /*let formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);
    formData.append("captcha", captcha);
    return api.request({
      method: "POST",
      url: "api/token/",
      data: formData,
    });*/
  },
  logout({ refresh }) {
    return api.post("api/token/logout/", { refresh_token: refresh });
  },
  refreshToken({ refresh }) {
    return api.post("api/token/refresh/", { refresh });
  },
  patchUser({ body }) {
    return api.patch("usernew/1/", body);
  },
  getUserIdentifier() {
    return api.get("usernew/1/");
  },
  getRelatedVariables() {
    return api.get("relatedvariable/1/");
  },
  postSupport({ body }) {
    return api.post("support/", body);
  },
  postFeedback({ feedback }) {
    return api.post("messagenew/", feedback);
  },
  patchFeedback({ feedback, id }) {
    return api.patch(`messagenew/${id}/`, feedback);
  },
};
