<template>
	<p style="padding:0px;margin:0px;" v-html="processedText"></p>
</template>

<script>
	import { mapGetters } from 'vuex';
	export default {
		name: 'CustomText',
		props: ['text', 'size', 'color', 'ignoreStyles'],
		watch: {
			text: function(newVal) {
				// watch it
				this.processedText = newVal;
				this.processText();
			},
		},
		computed: {
			...mapGetters({
				variables: 'authentication/variables',
			}),
		},
		data() {
			return {
				processedText: this.text,
			};
		},
		methods: {
			processText() {
				this.newText = this.processedText;
				// variable
				this.processedText = this.processedText.replace(
					/\\variable\{([\d.:?!¿¡\wóéíúá ÁÉÍÓÚ/]+?)\}/g,
					// eslint-disable-next-line
					(a, b) => {
						if (b in this.variables) {
							return `${this.variables[b]}`;
						}

						return '';
					}
				);

				// size
				if (this.ignoreStyles) {
					this.processedText = this.processedText.replace(
						/\\big\{([ñ\d.:?!¿¡\\wóéíúá ÁÉÍÓÚ/,()%]+?)\}/g,
						function(a, b) {
							return b;
						}
					);
				} else {
					this.processedText = this.processedText.replace(
						/\\big\{([ñ\\\d.:?!¿¡\wóéíúá ÁÉÍÓÚ/,()%]*?)\}/g,
						function(a, b) {
							return "<span style='font-size: 200%'>" + b + '</span>';
						}
					);
				}
				// link
				if (this.ignoreStyles) {
					this.processedText = this.processedText.replace(
						/\\link\{([ñ\d.:?!¿¡\wóéíúá ÁÉÍÓÚ/,()%{}<>=\-"$\\]+?), ([\w]+?)\}/g,
						function(a, link, text) {
							return text;
						}
					);
				} else {
					this.processedText = this.processedText.replace(
						/\\link\{([ñ\d.:?!¿¡\wóéíúá ÁÉÍÓÚ/,()%{}<>=\-"$\\]+?), ([\w]+?)\}/g,
						function(a, link, text) {
							return (
								"<a href='" + link + "' target = '_blank'>" + text + '</a>'
							);
						}
					);
				}
				// bold
				this.processedText = this.processedText.replace(
					/\\bold\{(.*?)\}/g,
					function(a, b) {
						return "<span style='font-weight:bold'>" + b + '</span>';
					}
				);

				// italic
				if (this.ignoreStyles) {
					this.processedText = this.processedText.replace(
						/\\italic\{(.*?)\}/g,
						function(a, b) {
							return b;
						}
					);
				} else {
					this.processedText = this.processedText.replace(
						/\\italic\{(.*?)\}/g,
						function(a, b) {
							return '<i>' + b + '</i>';
						}
					);
				}
				// icon
				// ADD color
				if (!this.ignoreStyles) {
					this.processedText = this.processedText.replace(
						/\\icon\{(.*?)\}/g,
						function(a, b) {
							return "<span class='color--primary mdi " + b + "'></span>";
						}
					);
				} else {
					this.processedText = this.processedText.replace(
						/\\icon\{(.*?)\}/g,
						function(a, b) {
							return b;
						}
					);
				}
				// icon color
				if (!this.ignoreStyles) {
					this.processedText = this.processedText.replace(
						/\\iconcolor\{([\\<>ñ\d.:'?!¿¡\wóéíúá ÁÉÍÓÚ/=\-,()%]+?), ([#\w]+?)\}/g,
						function(a, b, c) {
							return (
								"<span class='color--primary mdi " +
								b +
								"' style='color: " +
								c +
								" !important'></span>"
							);
						}
					);
				} else {
					this.processedText = this.processedText.replace(
						/\\iconcolor\{([\\<>ñ\d.:'?!¿¡\wóéíúá ÁÉÍÓÚ/=\-,()%]+?), ([#\w]+?)\}/g,
						function(a, b, c) {
							return b + c;
						}
					);
				}

				// underline
				if (this.ignoreStyles) {
					this.processedText = this.processedText.replace(
						/\\underline\{(.*?)\}/g,
						function(a, b) {
							return b;
						}
					);
				} else {
					this.processedText = this.processedText.replace(
						/\\underline\{(.*?)\}/g,
						function(a, b) {
							return "<span style='text-decoration:underline'>" + b + '</span>';
						}
					);
				}
				// color underline
				if (this.ignoreStyles) {
					this.processedText = this.processedText.replace(
						/\\colorunderline\{([\d.:?!¿¡\wóéíúá ÁÉÍÓÚ/]+?), ([#\w]+?)\}/g,
						// eslint-disable-next-line
						function(a, text, color) {
							return text;
						}
					);
				} else {
					this.processedText = this.processedText.replace(
						/\\colorunderline\{([\d.:?!¿¡\wóéíúá ÁÉÍÓÚ/]+?), ([#\w]+?)\}/g,
						function(a, text, color) {
							return (
								"<span style='text-decoration:underline;text-decoration-thickness: 3px;text-decoration-color:" +
								color +
								"'>" +
								text +
								'</span>'
							);
						}
					);
				}
				// special underline
				if (this.ignoreStyles) {
					this.processedText = this.processedText.replace(
						/\\specialunderline\{([\d.:?!¿¡\wóéíúá ÁÉÍÓÚ/]+?), ([#\w]+?), ([#\w]+?)\}/g,
						// eslint-disable-next-line
						function(a, text, highlightColor, textColor) {
							return text;
						}
					);
				} else {
					this.processedText = this.processedText.replace(
						/\\specialunderline\{([\d.:?!¿¡\wóéíúá ÁÉÍÓÚ/]+?), ([#\w]+?), ([#\w]+?)\}/g,
						function(a, text, highlightColor, textColor) {
							return (
								"<span style='background: linear-gradient(to top, " +
								highlightColor +
								' 50%, transparent 50%)' +
								';color:' +
								textColor +
								"'>" +
								text +
								'</span>'
							);
						}
					);
				}
				// higlight
				if (this.ignoreStyles) {
					this.processedText = this.processedText.replace(
						/\\highlight\{([ñ\d.:?!¿¡\wóéíúá ÁÉÍÓÚ/,()%{}<>=\-"$\\/']+?), ([#\w]+?), ([#\w]+?)\}/g,
						function(a, text) {
							return text;
						}
					);
				} else {
					this.processedText = this.processedText.replace(
						/\\highlight\{([ñ\d.:?!¿¡\wóéíúá ÁÉÍÓÚ/,()%{}<>=\-"$\\/']+?), ([#\w]+?), ([#\w]+?)\}/g,
						function(a, text, highlightColor, textColor) {
							var t =
								"<span class='text' style='border-radius: 30px; color:" +
								textColor +
								';background:' +
								highlightColor +
								";padding: 5px 8px;'>" +
								text +
								'</span>';
							return t;
						}
					);
				}

				// boldcolor
				if (this.ignoreStyles) {
					this.processedText = this.processedText.replace(
						/\\boldcolor\{([<>\\#\d.:?!¿¡\wóéíúá ÁÉÍÓÚ/,()]+?), ([#\w]+?)\}/g,
						// eslint-disable-next-line
						function(a, text, textColor) {
							return text;
						}
					);
				} else {
					this.processedText = this.processedText.replace(
						/\\boldcolor\{([\\<>ñ\d.:'?!¿¡\wóéíúá ÁÉÍÓÚ/=\-,()%]+?), ([#\w]+?)\}/g,
						function(a, text, textColor) {
							return (
								"<span style='font-weight:bold;color:" +
								textColor +
								" !important;'>" +
								text +
								'</span>'
							);
						}
					);
				}

				// newline
				this.processedText = this.processedText.replace(/\\n/g, '<br/>');
			},
		},
		mounted() {
			this.processText();
		},
	};
</script>
