import router from "@/router";
import axios from "axios";
import mixpanel from "mixpanel-browser";
import Vue from "vue";
import Vuex from "vuex";
import services from "@/services";

function normalize(str) {
  return str
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
}
var baseUrl = process.env.VUE_APP_API_ENDPOINT;

Vue.use(Vuex);
const getDefaultState = () => ({
  // log in information
  accessToken: localStorage.getItem("access_token") || null,
  refreshToken: localStorage.getItem("refresh_token") || null,
  blockingDialog: false,
  blockingDialogText: "",
  screenWidth: 0,
  screenHeight: 0,
  dev: false,
  showDataCard: true,
  variables: Object(),
  country: null,
  students: null,
  showFAQ: false,
  peruOptions: null,
  tutorials: [],
  currentTutorial: null,
  tutorialDialog: false,
  tutorialDialogSeen: false,
  unavailableDialog: false,
  // current dashboard view
  page: "home",
  // dashboard information
  classes: null,
  phases: null,
  stages: {},
  currentClass: null,
  currentPhase: null,
  currentInstance: null,
  dateExtensionSeen: false,
  /*peruRows: readXlsxFile(peruDatabase).then((rows) => {
    return rows;
  }),*/
  // color themes
  themes: [
    { primary: "#E23832", secondary: "#FF96CD" },
    { primary: "#08B73D", secondary: "#BDFFB7" },
    { primary: "#FF6600", secondary: "#FFD6BA" },
    { primary: "#6B176E", secondary: "#C385FF" },
    { primary: "#407AF2", secondary: "#C5FFF1" },
  ],
  // building progress
  progress: 0,
  // messages
  messages: [],
  // main theme
  colors: { black: "#000000", dark: "#333333", gray: "#F7F4F9" },
  // current modal
  modal: null,
  // results
  results: null,
  backgroundColor: "#f7f4f9",
  // toast
  toasts: [],
  success: false,
  user: null,
  APIData: "",
});

const state = {
  ...getDefaultState(),
};

const getters = {
  // get style
  messages: ({ messages }) => messages,
  showDataCard: ({ showDataCard }) => showDataCard,

  country: ({ country }) => country,
  variables: ({ variables }) => variables,
  blockingDialog: ({ blockingDialog }) => blockingDialog,
  blockingDialogText: ({ blockingDialogText }) => blockingDialogText,
  tutorials: ({ tutorials }) => tutorials,
  unavailableDialog: ({ unavailableDialog }) => unavailableDialog,
  tutorialDialog: ({ tutorialDialog }) => tutorialDialog,
  tutorialDialogSeen: ({ tutorialDialogSeen }) => tutorialDialogSeen,
  dateExtensionSeen: ({ dateExtensionSeen }) => dateExtensionSeen,
  currentTutorial: ({ currentTutorial }) => currentTutorial,
  getTextClass: (state) => (textClass, extraClasses) => {
    // if mobile
    if (state.screenWidth <= 768) {
      return [`${textClass}-mobile`, extraClasses].join(" ");
    } else {
      return [`${textClass}`, extraClasses].join(" ");
    }
  },
  // peruRows: ({ peruRows }) => peruRows,
  currentStudents(state) {
    return state.students;
  },
  loggedIn(state) {
    return state.accessToken != null;
  },
  isMobile(state) {
    if (state.screenWidth <= 768) {
      return true;
    } else {
      return false;
    }
  },
  currentClass(state) {
    let userType = localStorage.getItem("user_type");
    if (!state.currentClass) {
      return null;
    }
    if (
      userType == "teacher" ||
      userType == "manager" ||
      userType == "principal"
    ) {
      if (!state.classes) {
        return null;
      }
      return state.classes.find((x) => x.id === state.currentClass.id);
    } else {
      return state.currentClass;
    }
  },
  currentPhase(state) {
    return state.currentPhase;
  },
  user: ({ user }) => user,
  currentTheme(state) {
    return state.themes[state.currentPhase.number];
  },
  getColors(state) {
    return state.colors;
  },
  // check permissions
  //eslint-disable-next-line
  checkPermissions: (state) => (classes) => {
    let userType = localStorage.getItem("user_type");
    let returnValue = false;
    classes.forEach((c) => {
      if (returnValue == true) {
        return;
      }
      if (c == userType) {
        returnValue = true;
        return;
      }
    });
    return returnValue;
  },
};
const mutations = {
  setUserIdentifier(state, { identifier }) {
    state.user.identifier = identifier;
  },
  setBlockingDialog(state, { blockingDialog }) {
    state.blockingDialog = blockingDialog;
  },
  setBlockingDialogText(state, { blockingDialogText }) {
    state.blockingDialogText = blockingDialogText;
  },
  setUser(state, { user }) {
    state.user = user;
  },
  setDateExtensionSeen(state, { dateExtensionSeen }) {
    state.dateExtensionSeen = dateExtensionSeen;
  },
  setShowDataCard(state, { showDataCard }) {
    state.showDataCard = showDataCard;
  },
  setVariable(state, { varName, varValue }) {
    state.variables[varName] = varValue;
  },
  setTutorials(state, { tutorials }) {
    state.tutorials = tutorials;
  },
  setMessages(state, { messages }) {
    state.messages = messages;
  },
  setTutorialDialog(state, { dialog, dialogSeen }) {
    state.tutorialDialog = dialog;
    state.tutorialDialogSeen = dialogSeen;
  },
  setUnavailableDialog(state, { dialog }) {
    state.unavailableDialog = dialog;
  },
  setCurrentTutorial(state, { currentTutorial }) {
    state.currentTutorial = currentTutorial;
  },
  // token update
  updateLocalStorage(state, { access, refresh, userType }) {
    state.accessToken = access;
    state.refreshToken = refresh;
    localStorage.setItem("access_token", access);
    localStorage.setItem("refresh_token", refresh);
    if (userType) {
      localStorage.setItem("user_type", userType);
    }
  },
  // update access token
  updateAccess(state, access) {
    state.accessToken = access;
  },
  // token deletion
  destroyToken(state) {
    state.accessToken = null;
    state.refreshToken = null;
  },
  // mobile - desktop checker
  updateScreenWidth(state, screenWidth) {
    state.screenWidth = screenWidth;
  },
  updateScreenHeight(state, screenHeight) {
    state.screenHeight = screenHeight;
  },
  // dashboard page update
  updateDashboardPage(state, page) {
    state.page = page;
  },
  toggleFAQ(state) {
    state.showFAQ = !state.showFAQ;
  },
  showFAQ(state) {
    state.showFAQ = true;
  },
  hideFAQ(state) {
    state.showFAQ = false;
  },
  // modal update
  updateModal(state, modal) {
    state.modal = modal;
  },
  // current class change
  changeClass(state, classId) {
    state.currentClass = state.classes.find((x) => x.id === classId);
    state.modal = null;
  },
  // change current phase
  goToPhase(state, phase) {
    state.currentPhase = phase;
    state.page = "phase";
  },
  goToPhaseStudent(state, phase) {
    state.currentPhase = phase;
  },
  logErrors(state, error) {
    if (process.env.VUE_APP_ENVIRONMENT != "PROD") {
      console.log(error.src);
      console.log(error.error);
    }
  },
};
const actions = {
  enableBlockingDialog({ commit }) {
    commit("setBlockingDialog", { blockingDialog: true });
  },
  disableBlockingDialog({ commit }) {
    commit("setBlockingDialog", { blockingDialog: false });
  },
  setBlockingDialogText({ commit }, { blockingDialogText }) {
    commit("setBlockingDialogText", { blockingDialogText });
  },
  setShowDataCard({ commit }, { showDataCard }) {
    commit("setShowDataCard", { showDataCard });
  },
  setDateExtensionSeen({ commit }, { dateExtensionSeen }) {
    commit("setDateExtensionSeen", { dateExtensionSeen });
  },
  postFeedback({ dispatch }, { feedback }) {
    // todo, remove from here
    return services.authenticationService
      .postFeedback({ feedback })
      .then((response) => {
        dispatch(
          "layout/addToast",
          {
            toastState: "success",
            text: `Mensaje enviado correctamente.`,
          },
          { root: true }
        );
        return response;
      });
  },
  patchFeedback({ state }, { feedback, id }) {
    // todo, remove from here
    return services.authenticationService
      .patchFeedback({ feedback, id })
      .then((response) => {
        state.messages.forEach((m) => {
          if (m.id == id) {
            m.seen = true;
          }
        });
        return response;
      });
  },
  setTutorialDialog({ commit }, { dialog, dialogSeen }) {
    commit("setTutorialDialog", { dialog, dialogSeen });
  },
  setUnavailableDialog({ commit }, { dialog }) {
    commit("setUnavailableDialog", { dialog });
  },
  setUser({ commit }, { user }) {
    return new Promise((resolve) => {
      commit("setUser", { user });
      resolve();
    });
  },
  // NEW ACTIONS
  getCountry(context) {
    const country = localStorage.getItem("country");
    if (!country) {
      context.state.country = new Date()
        .toString()
        .split("(")[1]
        .split(" ")[0]
        .toLowerCase();

      if (!services.includes(["colombia", "chile"], context.state.country)) {
        context.state.country = "colombia";
      }

      const host = window.location.host;
      let subdomain = host.split(".")[0];
      if (subdomain === "www") {
        subdomain = host.split(".")[1];
      }
      if (
        subdomain != "decidiendofuturomejor" &&
        !(subdomain.indexOf("localhost") >= 0) &&
        !(subdomain.indexOf("staging") >= 0) &&
        !(subdomain.indexOf("develop") >= 0)
      ) {
        context.state.country = subdomain;
      }
      localStorage.setItem("country", context.state.country);
      return context.state.country;
    } else {
      context.state.country = country;
      return country;
    }
  },
  reset({ state }) {
    Object.assign(state, getDefaultState());
  },

  // OLD ACTIONS
  /* token refresh TODO: include
  refreshToken(context) {
    return new Promise((resolve, reject) => {
      axiosBase
        .post("/api/token/refresh/", {
          refresh: context.state.refreshToken,
        }) // send token refresh
        .then((response) => {
          // if new refresh and access, update
          resolve(response.data.access);
        })
        .catch((err) => {
          reject(err); // token expired error
        });
    });
  }, */
  // log out
  logout({ dispatch, commit }) {
    return services.authenticationService
      .logout({ refresh: `${localStorage.getItem("refresh_token")}` })
      .then((response) => {
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        commit("destroyToken");
        dispatch("reset");
        [
          "admin",
          "answer",
          "_class",
          "personalization",
          "program",
          "qualtrics",
          "school",
          "student",
        ].forEach((storeModule) => {
          dispatch(`${storeModule}/reset`, null, { root: true });
        });
        router.push({ name: "login" });
        return response;
      })
      .catch((err) => {
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        commit("destroyToken");
        dispatch("reset");
        [
          "admin",
          "answer",
          "_class",
          "personalization",
          "program",
          "qualtrics",
          "school",
          "student",
        ].forEach((storeModule) => {
          dispatch(`${storeModule}/reset`, null, { root: true });
        });
        router.push({ name: "login" });
        return err;
      });
  },
  // reset password request
  resetPasswordRequest(context, payLoad) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      Object.keys(payLoad).forEach((k) => {
        formData.append(k, payLoad[k]);
      });
      axios
        .request({
          baseURL: baseUrl,
          method: "POST",
          url: "reset_password/",
          data: formData,
        })
        .then((response) => {
          context.state.success = true;
          context.state.toasts.push("Correo fue enviado exitosamente.");
          resolve(response);
        })
        .catch((err) => {
          context.commit("logErrors", {
            src: "resetPasswordRequest",
            error: err,
          });
          context.state.success = false;
          context.state.toasts.push("Correo no pudo ser enviado.");
          reject(err);
        });
    });
  },
  // reset password
  resetPassword(context, payLoad) {
    let data = {
      password: payLoad.password,
      password_confirm: payLoad.password_confirm,
      token: router.currentRoute.params.token,
      uidb64: router.currentRoute.params.uid,
    };
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      Object.keys(data).forEach((k) => {
        formData.append(k, data[k]);
      });
      axios
        .request({
          baseURL: baseUrl,
          method: "POST",
          url: "reset_password_complete/",
          data: formData,
        })
        .then((response) => {
          mixpanel.track(
            "change_password",
            { env: process.env.VUE_APP_ENVIRONMENT },
            () => {}
          );
          resolve(response);
        })
        .catch((err) => {
          context.commit("logErrors", { src: "resetPassword", error: err });
          context.state.success = false;
          context.state.toasts.push(
            "La contraseña no pudo ser cambiada. Favor solicitar recuperación de contraseña nuevamente."
          );
          reject(err);
        });
    });
  },
  // patch email
  patchUser({ dispatch, state }, { body }) {
    return services.authenticationService
      .patchUser({ body })
      .then((response) => {
        if (response.status == 200) {
          if ("email" in body) {
            state.user.email = body.email;
            dispatch(
              "layout/addToast",
              {
                toastState: "success",
                text: `Cambios actualizados correctamente.`,
              },
              { root: true }
            );
          }
        }
        return response;
      });
  },
  getTutorials({ commit }) {
    return services.adminService
      .get({ endpoint: "tutorial", params: {} })
      .then((response) => {
        let tutorials = response.data;
        commit("setTutorials", { tutorials });
        return tutorials;
      });
  },
  setTutorial({ commit }, { currentTutorial }) {
    commit("setCurrentTutorial", { currentTutorial: currentTutorial });
  },
  // track mixpanel
  track({ getters }, { event, data }) {
    if (getters.user) {
      data["email"] = getters.user.email ?? "";
      if (data["email"] == "") {
        data["email"] = getters.user.identifier ?? "";
      }
      if (getters.user.user_type) {
        data["user_type"] = getters.user.user_type;
      }
    }
    data["env"] = process.env.VUE_APP_ENVIRONMENT;
    mixpanel.track(event, data, () => {});
  },
  // eslint-disable-next-line
  getRelatedVariables({ commit }, {}) {
    return services.authenticationService
      .getRelatedVariables()
      .then((response) => {
        Object.keys(response.data).forEach((v) => {
          commit("setVariable", {
            varName: v,
            varValue: response.data[v],
          });
        });
        return response;
      });
  },
  // eslint-disable-next-line
  patchForm({}, { data }) {
    return services.authenticationService
      .patchForm({ data })
      .then((response) => {
        return response;
      });
  },
  // eslint-disable-next-line
  getForm({}) {
    return services.authenticationService.getForm().then((response) => {
      return response;
    });
  },
  // eslint-disable-next-line
  register({}, { data }) {
    return services.authenticationService
      .register({ data })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  },
  // eslint-disable-next-line
  getUserIdentifier({ commit }, {}) {
    return services.authenticationService
      .getUserIdentifier()
      .then((response) => {
        commit("setUserIdentifier", { identifier: response.data.identifier });
      });
  },
  // log in
  login({ commit, dispatch }, { username, password, captcha }) {
    return services.authenticationService
      .login({ username, password, captcha })
      .then((response) => {
        let user = {
          identifier: response.data.identifier ?? "",
          first_name: response.data.first_name,
          last_name: response.data.last_name,
          type: response.data.user_type,
          email: response.data.email,
          has_baseline: response.data.has_baseline,
          last_login: response.data.last_login,
        };
        if ("P2" in response.data) {
          user["P2"] = response.data.P2;
        }
        if ("has_dfm" in response.data) {
          user["has_dfm"] = response.data.has_dfm;
        }
        commit("setUser", { user });
        // add data to state
        mixpanel.people.set({
          $distinct_id: response.data.uuid,
          $first_name: response.data.first_name,
          $last_name: response.data.last_name,
          $email: username,
          type: response.data.user_type,
          rbd: response.data.rbd,
        });
        // add rbd
        if ("rbd" in response.data) {
          mixpanel.people.set({ rbd: response.data.rbd });
        }
        if ("schools" in response.data) {
          let schools = response.data.schools;

          dispatch(
            "school/setRbd",
            { rbd: schools[0].school.rbd },
            { root: true }
          );
          commit(
            "school/setSchools",
            { schools: schools.map((x) => x.school) },
            { root: true }
          );
        }
        mixpanel.identify(response.data.uuid);
        dispatch("track", { event: "login", data: {} });

        // add data to local storage
        commit("updateLocalStorage", {
          access: response.data.access,
          refresh: response.data.refresh,
          userType: response.data.user_type,
        });
        // add default headers
        axios.defaults.headers.common["Authorization"] = response.data.access;
        // add variables
        if ("variables" in response.data) {
          Object.keys(response.data.variables).forEach((v) => {
            commit("setVariable", {
              varName: v,
              varValue: response.data.variables[v],
            });
          });
        }
        // add messages
        commit("setMessages", { messages: response.data.messages });
        return user;
      })
      .catch((err) => {
        throw err;
      });
  },

  // send contact to the server
  sendContact(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .request({
          baseURL: baseUrl,
          method: "POST",
          url: `contact/`,
          data: data,
        })
        .then((response) => resolve(response))
        .catch((err) => {
          context.commit("logErrors", { src: "sendContact", error: err });
          context.state.success = false;
          context.state.toasts.push("Hubo un problema enviando contacto.");
          reject(err);
        });
    });
  },
  // get schools from a country
  getSchools({ context, rootGetters, state }, { searchTerm }) {
    let country = rootGetters["layout/country"];
    if (country == "peru") {
      let results = [];
      let searchTermNormalized = normalize(searchTerm);

      results = state.peruOptions.filter(
        (x) =>
          normalize(x[2]).indexOf(searchTermNormalized) >= 0 ||
          normalize(x[1]).indexOf(searchTermNormalized) >= 0
      );

      if (results.length > 10) {
        results = results.slice(0, 10);
      }

      return results.map((x) => {
        return { campus_name: x[2], institution_code: x[1], campus_code: x[0] };
      });
    }

    let xIndex = {
      chile: "chile",
      colombia: "colombia",
      dominicana: "dom",
      "República Dominicana": "dom",
    }[country];

    if (!searchTerm.trim()) {
      return [];
    }
    return new Promise((resolve, reject) => {
      let env = process.env.VUE_APP_ELASTIC_SEARCH_ENV;
      axios
        .request({
          baseURL: baseUrl,
          method: "POST",

          url: `${process.env.VUE_APP_ELASTIC_SEARCH_ENDPOINT}campuses/`,
          data: {
            fields_required: [
              "institution_code",
              "campus_code",
              "campus_name",
              "institution_name",
            ],
            search_size: 20,
            search_operator: "and",
            search_partial: true,
            search_term: searchTerm,
          },
          headers: {
            "x-index": `${xIndex}-campuses-${env}`,
            "Content-Type": "application/json",
            "X-Tenant": xIndex,
            // "x-api-key": process.env.VUE_APP_APIGATEWAY_KEY,
          },
        })
        .then((response) => {
          let results = response.data.results;
          function compare(a, b) {
            if (a.institution_code.length < b.institution_code.length) {
              return -1;
            }
            if (a.institution_code.length > b.institution_code.length) {
              return 1;
            }
            return 0;
          }
          results.sort(compare);
          if (results.length > 10) {
            results = results.slice(0, 10);
          }
          resolve(results);
        })
        .catch((err) => {
          context.commit("logErrors", { src: "sendAnswer", error: err });
          context.state.success = false;
          context.state.toasts.push(
            "Hubo un problema obteniendo los colegios. Favor intentar más tarde."
          );
          reject(err);
        });
    });
  },
  // eslint-disable-next-line
  postSupport({}, { body }) {
    // body -> question, answer
    return services.authenticationService
      .postSupport({ body })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  },
  getPeruOptions(context) {
    fetch("https://api.github.com/gists/a3c3f756b8aab35e8101a0326f673ad7")
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        let peruData = data.files["peru2.json"].content;
        let array = JSON.parse(peruData).records;
        context.state.peruOptions = array;
      });
  },
};

export default {
  namespaced: true,
  getters,
  state,
  mutations,
  actions,
};
