<template>
  <div class="text-left mr-auto w-100 relative">
    <span class="text-label">{{ label }}</span>
    <div class="d-flex justify-content-center align-items-center mt-1">
      <div class="m-auto w-100">
        <v-select
          :disabled="disabled"
          v-model="computedValue"
          dense
          outlined
          :items="items"
          :rules="allRules"
          :type="showHidden ? 'text' : type"
          @change="$emit('change')"
        >
          <template v-slot:prepend v-if="true"> </template>
          <template v-slot:append>
            <div>
              <v-icon>mdi-chevron-down</v-icon>
            </div>
          </template>
        </v-select>
      </div>
      <div>
        <v-icon
          style="position:absolute;top:34px;right:-30px;color:var(--primary)"
          dark
          class="clickable"
          @click.native="$emit('clickIcon')"
          v-if="showTooltip"
        >
          mdi-help-circle
        </v-icon>
        <!--<v-tooltip top v-if="showError || showTooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              style="position:absolute;top:34px;right:-30px"
              :class="showError ? 'red--text' : ''"
              :style="{ color: showError ? 'var(--error)' : 'var(--primary)' }"
              dark
              v-bind="attrs"
              v-on="on"
            >
              {{ showError ? "mdi-alert-circle" : "mdi-help-circle" }}
            </v-icon>
          </template>
          <slot name="tooltip" v-if="showTooltip"></slot>
          <slot name="error" v-if="showError"></slot>
        </v-tooltip>-->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CustomField",
  data() {
    return {
      allRules: [],
      showHidden: false,
    };
  },
  props: {
    value: {
      required: true,
    },
    disabled: {
      required: false,
      default: false,
      type: Boolean,
    },
    rules: {
      required: false,
      default: () => [],
      type: Array,
    },
    label: {
      required: false,
      default: "",
      type: String,
    },
    showError: {
      required: false,
      default: false,
      type: Boolean,
    },
    required: {
      default: false,
      required: false,
      type: Boolean,
    },
    showTooltip: {
      required: false,
      default: false,
      type: Boolean,
    },
    type: {
      required: false,
      default: "text",
      type: String,
    },
    items: {
      required: false,
      default: () => [],
      type: Array,
    },
  },
  mounted() {
    this.allRules = this.rules;
    if (this.required) {
      this.rules.push(function required(v) {
        return !!(v && v.length) || "Este campo es requerido.";
      });
    }
  },
  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
};
</script>
