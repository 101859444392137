var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contact-form-form"},[_c('div',[_c('div',{staticClass:"d-flex w-100 impact-container w-100"},[_c('responsive-div',{staticClass:"text-left d-flex flex-column",attrs:{"mobileClass":"w-100","desktopClass":"w-50"}},[_c('label',{staticClass:"contact-form-label",style:(_vm.mobile ? '  ' : 'margin-top:23px;margin-bottom:8px'),attrs:{"for":"school"}},[_vm._v(" "+_vm._s(_vm.$t("contact-form.contact-form.school.label"))+" ")]),_c('DropDown',{staticClass:"home-input contact-form-input",staticStyle:{"height":"58px"},attrs:{"showSearch":true,"marginTop":"-8px !important","initialValue":_vm.formSchool,"fontsize":"12px","loading":_vm.searching,"options":_vm.schools,"label":"full_name"},on:{"setData":_vm.setSchool,"setInput":_vm.setInput,"debounceInput":_vm.search,"sendToParent":_vm.sendToParent}}),_c('label',{staticClass:"contact-form-label",class:_vm.mobile ? '' : 'mb-2',staticStyle:{"margin-top":"10px"},attrs:{"for":"name"}},[_vm._v(_vm._s(_vm.$t("contact-form.contact-form.name.label")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formName),expression:"formName"}],staticClass:"home-input contact-form-input",attrs:{"type":"text","id":"name","placeholder":_vm.$t('contact-form.contact-form.name.placeholder')},domProps:{"value":(_vm.formName)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.formName=$event.target.value},function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.sendToParent.apply(void 0, argsArray.concat( [_vm.formName], ['formName'] ))}]}})],1),_c('responsive-div',{staticClass:"text-left d-flex flex-column",attrs:{"mobileClass":"w-100","desktopClass":"w-50"}},[_c('label',{staticClass:"contact-form-label",class:_vm.mobile ? 'mt-4' : '  mt-4',style:(_vm.mobile ? '' : 'margin-bottom:14px'),attrs:{"for":"school"}},[_vm._v(_vm._s(_vm.$t("contact-form.contact-form.email.label")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formEmail),expression:"formEmail"}],staticClass:"home-input contact-form-input",attrs:{"type":"text","id":"school","placeholder":_vm.$t('contact-form.contact-form.email.placeholder')},domProps:{"value":(_vm.formEmail)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.formEmail=$event.target.value},function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.sendToParent.apply(void 0, argsArray.concat( [_vm.formEmail], ['formEmail'] ))}]}}),_c('label',{staticClass:"contact-form-label",class:_vm.mobile ? 'mt-4' : '  mt-4',style:(_vm.mobile ? '' : 'margin-bottom:14px'),attrs:{"for":"cellphone"}},[_vm._v(_vm._s(_vm.$t("contact-form.contact-form.phone.label")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formPhone),expression:"formPhone"}],staticClass:"home-input contact-form-input no-arrow",attrs:{"type":"text","name":"phone","id":"cellphone","placeholder":_vm.$t('contact-form.contact-form.phone.placeholder')},domProps:{"value":(_vm.formPhone)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.formPhone=$event.target.value},function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.sendToParent.apply(void 0, argsArray.concat( [_vm.formPhone], ['formPhone'] ))}]}})])],1)]),_c('div',{staticClass:"mt-4"},[_c('div',{staticClass:"d-flex flex-row mb-2"},[_c('b-form-group',{attrs:{"label-cols-sm":"1","label":"Cargo:"}},[_c('b-form-radio-group',{staticStyle:{"display":"flex","flex-direction":"row"},attrs:{"id":"radio-group-2","name":"radio-options"},on:{"input":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.sendToParent.apply(void 0, argsArray.concat( [_vm.formPosition], ['formPosition'] ))}},model:{value:(_vm.formPosition),callback:function ($$v) {_vm.formPosition=$$v},expression:"formPosition"}},[_c('b-form-radio',{staticClass:"mt-1",attrs:{"value":_vm.$t('contact-form.contact-form.position')[0]}},[_vm._v(" "+_vm._s(_vm.$t("contact-form.contact-form.position")[0])+" ")]),_c('div',{staticStyle:{"width":"30px"}}),_c('b-form-radio',{staticClass:"mt-1",attrs:{"value":_vm.$t('contact-form.contact-form.position')[1]}},[_vm._v(_vm._s(_vm.$t("contact-form.contact-form.position")[1]))])],1),(_vm.formPosition == 'Otro (especificar)')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formPosition2),expression:"formPosition2"}],staticClass:"home-input contact-form-input",attrs:{"placeholder":"Especificar cargo...","type":"text"},domProps:{"value":(_vm.formPosition2)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.formPosition2=$event.target.value},function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.sendToParent.apply(void 0, argsArray.concat( [_vm.formPosition2], ['formPosition'] ))}]}}):_vm._e()],1)],1)]),_c('div',[_vm._v(" "+_vm._s(_vm.$t("contact-form.contact-form.assign-in-charge-text"))+" ")]),_c('div',{staticClass:"mt-4"},[_c('div',{staticClass:"d-flex flex-row "},[_c('b-form-checkbox',{attrs:{"id":"checkbox-1","name":"checkbox-1","value":true,"unchecked-value":false},on:{"input":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.sendToParent.apply(void 0, argsArray.concat( [_vm.inCharge], ['inCharge'] ))}},model:{value:(_vm.inCharge),callback:function ($$v) {_vm.inCharge=$$v},expression:"inCharge"}},[_vm._v(" "+_vm._s(_vm.$t("contact-form.contact-form.assign-in-charge-instruction"))+" ")])],1)]),(_vm.inCharge)?_c('div',[_c('div',{staticClass:"d-flex w-100 impact-container w-100"},[_c('responsive-div',{staticClass:"text-left d-flex flex-column",class:_vm.mobile ? 'w-100' : 'w-50'},[_c('label',{staticClass:"contact-form-label",class:_vm.mobile ? '' : 'mb-2',staticStyle:{"margin-top":"10px"},attrs:{"for":"name"}},[_vm._v(_vm._s(_vm.$t("contact-form.contact-form.in-charge-name.label")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inChargeName),expression:"inChargeName"}],staticClass:"home-input contact-form-input",attrs:{"type":"text","id":"name","placeholder":_vm.$t('contact-form.contact-form.in-charge-name.placeholder')},domProps:{"value":(_vm.inChargeName)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.inChargeName=$event.target.value},function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.sendToParent.apply(void 0, argsArray.concat( [_vm.inChargeName], ['inChargeName'] ))}]}}),_c('label',{staticClass:"contact-form-label",class:_vm.mobile ? 'mt-4' : 'mb-2',staticStyle:{"margin-top":"10px"},attrs:{"for":"name"}},[_vm._v(_vm._s(_vm.$t("contact-form.contact-form.in-charge-email.label")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inChargeEmail),expression:"inChargeEmail"}],staticClass:"home-input contact-form-input",attrs:{"type":"text","id":"name","placeholder":_vm.$t('contact-form.contact-form.in-charge-email.placeholder')},domProps:{"value":(_vm.inChargeEmail)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.inChargeEmail=$event.target.value},function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.sendToParent.apply(void 0, argsArray.concat( [_vm.inChargeEmail], ['inChargeEmail'] ))}]}})]),_c('div',{staticClass:"text-left d-flex flex-column",attrs:{"mobileClass":"w-100","desktopClass":"w-50"}},[_c('label',{staticClass:"contact-form-label",class:_vm.mobile ? 'mt-4' : ' ',style:(_vm.mobile ? '' : 'margin-bottom:14px'),attrs:{"for":"school"}},[_vm._v(_vm._s(_vm.$t("contact-form.contact-form.in-charge-role.label")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inChargePosition),expression:"inChargePosition"}],staticClass:"home-input contact-form-input",attrs:{"type":"text","id":"school","placeholder":_vm.$t('contact-form.contact-form.in-charge-role.placeholder')},domProps:{"value":(_vm.inChargePosition)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.inChargePosition=$event.target.value},function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.sendToParent.apply(void 0, argsArray.concat( [_vm.inChargePosition], ['inChargePosition'] ))}]}}),_c('label',{staticClass:"contact-form-label",class:_vm.mobile ? 'mt-4' : 'mt-3',style:(_vm.mobile ? '' : 'margin-bottom:5px'),attrs:{"for":"cellphone"}},[_vm._v(_vm._s(_vm.$t("contact-form.contact-form.in-charge-phone.label")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inChargePhone),expression:"inChargePhone"}],staticClass:"home-input contact-form-input",attrs:{"type":"text","id":"cellphone","placeholder":_vm.$t('contact-form.contact-form.in-charge-phone.placeholder')},domProps:{"value":(_vm.inChargePhone)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.inChargePhone=$event.target.value},function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.sendToParent.apply(void 0, argsArray.concat( [_vm.inChargePhone], ['inChargePhone'] ))}]}})])],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }