<template>
	<v-text-field
		outlined
		dense
		v-bind="$attrs"
		v-model="computedValue"
	></v-text-field>
</template>
<script>
	export default {
		name: 'CommonTextField',
		computed: {
			computedValue: {
				get() {
					return this.value;
				},
				set(v) {
					this.$emit('input', v);
				},
			},
		},
		props: {
			value: {
				required: true,
			},
		},
	};
</script>
