<template>
  <div class="w-100 relative pt-0" v-if="loaded">
    <custom-dialog v-model="removeWarning">
      <span class="text-left"
        >Estas a punto de eliminar el elemento. Esta acción no podrá ser
        revertida.</span
      >
      <div class="m-auto w-100 mt-2">
        <common-button
          text="Volver"
          class="bg--neutral me-1"
          @click="removeWarning = false"
        />

        <common-button
          text="Confirmar"
          class="bg--error ms-1"
          @click="removeFromParent"
        />
      </div>
    </custom-dialog>

    <input
      class="absolute"
      v-on:focus="$event.target.select()"
      ref="currentData"
      style="height:0px;"
      :value="currentData"
    />
    <v-expansion-panels accordion v-model="expansionPanels">
      <v-expansion-panel :class="`bg--primary-${tint}-t`">
        <v-expansion-panel-header @click.stop class="generic-header">
          <div
            class="w-100 d-flex justify-content-center align-items-center"
            style="gap:5px"
          >
            <div class="me-2">
              <b>[{{ type }} {{ object.id }}]</b> {{ object[title] }}
            </div>
            <div v-if="!sameData" class="me-auto color--primary">
              Editado
            </div>
            <v-icon
              class="color--primary ms-auto"
              @click.native.stop
              :disabled="sameData"
              @click="save"
              @click.stop
            >
              mdi-content-save
            </v-icon>
            <v-icon
              @click.native.stop
              @click.stop
              @click="removeTrigger"
              class="color--error me-3"
            >
              mdi-trash-can
            </v-icon>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="generic-panel">
          <!-- my keys -->
          <v-expansion-panels v-model="showData">
            <v-expansion-panel :class="`bg--primary-${tint - 10}-t`">
              <v-expansion-panel-header class="generic-header bold">
                Datos
              </v-expansion-panel-header>
              <v-expansion-panel-content class="generic-panel">
                <div v-for="key in keys" :key="key" class="d-flex">
                  <v-textarea
                    auto-grow
                    :disabled="$includes(['id', parentName], key)"
                    class="ms-2 mt-1 mb-2 me-2 bg--neutral-100-t rounded text-label"
                    content-class="text-label"
                    flat
                    hide-details
                    v-model="editedData[key]"
                    :label="key"
                    rows="1"
                    @input="update"
                  ></v-textarea>
                  <v-tooltip bottom v-if="editedData[key] != originalData[key]">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="color--primary"
                        @click="copyToClipboard(key)"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-content-copy
                      </v-icon>
                    </template>
                    <span>Copiar contenido original</span>
                  </v-tooltip>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-divider></v-divider>
          <!-- my lists -->
          <v-expansion-panels
            v-for="(element, index) in lists"
            :key="index"
            :disabled="object.id < 0"
            accordion
          >
            <v-expansion-panel :class="`bg--primary-${tint}-t`">
              <v-expansion-panel-header class="generic-header"
                >{{ element.name }}
                {{
                  object.id >= 0
                    ? ""
                    : "(para editar, debe crear elemento padre)"
                }}
              </v-expansion-panel-header>
              <v-expansion-panel-content class="generic-panel">
                <edit-generic
                  :parentName="type"
                  :parentId="object.id"
                  :tint="tint - 10"
                  :title="element.title"
                  :listName="element.name"
                  @remove="remove"
                  v-for="(subObject, subIndex) in object[element.name]"
                  :key="subIndex"
                  :keys="element.keys"
                  :object="subObject"
                  :lists="element.lists"
                  :type="element.type"
                ></edit-generic>
                <v-btn
                  class="mb-2"
                  block
                  style="background:white"
                  @click="add(index)"
                  >Agregar</v-btn
                >
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "EditGeneric",
  data() {
    return {
      showData: 0,
      expansionPanels: null,
      defaultObject: Object,
      removeWarning: false,
      sameData: true,
      editedData: Object(),
      originalData: Object(),
      showOriginalData: false,
      currentKey: null,
      currentData: "",
      loaded: false,
    };
  },
  mounted() {
    this.keys.forEach((k) => {
      this.editedData[k] = this.object[k];
      this.originalData[k] = this.object[k];
    });
    this.lists.forEach((l) => {
      this.defaultObject[l.name] = this.createDefaultObject(l.keys, l.lists);
    });
    this.loaded = true;
  },
  methods: {
    ...mapActions({
      delete: "program/delete",
      create: "program/create",
      patch: "program/patch",
      addToast: "layout/addToast",
    }),
    patchObject() {
      this.patch({
        id: this.object.id,
        object: this.type,
        body: this.editedData,
      }).then((response) => {
        if (response.status == 200) {
          this.loaded = false;
          this.$nextTick(() => {
            Object.keys(this.editedData).forEach((k) => {
              this.originalData[k] = this.editedData[k];
            });
            this.sameData = this.$objectEquals(
              this.originalData,
              this.editedData
            );
            this.loaded = true;
            this.expansionPanels = 0;
          });
        }
      });
    },
    createObject() {
      this.create({
        object: this.type,
        body: this.editedData,
      })
        .then((response) => {
          if (response.status == 200 || response.status == 201) {
            this.editedData.id = response.data.id;
            this.object.id = response.data.id;
            this.loaded = false;
            this.$nextTick(() => {
              Object.keys(this.editedData).forEach((k) => {
                this.originalData[k] = this.editedData[k];
              });
              this.sameData = this.$objectEquals(
                this.originalData,
                this.editedData
              );
              this.loaded = true;
              this.expansionPanels = 0;
            });
          }
        })
        .catch((err) => {
          console.log("ERROR", err);
        });
    },
    save() {
      if (this.object.id > 0) {
        // patch
        this.patchObject();
      } else {
        // create
        this.editedData[this.parentName] = this.parentId;
        this.createObject();
      }
    },
    copyToClipboard(key) {
      this.currentData = this.originalData[key];
      this.$nextTick(() => {
        this.$refs.currentData.focus();
        document.execCommand("copy");
        this.editedData[key] = this.originalData[key];
        this.showOriginalData = false;
      });
    },
    toggleOriginalData(key) {
      this.currentKey = key;
      this.showOriginalData = true;
    },
    add(index) {
      this.object[this.lists[index].name].push({
        ...this.defaultObject[this.lists[index].name],
      });
      this.defaultObject[this.lists[index].name].id -= 1;
    },

    update() {
      this.sameData = this.$objectEquals(this.originalData, this.editedData);
    },
    createDefaultObject(keys, lists) {
      let object = Object();
      keys.forEach((k) => {
        object[k] = "";
      });
      lists.forEach((l) => {
        object[l.name] = [];
      });
      object.id = -1;
      return object;
    },
    remove(id, type) {
      let removalName = this.lists.filter((x) => x.name == type)[0].type;
      if (id >= 0) {
        this.delete({ object: removalName, id: id }).then(() => {
          this.object[type] = this.object[type].filter((x) => x.id != id);
        });
      } else {
        this.object[type] = this.object[type].filter((x) => x.id != id);
      }
    },
    removeTrigger() {
      this.removeWarning = true;
    },
    removeFromParent() {
      this.removeWarning = false;
      this.$emit("remove", this.object.id, this.listName);
    },
  },
  props: {
    parentName: {
      required: false,
      type: String,
    },
    parentId: {
      required: false,
      type: Number,
    },
    title: {
      required: true,
      type: String,
    },
    listName: {
      required: true,
      type: String,
    },
    tint: {
      required: false,
      default: 100,
    },
    object: {
      required: true,
      type: Object,
    },
    keys: {
      required: true,
      type: Array,
    },
    lists: {
      required: true,
      type: Array,
    },
    type: {
      required: true,
      type: String,
    },
  },
};
</script>
<style>
.generic-header {
  padding: 0px 5px 0px 5px !important;
}
.generic-panel > div.v-expansion-panel-content__wrap {
  margin: 5px 5px 5px 15px !important;
  padding: 0px !important;
}
</style>
