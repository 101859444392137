<template>
  <div>
    <DashboardCard title="Notificaciones" />
  </div>
</template>
<script>
import DashboardCard from "../DashboardCard.vue";
export default {
  name: "DashboardNotifications",
  components: { DashboardCard },
};
</script>
