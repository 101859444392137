<template>
  <!-- mixpanel on -->
  <DashboardCard id="students-panel" class="bg--neutral-100-t h-100">
    <div class="d-flex w-100" :class="mobile ? 'flex-column' : ''">
      <div>
        <span class="text-body">Editar lista de estudiantes</span>
        <br />
        <span class="text-title color--primary">{{ currentClass.name }}</span>
      </div>
      <div
        class="d-flex"
        style="gap:10px"
        :class="mobile ? 'flex-column-reverse mr-auto' : 'ms-auto'"
      >
        <common-button
          v-if="mobile"
          prependIcon="mdi-content-save"
          :disabled="!classLoaded || invalidStudents.length > 0"
          :class="mobile ? 'me-auto' : 'ms-auto'"
          text="Guardar cambios"
          secondary
          @click="save()"
        ></common-button>
        <a
          :href="templateLink"
          download="Plantilla_Alumnos.xlsx"
          style="color:white;text-decoration:none"
          ><common-button
            prepend-icon="mdi-download"
            @click="downloadTemplate()"
            class="w-100text-label"
            text="Descargar plantilla"
          ></common-button
        ></a>

        <custom-file-upload
          :disabled="!classLoaded"
          @validateRow="validateRow"
          @upload="upload"
          text="Subir datos"
          :class="mobile ? 'me-auto' : ''"
        />
        <common-button
          text="Eliminar"
          v-if="showDelete"
          @click="deleteStudents()"
          class="bg--error"
          :class="mobile ? 'me-auto' : ''"
        ></common-button>
        <common-button
          prependIcon="mdi-plus"
          text="Agregar estudiante"
          primary
          :class="mobile ? 'me-auto' : ''"
          @click="addStudent()"
        ></common-button>
        <common-button
          v-if="!mobile"
          prependIcon="mdi-content-save"
          :disabled="!classLoaded || invalidStudents.length > 0"
          class="ms-auto"
          text="Guardar cambios"
          secondary
          @click="save()"
        ></common-button>
      </div>
    </div>
    <span class="text-label" :class="mobile ? 'mt-4' : ''">
      {{
        mobile
          ? "Haz click sobre el estudiante que quieras editar."
          : "Haz click sobre el campo que quieras editar."
      }}
      {{
        lang == "es-CL" ? "El R.U.T debe ser sin puntos, y con guión." : ""
      }}</span
    >
    <div
      v-if="classLoaded"
      class="w-100 h-100 mt-5"
      style="height:70%;max-height:50vh"
    >
      <div class="d-flex w-100 bg--primary color--neutral-100-t p-2">
        <div class="dashboard-student-box">
          <v-checkbox
            @click="setSelectOnAllStudents()"
            v-model="selectAll"
            hide-details
            color="white"
            class="mt-0 pt-0"
          ></v-checkbox>
        </div>
        <div v-if="!mobile" class="w-100 d-flex">
          <div
            class="dashboard-student-box self-stretch ml-2 d-flex"
            v-for="(columnName, index) in $t(
              'dashboard.students-panel.column-names'
            )"
            :key="index"
          >
            <div v-if="!mobile">{{ columnName }}</div>
          </div>
        </div>
        <div class="dashboard-student-box self-stretch ml-2" v-if="mobile">
          Estudiante
        </div>
        <div class="dashboard-student-box" v-if="mobile"></div>
      </div>

      <div
        id="students-container"
        style="max-height:calc(100% - 100px);overflow-y:auto;"
        v-if="show"
      >
        <dashboard-students-single
          @setRUTvalidation="setRUTvalidation"
          v-for="(s, index) in activeStudents"
          :key="s.user.id"
          :ref="s.user.id"
          :class="index % 2 === 0 ? 'bg--primary-80-t' : 'bg--primary-90-t'"
          :student="s.user"
          @input="selectStudent"
          v-model="s.user.selected"
          :selectAll="selectAll"
        />
      </div>
      <div class="d-flex w-100 mt-5 "></div>
    </div>
    <v-progress-circular
      v-else
      indeterminate
      class="m-auto"
      color="primary"
    ></v-progress-circular>
  </DashboardCard>
</template>
<script>
import DashboardCard from "../DashboardCard.vue";
import DashboardStudentsSingle from "../DashboardStudentsSingle.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Students",
  components: { DashboardCard, DashboardStudentsSingle },
  mounted() {
    if (this.currentClassStudents == undefined) {
      this.getClassStudents().then((students) => {
        this.setStudents(students);
      });
    } else {
      this.setStudents(this.currentClassStudents);
    }
    this.setSelectOnAllStudents();
  },
  data() {
    return {
      templateDownloaded: false,
      invalidStudents: [],
      currentId: -1,
      show: true,
      showDelete: false,
      selectAll: false,
      editedStudents: [],
      selectedStudents: new Set(),
    };
  },
  methods: {
    ...mapActions({
      track: "authentication/track",
      getClassStudents: "_class/getClassStudents",
      patchStudents: "student/patchStudents",
    }),
    downloadTemplate() {
      this.templateDownloaded = true;
      this.track({ event: "dashboard-students-download-template", data: {} });
    },
    upload(jsonArray) {
      this.track({ event: "dashboard-students-upload-template", data: {} });
      jsonArray.forEach((s) => {
        this.addStudentWithData(s);
      });
    },
    validEmail(email) {
      if (email === null) {
        return false;
      }
      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (email.match(validRegex)) {
        return true;
      } else {
        return false;
      }
    },
    validRUT(rutCompleto) {
      if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)) return false;
      var tmp = rutCompleto.split("-");
      var digv = tmp[1];
      var rut = tmp[0];
      if (digv == "K") digv = "k";
      return this.dv(rut) == digv;
    },
    dv(T) {
      var M = 0,
        S = 1;
      for (; T; T = Math.floor(T / 10))
        S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
      return S ? S - 1 : "k";
    },
    validateRow(row, resolve) {
      let arr = ["Nombre", "RUT", "Género"];
      if (this.lang == "es-CO") {
        arr = ["Nombre", "Identificador", "Género"];
      }
      for (var i = 0; i < arr.length; i++) {
        var n = arr[i];
        if (row[n] == "" || row[n] == null) {
          resolve(`${n} de alumno no puede ser vacío.`);
        }
      }

      if (this.lang == "es-CL" && !this.validRUT(row.RUT)) {
        resolve("RUT del alumno inválido");
      }
      resolve(true);
    },
    setRUTvalidation(studentId, isValid) {
      if (
        !isValid &&
        !this.$includes(this.invalidStudents, studentId) &&
        this.lang == "es-CL"
      ) {
        this.invalidStudents.push(studentId);
      } else if (isValid && this.$includes(this.invalidStudents, studentId)) {
        if (this.$includes(this.invalidStudents, studentId)) {
          this.invalidStudents.splice(
            this.invalidStudents.indexOf(studentId),
            1
          );
        }
      }
    },
    save() {
      this.$nextTick(() => {
        this.patchStudents({
          students: this.editedStudents,
          _class: this.currentClass.id,
        });
      });

      this.track({
        event: "dashboard-students-save-changes",
        data: { class: this.currentClass.name ?? "" },
      });
    },
    resetRUTvalidation() {
      this.invalidStudents = [];
      this.editedStudents.forEach((s) => {
        if (this.validRUT(s.user.identifier) || this.lang != "es-CL") {
          this.setRUTvalidation(s.user.id, true);
        } else {
          this.setRUTvalidation(s.user.id, false);
        }
      });
    },
    deleteStudents() {
      /* this.editedStudents = this.editedStudents.filter(
        (x) => !this.selectedStudents.has(x.user.id)
      );*/

      this.editedStudents.forEach((s) => {
        if (this.selectedStudents.has(s.user.id)) {
          s.state = "deleted";
        }
      });
      let students = this.editedStudents;
      this.editedStudents = [];
      this.$nextTick(() => {
        this.editedStudents = students;
      });
      this.resetRUTvalidation();
      this.selectedStudents = new Set();
      this.showAll = false;
      this.showDelete = false;
      this.track({
        event: "dashboard-students-delete",
        data: { class: this.currentClass.name ?? "" },
      });
    },
    setStudents(students) {
      if (students) {
        this.editedStudents = students;
      } else {
        this.editedStudents = [...this.currentClass.students];
      }
    },
    setSelectOnAllStudents() {
      this.editedStudents.forEach((x) => {
        this.selectStudent(this.selectAll, x.user.id);
      });
      this.showDelete = this.selectAll;
    },
    selectStudent(selected, id) {
      if (selected) {
        this.selectedStudents.add(id);
        this.track({
          event: "dashboard-students-select",
          data: { student: id },
        });
      } else if (this.selectedStudents.has(id)) {
        this.selectedStudents.delete(id);
        this.track({
          event: "dashboard-students-unselect",
          data: { student: id },
        });
      }
      if (this.selectedStudents.size === 0) {
        this.showDelete = false;
      } else {
        this.showDelete = true;
      }

      let s = this.editedStudents.find((x) => x.user.id === id);
      s.user.selected = selected;
    },
    addStudentWithData(data) {
      let ruts = this.editedStudents.map((x) => x.user.identifier);
      let emails = this.editedStudents.map((x) => x.user.email);
      if (
        (!this.$includes(emails, data.Email) ||
          data.Email == "" ||
          data.Email === null) &&
        !this.$includes(ruts, data.RUT)
      ) {
        this.editedStudents.push({
          _class: this.currentClass.id,
          user: {
            first_name: data.Nombre,
            last_name: data.Apellido,
            email: data.Email,
            identifier:
              this.lang == "es-CO" ? `${data.Identificador}` : data.RUT,
            gender: data["Género"],
            id: this.currentId,
            selected: false,
          },
        });
        this.currentId--;
      }
    },
    addStudent() {
      this.editedStudents.push({
        _class: this.currentClass.id,
        user: {
          first_name: "",
          last_name: "",
          email: "",
          identifier: "",
          gender: "",
          id: this.currentId,
          selected: false,
        },
      });
      this.setRUTvalidation(this.currentId, false);
      this.currentId--;
      this.$nextTick(() => {
        var container = this.$el.querySelector("#students-container");
        container.scrollTop = container.scrollHeight;
        window.scrollTo(0, document.body.scrollHeight);
      });
    },
  },
  watch: {
    classLoaded(newVal) {
      if (newVal == true) {
        this.setStudents(this.currentClassStudents);
      }
    },
  },

  computed: {
    activeStudents() {
      return this.editedStudents.filter((s) => s.state != "deleted");
    },
    templateLink() {
      if (this.lang == "es-CO") {
        return "https://drive.google.com/uc?export=download&id=1CIQhhE0H47GambRsGBvLZuD0hDk8go9G";
      }
      return "https://drive.google.com/uc?export=download&id=1dAtuH3LfcLrn5dvH5dwssNF2lAkbjndB";
    },
    ...mapGetters({
      lang: "layout/lang",
      classLoaded: "_class/classLoaded",
      students: "student/students",
      currentClassStudents: "_class/currentClassStudents",
      currentClass: "_class/currentClass",
      mobile: "layout/isMobile",
    }),
  },
};
</script>
<style>
.dashboard-students-box {
  padding: 5px;
}
</style>
