<template>
  <div
    id="stage"
    :style="
      stage.is_start
        ? ''
        : 'max-height:calc(100vh - 154px);overflow-y:auto;overflow-x:hidden;max-width:100vw;'
    "
    class="ps-4 pe-4 dark-scrollbar"
  >
    <div
      id="stage-start"
      ref="stageStart"
      style="height:0px;background:red;width:100%"
    ></div>
    <div v-if="stage.is_start" class="vh-100 w-100 d-flex">
      <responsive-div
        id="phase-welcome"
        class="bg-neutral-100-r text-left color--neutral-10-t pt-3 pb-3 ps-4 pe-4 h-100 relative ms-auto me-auto"
        style="overflow-x:hidden;max-width:min(744px, 100vw);"
      >
        <triangle
          class="color--secondary absolute"
          height="85"
          style="right:0px;top:-25px"
        />
        <semi-circle
          style="color:#F073D4;z-index:0;bottom:50px;left:0px"
          class="absolute"
          height="54"
        />
        <semi-circle
          style="color:#F073D4;z-index:0;bottom:50px;left:39px"
          class="absolute"
          height="54"
        />
        <!--<square
          class="color--primary absolute"
          size="76"
          style="right:-px;top:360px"
        />-->
        <div style="z-index:1;" class="d-flex flex-column h-100 relative">
          <img
            src="@/assets/Dashboard/Logo.svg"
            class="pt-10 pb-5 me-auto mt-5"
            style="max-height:100px"
          />
          <div class="text-title">
            ¡Hola {{ user.first_name }} {{ user.last_name }}! <br />
          </div>
          <div class="text-subtitle pt-5">
            <custom-text :text="stage.title"></custom-text>
          </div>
          <div class="text-subtitle pt-5">
            <custom-text :text="stage.description"></custom-text>
          </div>
          <div class="w-100 text-center mt-auto mb-4">
            <common-button
              class="bg--secondary"
              @click.native="$emit('nextStage')"
              text="¡Comenzar!"
            ></common-button>
          </div>
        </div>
      </responsive-div>
    </div>

    <div v-if="!stage.is_start" id="stage-activities">
      <div
        class="d-flex flex-column ms-auto me-auto text-overline mt-1"
        style="max-width:744px"
      >
        <custom-text v-if="stage.label" :text="stage.label" />
      </div>

      <Activity
        :ref="index == shownActivities.length - 1 ? 'activityEnd' : ''"
        :readOnly="readOnly"
        v-for="(activity, index) in shownActivities"
        :key="activity"
        :activity="activity"
        :stage="computedStage"
        :id="index == shownActivities.length - 1 ? 'activityEnd' : ''"
        :class="stage.label ? 'mb-3 me-3 ms-3' : 'm-3'"
      />

      <div
        id="activityEnd2"
        ref="activityEndTrue"
        style="width:100%;height1px;background:transparent"
      ></div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Activity from "./Activity.vue";
export default {
  name: "Stage",
  components: {
    Activity,
  },
  mounted() {
    window.addEventListener("popstate", () => {
      history.pushState("", document.title, window.location.pathname);
    });
    this.getShownActivities();
    if (this.stage.is_start) {
      if (this.user.type == "student") {
        this.postCompleted({
          body: {
            type: "stage",
            id: this.stage.id,
          },
        });
      } else {
        this.addCompletedStageTeacher({
          stage: this.stage,
          phase: this.phase,
        });
      }
    }
    // scroll to top of div
  },
  data() {
    return {
      shownActivities: [],
    };
  },
  watch: {
    completedActivities() {
      this.getShownActivities();
    },
    stage() {
      var myDiv = document.getElementById("stage");
      myDiv.scrollTop = 0;
      this.getShownActivities();
    },
  },
  methods: {
    ...mapActions({
      postCompleted: "answer/postCompleted",
      addCompletedStageTeacher: "program/addCompletedTeacher",
    }),
    scrollToLast() {
      setTimeout(() => {
        var element = this.$refs.activityEndTrue;
        this.$smoothScroll(
          {
            scrollTo: element,
            duration: 400,
            offset: -99,
          },
          200
        );
        // var objDiv = document.getElementById("activityEnd2");
        // objDiv.scrollTop = objDiv.scrollHeight;
      });
    },
    getShownActivities() {
      if (this.readOnly) {
        this.stage.activities.forEach((a) => {
          a.expandable = false;
        });
        let activitiesWithQuestions = this.stage.activities.filter(
          (activity) => {
            return (
              activity.questions.filter((x) => x.type != "IN").length > 0 &&
              activity.btn_link == ""
            );
          }
        );

        this.shownActivities = activitiesWithQuestions;
        this.shownActivities.sort(
          (a, b) => parseInt(a.number) - parseInt(b.number)
        );
        return;
      }
      let i = 0;
      let activities = [];
      while (i < this.stage.activities.length) {
        let activity = this.stage.activities[i];

        if (
          activity.expandable &&
          !this.$includes(this.completedActivities, activity.id)
        ) {
          // if its expandable and not completed, only add this one and break.
          activities.push(activity);
          break;
        } else {
          // otherwise, it's either not expandable or it is completed. push and continue.
          activities.push(activity);
        }
        i++;
      }

      this.shownActivities = activities;
      this.shownActivities.sort(
        (a, b) => parseInt(a.number) - parseInt(b.number)
      );
      /*if (initialLength > 0 && this.shownActivities.length > initialLength) {
        // an activity just expanded, go to that one
        this.scrollToLast();
      }*/
    },
  },

  props: {
    stage: {
      required: true,
      type: Object,
    },
    readOnly: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      completedActivities: "program/completedActivities",
      user: "authentication/user",
      phase: "program/currentPhase",
    }),
    computedStage() {
      let stage = new Object();
      stage.id = this.stage.id;
      stage.activities = [];
      stage.format = this.stage.format;
      stage.description = this.stage.description;
      this.stage.activities.forEach((q) => {
        stage.activities.push({ id: q.id });
      });
      return stage;
    },
  },
};
</script>
<style>
/*.dark-scrollbar::-webkit-scrollbar-thumb {
  background: #424242;
  border-radius: 5px;
}
.dark-scrolllbar::-webkit-scrollbar-thumb:hover {
  margin-right: -10px;
  background: #1b1b1b !important;
}*/
</style>
