<template>
  <grid
    gridTemplateRows="auto-fill"
    gridTemplateColumns="1fr minmax(0, min-content)"
    class="vh-100 w-100"
    style="background:white"
  >
    <!-- incorrect password dialog -->
    <custom-dialog v-model="showIncorrectPasswordDialog">
      <input
        @keyup.enter="onEnter"
        v-model="test"
        ref="handlefocus"
        style="width:0px;height:0px;max-height:0;max-width:0"
        placeholder="test"
      />
      <div>
        Credenciales incorrectas. Por favor, revisa que tu contraseña coincida
        con tu cuenta
        <br />
        <br />

        <v-form @submit.prevent="showIncorrectPasswordDialog = false">
          <common-button text="OK" type="submit" />
        </v-form>
      </div>
    </custom-dialog>
    <!-- already created dialog -->
    <custom-dialog v-model="alreadyCreatedDialog">
      <input
        @keyup.enter="onEnter"
        v-model="test"
        ref="handlefocus"
        style="width:0px;height:0px;max-height:0;max-width:0"
        placeholder="test"
      />
      <div>
        Ya hay un usuario registrado con ese {{ errorMessage }}. Favor intentar
        nuevamente.
        <br />
        <br />
        <v-form @submit.prevent="alreadyCreatedDialog = false">
          <common-button text="OK" type="submit" />
        </v-form>
      </div>
    </custom-dialog>
    <!-- successful create dialog -->
    <custom-dialog v-model="successfulCreateDialog">
      <input
        @keyup.enter="onEnter"
        v-model="test"
        ref="handlefocus"
        class="bg--success-50-t"
        style="width:0px;height:0px;max-height:0;max-width:0"
        placeholder="test"
      />
      <div>
        <div v-if="formData.userType != 'Estudiante'">
          ¡Registro exitoso! Por favor, le solicitamos iniciar sesión. Recuerde
          que puede utilizar su documento de identificación o correo electrónico
          como usuario.
        </div>
        <div v-else>
          ¡Registro exitoso! Por favor, le solicitamos iniciar sesión. Recuerda
          que tu usuario corresponde a tu documento de identidad y tu clave a
          los primeros cuatro números y/o letras de tu documento.
        </div>
        <br />
        <br />
        <v-form @submit.prevent="closeSuccessfulDialog">
          <common-button text="OK" type="submit" />
        </v-form>
      </div>
    </custom-dialog>
    <!-- help dialog -->
    <!--<custom-dialog v-model="showHelpDialog">
      <div>
        La contraseña que debes ingresar, es la que enviamos a tu correo
        electrónico
        <br />
        <br />

        <span class="bold">¿La contraseña no funciona?</span>
        <br />

        <br />
        <common-button text="Ir al centro de ayuda" @click="goToHelpCenter()" />
      </div>
    </custom-dialog>-->
    <!-- end dialog -->
    <!-- reset password request dialog -->
    <custom-dialog
      v-model="showPasswordResetRequestDialog"
      @close="currentScreen = 'login'"
    >
      <div>
        Te hemos enviado un correo con las instrucciones de para poder
        reestablecer tu contraseña.
        <br />
        <br />
        <common-button text="Listo" @click="goToLogin()" />
      </div>
    </custom-dialog>
    <!-- end dialog -->
    <!-- reset password dialog -->
    <custom-dialog
      v-model="showResetPasswordDialog"
      @close="currentScreen = 'login'"
    >
      <div>
        Tu cambio ha sido efectuado correctamente.
        <br />
        <br />
        <common-button text="OK" @click="goToLogin()" />
      </div>
    </custom-dialog>
    <!-- end reset password dialog -->
    <!-- maintenance dialog -->
    <custom-dialog v-model="showMaintenanceDialog">
      <input
        @keyup.enter="onEnter"
        v-model="test"
        ref="handlefocus"
        style="width:0px;height:0px;max-height:0;max-width:0"
        placeholder="test"
      />
      <div>
        Actualmente la página se encuentra en mantenimiento
        <br />
        <br />
        <v-icon class="color--primary">mdi-progress-wrench</v-icon>
        <br />
        <br />

        <v-form @submit.prevent="showMaintenanceDialog = false">
          <custom-button text="OK" type="submit" />
        </v-form>
      </div>
    </custom-dialog>
    <!-- end maintenance dialog -->
    <grid-element rowStart="1" rowEnd="2" colStart="1" colEnd="2">
      <div v-if="mobile" class="w-100">
        <img
          src="@/assets/Login/LoginScreenMobile.png"
          class="ma-0 pa-0 w-100"
        />
      </div>
      <div
        class="h-100 d-flex flex-column"
        :class="mobile ? '' : 'h-100'"
        :style="mobile ? { height: `calc(100% - 50px)` } : {}"
      >
        <div
          :class="
            mobile ? 'mb-4 mr-auto text-center' : 'mb-16 w-50 m-auto text-left'
          "
          :style="mobile ? 'width:70%' : ''"
        >
          <img
            class="mt-10"
            src="@/assets/Dashboard/Logo.svg"
            style="max-height:60px"
          />
        </div>
        <!-- login screen -->
        <div
          v-if="currentScreen === 'login'"
          class="h-100 d-flex flex-column m-auto  text-left"
          :class="mobile ? '' : 'w-50 '"
          :style="mobile ? 'width:70%' : ''"
        >
          <div class="d-flex">
            <common-button
              prepend="mdi-arrow-left"
              dark
              @click="$router.push({ name: 'home' })"
              small
              >Volver</common-button
            >
          </div>
          <div class="text-heading">¡Comencemos!</div>
          <form v-on:submit.prevent="recaptcha" class="w-100">
            <custom-field
              :label="
                lang == 'es-CO'
                  ? 'Usuario (Si eres estudiante, ingresa tu número de Tarjeta de Identidad):'
                  : 'Usuario:'
              "
              v-model="email"
              required
            >
            </custom-field>
            <custom-field
              :label="
                lang == 'es-CO'
                  ? 'Contraseña (Si eres estudiante, utiliza los primeros 4 dígitos de tu Tarjeta de Identidad):'
                  : 'Contraseña:'
              "
              type="password"
              v-model="password"
              required
            ></custom-field>
            <div class="w-100 text-center">
              <common-button
                text="Comenzar"
                :disabled="isLoggingIn"
                size="x-large"
                type="submit"
              />
            </div>
          </form>

          <div class="mt-10 w-100 text-center">
            <span
              class="clickable bold text-label"
              @click="currentScreen = 'reset-password'"
              >Cambiar contraseña</span
            >
          </div>
          <div class="mt-2 w-100 text-center">
            <span
              class="clickable bold text-label color--primary"
              @click="currentScreen = 'help'"
              >¿Necesitas ayuda?</span
            >
          </div>
          <!--<div
            v-if="$includes(['es-CO', 'es-CL'], lang)"
            class="mt-10 w-100  text-center"
          >
            <v-btn
              v-if="mobile"
              class="register-btn bg-white color--primary bold p-3"
              depressed
              rounded
              @click="changeToRegisterScreen()"
              >¿No tienes cuenta?<br />Regístrate aquí</v-btn
            >
            <v-btn
              v-else
              class="register-btn bg-white color--primary bold p-3"
              depressed
              rounded
              @click="changeToRegisterScreen()"
              >¿No tienes cuenta? Regístrate aquí</v-btn
            >
          </div>-->
        </div>
        <!-- register-1 screen -->
        <div
          v-else-if="currentScreen === 'register-1'"
          class="h-100 d-flex flex-column  m-auto text-left"
          :class="mobile ? '' : 'w-50'"
          :style="mobile ? 'width:70%' : ''"
        >
          <div class="text-heading">¡Comencemos!</div>

          <custom-select
            :label="lang == 'es-CO' ? 'Municipio:' : 'Región:'"
            v-model="formData.municipality"
            required
            :items="municipalities"
          ></custom-select>
          <custom-select
            :label="lang == 'es-CO' ? 'Institución:' : 'Comuna:'"
            :disabled="formData.municipality == ''"
            v-model="formData.institution"
            required
            :items="institutions"
          ></custom-select>
          <custom-select
            :label="lang == 'es-CO' ? 'Sede educativa:' : 'Colegio:'"
            :disabled="formData.institution == ''"
            v-model="formData.headquarters"
            required
            :items="headquarters"
          ></custom-select>

          <div class="w-100 text-center">
            <common-button
              text="Siguiente"
              :disabled="isRegistering"
              name="registro intermedio"
              size="x-large"
              @click="currentScreen = 'register-2'"
            />
          </div>
        </div>
        <!-- register-2 screen -->
        <div
          v-else-if="currentScreen === 'register-2'"
          class="h-100 d-flex flex-column  m-auto text-left"
          :class="mobile ? '' : 'w-50'"
          :style="mobile ? 'width:70%' : ''"
        >
          <form v-on:submit.prevent="registerRecaptcha" class="w-100">
            <div class="text-heading">¡Comencemos!</div>
            <custom-select
              label="Tipo de usuario:"
              v-model="formData.userType"
              required
              :items="
                lang == 'es-CO'
                  ? ['Encargado de sede', 'Docente', 'Estudiante']
                  : ['Director', 'Profesor', 'Estudiante']
              "
            ></custom-select>
            <custom-field
              label="Fecha de nacimiento:"
              v-model="formData.birthDate"
              required
              type="date"
            ></custom-field>

            <div
              class="d-flex flex-row"
              style="gap: 15px"
              v-if="
                formData.userType != 'Encargado de sede' &&
                  formData.userType != 'Director'
              "
            >
              <custom-select
                :label="lang == 'es-CO' ? 'Grado:' : 'Nivel:'"
                v-model="formData.grade"
                required
                :items="
                  lang == 'es-CO'
                    ? ['6', '7', '8', '9', '10', '11']
                    : [
                        'Séptimo',
                        'Octavo',
                        'Primero Medio',
                        'Segundo Medio',
                        'Tercero Medio',
                        'Cuarto Medio',
                      ]
                "
              ></custom-select>
              <custom-select
                :label="lang == 'es-CO' ? 'Grupo / Curso:' : 'Curso'"
                v-model="formData.course"
                required
                :items="
                  lang == 'es-CO'
                    ? [
                        '01',
                        '02',
                        '03',
                        '04',
                        '05',
                        '06',
                        '07',
                        '08',
                        '09',
                        '10',
                        '11',
                        '12',
                        '13',
                        '14',
                        '15',
                      ]
                    : [
                        'A',
                        'B',
                        'C',
                        'D',
                        'E',
                        'F',
                        'G',
                        'H',
                        'I',
                        'J',
                        'K',
                        'L',
                        'M',
                        'N',
                        'O',
                      ]
                "
              ></custom-select>
            </div>
            <custom-field
              label="Nombre:"
              v-model="formData.firstName"
              required
              :rules="[
                (v) => {
                  return validName(v) || 'El nombre ingresado es inválido.';
                },
              ]"
            ></custom-field>
            <custom-field
              label="Apellido:"
              v-model="formData.lastName"
              required
              :rules="[
                (v) => {
                  return validName(v) || 'El apellido ingresado es inválido.';
                },
              ]"
            ></custom-field>
            <custom-field
              label="Email:"
              v-model="formData.email"
              :rules="[
                (v) => {
                  return (
                    studentConditionalvalidEmail(v) ||
                    'El E-mail ingresado es inválido.'
                  );
                },
              ]"
            ></custom-field>
            <custom-select
              label="Tipo de documento:"
              v-if="lang == 'es-CO'"
              v-model="formData.idType"
              required
              :items="[
                'RC:Registro civil de nacimiento',
                'TI:Tarjeta de identidad',
                'CC:Cédula de ciudadanía',
                'CCB: Certificado Cabildo',
                'ND:Documento Expedido en el extranjero',
                'NES:Número Establecido por la secretaría',
                'PEP:Permiso especial de permanencia',
                'PPT:Permiso de protección temporal',
                'TMF:Tarjeta de movilidad fronteriza',
                'VI:VISA',
              ]"
            ></custom-select>

            <custom-field
              :label="
                lang == 'es-CO'
                  ? 'Número de documento:'
                  : 'R.U.T. (sin puntos, con guión):'
              "
              v-model="formData.id"
              required
              :rules="[
                (v) => {
                  return lang == 'es-CL'
                    ? validRUT(v) || 'R.U.T. Inválido.'
                    : v.length < 25 ||
                        'El largo debe ser menor a 25 caracteres.';
                },
              ]"
            ></custom-field>
            <custom-field
              :label="
                lang == 'es-CO'
                  ? 'Repetir número de documento:'
                  : 'Repetir R.U.T. (sin puntos, con guión):'
              "
              v-model="formData.idVerifier"
              required
              :rules="[
                (v) => {
                  return (
                    v == this.formData.id ||
                    'Los números de documento no coinciden'
                  );
                },
              ]"
            ></custom-field>
            <div class="italic text-label pb-2" v-if="lang == 'es-CO'">
              Favor verificar que tu número de documento está bien escrito.
            </div>
            <custom-field
              label="Contraseña:"
              type="password"
              v-if="formData.userType != 'Estudiante'"
              v-model="formData.password"
              :rules="[
                (v) => {
                  return (
                    v.length >= 8 ||
                    'La contraseña debe tener sobre 8 caracteres.'
                  );
                },
              ]"
            ></custom-field>
            <custom-field
              label="Confirma contraseña:"
              type="password"
              v-if="formData.userType != 'Estudiante'"
              v-model="formData.passwordConfirm"
              :rules="[
                (v) => {
                  return (
                    v == this.formData.password ||
                    'Las contraseñas no coinciden'
                  );
                },
              ]"
            ></custom-field>

            <div class="w-100 text-center">
              <common-button
                :disabled="isRegistering2"
                name="registro finalizado"
                text="Siguiente"
                size="x-large"
                type="submit"
              />
            </div>
          </form>
        </div>
        <!-- reset password screen -->
        <div
          v-else-if="currentScreen === 'reset-password'"
          class="h-100 d-flex flex-column  m-auto text-left"
          :class="mobile ? '' : 'w-50'"
          :style="mobile ? 'width:70%' : ''"
        >
          <div
            class="text-label  clickable w-100 bold"
            @click="currentScreen = 'login'"
          >
            <v-icon>mdi-chevron-left</v-icon>Volver
          </div>
          <div class="text-heading w-100">Recuperar contraseña</div>
          <div class="text-body w-100 color--neutral-30-t mb-10">
            Escribe tu correo electrónico asociado a tu cuenta. Te enviaremos
            las instrucciones para recuperar contraseña ahí.
          </div>
          <custom-field
            label="Correo electrónico:"
            v-model="resetPasswordEmail"
          ></custom-field>
          <div class="w-100 text-center">
            <common-button
              text="Recuperar"
              size="x-large"
              @click="resetPasswordRequest()"
            />
          </div>
        </div>
        <!-- reset password screen -->
        <div
          v-else-if="currentScreen === 'change-password'"
          class="h-100 d-flex flex-column  m-auto text-left"
          :class="mobile ? '' : 'w-50'"
        >
          <div
            class="text-label  clickable w-100 bold"
            @click="currentScreen = 'login'"
          >
            <v-icon>mdi-chevron-left</v-icon>Volver
          </div>
          <div class="text-heading w-100">Cambiar contraseña</div>
          <div class="text-body w-100 color--neutral-30-t mb-10">
            Tu contraseña debe tener 8 caractéres mínimo.
          </div>
          <custom-field
            label="Contraseña:"
            type="password"
            v-model="resetPassword"
            :rules="[
              (v) => {
                return (
                  v.length >= 8 ||
                  'La contraseña debe tener sobre 8 caracteres.'
                );
              },
            ]"
          ></custom-field>
          <custom-field
            label="Confirma contraseña:"
            type="password"
            v-model="resetPasswordConfirm"
            :rules="[
              (v) => {
                return v === resetPassword || 'Las contraseñas no coinciden';
              },
            ]"
          ></custom-field>
          <div class="w-100 text-center">
            <common-button
              text="Cambiar contraseña"
              size="x-large"
              @click="handleSubmit"
            />
          </div>
        </div>
        <!-- help center screen screen -->
        <div
          v-else
          class="h-100 d-flex flex-column  m-auto text-left"
          :class="mobile ? '' : 'w-50'"
          :style="mobile ? 'width:70%' : ''"
        >
          <div
            class="text-label  clickable w-100 bold"
            @click="currentScreen = 'login'"
          >
            <v-icon>mdi-chevron-left</v-icon>Volver
          </div>

          <div class="text-heading  w-100">Centro de ayuda</div>

          <div v-if="!helpRequested">
            <p class="text-subtitle color--neutral-30-t">
              Estamos aquí para ayudarte <br />¿Cuál es el problema?
            </p>
            <div class="d-flex">
              <v-btn-toggle
                v-model="problem"
                style="background-color:transparent;gap:3px"
                class="w-100 text-left d-flex flex-wrap"
              >
                <common-button
                  textClass="bold text-label"
                  v-for="(button, index) in problems"
                  :key="index"
                  borderRadius="5px"
                  secondaryClass="bg--primary-90-t"
                  :selected="index === problem"
                  :text="button"
                  size="small"
                />
              </v-btn-toggle>
            </div>
            <p v-if="problem === 3" class="bold mt-2 mb-3">
              A continuación escribe tu problema:
            </p>
            <v-textarea
              v-model="problemDescription"
              v-if="problem === 3"
              label=""
              outlined
              flat
              auto-grow
              rows="2"
            >
            </v-textarea>
            <custom-field
              label="Ingresa tu email para contactarnos contigo:"
              class="mt-5"
              v-model="helpEmail"
              v-if="problem != null"
              :rules="[
                (v) => {
                  return validEmail(v) || 'El E-mail ingresado es inválido.';
                },
              ]"
            >
            </custom-field>

            <div class="w-100 text-center mt-5">
              <common-button
                text="Enviar"
                size="x-large"
                :selected="false"
                :disabled="
                  (problem === 3 && problemDescription === '') ||
                    problem === null ||
                    !validEmail(helpEmail)
                "
                primaryColor="var(--primary)"
                secondaryColor="white"
                @click="requestHelp()"
              />
            </div>
          </div>
          <div v-else>
            <div class="text-title">
              Nos contactaremos con usted a la brevedad a {{ helpEmail }}
            </div>
          </div>
        </div>
      </div>
    </grid-element>
    <grid-element
      rowStart="1"
      rowEnd="2"
      colStart="2"
      colEnd="3"
      class="ma-0 pa-0"
    >
      <div class="vh-100" v-if="!mobile">
        <img src="@/assets/Login/LoginScreen.png" class="h-100 ma-0 pa-0" />
      </div>
    </grid-element>
  </grid>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Login",
  data() {
    return {
      errorMessage: "",
      successfulCreateDialog: false,
      alreadyCreatedDialog: false,
      isLoggingIn: false,
      colombiaSchools: [],
      formData: {
        firstName: "",
        lastName: "",
        course: "",
        grade: "",
        birthDate: "",
        userType: "",
        municipality: "",
        institution: "",
        headquarters: "",
        email: "",
        idType: "",
        id: "",
        idVerifier: "",
        password: "",
        passwordConfirm: "",
      },
      test: "",
      email: "",
      validNewPassword: false,
      validNewID: false,
      helpEmail: "",
      password: "",
      problem: null,
      problemDescription: "",
      resetPassword: "",
      resetPasswordConfirm: "",
      helpRequested: false,
      currentScreen: "login",
      resetPasswordEmail: "",
      // dialogs
      showHelpDialog: false,
      showPasswordResetRequestDialog: false,
      showResetPasswordDialog: false,
      showIncorrectPasswordDialog: false,
      showMaintenanceDialog: false,
    };
  },
  computed: {
    ...mapGetters({
      mobile: "layout/isMobile",
      user: "authentication/user",
      lang: "layout/lang",
      country: "layout/country",
    }),
    isRegistering2() {
      let i = 0;
      let array = [
        "userType",
        "course",
        "grade",
        "email",
        "password",
        "passwordConfirm",
        "birthDate",
        "id",
        "idVerifier",
        "idType",
      ];
      if (this.formData.userType == "Estudiante") {
        array = [
          "userType",
          "course",
          "grade",
          "birthDate",
          "id",
          "idType",
          "idVerifier",
        ];
        if (!this.studentConditionalvalidEmail(this.formData.email)) {
          return true;
        }
      }
      if (
        this.formData.userType == "Encargado de sede" ||
        this.formData.userType == "Director"
      ) {
        array = [
          "userType",
          "email",
          "password",
          "passwordConfirm",
          "birthDate",
          "id",
          "idVerifier",
          "idType",
        ];
      }
      // new for chile
      if (this.lang == "es-CL" && this.$includes(array, "idType")) {
        array.splice(array.indexOf("idType"), 1);
      }
      // for chile, validate rut
      if (this.lang == "es-CL" && !this.validRUT(this.formData.id)) {
        return true;
      }
      while (i < array.length) {
        if (this.formData[array[i]] == "") {
          return true;
        }
        i++;
      }
      if (this.formData.id != this.formData.idVerifier) {
        return true;
      }
      if (
        this.formData.userType !== "Estudiante" &&
        this.formData.password != this.formData.passwordConfirm
      ) {
        return true;
      }
      if (
        this.formData.userType !== "Estudiante" &&
        this.formData.password.length < 8
      ) {
        return true;
      }
      return false;
    },
    isRegistering() {
      let i = 0;
      let array = ["institution", "municipality", "headquarters"];
      while (i < array.length) {
        if (this.formData[array[i]] == "") {
          return true;
        }
        i++;
      }
      return false;
    },
    municipalities() {
      if (this.lang == "es-CO") {
        return this.colombiaSchools
          .map((x) => x.municipality)
          .filter((v, i, a) => a.indexOf(v) === i)
          .sort();
      } else if (this.lang == "es-CL") {
        return this.colombiaSchools
          .map((x) => x.region)
          .filter((v, i, a) => a.indexOf(v) === i)
          .sort();
      }
      return [];
    },
    institutions() {
      if (this.lang == "es-CO") {
        return this.colombiaSchools
          .filter((x) => x.municipality == this.formData.municipality)
          .map((x) => x.institution)
          .filter((v, i, a) => a.indexOf(v) === i)
          .sort();
      } else if (this.lang == "es-CL") {
        return this.colombiaSchools
          .filter((x) => x.region == this.formData.municipality)
          .map((x) => x.comuna)
          .filter((v, i, a) => a.indexOf(v) === i)
          .sort();
      }
      return [];
    },
    headquarters() {
      if (this.lang == "es-CO") {
        return this.colombiaSchools
          .filter(
            (x) =>
              x.institution == this.formData.institution &&
              x.municipality == this.formData.municipality
          )
          .map((x) => x.campus)
          .filter((v, i, a) => a.indexOf(v) === i)
          .sort();
      } else if (this.lang == "es-CL") {
        return this.colombiaSchools
          .filter(
            (x) =>
              x.region == this.formData.municipality &&
              x.comuna == this.formData.institution
          )
          .map((x) => x.school_name)
          .filter((v, i, a) => a.indexOf(v) === i)
          .sort();
      }
      return [];
    },
    schoolCode() {
      if (this.lang == "es-CO") {
        let l = this.colombiaSchools
          .filter(
            (x) =>
              x.institution == this.formData.institution &&
              x.municipality == this.formData.municipality &&
              x.campus == this.formData.headquarters
          )
          .map((y) => y.school_code);
        if (l.length > 0) {
          return l[0];
        }
      } else if (this.lang == "es-CL") {
        let l = this.colombiaSchools
          .filter(
            (x) =>
              x.comuna == this.formData.institution &&
              x.region == this.formData.municipality &&
              x.school_name == this.formData.headquarters
          )
          .map((y) => y.school_code);
        if (l.length > 0) {
          return l[0];
        }
      }

      return "";
    },
    problems() {
      return [
        "No reconoce mi E-mail",
        "Mi contraseña no funciona",
        "No han llegado mis credenciales",
        "Tengo otro problema",
      ];
    },
  },
  mounted() {
    const { uuid, token } = this.$route.params;
    if (uuid || token) {
      this.currentScreen = "change-password";
    }
  },
  methods: {
    closeSuccessfulDialog() {
      this.isLoggingIn = false;
      this.successfulCreateDialog = false;
      this.currentScreen = "login";
    },
    changeToRegisterScreen() {
      this.currentScreen = "register-1";
      let link;
      let country;
      if (this.lang == "es-CO") {
        country = "colombia";
        link = "https://api.github.com/gists/482ec277aa3980ef7350404ba579267b";
      } else if (this.lang == "es-CL") {
        country = "chile";
        link = "https://api.github.com/gists/a18ec908803429ee7288d09171980f78";
      }
      fetch(link)
        .then((results) => {
          return results.json();
        })
        .then((data) => {
          let colombiaData = data.files[`${country}.json`].content;
          let array = JSON.parse(colombiaData).records;
          this.colombiaSchools = array;
        });
    },
    onEnter() {
      this.showIncorrectPasswordDialog = false;
      this.alreadyCreatedDialog = false;
      this.showMaintenanceDialog = false;
    },
    ...mapActions({
      registerUser: "authentication/register",
      setUser: "authentication/setUser",
      postSupport: "authentication/postSupport",
    }),
    requestHelp() {
      if (this.validEmail(this.helpEmail)) {
        let body = {
          email: this.helpEmail,
          role: "",
          assigned: "",
          category: this.problems[this.problem],
          text: this.problemDescription ?? "",
          rbd: "",
        };
        this.postSupport({ body }).then(() => {
          this.helpRequested = true;
        });
      }
    },
    studentConditionalvalidEmail(email) {
      if (this.formData.userType == "Estudiante") {
        if (email.length == 0) {
          return true;
        }
      }
      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (email.match(validRegex)) {
        return true;
      } else {
        return false;
      }
    },
    validEmail(email) {
      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (email.match(validRegex)) {
        return true;
      } else {
        return false;
      }
    },
    validName(name) {
      var validRegex = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
      if (name.match(validRegex)) {
        return true;
      } else {
        return false;
      }
    },
    validatePassword(password) {
      // length > 8
      if (password.length < 8) {
        return false;
      }
      return true;
    },
    validRUT(rutCompleto) {
      if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)) return false;
      var tmp = rutCompleto.split("-");
      var digv = tmp[1];
      var rut = tmp[0];
      if (digv == "K") digv = "k";
      return this.dv(rut) == digv;
    },
    dv(T) {
      var M = 0,
        S = 1;
      for (; T; T = Math.floor(T / 10))
        S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
      return S ? S - 1 : "k";
    },
    handleSubmit() {
      if (this.resetPassword != this.resetPasswordConfirm) {
        this.$store.state.success = false;
        this.$store.state.toasts.push("Las contraseñas no coinciden.");
      } else if (!this.validatePassword(this.resetPassword)) {
        this.$store.state.success = false;
        this.$store.state.toasts.push(
          "Las contraseña no cumple los requisitos."
        );
      } else {
        this.$store
          .dispatch("authentication/resetPassword", {
            password: this.resetPassword,
            password_confirm: this.resetPasswordConfirm,
          })
          .then(() => {
            this.showResetPasswordDialog = true;
          })
          .catch(() => {});
      }
    },
    resetPasswordRequest() {
      this.showResetPassword = false;
      this.$store
        .dispatch("authentication/resetPasswordRequest", {
          email: this.resetPasswordEmail,
        })
        .then(() => {
          this.showPasswordResetRequestDialog = true;
        })
        .catch(() => {});
    },
    goToLogin() {
      this.showResetPasswordDialog = false;
      this.showPasswordResetRequestDialog = false;
      this.currentScreen = "login";
    },
    goToHelpCenter() {
      this.showHelpDialog = false;
      this.currentScreen = "help";
    },
    goToRegister() {
      this.currentScreen = "register";
    },
    async recaptcha() {
      //if (this.country != "colombia"){
      this.isLoggingIn = true;
      await this.$recaptchaLoaded();
      const captcha = await this.$recaptcha("login");
      this.tryLogin(captcha);
      /*}
      else {
        this.showMaintenanceDialog = true;
      }*/
    },
    async registerRecaptcha() {
      this.isLoggingIn = true;
      await this.$recaptchaLoaded();
      const captcha = await this.$recaptcha("login");
      this.tryRegister(captcha);
    },
    async tryLogin(captcha) {
      this.login(captcha);
    },
    async tryRegister(captcha) {
      this.register(captcha);
    },
    register(captcha) {
      this.formData.schoolCode = this.schoolCode;
      this.registerUser({
        data: {
          ...this.formData,
          captcha,
        },
      }).then((response) => {
        if (response.status == 403) {
          this.errorMessage = response.data.message;
          this.alreadyCreatedDialog = true;
        }
        if (response.status == 200) {
          this.successfulCreateDialog = true;
        }
      });
    },
    login(captcha) {
      this.$store
        .dispatch("authentication/login", {
          username: this.email,
          password: this.password,
          captcha: captcha,
        })
        .then((user) => {
          this.isLoggingIn = false;
          this.setUser({ user }).then(() => {
            const recaptcha = this.$recaptchaInstance;
            recaptcha.hideBadge();

            this.$router.push({ name: `dashboard` });
          });
        })
        .catch(() => {
          this.isLoggingIn = false;

          this.showIncorrectPasswordDialog = true;
          setTimeout(() => {
            this.$refs.handlefocus.focus();
          }, 200);
        });
    },
  },
};
</script>
<style scoped>
.register-btn {
  border: 2px solid #5669fb !important;
  text-transform: none;
}
</style>
