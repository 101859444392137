import api from "../api";
export default {
  getClasses({ rbd }) {
    if (rbd) {
      return api.get(`classnew/?school__rbd=${rbd}&has_dfm=1`);
    } else {
      return api.get(`classnew/?has_dfm=1`);
    }
  },
};
