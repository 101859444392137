<template>
  <DashboardCard>
    <edit-table
      endpoint="support"
      externalSearchableField="id"
      label="Filtrar por id"
      :keys="[
        'email',
        'role',
        'assigned',
        'category',
        'text',
        'rbd',
        'date_sent',
        'completed',
      ]"
      name="Soporte"
      object="support"
    />
  </DashboardCard>
</template>
<script>
import DashboardCard from "../DashboardCard.vue";
import EditTable from "./ProgramEditor/EditTable.vue";
export default {
  name: "DashboardAdminSupport",
  components: { DashboardCard, EditTable },
};
</script>
