<template>
  <!-- mixpanel on -->
  <div>
    <custom-dialog v-model="noAnswers" @close="noAnswers = false">
      <div>Esta actividad no tiene respuestas a mostrar.</div>
      <br />
      <common-button @click="noAnswers = false" text="OK"></common-button>
    </custom-dialog>
    <custom-dialog width="500px" v-model="studentAnswers">
      <div
        style="max-height:400px;height:400px;min-height:400px;overflow-y:auto !important"
      >
        <stage :stage="stage" :read-only="true" />
      </div>
      <v-textarea
        no-elevation
        background-color="gray"
        class="bg--neutral-80-t mt-2"
        rows="3"
        placeholder="¿Quieres enviar retroalimentación? Escríbela aquí"
        solo
        v-model="feedback"
        hide-details
      ></v-textarea>
      <div class="text-right pt-5">
        <common-button
          text="Enviar feedback"
          :disabled="feedback == ''"
          @click="sendMessage()"
        />
      </div>
    </custom-dialog>
    <DashboardPhaseTemplate overflowY="hidden">
      <DashboardCard
        v-if="currentPhase.available"
        :title="title"
        style="overflow-y:overlay"
      >
        <div class="w-100">
          <v-progress-linear
            :value="phaseProgress"
            class="w-100 mt-2 mb-2"
            background-color="#EDEDED"
            rounded
            height="16"
          ></v-progress-linear>
        </div>
        <span>{{
          $t("dashboard.progress-panel.subtitle", { value: phaseProgress })
        }}</span>
        <span class="mt-2 mb-2">{{
          $t("dashboard.progress-panel.click-here")
        }}</span>
        <div class="w-100" style="overflow-y:overlay;" v-if="show">
          <div
            class="w-100 text-left p-3 bold bg--primary"
            style="color:white;background:#524789 !important;border-radius: 6px 6px 0px 0px"
            v-text="$t('dashboard.progress-panel.class-list')"
          />
          <div
            v-for="(s, index) in myStudents"
            :key="index"
            class="p-3 d-flex"
            :class="mobile ? 'flex-column' : ''"
            style="flex: 1 1 0;"
            :style="{ background: getBackgroundColor(i) }"
          >
            <div :style="mobile ? '' : 'width:40%'">
              {{ s.user.first_name }} {{ s.user.last_name }}
            </div>
            <div
              :style="mobile ? '' : 'width:60%;'"
              class="d-flex flex-column  justify-content-center"
            >
              <StepProgressBar
                :current="getCurrent(s)"
                :total="s.total[currentPhase.id]"
                @clickNode="getAnswers(...arguments, s)"
              />
            </div>
          </div>
        </div>
      </DashboardCard>
      <DashboardCard v-else class="text-body bold italic color--primary">
        No hay ninguna etapa disponible por el momento.
      </DashboardCard>
    </DashboardPhaseTemplate>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import DashboardCard from "../DashboardCard.vue";
import DashboardPhaseTemplate from "../DashboardPhaseTemplate.vue";
import StepProgressBar from "../../layout/StepProgressBar.vue";
import Stage from "../../phase/Stage.vue";
export default {
  /**
   * Upon render, should access different information based on role.
   * for students:
   *  for building:
   *    get latest answered phase of current program
   *  for progress:
   *    get progress %  for each phase of current program
   * for teachers and admins:
   *  for building:
   *    get latest available phase of current program
   *  for progress:
   *    get students and their % of progress of program
   *    [{id, progress}, {id, progress}]
   */
  name: "DashboardProgress",
  components: { DashboardPhaseTemplate, DashboardCard, StepProgressBar, Stage },
  mounted() {
    if (
      this.currentClass.students === undefined ||
      this.currentClass.students === null
    ) {
      this.getClassStudents().then((students) => {
        this.myStudents = students;
        this.show = true;
      });
    } else {
      this.myStudents = this.currentClass.students;
      this.show = true;
    }
  },
  data() {
    return {
      currentStudent: null,
      currentIndex: null,
      noAnswers: false,
      feedback: "",
      myStudents: [],
      show: false,
      val: 0,
      studentAnswers: false,
      stage: null,
    };
  },
  methods: {
    sendMessage() {
      this.track({
        event: "dashboard-send-feedback",
        data: { studentId: this.currentStudent, type: "specific" },
      });
      this.postFeedback({
        feedback: {
          header: `Respecto a la actividad ${
            this.currentIndex
          } de la etapa ${this.currentPhase.number + 1}, tu profesor escribió:`,
          message: this.feedback,
          receiver: this.currentStudent,
        },
      }).then(() => {
        this.feedback = "";
        this.studentAnswers = false;
      });
    },
    getAnswers(node, student) {
      let index = node - 1;
      this.currentIndex = node;
      let currIndex = 0;
      // get completed stages from student
      this.currentStudent = student.user.id;
      let completedStages = student.progress
        .filter(
          (y) =>
            y.related == "stage" && y.related_parent_id == this.currentPhase.id
        )
        .map((x) => x.related_id);
      let stageId;
      let start = 0;
      // iterate over all stages. if it has feedback and it includes
      // the student id, increment index.
      while (start < this.currentPhase.stages.length) {
        let s = this.currentPhase.stages[start];
        if (this.$includes(completedStages, s.id)) {
          // TODO: check if has feedback

          // if its index is the same as the index of the clicked node,
          // show this answer.
          if (currIndex == index) {
            stageId = s.id;
            if (!s.has_feedback) {
              this.noAnswers = true;
              return;
            }
          }
          currIndex += 1;
        }
        start++;
      }
      // get answers
      this.getStudentAnswers({ studentId: student.pk, stageId: stageId }).then(
        (answers) => {
          answers.data.forEach((a) => {
            this.addAnswer({ questionId: a.question, answer: a.answer });
          });
          this.studentAnswers = true;
          this.stage = this.currentPhase.stages.filter(
            (s) => s.id == stageId
          )[0];
        }
      );
    },
    getCurrent(student) {
      // get student finished stages
      let studentCompletedStages = student.progress
        .filter((completed) => {
          return completed.related_parent_id === this.currentPhase.id;
        })
        .map((completed) => completed.related_id);
      // get stages with answers

      studentCompletedStages = studentCompletedStages.filter((s) =>
        this.$includes(this.stagesWithAnswers, s)
      );
      return studentCompletedStages.length;
    },
    ...mapActions({
      postFeedback: "authentication/postFeedback",
      getClassStudents: "_class/getClassStudents",
      getStudentAnswers: "answer/getStudentAnswers",
      getCompleted: "answer/getCompleted",
      addAnswer: "answer/addAnswer",
      removeAnswers: "answer/removeAnswers",
      track: "authentication/track",
    }),
    getBackgroundColor(i) {
      return i % 2 === 0 ? "white" : "#EFF1F6";
    },
  },
  watch: {
    currentClass(newVal) {
      this.show = false;
      if (newVal.students === undefined) {
        this.getClassStudents().then((students) => {
          this.myStudents = students;
          this.show = true;
        });
      } else {
        this.myStudents = newVal.students;
        this.show = true;
      }
    },
  },
  computed: {
    ...mapGetters({
      mobile: "layout/isMobile",
      currentClass: "_class/currentClass",
      currentPhase: "program/currentPhase",
      user: "authentication/user",
    }),
    phaseProgress() {
      if (this.myStudents) {
        let finishedStudents = this.myStudents.filter((s) => {
          let progress = s.progress;
          let filteredProgress = progress.filter((p) => {
            return p.related == "phase" && p.related_id == this.currentPhase.id;
          });
          return filteredProgress.length > 0;
        });
        return (
          Math.round(
            (100 * finishedStudents.length) / this.myStudents.length
          ) || 0
        );
      }
      return 0;
    },
    title() {
      return this.$t("dashboard.progress-panel.title", {
        value: this.phaseProgress,
      });
    },
    stagesWithAnswers() {
      return this.currentPhase.stages.map((stage) => stage.id);
    },
  },
};
</script>
<style>
.v-progress-linear__determinate.primary {
  background: linear-gradient(90deg, #ffd166 2.76%, #ff7497 74.37%) !important;
  border-radius: 6px;
}
</style>
