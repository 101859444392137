<template>
  <div
    class="text-left m-4 relative"
    style="background:white;border-radius:10px;z-index:2"
  >
    <div class="pa-6 pb-6 d-flex">
      <v-icon color="#4f4f4f;" v-if="icon" class="me-4 mt-auto mb-auto">{{
        icon
      }}</v-icon>
      <div v-if="title" style="max-width:70%" class="primer-text-title">
        {{ title }}
      </div>
    </div>
    <div>
      <slot> </slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "PrimerSection",
  props: {
    title: {
      required: false,
      default: null,
      type: String,
    },
    video: {
      required: false,
      default: null,
      type: String,
    },
    icon: {
      required: false,
      default: null,
      type: String,
    },
  },
};
</script>
