<template>
  <v-select :outlined="outlined" dense v-bind="$attrs" v-model="computedValue">
  </v-select>
</template>
<script>
export default {
  name: "CommonDropdown",
  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
  props: {
    outlined: {
      required: false,
      default: true,
      type: Boolean,
    },

    value: {
      required: true,
    },
  },
};
</script>
