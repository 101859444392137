var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-btn',_vm._b({staticClass:"no-capitalize",class:_vm.selected ? _vm.primaryClass : _vm.secondaryClass,style:({
    background: _vm.selected ? _vm.secondaryColor : _vm.primaryColor,
    color: _vm.selected ? _vm.primaryColor : _vm.secondaryColor,
    'border-radius': _vm.borderRadius,
    'min-height': _vm.textWrap ? '36px' : '',
    height: _vm.textWrap ? 'auto' : '',
    'pointer-events': _vm.readOnly ? 'none' : 'auto',
    border: _vm.borderStyle,
  }),attrs:{"disabled":_vm.disabled,"rounded":"","no-elevation":"","dense":"","active-class":"no-active","hideOverlay":"","content-class":"elevation-0","elevation":"0","type":_vm.type,"icon":_vm.icon != null},on:{"click":function($event){return _vm.$emit('click')}}},'v-btn',Object.assign({}, _vm.extraProps),false),[(_vm.prependIcon)?_c('v-icon',{staticClass:"me-1"},[_vm._v(_vm._s(_vm.prependIcon))]):_vm._e(),_c('custom-text',{class:[_vm.textClass, _vm.textWrap ? 'text-wrap' : ''],attrs:{"text":_vm.text,"ignoreStyles":_vm.ignoreStyles}}),(_vm.icon)?_c('v-icon',[_vm._v(_vm._s(_vm.icon))]):_vm._e(),(_vm.appendIcon)?_c('v-icon',{staticClass:"ms-1"},[_vm._v(_vm._s(_vm.appendIcon))]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }