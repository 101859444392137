<template>
  <!-- mixpanel on -->
  <div
    ref="tool"
    class="dashboard-tool p-3 relative"
    style="flex: 1 1 0;"
    @focusout="showOptions = false"
    tabindex="0"
    @click="clickTool()"
  >
    <div style="position:relative">
      <!-- tools -->

      <div
        class="absolute w-100 bg--primary-30-t color--primary-30-t my-menu"
        style="border-radius:8px;margin-left:-16px"
        :style="{
          'margin-top': toolHeight,
          width: toolWidth,
          'min-width': toolWidth,
          'max-width': toolWidth,
        }"
        v-if="showOptions"
      >
        <div class="mt-3 mb-3" style="max-height:50vh; overflow-y: auto">
          <div
            v-if="options.length == 0"
            class="italic bg--neutral-100-t mt-2 mb-2"
          >
            <div class="pt-2 pb-2" v-text="$t('dashboard.no-notification')" />
          </div>
          <div
            style="max-width:100%;"
            class="bg--neutral-100-t"
            v-for="(o, index) in options"
            @click.stop.prevent
            @click="selectOption(o.id)"
            :key="index"
          >
            <div
              v-if="tool.name != 'notifications'"
              class="color--neutral-30-t pt-2 pb-2 bold"
            >
              {{ o.text }}
            </div>
            <div v-else class="d-flex">
              <div class="d-flex flex-column w-100 text-left p-2">
                <div class="text-label color--neutral-50-t">
                  {{ o.header }}
                </div>
                <div class="color--neutral-30-t text-body">{{ o.message }}</div>
                <div class="mt-1 text-label color--primray">
                  {{ getDate(o.date_sent) }}
                </div>
              </div>
            </div>
            <div
              style="height:1px;width:100%"
              class="bg--neutral-80-t"
              v-if="index < options.length - 1"
            ></div>
          </div>
        </div>
      </div>
      <!-- text -->
      <div class="h-100 d-flex flex-column">
        <v-icon class="color--secondary mt-auto mb-auto" icon="mdi-lock"
          >{{ tool.icon }}
        </v-icon>
      </div>

      <div
        v-if="
          tool.name == 'notifications' &&
            tool.options.filter((x) => !x.seen).length > 0
        "
        class="bg--error"
        style="position:absolute;width:10px;height:10px;border-radius:10px;top:0;right:0;"
      ></div>
    </div>

    <div
      class="d-flex flex-column text-left ms-2 h-100 justify-content-center"
      :style="{ '--color': getColor() }"
    >
      <span class="color--neutral-30-t bold text-body">{{ tool.title }}</span>
      <span v-if="!mobile" class="text-small">{{ tool.subtitle }}</span>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "DashboardTool",
  methods: {
    ...mapActions({
      track: "authentication/track",
      patchFeedback: "authentication/patchFeedback",
    }),
    selectOption(id) {
      this.track({
        event: "dashboard-tool-select-option",
        data: { tool: this.tool.name, option: id },
      });
      if (this.tool.name !== "notifications") {
        this.$emit("selectOption", id);
        this.showOptions = false;
      }
    },
    getColor() {
      return "#a7aebe";
    },
    getDate(originalDate) {
      if (originalDate) {
        var date = new Date(originalDate);
        var formatOptions = {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        };
        var dateString = date.toLocaleDateString("en-UK", formatOptions);
        dateString = dateString
          .replace(",", "")
          .replace("PM", "p.m.")
          .replace("AM", "a.m.");

        return dateString;
      }
    },
    clickTool() {
      this.track({
        event: "dashboard-select-tool",
        data: { tool: this.tool.name },
      });
      if (this.options) {
        if (this.tool.name == "notifications") {
          this.tool.options.forEach((notification) => {
            if (!notification.seen) {
              this.track({
                event: "dashboard-student-see-notification",
                data: {},
              });
              this.patchFeedback({
                feedback: {
                  seen: true,
                },
                id: notification.id,
              });
            }
          });
        }

        this.showOptions = !this.showOptions;
      } else {
        if (this.tool.name !== "notifications") {
          this.$emit("clickTool");
        }
      }
    },
  },
  data() {
    return {
      showOptions: false,
    };
  },
  computed: {
    ...mapGetters({
      mobile: "layout/isMobile",
    }),
    toolWidth() {
      return `${this.$refs.tool.clientWidth}px`;
    },
    toolHeight() {
      return `calc(${this.$refs.tool.clientHeight}px - 10px)`;
    },
  },
  props: {
    tool: {
      required: true,
      type: Object,
    },
    options: {
      required: false,
      default: () => [],
    },
  },
};
</script>
<style>
.my-menu {
  position: absolute;
  z-index: 1001;
}
.my-menu::before {
  position: absolute;
  content: "";
  top: 0;
  right: 50%;
  transform: translate(50%, -100%);
  width: 10px;
  height: 13px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 13px solid currentColor;
}
</style>
