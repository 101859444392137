<template>
  <div class="form-background">
    <!-- background -->
    <div>
      <canvas class="green-circle" />
      <canvas class="purple-shape" />
      <canvas class="red-shape" />
    </div>
    <img class="mt-10 form-logo" src="@/assets/Dashboard/Logo.svg" />

    <!-- content -->
    <div class="form-content h-100 pt-5 text-left">
      <!-- Results -->
      <div>
        <div class="text-heading">
          {{ lang == "es-CO" ? "¡Bienvenidos!" : "¡Tiempo de encuesta!" }}
        </div>
        <div v-if="lang == 'es-CO'">
          A continuación podrán revisar el turno en el que le corresponderá a
          sus instituciones educativas acceder al programa Decidiendo para un
          Futuro Mejor en Colombia. Aquellas que se indican en Turno 1,
          comenzarán las actividades el presente año académico; mientras que las
          que se encuentren en el Turno 2, lo harán a comienzos del año
          académico 2023.
          <br /><br />Para conocer la manera en que las comunidades educativas
          seleccionadas para el primer turno pueden dar inicio al programa, los
          invitamos a revisar el siguiente instructivo: <br /><br />
        </div>
        <div v-else-if="lang == 'es-CL'">
          Estamos llegando a la parte final del programa Decidiendo para un
          Futuro Mejor. En esta última etapa estudiantes, docentes y rectores
          deberán contestar la encuesta final del programa. La encuesta tiene
          como propósito conocer las expectativas de las y los estudiantes,
          docentes y rectores sobre la educación y la información que manejan
          sobre las diferentes opciones que existen para acceder a la educación
          superior.
          <br />
          <br />
          Para ingresar a la encuesta cada estamento debe ingresar al link
          correspondiente.
          <br />
          <div class="d-flex mt-4" :class="mobile ? 'flex-column' : ''">
            <common-button
              class="text-label me-1"
              :class="mobile ? 'mb-1' : 'ms-auto'"
              text="Encuesta estudiantes"
              @click="
                goTo(
                  'https://mit.co1.qualtrics.com/jfe/form/SV_1S5XNFTaOvLwjm6'
                )
              "
            ></common-button>
            <common-button
              @click="
                goTo(
                  'https://mit.co1.qualtrics.com/jfe/form/SV_6ViSAwxJrjRROJ0'
                )
              "
              class="text-label ms-1 me-1"
              :class="mobile ? 'mb-1' : ''"
              text="Encuesta profesores"
            ></common-button>
            <common-button
              @click="
                goTo(
                  'https://mit.co1.qualtrics.com/jfe/form/SV_0DjNoZAAFwH6U6y'
                )
              "
              class="text-label ms-1 me-1"
              :class="mobile ? 'mb-1' : ''"
              text="Encuesta directores"
            ></common-button>
            <common-button
              @click="
                goTo(
                  'https://mit.co1.qualtrics.com/jfe/form/SV_3xDduvzht7lEWyy'
                )
              "
              class="text-label ms-1"
              :class="mobile ? 'mb-1' : 'me-auto'"
              text="Consentimiento apoderados"
            ></common-button>
          </div>
          <br />
          Es importante que cada uno de estos cuestionarios sea compartido a
          cada estamento, y luego sea respondido por ellos, ya que solo de esta
          manera su establecimiento tendrá la posibilidad recibir un informe
          personalizado para su establecimiento con información completa.
        </div>

        <a
          v-if="lang == 'es-CO'"
          href="https://drive.google.com/uc?export=download&id=1OYGPaNVdaJ-Q5oLqfJ9EGBcOssU96_3t"
          download="Instructivo_DFM.pdf"
          style="color:white;text-decoration:none; margin: 0;position: absolute;left: 50%;transform: translateX(-50%);"
          ><common-button
            class=" horizontal-center mt-1 text-label"
            text="Instructivo Colombia"
          ></common-button
        ></a>
        <div v-if="lang == 'es-CO'">
          <br />
          <br /><br />Aquellos integrantes de la comunidad educativa que ya se
          crearon sus perfiles, deben ingresar con su usuario y clave
          <a
            href="https://colombia.decidiendofuturomejor.com/login"
            target="_blank"
          >
            aquí</a
          >.
          <b>
            Recuerde que el usuario de los estudiantes es su número de cédula u
            identificador y su clave los primeros 4 dígitos de dicho número.
          </b>
          Asimismo, los estaremos contactando en los próximos días con más
          información y recursos para que puedan implementar el programa sin
          problemas.

          <br /><br />
        </div>
        <div class="italic" style="text-align: right; " v-if="lang == 'es-CO'">
          *Deslice para encontrar su sede
        </div>
        <v-simple-table
          style="max-height: 500px; overflow-y: scroll"
          v-if="lang == 'es-CO'"
        >
          <template v-slot:default>
            <thead>
              <tr class="bg--primary bold">
                <th class="text-left color--neutral-100-t">
                  Institución
                </th>
                <th class="text-left color--neutral-100-t">
                  Sede
                </th>
                <th class="text-left color--neutral-100-t bo">
                  Turno
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in schools"
                :key="index"
                :class="
                  index % 2 == 0
                    ? 'bold bg--primary-60-t'
                    : 'bold bg--primary-90-t'
                "
              >
                <td>{{ item.Institución }}</td>
                <td>{{ item.Sede }}</td>
                <td style="white-space:nowrap">{{ item.Turno }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <br /><br />
        <div v-if="lang == 'es-CO'">
          Para dudas y consultas sobre la obtención de perfiles de rectores,
          profesores y estudiantes, los invitamos a escribirnos a
          <a href="mailto: decidiendofuturomejor@consiliumbots.com"
            >decidiendofuturomejor@consiliumbots.com</a
          >
        </div>
        <div v-else-if="lang == 'es-CL'">
          Ante cualquier duda se puede contactar a través del email
          <a href="mailto: decidiendofuturomejor@mineduc.cl"
            >decidiendofuturomejor@mineduc.cl</a
          >
        </div>
        <br />
        <br />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      schools: [
        {
          Institución: "INSTITUCION EDUCATIVA ARTURO GOMEZ JARAMILLO",
          Sede: "ARTURO GOMEZ JARAMILLO",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA ARTURO GOMEZ JARAMILLO",
          Sede: "JOSE IGNACIO RENGIFO",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA SAN JOSE",
          Sede: "SAN JOSE",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA AGRICOLA CAMPOALEGRE",
          Sede: "AGRICOLA CAMPOALEGRE",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA ELEAZAR LIBREROS SALAMANCA",
          Sede: "ELEAZAR LIBREROS SALAMANCA",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA ELEAZAR LIBREROS SALAMANCA",
          Sede: "JUAN DE DIOS RODRIGUEZ",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA EL PLACER",
          Sede: "EL PLACER",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA JORGE ISAACS",
          Sede: "JORGE ISAACS",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA SANTA ANA DE LOS CABALLEROS",
          Sede: "ANTONIO RICAURTE",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA SANTA ANA DE LOS CABALLEROS",
          Sede: "SANTA ANA DE LOS CABALLEROS",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA SANTA INES",
          Sede: "SANTA INES",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA GILBERTO ALZATE AVENDAÑO",
          Sede: "GILBERTO ALZATE AVENDAÑO",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA SANTIAGO GUTIERREZ ANGEL",
          Sede: "SANTIAGO GUTIERREZ ANGEL",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA BETANIA",
          Sede: "MANUEL DOLORES MONDRAGON",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA LA TULIA",
          Sede: "MANUEL DOLORES MONDRAGON",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA LA TULIA",
          Sede: "MARIA INMACULADA",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA MANUEL DOLORES MONDRAGON",
          Sede: "MANUEL DOLORES MONDRAGON",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA MANUEL DOLORES MONDRAGON",
          Sede: "SATELITE RICAURTE",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA NARANJAL",
          Sede: "MANUEL DOLORES MONDRAGON",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA PRIMAVERA",
          Sede: "CONCENTRACION AGRICOLA",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA RODRIGO LLOREDA CAICEDO",
          Sede: "DIEGO FALLON",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA RODRIGO LLOREDA CAICEDO",
          Sede: "RODRIGO LLOREDA",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA ANTONIO NARIÑO",
          Sede: "ANTONIO NARIÑO",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA CEILAN",
          Sede: "INSTITUTO AGRICOLA CEILAN",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA DIEGO RENGIFO SALAZAR",
          Sede: "ANTONIO NARIÑO CHORRERAS",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA DIEGO RENGIFO SALAZAR",
          Sede: "ANTONIO NARIÑO LA URIBE",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA DIEGO RENGIFO SALAZAR",
          Sede: "ANTONIO RICAURTE",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA DIEGO RENGIFO SALAZAR",
          Sede: "DIEGO RENGIFO SALAZAR",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA DIEGO RENGIFO SALAZAR",
          Sede: "MARIANO OSPINA PEREZ",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA MARIANO GONZALEZ",
          Sede: "ANTONIO NARIÑO",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA ANTONIA SANTOS",
          Sede: "ANTONIA SANTOS",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA BOLIVARIANO",
          Sede: "BOLIVARIANO",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA GILBERTO ALZATE AVENDAÑO",
          Sede: "CENT DOC NO 22 GILBERTO ALZATE AVENDAÑO",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA LA CONSOLITA",
          Sede: "LA CONSOLITA",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA NORMAL SUPERIOR MARIA INMACULADA",
          Sede: "ESC NORMAL SUPERIOR MARIA INMACULADA",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA SAGRADO CORAZON DE JESUS",
          Sede: "SAGRADO CORAZON DE JESUS",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA GIMNASIO DEL CALIMA",
          Sede: "GIMNASIO DEL CALIMA",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA PABLO SEXTO",
          Sede: "PABLO SEXTO",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA SIMON BOLIVAR",
          Sede: "SIMON BOLIVAR",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA INMACULADA CONCEPCION",
          Sede: "FRANCISCO JOSE DE CALDAS",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA INMACULADA CONCEPCION",
          Sede: "JOSE EUSEBIO CARO",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA INMACULADA CONCEPCION",
          Sede: "SANTA TERESITA",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA INMACULADA CONCEPCION",
          Sede: "SANTIAGO RENGIFO SALCEDO",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA MARINO RENGIFO SALCEDO",
          Sede: "MARINO RENGIFO SALCEDO",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA NUESTRA SEÑORA DE LA CANDELARIA",
          Sede: "ANTONIO NARIÑO",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA NUESTRA SEÑORA DE LA CANDELARIA",
          Sede: "GERMAN NIETO",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA NUESTRA SEÑORA DE LA CANDELARIA",
          Sede: "SAGRADA FAMILIA",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA PANEBIANCO AMERICANO",
          Sede: "ENRIQUE OLAYA HERRERA",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA PANEBIANCO AMERICANO",
          Sede: "JOSE MARIA CORDOBA",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA PANEBIANCO AMERICANO",
          Sede: "PANEBIANCO AMERICANO - PRINCIPAL",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA PANEBIANCO AMERICANO",
          Sede: "SANTA RITA DE CASSIA",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA RODRIGO LLOREDA CAICEDO",
          Sede: "JORGE ISAACS",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA RODRIGO LLOREDA CAICEDO",
          Sede: "RODRIGO LLOREDA CAICEDO",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA RODRIGO LLOREDA CAICEDO",
          Sede: "SIMON BOLIVAR",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA BORRERO AYERBE",
          Sede: "GIMNASIO DEL DAGUA BORRERO AYERBE",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA CAMILO TORRES",
          Sede: "CAMILO TORRES",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA CAMILO TORRES",
          Sede: "JUAN DEL CORRAL",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA CRISTOBAL COLON",
          Sede: "CENTRAL CRISTOBAL COLON",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA DEL DAGUA",
          Sede: "ANTONIA SANTOS",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA DEL DAGUA",
          Sede: "ANTONIO RICAURTE",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA DEL DAGUA",
          Sede: "GIMNASIO DEL DAGUA",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA EL PALMAR",
          Sede: "FRANCISCO DE PAULA SANTANDER",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA EL PALMAR",
          Sede: "GIMNASIO DEL DAGUA",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA EL QUEREMAL",
          Sede: "GIMNASIO DEL DAGUA QUEREMAL",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA GUILLERMO VALENCIA",
          Sede: "GUILLERMO VALENCIA",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA GUILLERMO VALENCIA",
          Sede: "SAN VICENTE",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA MIGUEL ANTONIO CARO",
          Sede: "MIGUEL ANTONIO CARO",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA PEDRO FERMIN DE VARGAS",
          Sede: "POLICARPA SALAVARRIETA",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA SAN PEDRO CLAVER",
          Sede: "GENERAL CARLOS ALBAN",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA SAN PEDRO CLAVER",
          Sede: "INSTITUTCION EDUCATIVA SAN PEDRO CLAVER - PRINCIPAL",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA SANTA TERESITA DEL NIÑO JESUS",
          Sede: "NUESTRA SEÑORA DE FATIMA",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA SANTA TERESITA DEL NIÑO JESUS",
          Sede: "SANTA TERESITA DEL NIÑO JESUS",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA EL AGUILA",
          Sede: "ANTONIO RICAURTE",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA EL AGUILA",
          Sede: "JOSE MARIA CORDOBA",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA EL AGUILA",
          Sede: "RICARDO LUIS BETANCOURTH",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA JUSTINIANO ECHAVARRIA",
          Sede: "JOSE MARIA CORDOBA",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA JUSTINIANO ECHAVARRIA",
          Sede: "SANTA ISABEL",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA SANTA MARTA",
          Sede: "CONCENTRACION DE DESARROLLO RURAL SANTA MARTA",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA SANTA MARTA",
          Sede: "DIONISIO CORTES",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA GILBERTO ALZATE AVENDAÑO",
          Sede: "GILBERTO ALZATE AVENDAÑO",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA GILBERTO ALZATE AVENDAÑO",
          Sede: "GILBERTO ALZATE AVENDAÑO - ALBAN",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA LA PRESENTACION",
          Sede: "LA PRESENTACION",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA HERNANDO BORRERO CUADROS",
          Sede: "JORGE ISAACS",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA JORGE ISAACS",
          Sede: "CINCUENTENARIO",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA JORGE ISAACS",
          Sede: "JORGE ISAACS",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA JORGE ISAACS",
          Sede: "SANTA BARBARA",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA JORGE ISAACS EL PLACER",
          Sede: "JORGE ISAACS EL PLACER",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA SAGRADO CORAZON",
          Sede: "PEDRO ANTONIO MOLINA",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA SAGRADO CORAZON",
          Sede: "SAGRADO CORAZON",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA SANTA ELENA",
          Sede: "JORGE ISAACS PRINCIPAL",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA ACERG",
          Sede: "JUAN SALVADOR GAVIOTA",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA JOSE MARIA FALLA",
          Sede: "JOSE MARIA FALLA",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA JOSE MARIA FALLA",
          Sede: "POLICARPA SALAVARRIETA",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA KWE SX NASA KXSA WNXI IDEBIC",
          Sede: "KIMI PERNIA DOMICO 2",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA ABSALON TORRES CAMACHO",
          Sede: "ABSALON TORRES CAMACHO",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA ABSALON TORRES CAMACHO",
          Sede: "EMETERIO PIEDRAHITA",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA ABSALON TORRES CAMACHO",
          Sede: "JULIO ARBOLEDA",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA ATANASIO GIRARDOT",
          Sede: "ATANASIO GIRARDOT",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA CIUDAD FLORIDA",
          Sede: "GABRIELA MISTRAL",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA CIUDAD FLORIDA",
          Sede: "LICEO COMERCIAL FEMENINO",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA CIUDAD FLORIDA",
          Sede: "POLICARPA SALAVARRIETA",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA JOSE MARIA CORDOBA",
          Sede: "ANTONIO JOSE DE SUCRE",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA JOSE MARIA CORDOBA",
          Sede: "MANUELA BELTRAN",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA JOSE MARIA CORDOBA",
          Sede: "SATELITE LAS AMERICAS",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA KWE SX NASA KXSA WNXI IDEBIC",
          Sede: "KWE`SX NASA KSXA' WNXI",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA LAS AMERICAS",
          Sede: "INSTITUTO LAS AMERICAS",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA LAS AMERICAS",
          Sede: "NORMAN ZULUAGA JARAMILLO",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA REGIONAL SIMON BOLIVAR",
          Sede: "REGIONAL SIMON BOLIVAR",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA DE DESARROLLO RURAL LA SELVA",
          Sede: "CONCENTRACION DE DESARROLLO RURAL LA SELVA",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA GINEBRA LA SALLE",
          Sede: "GINEBRA LA SALLE",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA INMACULADA CONCEPCION",
          Sede: "INMACULADA CONCEPCION",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA MANUELA BELTRAN",
          Sede: "MANUELA BELTRAN",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA MANUELA BELTRAN",
          Sede: "MIGUEL ANTONIO CARO",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA MANUELA BELTRAN",
          Sede: "NUESTRA SEÑORA DEL CARMEN",
          Turno: "Segundo Turno",
        },
        {
          Institución:
            "INSTITUCION EDUCATIVA ESCUELA NORMAL SUPERIOR MIGUEL DE CERVANTES SAAVEDRA",
          Sede: "MIGUEL DE CERVANTES SAAVEDRA",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA GENERAL SANTANDER",
          Sede: "GENERAL SANTANDER",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA GENERAL SANTANDER",
          Sede: "SATELITE PEDRO VICENTE ABADIA",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA JOSE CELESTINO MUTIS",
          Sede: "PEDRO VICENTE ABADIA",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA JOSE IGNACIO OSPINA",
          Sede: "JORGE ELIECER GAITAN",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA JOSE IGNACIO OSPINA",
          Sede: "POLICARPA SALAVARRIETA",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA PEDRO VICENTE ABADIA",
          Sede: "PEDRO VICENTE ABADIA",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA SIMON BOLIVAR",
          Sede: "MANUELA BELTRAN",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA KWE SX NASA KXSA WNXI IDEBIC",
          Sede: "LEONEL TROCHEZ",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA FRANCISCO DE PAULA SANTANDER",
          Sede: "FRANCISCO DE PAULA SANTANDER",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA LA LIBERTAD",
          Sede: "CONCENTRACION DE DESARROLLO RURAL",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA MARIA AUXILIADORA",
          Sede: "MARIA AUXILIADORA",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA MARIA AUXILIADORA",
          Sede: "RAFAEL NUÑEZ",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA SAN PIO X",
          Sede: "SAN PIO X",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA SIMON BOLIVAR",
          Sede: "INSTITUTO PROMOCION SOCIAL",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA ARGEMIRO ESCOBAR CARDONA",
          Sede: "ARGEMIRO ESCOBAR CARDONA",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA ARGEMIRO ESCOBAR CARDONA",
          Sede: "SIMON BOLIVAR",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA JUAN DE DIOS GIRON",
          Sede: "JUAN DE DIOS GIRON",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA MAGDALENA ORTEGA",
          Sede: "MAGDALENA ORTEGA",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA SAN JOSE",
          Sede: "SAN JOSE",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA MANUEL ANTONIO BONILLA",
          Sede: "MANUEL ANTONIO BONILLA",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA NUESTRA SEÑORA DE LA PAZ",
          Sede: "NUESTRA SEÑORA DE LA PAZ",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA SAN JOSE",
          Sede: "CONCENTRACION DE DESARROLLO RURAL SAN JOSE",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA SANTA TERESITA",
          Sede: "SANTA TERESITA",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA MARIA ANALIA ORTIZ HORMAZA",
          Sede: "MARIA ANALIA ORTIZ HORMAZA",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA POLICARPA SALAVARRIETA",
          Sede: "POLICARPA SALAVARRIETA",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA SAN JOSE",
          Sede: "SAN JOSE",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA ALFREDO POSADA CORREA",
          Sede: "ALFREDO POSADA CORREA",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA ALFREDO POSADA CORREA",
          Sede: "BELLO HORIZONTE",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA ALFREDO POSADA CORREA",
          Sede: "FRANCISCO ANTONIO ZEA",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA ALFREDO POSADA CORREA",
          Sede: "LEONIDAS MOSQUERA",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA ALFREDO POSADA CORREA",
          Sede: "SAN JUAN BOSCO",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA ANTONIO NARIÑO",
          Sede: "FRANCISCO ANTONIO ZEA",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA ATENEO",
          Sede: "ATENEO COMERCIAL FEMENINO",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA ATENEO",
          Sede: "SANTA ISABEL",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA FRANCISCO ANTONIO ZEA",
          Sede: "ANTONIO RICAURTE",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA FRANCISCO ANTONIO ZEA",
          Sede: "FRANCISCO ANTONIO ZEA",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA MARCO FIDEL SUAREZ",
          Sede: "BLASS DE LESSO",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA MARCO FIDEL SUAREZ",
          Sede: "MARCO FIDEL SUAREZ",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA MARCO FIDEL SUAREZ",
          Sede: "SIMON BOLIVAR",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA JORGE ELICER GAITAN",
          Sede: "JORGE ELIECER GAITAN",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA JORGE ELICER GAITAN",
          Sede: "NUESTRA SEÑORA DE LA CONSOLACION",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA JOSE ACEVEDO Y GOMEZ",
          Sede: "JOSE ACEVEDO Y GOMEZ",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA JOSE FELIX RESTREPO",
          Sede: "CONCENTRACION ESCOLAR URBANA",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA JULIO FERNANDEZ MEDINA",
          Sede: "BUENVIVIR",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA JULIO FERNANDEZ MEDINA",
          Sede: "MEDINA",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA TEODORO MUNERA HINCAPIE",
          Sede: "ANTONIO RICAURTE",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA TEODORO MUNERA HINCAPIE",
          Sede: "TEODORO MUNERA HINCAPIE- PRINCIPAL",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA ALFREDO GARRIDO TOVAR",
          Sede: "ALFREDO GARRIDO TOVAR",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA CAMILO TORRES",
          Sede: "INSTITUTCION EDUCATIVA CAMILO TORRES",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA HERNANDO LLORENTE ARROYO",
          Sede: "HERNANDO LLORENTE ARROYO",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA NEMESIO RODRIGUEZ ESCOBAR",
          Sede: "LA GRAN COLOMBIA",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA NEMESIO RODRIGUEZ ESCOBAR",
          Sede: "NEMESIO RODRIGUEZ",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA PRIMITIVO CRESPO",
          Sede: "PRIMITIVO CRESPO",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA BELISARIO PEÑA PIÑEIRO",
          Sede: "BELISARIO PEÑA PIÑEIRO",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA BELISARIO PEÑA PIÑEIRO",
          Sede: "BELISARIO PEÑA PIÑEIRO- CAJAMARCA",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA NORMAL SUPERIOR JORGE ISAACS",
          Sede: "NORMAL SUPERIOR JORGE ISAACS",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA NUESTRA SEÑORA DE CHIQUINQUIRA",
          Sede: " ESCUELA NUESTRA SEÑORA DE CHIQUINQUIRA",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA NUESTRA SEÑORA DE CHIQUINQUIRA",
          Sede: "LICEO NUESTRA SEÑORA DE CHIQUINQUIRA",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA SANTA ROSALIA DE PALERMO",
          Sede: "BELISARIO PEÑA PIÑEIRO",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA SANTA ROSALIA DE PALERMO",
          Sede: "BELISARIO PEÑA PIÑEIRO SATELITE",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA JOSE ANTONIO AGUILERA",
          Sede: "JOSE ANTONIO AGUILERA",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA JULIO CAICEDO TELLEZ",
          Sede: "JORGE ELIECER GAITAN",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA JULIO CAICEDO TELLEZ",
          Sede: "JULIO CAICEDO TELLEZ",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA MIGUEL ANTONIO CARO",
          Sede: "GABRIELA MISTRAL",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA MIGUEL ANTONIO CARO",
          Sede: "MIGUEL A CARO",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA BENJAMIN HERRERA",
          Sede: "GENERAL SANTANDER SATELITE DE CUMBARCO",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA BENJAMIN HERRERA",
          Sede: "GENERAL SANTANDER SATELITE MEDIO SAN MARCOS",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA DOCE DE OCTUBRE",
          Sede: "DOCE DE OCTUBRE",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA GENERAL SANTANDER",
          Sede: "GENERAL SANTANDER",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA GENERAL SANTANDER",
          Sede: "TRES DE MAYO",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA HERACLIO URIBE URIBE",
          Sede: "CONCENTRACION RURAL AGRICOLA HERACLIO URIBE URIBE",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA JORGE ELICER GAITAN",
          Sede: "GENERAL SANTANDER SATELITE LA CUCHILLA",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA MARIA AUXILIADORA",
          Sede: "MARIA AUXILIADORA",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA SANTA BARBARA",
          Sede: "ESCUELA SANTA BARBARA",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA SANTA BARBARA",
          Sede: "GENERAL SANTANDER ",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA SANTA TERESITA",
          Sede: "CENTRO EDUCATIVO LA BETULIA",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA SANTA TERESITA",
          Sede: "GENERAL SANTANDER SATELITE COLORADAS",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA SANTA TERESITA",
          Sede: "SATELITE GENERAL SANTANDER",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA SEVILLA",
          Sede: "ANTONIA SANTOS",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA SEVILLA",
          Sede: "JOHN FITZGERALD KENNEDY",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA SEVILLA",
          Sede: "SEVILLA",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA FRAY JOSE JOAQUIN ESCOBAR",
          Sede: "FRAY JOSE JOAQUIN ESCOBAR",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA NUESTRA SEÑORA DE LA CONSOLACION",
          Sede: "NUESTRA SEÑORA DE LA CONSOLACION",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA TECNICA AGROPECUARIA TORO",
          Sede: "CRISTOBAL COLON",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA TECNICA AGROPECUARIA TORO",
          Sede: "INSTITUTO AGRICOLA",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA TECNICA AGROPECUARIA TORO",
          Sede: "NUESTRA SEÑORA DE FATIMA",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA ANTONIO JOSE DE SUCRE",
          Sede: "BELISARIO PEÑA",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA ANTONIO JOSE DE SUCRE",
          Sede: "JULIAN TRUJILLO - ANDINAPOLIS",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA CRISTOBAL COLON",
          Sede: "CRISTOBAL COLON",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA JULIAN TRUJILLO",
          Sede: "JORGE ROBLEDO",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA JULIAN TRUJILLO",
          Sede: "JULIAN TRUJILLO",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA MANUEL MARIA MALLARINO",
          Sede: "MANUEL MARIA MALLARINO",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA SAGRADO CORAZON DE JESUS",
          Sede: "SAGRADO CORAZON DE JESUS",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA SAN ISIDRO",
          Sede: "JUAN XXIII",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA SAN ISIDRO",
          Sede: "SAN ISIDRO",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA LEOCADIO SALAZAR",
          Sede: "LEOCADIO SALAZAR",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA LEOCADIO SALAZAR",
          Sede: "SANTA CECILIA",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA MARIA INMACULADA",
          Sede: "MARIA INMACULADA",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA CARLOS HOLGUIN SARDI",
          Sede: "CARLOS HOLGUIN SARDI",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA CARLOS HOLGUIN SARDI",
          Sede: "ROSA ZARATE DE PEÑA",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA LA INMACULADA",
          Sede: "LA INMACULADA",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA ANTONIO JOSE DE SUCRE",
          Sede: "ANTONIO JOSE DE SUCRE",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA ANTONIO JOSE DE SUCRE",
          Sede: "ATANASIO GIRARDOT",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA JORGE ROBLEDO",
          Sede: "JORGE ROBLEDO",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA JORGE ROBLEDO",
          Sede: "MANUEL JOSE REINA COLLAZOS",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA VEINTE DE JULIO",
          Sede: "MANUELA BELTRAN",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA VEINTE DE JULIO",
          Sede: "VEINTE DE JULIO",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA ALFONSO ZAWADZKY",
          Sede: "ALFONSO ZAWADZKY",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA ALFONSO ZAWADZKY",
          Sede: "JORGE ELIECER GAITAN",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA GABRIELA MISTRAL",
          Sede: "GABRIELA MISTRAL",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA SAN JUAN BOSCO",
          Sede: "SAN JUAN BOSCO",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA ANTONIO NARIÑO",
          Sede: "ANTONIO NARIÑO",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA EFRAIN VARELA VACA",
          Sede: "PABLO EMILIO CAMACHO",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA LUIS GABRIEL UMAÑA MORALES",
          Sede: "LUIS GABRIEL UMAÑA MORALES",
          Turno: "Primer Turno",
        },
        {
          Institución:
            "INSTITUCION EDUCATIVA NORMAL SUPERIOR NUESTRA SEÑORA DE LAS MERCEDES",
          Sede: "NUESTRA SEÑORA DE LAS MERCEDES",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA SIMON BOLIVAR",
          Sede: "FRANCISCO JOSE DE CALDAS",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA SIMON BOLIVAR",
          Sede: "SIMON BOLIVAR",
          Turno: "Primer Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA AGRICOLA CAMPOALEGRE",
          Sede: "JOSE ANTONIO ANZOATEGUI",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA AGRICOLA CAMPOALEGRE",
          Sede: "SAN FRANCISCO",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA SANTA INES",
          Sede: "CRISTO REY",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA SANTA INES",
          Sede: "NUESTRA SEÑORA DE LAS MERCEDES",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA SAGRADO CORAZON DE JESUS",
          Sede: "SAN ISIDRO",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA SIMON BOLIVAR",
          Sede: "ERASMO VIVAS",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA SIMON BOLIVAR",
          Sede: "LA PLAYA",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA EL QUEREMAL",
          Sede: "PIO XII",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA SAN PEDRO CLAVER",
          Sede: "RAFAEL URIBE URIBE",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA SANTA MARTA",
          Sede: "BOYACA",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA ACERG",
          Sede: "ATANASIO GIRARDOT",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA ACERG",
          Sede: "GENERAL RENGIFO",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA ACERG",
          Sede: "ROMULO RENGIFO",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA ACERG",
          Sede: "SAN ISIDRO",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA ACERG",
          Sede: "SAN MARTIN DE PORRES",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA ACERG",
          Sede: "SANTA TERESITA",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA ACERG",
          Sede: "SIMON BOLIVAR",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA KWE SX NASA KXSA WNXI IDEBIC",
          Sede: "CACIQUE UMADA",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA JOSE MARIA CORDOBA",
          Sede: "MARIA AUXILIADORA",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA JOSE MARIA CORDOBA",
          Sede: "SAN JOAQUIN",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA MANUELA BELTRAN",
          Sede: "CANAIMA",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA FRANCISCO DE PAULA SANTANDER",
          Sede: "MANUELA BELTRAN",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA QUEBRADAGRANDE",
          Sede: "CONCENTRACION ESCOLAR DE DESARROLLO RURAL QUEBRADA GRANDE",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA POLICARPA SALAVARRIETA",
          Sede: "ISIDORO PAOLI",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA FRANCISCO ANTONIO ZEA",
          Sede: "LIBARDO LOZANO",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA MARCO FIDEL SUAREZ",
          Sede: "FRANCISCO P SANTANDER",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA MERCEDES ABREGO",
          Sede: "ALFONSO LOPEZ PUMAREJO",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA MERCEDES ABREGO",
          Sede: "MERCEDES ABREGO - PRINCIPAL",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA MERCEDES ABREGO",
          Sede: "SIMBAD ARTURO BUENO",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA JOSE ACEVEDO Y GOMEZ",
          Sede: "ATANASIO GIRARDOT",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA JOSE ACEVEDO Y GOMEZ",
          Sede: "FRANCISCO JOSE DE CALDAS",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA JOSE ACEVEDO Y GOMEZ",
          Sede: "LOS MORENOS",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA ALFREDO GARRIDO TOVAR",
          Sede: "SANTA FE",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA CAMILO TORRES",
          Sede: "MARIA AUXILIADORA",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA CAMILO TORRES",
          Sede: "SANTO DOMINGO SAVIO",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA JULIO CAICEDO TELLEZ",
          Sede: "ANTONIO NARIÑO",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA BENJAMIN HERRERA",
          Sede: "BENJAMIN HERRERA",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA BENJAMIN HERRERA",
          Sede: "SAN GERARDO",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA TECNICA AGROPECUARIA TORO",
          Sede: "CAICEDO Y CUERO",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA TECNICA AGROPECUARIA TORO",
          Sede: "GUILLERMO LEON VALENCIA",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA TECNICA AGROPECUARIA TORO",
          Sede: "JOSE MARIA CORDOBA",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA LA INMACULADA",
          Sede: "LIBARDO DE JESUS GOMEZ CARDONA",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA LA INMACULADA",
          Sede: "RAMON ELIAS HERNANDEZ",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA ANTONIO JOSE DE SUCRE",
          Sede: "ANTONIO RICAURTE",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA ANTONIO JOSE DE SUCRE",
          Sede: "JUAN JOSE RONDON",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA ANTONIO JOSE DE SUCRE",
          Sede: "LA PEDRERA",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA ANTONIO JOSE DE SUCRE",
          Sede: "MARIANO OSPINA PEREZ",
          Turno: "Segundo Turno",
        },
        {
          Institución: "INSTITUCION EDUCATIVA ANTONIO JOSE DE SUCRE",
          Sede: "VICTOR HUMBERTO BARRERA",
          Turno: "Segundo Turno",
        },
      ],
    };
  },
  mounted() {
    this.schools = this.schools.sort(function(a, b) {
      if (a.Institución < b.Institución) {
        return -1;
      }
      if (a.Institución > b.Institución) {
        return 1;
      }
      return 0;
    });
  },
  computed: {
    ...mapGetters({
      mobile: "layout/isMobile",
      lang: "layout/lang",
    }),
  },
  methods: {
    goTo(route) {
      window.open(route, "_blank");
    },
  },
};
</script>
<style>
.form-logo {
  position: absolute;
  left: 150px;
  top: 0px;
  max-height: 60px;
}
.form-content {
  position: relative !important;
}
.form-background {
  width: 100vw;
  min-height: 100vh;
  height: max-content;
  padding: 10% 17%;
  background: white;
}
.green-circle {
  background: rgba(35, 215, 180, 0.15);
  width: 300px;
  height: 300px;
  border-radius: 100%;
  position: absolute;
  top: 30%;
  left: -3%;
}
.purple-shape {
  background: #5669fb;
  width: 300px;
  height: 300px;
  opacity: 0.15;
  border-radius: 0 0 0 50%;
  position: absolute;
  top: 25%;
  right: -3%;
}
.red-shape {
  background: #ee77d3;
  width: 300px;
  height: 200px;
  opacity: 0.15;
  border-radius: 50% 0 0 0;
  position: absolute;
  bottom: 0;
  right: 15%;
}
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: #5669fb;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #7888ff;
}
@media (max-width: 700px) {
  .form-logo {
    position: absolute;
    left: 20px;
    top: -15px;
    max-height: 35px;
  }
  .form-background {
    padding: 15% 10%;
  }
  .green-circle {
    width: 100px;
    height: 100px;
    top: 30%;
    left: -3%;
  }
  .purple-shape {
    background: #5669fb;
    width: 100px;
    height: 100px;
    top: 25%;
    right: -3%;
  }
  .red-shape {
    background: #ee77d3;
    width: 100px;
    height: 100px;
    bottom: 0;
    right: 15%;
  }
}
</style>
