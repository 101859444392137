<template>
	<v-row class="relative" style="top:45px;margin-bottom:45px;">
		<!-- title -->
		<v-row
			class=" ml-0 white--text text-left"
			:class="isMobile ? 'bg--primary' : 'title vh-100'"
			style="display: flex; align-items: flex-end; padding: 5rem 5%"
		>
			<p :class="isMobile ? 'w-100 text-title' : 'w-50 text-heading'">
				Somos un equipo multidisciplinario especializado en áreas de educación,
				información y tecnología
			</p>
		</v-row>
		<!-- pi's -->
		<v-row class="ml-0" style="padding:7% 5%">
			<v-col
				class="text-left"
				:cols="isMobile ? 12 : 4"
				v-for="(piColumn, index) in [
					{
						image: 'pi1.png',
						name: 'Christopher Neilson',
						charge: 'CEO',
						site: 'https://christopherneilson.github.io/',
						text:
							'Christopher A. Neilson, es profesor de Economía y Asuntos Públicos en la Universidad de Yale y tiene un doctorado en Economía en la Universidad de Yale. El profesor Neilson dirige la investigación en la que se basan las innovaciones de ConsiliumBots. En particular su agenda de investigación se centra especialmente en cómo los conocimientos y las creencias de los estudiantes y sus familias afectan sus decisiones y cómo las intervenciones de política pública pueden generar cambios en los diferentes actores a nivel individual, modificando estas creencias o facilitando las elecciones y la participación.',
					},
					{
						image: 'pi2.png',
						name: 'Francisco Gallego',
						charge: 'Investigador principal',
						site: 'https://sites.google.com/view/franciscoagallego',
						text:
							'Profesor titular del Instituto de Economía de la Pontificia Universidad Católica de Chile (PUC) y Director Científico de J-PAL LAC. Sus áreas de interés son la economía del desarrollo, la economía política y la economía de la educación. Ha trabajado en diversas evaluaciones educativas centradas en el impacto de la información en las decisiones de los padres, incluyendo la evaluación de DFM Perú. En los últimos años, el profesor Gallego ha establecido una sólida colaboración con el Gobierno de Chile en diversos temas, como la educación y la prevención del abandono escolar.',
					},
					{
						image: 'pi3.png',
						name: 'Oswaldo Molina',
						charge: 'Investigador principal',
						site: 'https://sites.google.com/site/oswaldomolina/home',
						text:
							'Profesor asociado en el departamento de economía Universidad del Pacífico (Lima, Perú) e investigador asociado en el Centro de Estudios de la Universidad del Pacífico. Actualmente lidera la Red de Estudios para el Desarrollo (REDES), una iniciativa para difundir y aumentar el impacto de la investigación. Además, es editor asociado de Oxford Development Studies y columnista de opinión en el periódico El Comercio. Es doctor en Economía de la Universidad de Oxford, y se interesa por estudiar la economía del desarrollo.',
					},
				]"
				:key="index"
			>
				<img
					:src="require('../../assets/Home/Team/' + piColumn.image)"
					alt="pi"
				/>
				<h2 class="text-title color--neutral-60-t">{{ piColumn.name }}</h2>
				<h2 class="text-body color--neutral-60-t" style="white-space:nowrap">
					{{ piColumn.charge }}
				</h2>
				<h4 class="text-body color--neutral-40-t">
					<a :href="piColumn.site" target="_blank">Sitio web</a>
				</h4>
				<p class="text-label color--neutral-40-t">{{ piColumn.text }}</p>
			</v-col>
		</v-row>

		<!-- team -->
		<v-row class="ml-0" style="padding: 0% 5%">
			<h2
				class="text-left color--neutral-60-t"
				:class="isMobile ? 'text-title w-100' : 'text-heading w-75'"
			>
				Adicionalmente, tenemos un equipo de profesionales altamente capacitados
				en el desarrollo e implementación de estrategias que combinan la
				información, la tecnología y el mundo de la educación.
			</h2>
		</v-row>
		<v-row class="text-left ml-0" style="padding: 3% 5%">
			<v-col
				:cols="isMobile ? 6 : 3"
				v-for="(teamMember, index) in [
					{
						image: 'tm1.png',
						name: 'Josefina Lavín',
						employer: 'ConsiliumBots',
						charge: 'COO ConsiliumBots',
					},
					{
						image: 'tm2.png',
						name: 'Mauricio Cornejo',
						employer: 'ConsiliumBots',
						charge: 'Líder del programa',
					},

					{
						image: 'tm4.png',
						name: 'Anibal Guerrero',
						employer: 'JPAL',
						charge: 'Research manager en JPAL',
					},
					{
						image: 'tm5.png',
						name: 'Isidora Barría',
						employer: 'ConsiliumBots',
						charge: 'Coordinadora',
					},
					{
						image: 'tm8.png',
						name: 'Cristóbal Espinoza',
						employer: 'ConsiliumBots',
						charge: 'Ingeniero de Software',
					},
					{
						image: 'tm6.png',
						name: 'Juan Ignacio Isamitt',
						employer: 'ConsiliumBots',
						charge: 'Ingeniero de Software',
					},
					{
						image: 'tm7.png',
						name: 'Mayela Cerda',
						employer: 'ConsiliumBots',
						charge: 'Diseño',
					},
					{
						image: 'tm9.png',
						name: 'Mauricio Cáceres',
						employer: 'ConsiliumBots',
						charge: 'Diseño',
					},
					{
						image: 'tm11.png',
						name: 'Cristóbal Morgado',
						employer: 'ConsiliumBots',
						charge: 'Coordinador',
					},
					{
						image: 'tm10.png',
						name: 'M. Fernanda Ramírez',
						employer: 'ConsiliumBots',
						charge: 'Investigadora asociada',
					},
					{
						image: 'tm3.png',
						name: 'Eddie Escobar',
						employer: 'ConsiliumBots',
						charge: 'Consultor externo',
					},
				]"
				:key="index"
			>
				<img
					:src="require('../../assets/Home/Team/' + teamMember.image)"
					alt="tm"
				/>
				<h2 class="text-body color--neutral-60-t" style="white-space:nowrap">
					{{ teamMember.name }}
				</h2>
				<h2 class="text-body color--neutral-60-t" style="white-space:nowrap">
					{{ teamMember.employer }}
				</h2>
				<h4 class="text-body color--neutral-40-t">{{ teamMember.charge }}</h4>
			</v-col>
		</v-row>
		<v-row v-if="isMobile" class="mobile-picture ml-0" style="height:75vh">
		</v-row>
	</v-row>
</template>
<script>
	import { mapGetters, mapActions } from 'vuex';
	export default {
		data() {
			return {
				startTime: Date.now(),
			};
		},
		beforeDestroy() {
			const currentTime = Date.now();
			const timeSpent = (currentTime - this.startTime) / 1000;
			this.track({
				event: 'home-team-time',
				data: { timeSpent: timeSpent },
			});
		},
		computed: {
			...mapGetters({
				isMobile: 'layout/isMobile',
			}),
		},
		methods: {
			...mapActions({
				track: "authentication/track",
			}),
		},
	};
</script>
<style scoped>
	.title {
		background-size: cover;
		background-position: center;
		background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
			url('../../assets/Home/Team/background.png');
	}
	.mobile-picture {
		background-size: cover;
		background-position: center;
		background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
			url('../../assets/Home/Team/background.png');
	}
</style>
