<template>
  <DashboardCard>
    <edit-table
      endpoint="feature"
      :keys="['id', 'name', 'dynamic']"
      name="Características"
    />
    <edit-table
      endpoint="stagetransitionweight"
      :keys="['id', 'stage_from', 'feature_name', 'weight']"
      name="Transiciones de salida"
    />
    <edit-table
      endpoint="stagetransition"
      :keys="['id', 'stage_from', 'stage_to', 'feature_name', 'expected']"
      name="Transiciones de entrada"
    />
    <edit-table
      endpoint="questionfeatureweight"
      :keys="['id', 'question', 'answer', 'feature', 'weight']"
      name="Características en preguntas"
    />
    <edit-table
      endpoint="defaultweight"
      :keys="['id', 'related', 'related_id', 'feature', 'weight']"
      name="Características por defecto"
    />
  </DashboardCard>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import DashboardCard from "../DashboardCard.vue";
import EditTable from "./ProgramEditor/EditTable.vue";
export default {
  name: "DashboardAdminFeatures",
  components: { DashboardCard, EditTable },
  methods: {
    ...mapActions({
      getProgram: "program/getProgram",
    }),
  },
  computed: {
    ...mapGetters({
      program: "program/program",
    }),
  },
};
</script>
