<template>
	<v-row class="bg--primary-90-t">
		<!-- start video -->
		<!--
		<v-row align="center" class="vh-100 ml-0">
			<v-col>Video</v-col>
		</v-row>
    -->
		<!-- end video -->

		<!-- start info -->
		<v-row
			class="text-left bg--quinary ml-0 relative"
			:style="isMobile ? 'padding: 20% 5%' : 'padding: 10% 20%'"
			><img
				src="@/assets/Home/About/Semicircles.svg"
				alt=""
				:style="
					isMobile
						? 'transform-origin: left top;transform: scale(0.8);left:-10px;top:450px; max-width: 500px'
						: 'transform-origin: left top;transform: scale(0.34); left:-4px;top:45px'
				"
				style="position:absolute;"
			/>
			<img
				v-if="!isMobile"
				src="@/assets/Home/About/DFMCircle.svg"
				alt=""
				style="position:absolute;transform-origin: right top;transform: scale(0.15);right:-20px;top:350px;opacity:0.8"
			/>
			<v-row class="text-title ml-0">
				<v-col style="z-index:1">
					Reduciendo la exclusión escolar y abriendo las oportunidades de acceso
					a la educación superior.
				</v-col>
			</v-row>
			<v-row class=" ml-0">
				<v-col :cols="isMobile ? 12 : 6" style="z-index:1">
					Somos un programa de estudio digital y orientador virtual que entrega
					información personalizada a los estudiantes sobre los beneficios de la
					educación y las posibilidades que existen para alcanzar la educación
					superior.
				</v-col>
				<v-col :cols="isMobile ? 12 : 6" style="z-index:1">
					Nuestro objetivo a través de nuestro orientador virtual, es reducir la
					exclusión escolar y mostrar las oportunidades de acceso a la educación
					superior.
				</v-col>
			</v-row>
		</v-row>
		<!-- end info -->

		<!-- start whyJoin -->
		<v-row
			class="text-left ml-0 "
			style="z-index:1"
			:style="isMobile ? 'padding: 20% 5%' : 'padding: 7% 12%'"
		>
			<v-row class="text-title ml-0 bold mb-5">
				<v-col>
					¿Por qué sumarse a DFM?
				</v-col>
			</v-row>
			<v-row class="color--neutral-40-t ml-0">
				<v-col
					class="mb-5"
					v-for="(element, index) in whyJoinInformation"
					:key="index"
					:cols="isMobile ? 12 : 4"
				>
					<span v-html="element.html1" />
					<span v-html="element.html2" />
					<div :class="isMobile ? 'mb-2' : 'mb-10'" />
					{{ element.text }}
				</v-col>
			</v-row>
		</v-row>
		<!-- end whyJoin -->
	</v-row>
</template>
<script>
	import { mapActions, mapGetters } from 'vuex';
	export default {
		data() {
			return {
				startTime: Date.now(),
				whyJoinInformation: [
					{
						html1:
							'<canvas class="bg--secondary" width=36 height=36 style="border-radius:50%" />',
						html2:
							'<canvas class="bg--primary" width=36 height=36 style="border-radius:50%;" />',
						text:
							'Ayudamos a los estudiantes a entender la importancia de la educación, entregando información valiosa acerca de sus beneficios.',
					},
					{
						html1:
							'<canvas class="bg--secondary" width=36 height=18 style="border-radius:18px 18px 0  0; transform: translate(0%,-50%);margin-bottom: 18px" />',
						html2:
							'<canvas class="bg--secondary-60-t" width=36 height=18 style="border-radius:0 0 18px 18px; transform: translate(0%,50%);margin-bottom: 18px" />',
						text:
							'Los estudiantes pueden explorar de forma sencilla los diferentes programas e instituciones de educación superior y sus opciones de financiamiento.',
					},
					{
						html1: '<canvas class="bg--quinary mr-2" width=36 height=36 />',
						html2: '<canvas class="bg--secondary-60-t" width=8 height=36/>',
						text:
							'Acompañamos a los estudiantes en la preparación de un plan de estudio para su paso a la educación superior.',
					},
					{
						html1:
							'<canvas class="" style="width: 0;height: 0;border-style: solid;border-width: 0 24px 36px 24px;border-color: transparent transparent #FFD166 transparent;" />',
						html2: '<canvas class="bg--secondary" width=36 height=36/>',
						text:
							'Ayudamos a los docentes a orientar a los estudiantes, para que le puedan sacar el máximo provecho posible a la herramienta.',
					},
					{
						html1:
							'<canvas class="bg--secondary-40-t mr-1" width=36 height=36 style="border-radius:50%" />',
						html2: '<canvas class="bg--primary" width=36 height=36/>',
						text:
							'El programa es digital, flexible y personalizado, adaptándose a las necesidades particulares de cada estudiante y al currículum nacional del ramo de orientación.',
					},
				],
			};
		},
		beforeDestroy() {
			const currentTime = Date.now();
			const timeSpent = (currentTime - this.startTime) / 1000;
			this.track({
				event: 'home-about-time',
				data: { timeSpent: timeSpent},
			});
		},
		computed: {
			...mapGetters({
				isMobile: 'layout/isMobile',
			}),
		},
		methods: {
			...mapActions({
				track: "authentication/track",
			}),
		},
	};
</script>
<style scoped>
	.fade-in {
		opacity: 0;
		transition: opacity 0.5s ease-in-out;
	}

	.fade-in.show {
		opacity: 1;
	}
</style>
