import Vue from "vue";
import Router from "vue-router";
import Login from "./views/Login";
import Logout from "./views/Logout";
import Phase from "./views/Phase";
import ApplicationForm from "./views/ApplicationForm";
import Dashboard from "./views/Dashboard";
import Home2 from "./views/Home2.vue";
import Content from "./views/Content";
import Schedule from "./views/Schedule";
import PDFView from "./views/PDFView";
import Progress from "./views/Progress";
import Form from "./views/Form.vue";
import Results from "./views/Results.vue";
import Simulator from "./views/Simulator.vue";
import Primer from "./views/Primer.vue";
import Map from "./views/Map.vue";
import MapLogin from "./views/MapLogin.vue";
import Playground from "./views/Playground.vue";
// import NewDashboard from "./views/NewDashboard.vue";
Vue.use(Router);
// set available paths
export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  //
  routes: [
    /*{
      path: "/newdashboard",
      name: "newdashboard",
      component: NewDashboard,
    },*/
    {
      path: "/",
      name: "home",
      component: Home2,
      meta: {
        title: "Decidiendo para un Futuro Mejor",
        countries: "*",
        environments: ["PROD", "DEV", "STAGING"],
        metaTags: [
          {
            name: "description",
            content:
              "Decidiendo para un Futuro Mejor ayuda a las y los estudiantes a alcanzar sus metas personales y los orienta de manera gratuita en las decisiones a tomar para llegar a la educación superior.",
          },
          {
            property: "og:description",
            content:
              "Decidiendo para un Futuro Mejor ayuda a las y los estudiantes a alcanzar sus metas personales y los orienta de manera gratuita en las decisiones a tomar para llegar a la educación superior.",
          },
        ],
      },
    },
    {
      path: "/cronograma",
      name: "schedule",
      component: Schedule,
      meta: {
        countries: ["chile"],
        environments: ["PROD", "DEV", "STAGING"],
      },
    },
    {
      path: "/playground",
      name: "playground",
      component: Playground,
      meta: {
        countries: "*",
        environments: ["DEV"],
      },
    },
    {
      path: "/simulador",
      name: "simulator",
      component: Simulator,
      meta: {
        countries: ["chile"],
        environments: ["PROD", "DEV", "STAGING"],
      },
    },
    {
      path: "/informacion/:id/",
      name: "primer",
      component: Primer,
      meta: {
        countries: ["colombia"],
        environments: ["PROD", "DEV", "STAGING"],
      },
    },
    {
      path: "/encuesta_final",
      name: "encuesta_final",
      component: Results,
      meta: {
        countries: "*",
        environments: ["PROD", "DEV", "STAGING"],
      },
    },
    {
      path: "/mapa",
      name: "map",
      component: Map,
      meta: {
        countries: ["colombia"],
        environments: ["DEV", "STAGING", "PROD"],
      },
    },
    {
      path: "/mapa/acceso",
      name: "maplogin",
      component: MapLogin,
      meta: {
        countries: ["colombia"],
        environments: ["DEV", "STAGING", "PROD"],
      },
    },
    {
      path: "/encuestas",
      name: "encuestas",
      component: PDFView,
      meta: {
        countries: ["chile"],
        environments: ["PROD", "DEV", "STAGING"],
      },
    },
    {
      path: "/pdf",
      name: "pdf",
      component: PDFView,
      meta: {
        countries: ["chile"],
        environments: ["PROD", "DEV", "STAGING"],
      },
    },
    {
      path: "/form",
      name: "form",
      component: Form,
      meta: {
        //change to colombia
        countries: ["colombia"],
        environments: ["PROD", "DEV", "STAGING"],
      },
    },
    {
      path: "/resultados",
      name: "results",
      component: Results,
      meta: {
        countries: ["colombia"],
        environments: ["PROD", "DEV", "STAGING"],
      },
    },
    {
      path: "/progress/:uuid/",
      name: "progress",
      component: Progress,
      meta: {
        countries: ["chile"],
        environments: ["PROD", "DEV", "STAGING"],
      },
    },
    {
      path: "/postular",
      name: "apply",
      component: ApplicationForm,
      meta: {
        countries: ["colombia", "peru", "dominicana"],
        environments: ["PROD", "DEV", "STAGING"],
      },
    },
    {
      path: "*",
      name: "blocker",
      redirect: { name: "home" },
      component: Home2,
      meta: {
        countries: "*",
        environments: ["PROD", "DEV", "STAGING"],
      },
    },
    {
      path: "/contenido",
      name: "content",
      component: Content,
      meta: {
        countries: "*",
        environments: ["PROD", "DEV", "STAGING"],
      },
    },
    {
      path: "/panel",
      name: "dashboard",
      component: Dashboard,
      meta: {
        countries: "*",
        environments: ["PROD", "DEV", "STAGING"],
      },
    },
    {
      path: "/login",
      name: "login",
      component: Login,
      meta: {
        countries: "*",
        requiresLogged: true,
        environments: ["PROD", "DEV", "STAGING"],
      },
    },
    {
      path: "/login/mapa",
      redirect: () => {
        // the function receives the target route as the argument
        // we return a redirect path/location here.
        return { path: "/mapa/acceso" };
      },
    },
    {
      path: "/login/:uid/:token/",
      name: "login",
      component: Login,
      meta: {
        countries: "*",
        requiresLogged: true,
        environments: ["PROD", "DEV", "STAGING"],
      },
    },
    {
      path: "/etapa",
      name: "phase",
      component: Phase,
      meta: {
        countries: "*",
        requiresAuth: true,
        environments: ["PROD", "DEV", "STAGING"],
      },
      props: true,
    },
    {
      path: "/salir",
      name: "logout",
      component: Logout,
      meta: {
        countries: "*",
        requiresAuth: false,
        environments: ["PROD", "DEV", "STAGING"],
      },
    },
    {
      path: "/reset/:uid/:token/",
      name: "resetPassword",
      component: Login,
      meta: {
        countries: "*",
        requiresAuth: false,
        environments: ["PROD", "DEV", "STAGING"],
      },
    },
  ],
});
