import api from "../api";
export default {
  get({ endpoint, params }) {
    return api.get(endpoint, { params });
  },
  getWithHeaders({ endpoint, params, headers }) {
    return api.get(endpoint, { params, headers });
  },
  post({ endpoint, body }) {
    return api.post(`${endpoint}/`, body);
  },
  patch({ endpoint, body }) {
    let id = body.id;
    delete body.id;
    return api.patch(`${endpoint}/${id}/`, body);
  },
  delete({ endpoint, id }) {
    return api.delete(`${endpoint}/${id}`);
  },
};
