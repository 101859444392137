<template>
  <!-- mixpanel on -->
  <div>
    <custom-dialog v-model="loadingDialog">
      <v-title>Cargando...</v-title>
      <br />
      <v-progress-circular
        indeterminate
        class="m-auto"
        color="primary"
      ></v-progress-circular>
    </custom-dialog>
    <custom-dialog v-model="teacherDialog">
      <div v-for="key in Object.keys(teacherData)" :key="key">
        <v-text-field :label="key" v-model="teacherData[key]"></v-text-field>
      </div>
      <common-button text="Guardar" @click="saveTeacher"></common-button>
    </custom-dialog>
    <custom-dialog v-model="passwordDialog" @click="closePasswordDialog">
      <v-title
        >Password para {{ username }} cambiado a "{{ password }}".</v-title
      >
    </custom-dialog>
    <custom-dialog v-model="principalDialog">
      <div v-for="key in Object.keys(principalData)" :key="key">
        <v-text-field :label="key" v-model="principalData[key]"></v-text-field>
      </div>
      <common-button text="Guardar" @click="savePrincipal"></common-button>
    </custom-dialog>
    <custom-dialog v-model="changeKeyDialog">
      Está a punto de cambiar la credencial para el usuario {{ username }}.
      ¿Acepta?
      <div class="d-flex">
        <common-button
          text="Cancelar"
          @click="changeKeyDialog = false"
        ></common-button>

        <common-button
          text="Aceptar"
          class="ms-auto"
          @click="changeKey"
        ></common-button>
      </div>
    </custom-dialog>
    <custom-dialog
      v-model="teacherPasswordDialog"
      @close="closeTeacherPasswordDialog"
    >
      <v-title v-if="password == ''">
        Profesor asignado a curso correctamente. Este profesor ya contaba con
        cuenta, asi que no fue generado un password. De ser necesario, puede
        reestablecer el password.
      </v-title>
      <v-title v-else>
        Profesor creado y asignado a curso correctamente. Credenciales:
        <div>Usuario: {{ teacherData.email_profesor }}</div>
        <div>Clave: {{ password }}</div>
      </v-title>
    </custom-dialog>
    <custom-dialog
      v-model="principalPasswordDialog"
      @close="closePrincipalPasswordDialog"
    >
      <v-title v-if="error != ''">
        Director/encargado asignado a correo correctamente, cambiandolo desde el
        rbd anterior que tenía: {{ error }}. Este director/encargado ya contaba
        con cuenta, asi que no fue generado un password. De ser necesario, puede
        reestablecer el password.
      </v-title>
      <v-title v-else-if="password == ''">
        Director/encargado asignado a colegio correctamente. Este
        director/encargado ya contaba con cuenta, asi que no fue generado un
        password. De ser necesario, puede reestablecer el password.
      </v-title>
      <v-title v-else>
        Director/encargado creado a colegio correctamente. Credenciales:
        <div>Usuario: {{ principalData.email }}</div>
        <div>Clave: {{ password }}</div>
      </v-title>
    </custom-dialog>
    <DashboardCard>
      <edit-table
        :preLoadedData="schools"
        :keys="headers"
        :editable="false"
        name="Colegios"
      />
      <div class="d-flex">
        <v-autocomplete
          class="mb-1 bg--neutral-100-t rounded m-2"
          v-model="school"
          :items="schools"
          :item-text="(item) => `${item.name} - ${item.rbd}`"
          item-value="rbd"
          hide-details
          @change="selectSchool"
          label="colegio"
        >
        </v-autocomplete>
        <v-autocomplete
          class="mb-1 bg--neutral-100-t rounded m-2"
          v-model="schoolClass"
          :disabled="school == null || searchingClasses"
          @change="selectClass"
          :items="classes"
          :item-text="
            (item) =>
              `${item.name} - ${item.has_dfm ? 'habilitado' : 'deshabilitado'}`
          "
          item-value="id"
          hide-details
          label="curso"
        ></v-autocomplete>
      </div>
      <!-- principals -->
      <edit-table
        requiresPassword
        endpoint="usernew"
        label=""
        :disabled="school == null"
        :getParams="{ is_principal: 1, principal__school: school }"
        :keys="[
          'date_joined',
          'email',
          'first_name',
          'gender',
          'id',
          'identifier',
          'last_login',
          'last_name',
          'username',
        ]"
        name="Directores"
        object="user"
      >
        <template slot="externalactions" slot-scope="{ item }">
          <custom-icon
            tooltip-text="Cambiar contraseña"
            icon="key"
            @click="setChangeKeyDialog(item)"
          ></custom-icon
        ></template>
        <template slot="header">
          <common-button
            :disabled="school == null"
            @click="principalDialog = true"
            text="Agregar director/encargado"
          ></common-button></template
      ></edit-table>
      <!-- classes -->
      <edit-table
        requiresPassword
        :editable="false"
        endpoint="classnew"
        label=""
        :disabled="school == null"
        :getParams="{ school: school }"
        :keys="[
          'name',
          'year',
          'school',
          'teacher_email',
          'grade',
          'letter',
          'n_students',
          ...getSchoolKeys(),
        ]"
        name="Clases"
        object="class"
      >
        <template slot="header">
          <common-button
            :disabled="school == null"
            @click="teacherDialog = true"
            text="Agregar profesor y/o clase"
          ></common-button
        ></template>
      </edit-table>

      <!-- teachers -->
      <edit-table
        requiresPassword
        endpoint="usernew"
        label=""
        :disabled="schoolClass == null"
        :getParams="{ is_teacher: 1, teacher__classes: schoolClass }"
        :keys="[
          'date_joined',
          'email',
          'first_name',
          'gender',
          'id',
          'identifier',
          'last_login',
          'last_name',
          'username',
        ]"
        name="Profesores"
        object="user"
      >
        <template slot="externalactions" slot-scope="{ item }">
          <custom-icon
            tooltip-text="Cambiar contraseña"
            icon="key"
            @click="setChangeKeyDialog(item)"
          ></custom-icon
        ></template>
      </edit-table>
      <!-- end teachers -->
      <!-- students -->
      <edit-table
        endpoint="student"
        :disabled="schoolClass == null"
        :keys="[
          'first_name',
          'last_name',
          'identifier',
          'email',
          'class_name',
          'is_disabled',
        ]"
        object="student"
        :getParams="{ _class: schoolClass }"
        :postBody="{ school: school }"
        name="Alumnos"
        @update="reset"
        :keyOptions="{
          class_name: classes.map((c) => {
            return c.name;
          }),
        }"
      >
        <template slot="header">
          <a
            href="https://drive.google.com/uc?export=download&id=1dAtuH3LfcLrn5dvH5dwssNF2lAkbjndB"
            download="Plantilla_Alumnos.xlsx"
            style="color:white;text-decoration:none"
          >
            <common-button
              :disabled="schoolClass == null"
              prepend-icon="mdi-download"
              @click="templateDownloaded = true"
              class="w-100text-label"
              text="Descargar plantilla"
            ></common-button
          ></a>

          <custom-file-upload
            class="me-auto ms-2"
            style="max-width:70px;width:70px"
            :disabled="schoolClass == null"
            @validateRow="validateRow"
            @upload="upload"
            text="Subir datos"
          />
        </template>

        ></edit-table
      >
      <!-- end students -->
      <!-- single student -->
      <div class="d-flex">
        <v-text-field
          solo
          hide-details
          v-model="userIdentifier"
          placeholder="buscar usuario por identificador:"
        ></v-text-field>
        <common-button
          class="mt-2 ms-2"
          text="Buscar"
          @click="updateUserIdentifier"
        >
        </common-button>
      </div>

      <edit-table
        requiresPassword
        endpoint="userfeature"
        label=""
        :disabled="searchedUserIdentifier === null"
        :getParams="{ user__identifier: searchedUserIdentifier }"
        :keys="['feature', 'value']"
        name="Características"
        object="user_feature"
      >
      </edit-table>
    </DashboardCard>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import EditTable from "./ProgramEditor/EditTable";
import DashboardCard from "../DashboardCard.vue";
export default {
  name: "DashboardSchools",
  data() {
    return {
      error: "",
      username: "",
      changeKeyDialog: false,
      loadingDialog: false,
      principalPassword: false,
      templateDownloaded: false,
      password: "",
      passwordDialog: false,
      teacherPasswordDialog: false,
      teacherData: {
        rbd: "",
        first_name_profesor: "",
        last_name_profesor: "",
        grade: "",
        class: "",
        email_profesor: "",
      },
      principalData: {
        rbd: "",
        first_name: "",
        last_name: "",
        email: "",
      },
      principalDialog: false,
      teacherDialog: false,
      searchingClasses: false,
      classes: [],
      headers: [],
      school: null,
      schoolClass: null,
      userIdentifier: null,
      searchedUserIdentifier: null,

      students: [],
    };
  },
  mounted() {
    let country = this.lang == "es-CL" ? "chile" : "colombia";
    this.getSchools({ country }).then(() => {
      this.headers = Object.keys(this.schools[0]).filter(
        (column) => !this.$includes(["email", "contact", "country"], column)
      );
    });
  },
  components: {
    EditTable,
    DashboardCard,
  },
  methods: {
    closePasswordDialog() {
      this.password = "";
      this.passwordDialog = false;
      this.username = "";
    },
    setChangeKeyDialog(item) {
      if ("email" in item) {
        this.username = item.email;
      } else if ("user" in item) {
        if ("emaile" in item.user) {
          this.username = item.user.email;
        }
      }
      this.changeKeyDialog = true;
    },
    changeKey() {
      this.changeKeyDialog = false;
      this.patch({
        endpoint: "usernew",
        body: {
          id: 1,
          username: this.username,
        },
      }).then((response) => {
        this.password = response.data.password;
        this.passwordDialog = true;
      });
    },
    upload(jsonArray) {
      let finalArray = [];
      let className = this.classes.find((c) => {
        return c.id == this.schoolClass;
      }).name;
      jsonArray.forEach((s) => {
        finalArray.push({
          school: this.school,
          first_name: s.Nombre,
          last_name: s.Apellido,
          is_disabled: false,
          identifier: s.RUT,
          email: s.Email ?? s.RUT,
          gender: s["Género"],
          class_name: className,
        });
      });
      this.loadingDialog = true;
      this.post({ endpoint: "student", body: { list: finalArray } }).then(
        () => {
          let schoolClass = this.schoolClass;
          this.schoolClass = null;
          setTimeout(() => {
            this.loadingDialog = false;
            this.schoolClass = schoolClass;
          }, 300);
        }
      );
    },
    validRUT(rutCompleto) {
      if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)) return false;
      var tmp = rutCompleto.split("-");
      var digv = tmp[1];
      var rut = tmp[0];
      if (digv == "K") digv = "k";
      return this.dv(rut) == digv;
    },
    dv(T) {
      var M = 0,
        S = 1;
      for (; T; T = Math.floor(T / 10))
        S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
      return S ? S - 1 : "k";
    },
    validateRow(row, resolve) {
      let arr = ["Nombre", "RUT", "Género"];
      for (var i = 0; i < arr.length; i++) {
        var n = arr[i];
        if (row[n] == "" || row[n] == null) {
          resolve(`${n} de alumno no puede ser vacío.`);
        }
      }

      if (this.lang == "es-CL" && !this.validRUT(row.RUT)) {
        resolve("RUT del alumno inválido");
      }
      resolve(true);
    },
    closePrincipalPasswordDialog() {
      this.error = "";
      this.resetPrincipalData();
      this.password = "";
    },
    closeTeacherPasswordDialog() {
      this.resetTeacherData();
      this.password = "";
    },
    resetTeacherData() {
      this.teacherData = {
        rbd: this.school,
        first_name_profesor: "",
        last_name_profesor: "",
        grade: "",
        class: "",
        email_profesor: "",
      };
    },
    resetPrincipalData() {
      this.principalData = {
        rbd: this.school,
        first_name: "",
        last_name: "",
        email: "",
      };
    },
    savePrincipal() {
      let school = this.school;
      this.school = null;
      this.post({ endpoint: "principalnew", body: this.principalData }).then(
        (response) => {
          this.principalDialog = false;
          this.password = response.data.data.password;
          if ("rbd" in response.data.data) {
            this.error = response.data.data.rbd;
          }
          this.principalPasswordDialog = true;
          this.school = school;
        }
      );
    },
    saveTeacher() {
      let school = this.school;
      this.school = null;
      this.post({ endpoint: "classnew", body: this.teacherData })
        .then((response) => {
          this.teacherDialog = false;
          this.password = response.data.data.password;
          this.teacherPasswordDialog = true;
          this.searchingClasses = true;
          this.school = school;

          this.getSchoolClasses({ schoolId: this.school }).then((response) => {
            this.classes = response.data;
            this.searchingClasses = false;
          });
        })
        .catch(() => {
          this.searchingClasses = true;
          this.school = school;

          this.getSchoolClasses({ schoolId: this.school }).then((response) => {
            this.classes = response.data;
            this.searchingClasses = false;
          });
        });
    },
    updateUserIdentifier() {
      this.searchedUserIdentifier = null;
      setTimeout(() => {
        this.searchedUserIdentifier = this.userIdentifier;
      }, 300);
    },
    getSchoolKeys() {
      let array = [];
      this.program.phases.forEach((p) => {
        array.push(`porcentaje_etapa_${p.number + 1}`);
      });
      return array;
    },
    ...mapActions({
      post: "admin/post",
      patch: "admin/patch",
      getSchools: "school/getSchools",
      getSchoolClasses: "school/getSchoolClasses",
      setCurrentClass: "_class/setCurrentClass",
    }),
    reset() {
      this.selectClass(this.schoolClass);
    },
    selectSchool(schoolId) {
      this.searchingClasses = true;
      this.schoolClass = null;
      let school = this.school;
      this.school = null;
      setTimeout(() => {
        this.getSchoolClasses({ schoolId }).then((response) => {
          this.classes = response.data;
          this.searchingClasses = false;
        });
        this.school = school;
        this.teacherData.rbd = school;
        this.principalData.rbd = school;
      }, 300);
    },
    selectClass(classId) {
      this.schoolClass = null;
      this.$nextTick(() => {
        this.schoolClass = classId;
      });
    },
  },
  computed: {
    ...mapGetters({
      lang: "layout/lang",
      program: "program/program",
      schools: "school/schools",
    }),
  },
};
</script>
