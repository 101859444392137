<template>
  <v-select
    :disabled="disabled"
    :items="items"
    v-model="computedValue"
    class="color--primary p-2"
    :placeholder="placeholder"
    :background-color="disabled ? '#d8d8d8' : ''"
    @change="change"
    :item-value="itemValue"
    :item-text="itemText"
    flat
    hide-details
    dense
    solo
    text-wrap
  >
    <template slot="item" slot-scope="data" class="d-flex">
      <div class="text-left w-100">
        <v-divider
          class="w-100"
          v-if="data.item[itemText] != items[0][itemText]"
        />
        {{ data.item[itemText] }}
      </div>
    </template>
  </v-select>
</template>
<script>
export default {
  name: "SimulatorSelect",
  methods: {
    change(val) {
      this.$emit("change", val);
    },
  },
  props: {
    disabled: {
      required: false,
      default: false,
    },
    itemValue: {
      required: false,
      default: "",
    },
    itemText: {
      required: false,
      default: "",
    },
    items: {
      required: true,
      type: Array,
    },
    value: {
      required: true,
    },
    placeholder: {
      required: false,
      default: "",
    },
  },
  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
};
</script>
