import esCL from "./assets/i18n/es-CL.json";
import esCO from "./assets/i18n/es-CO.json";
import esPE from "./assets/i18n/es-PE.json";
import esRD from "./assets/i18n/es-RD.json";

// VueI18n
import VueI18n from "vue-i18n";
import Vue from "vue";

Vue.use(VueI18n);

export default new VueI18n({
  locale: localStorage.getItem("lang") || "es-CL",
  messages: {
    "es-CL": esCL,
    "es-CO": esCO,
    "es-RD": esRD,
    "es-PE": esPE,
  },
});
