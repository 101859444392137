<template>
  <div class="w-100">
    <v-expansion-panels v-model="panel" v-if="withPanel">
      <v-expansion-panel :disabled="disabled">
        <v-expansion-panel-header>
          <div class="d-flex">
            <v-toolbar-title class="me-3">{{ name }}</v-toolbar-title>
            <slot name="header"></slot>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <EditTableSingle v-if="!disabled" v-bind="$props" v-on="$listeners">
            <template slot="externalactions" slot-scope="{ item }">
              <slot name="externalactions" :item="item"></slot>
            </template>
          </EditTableSingle>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <EditTableSingle v-else v-bind="$props" v-on="$listeners">
      <template slot="externalactions">
        <slot name="externalactions"></slot>
      </template>
    </EditTableSingle>
  </div>
</template>
<script>
import EditTableSingle from "./EditTableSingle";
export default {
  name: "EditTable",
  components: { EditTableSingle },
  props: {
    requiresPassword: {
      // todo: move this to backend
      default: false,
      type: Boolean,
      required: false,
    },
    disabled: {
      required: false,
      default: false,
      type: Boolean,
    },
    withPanel: {
      required: false,
      default: true,
      type: Boolean,
    },
    keyOptions: {
      required: false,
      default: new Object(),
      type: Object,
    },
    postBody: {
      required: false,
      default: new Object(),
      type: Object,
    },
    editable: {
      required: false,
      default: true,
      type: Boolean,
    },
    panel: {
      required: false,
      default: null,
    },
    ignoredKeys: {
      required: false,
      default: () => [],
      type: Array,
    },
    preLoadedData: {
      required: false,
      default: null,
    },
    object: {
      required: false,
      type: String,
      default: "object",
    },
    externalSearchableField: {
      required: false,
      type: String,
      default: "",
    },
    label: {
      required: false,
      type: String,
      default: "Buscar",
    },
    endpoint: {
      required: true,
      type: String,
    },
    getParams: {
      required: false,
      type: String,
      default: new Object(),
    },
    keys: {
      required: true,
      type: Array,
    },
    name: {
      required: true,
      type: String,
    },
  },
};
</script>
