<template>
  <div class="contact-form-form">
    <p class="bold">
      {{ $t("contact-form.quit-reason-question") }}
    </p>
    <b-form-group
      ><b-form-checkbox-group
        v-model="quitReasons"
        @input="sendToParent(...arguments, quitReasons, 'quitReasons')"
        :options="$t('contact-form.quit-reasons')"
        class="mb-3"
      ></b-form-checkbox-group
    ></b-form-group>
    <b-form-textarea
      id="textarea"
      v-if="$includes(quitReasons, 'other')"
      v-model="otherText"
      @input="sendToParent(...arguments, otherText, 'otherText')"
      :placeholder="$t('contact-form.quit-reason-other')"
      rows="3"
      class="mb-2 contact-form-form"
      max-rows="6"
    ></b-form-textarea>
    <p class="bold">
      {{ $t("contact-form.interested") }}
    </p>
    <input
      v-model="interestedEmail"
      @input="sendToParent(...arguments, interestedEmail, 'interestedEmail')"
      class="home-input contact-form-input"
      type="text"
      id="school"
      :placeholder="$t('contact-form.interested-email-placeholder')"
    />
  </div>
</template>
<script>
export default {
  name: "QuitReasons",
  data() {
    return {
      quitReasons: [],
      otherText: "",
      interestedEmail: "",
    };
  },
  props: {
    external: {
      type: Object,
    },
  },
  mounted() {
    this.quitReasons = this.external["quitReasons"];
    this.otherText = this.external["otherText"];
    this.interestedEmail = this.external["interestedEmail"];
  },
  methods: {
    // eslint-disable-next-line
    sendToParent(args, value, variable) {
      this.$emit("updateVariable", variable, value);
    },
  },
};
</script>
