import services from "@/services";
const getDefaultState = () => ({
  program: null,
  programs: [],
  programLoading: false,
  currentPhase: null,
  completedPhases: [],
  completedStages: [],
  completedActivities: [],
  completedQuestions: [],
});

const state = {
  ...getDefaultState(),
};

const getters = {
  program: ({ program }) => program,
  programs: ({ programs }) => programs,
  currentPhase: ({ currentPhase }) => currentPhase,
  phases: ({ program }) => (program ? program.phases : []),
  completedStages: ({ completedStages }) => completedStages,
  completedActivities: ({ completedActivities }) => completedActivities,
  completedQuestions: ({ completedQuestions }) => completedQuestions,
  completedPhases: ({ completedPhases }) => completedPhases,
  programLoading: ({ programLoading }) => programLoading,
};

const mutations = {
  setProgramLoading(state, { programLoading }) {
    state.programLoading = programLoading;
  },
  appendStage(state, { stage }) {
    // add stage to phases and currentPhase
    state.program.phases.forEach((p) => {
      if (p.id == stage.phase) {
        // if stage is not included, add
        if (!(p.stages.map((s) => s.id).indexOf(stage.id) >= 0)) {
          p.stages.push(stage);
        }
      }
    });
    if (stage.phase == state.currentPhase.id) {
      if (
        !(state.currentPhase.stages.map((s) => s.id).indexOf(stage.id) >= 0)
      ) {
        state.currentPhase.stages.push(stage);
      }
    }
  },
  setProgram(state, { program }) {
    state.program = program;
  },
  setPrograms(state, { programs }) {
    state.programs = programs;
  },
  setCurrentPhase(state, { phase }) {
    state.currentPhase = phase;
  },
  addCompletedPhase(state, { id }) {
    if (!(state.completedPhases.indexOf(id) >= 0)) {
      state.completedPhases.push(id);
    }
  },
  addCompletedStage(state, { id }) {
    if (!(state.completedStages.indexOf(id) >= 0)) {
      state.completedStages.push(id);
    }
  },
  removeCompletedStage(state, { id }) {
    state.completedStages = state.completedStages.filter(function(item) {
      return item !== id;
    });
  },
  addCompletedActivity(state, { id }) {
    if (!(state.completedActivities.indexOf(id) >= 0)) {
      state.completedActivities.push(id);
    }
  },
  removeCompletedActivity(state, { id }) {
    state.completedActivities = state.completedActivities.filter(function(
      item
    ) {
      return item !== id;
    });
  },
  addCompletedQuestion(state, { id }) {
    if (!(state.completedQuestions.indexOf(id) >= 0)) {
      state.completedQuestions.push(id);
    }
  },
  removeCompletedQuestion(state, { id }) {
    state.completedQuestions = state.completedQuestions.filter(function(item) {
      return item !== id;
    });
  },
};

const actions = {
  reset({ state }) {
    Object.assign(state, { ...getDefaultState() });
  },
  addCompleted({ dispatch }, { type, id }) {
    if (type == "phase") {
      dispatch("addCompletedPhase", { phase: { id } });
    } else if (type == "stage") {
      dispatch("addCompletedStage", { stage: { id } });
    } else if (type == "activity") {
      dispatch("addCompletedActivity", { activity: { id } });
    } else if (type == "question") {
      dispatch("addCompletedQuestion", { question: { id } });
    }
  },
  // for teacher only
  addCompletedPhaseTeacher({ commit }, { phase }) {
    commit("addCompletedPhase", { id: phase.id });
  },
  addCompletedStageTeacher({ commit, dispatch }, { stage, phase }) {
    commit("addCompletedStage", { id: stage.id });
    if (stage.is_end) {
      dispatch("addCompletedPhaseTeacher", { phase });
    }
  },
  addCompletedActivityTeacher(
    { commit, getters, dispatch },
    { activity, stage, phase }
  ) {
    commit("addCompletedActivity", { id: activity.id });
    let completedStage = true;
    let i = 0;
    while (i < stage.activities.length) {
      let activity = stage.activities[i];
      if (!(getters.completedActivities.indexOf(activity.id) >= 0)) {
        completedStage = false;
        break;
      }
      i++;
    }
    if (completedStage) {
      dispatch("addCompletedStageTeacher", { stage, phase });
    }
  },
  addCompletedQuestionTeacher(
    { commit, getters, dispatch },
    { question, activity, stage, phase, answer }
  ) {
    commit("addCompletedQuestion", { id: question.id });
    dispatch(
      "answer/addAnswer",
      { questionId: question.id, answer: answer },
      { root: true }
    );
    let completedActivity = true;
    let i = 0;
    while (i < activity.questions.length) {
      let question = activity.questions[i];
      if (!(getters.completedQuestions.indexOf(question.id) >= 0)) {
        completedActivity = false;
        break;
      }
      i++;
    }
    if (completedActivity) {
      dispatch("addCompletedActivityTeacher", { activity, stage, phase });
    }
  },
  // end for teacher only
  addCompletedPhase({ commit }, { phase }) {
    commit("addCompletedPhase", { id: phase.id });
  },
  addCompletedStage({ commit }, { stage }) {
    commit("addCompletedStage", { id: stage.id });
  },
  addCompletedActivity({ commit }, { activity }) {
    commit("addCompletedActivity", { id: activity.id });
  },
  addCompletedQuestion({ commit }, { question }) {
    commit("addCompletedQuestion", { id: question.id });
  },
  removeCompletedStage({ commit }, { stage }) {
    commit("removeCompletedStage", { id: stage.id });
  },

  removeCompletedActivity({ commit, getters, dispatch }, { activity, stage }) {
    commit("removeCompletedActivity", { id: activity.id });
    if (getters.completedStages.indexOf(stage.id) >= 0) {
      dispatch("removeCompletedStage", { activity, stage });
    }
  },
  setProgram({ commit }, { program }) {
    commit("setProgram", { program });
  },

  removeCompletedQuestion(
    { commit, getters, dispatch },
    { question, activity, stage }
  ) {
    commit("removeCompletedQuestion", { id: question.id });
    if (getters.completedActivities.indexOf(activity.id) >= 0) {
      dispatch("removeCompletedActivity", { activity, stage });
    }
  },
  getProgram({ rootGetters, getters, commit }, { params }) {
    commit("setProgramLoading", { programLoading: true });
    services.programService
      .getProgram({ params })
      .then((response) => {
        if (rootGetters["authentication/user"].type == "admin") {
          const programs = response.data;
          let program;
          commit("setPrograms", { programs });
          if (getters["program"] == null) {
            program = programs[0];
          } else {
            program = getters["program"];
          }
          commit("setProgram", { program });
          commit("setCurrentPhase", {
            phase: program.phases[0],
          });
        } else {
          const program = response.data;
          commit("setProgram", { program });
          commit("setProgramLoading", { programLoading: false });

          if (!getters["currentPhase"]) {
            commit("setCurrentPhase", {
              phase: program.phases[0],
            });
          }
        }
      })
      .catch(() => {
        commit("setProgramLoading", { programLoading: false });
      });
  },
  patch({ dispatch }, { id, object, body }) {
    return services.programService
      .patch({ id, object, body })
      .then((response) => {
        dispatch(
          "layout/addToast",
          {
            toastState: "success",
            text: "Los cambios fueron guardados correctamente",
          },
          { root: true }
        );
        return response;
      })
      .catch((err) => {
        dispatch(
          "layout/addToast",
          {
            toastState: "error",
            text: "No se pudieron registrar los cambios.",
          },
          { root: true }
        );
        throw err;
      });
  },
  create({ dispatch }, { object, body }) {
    return services.programService
      .create({ object, body })
      .then((response) => {
        dispatch(
          "layout/addToast",
          {
            toastState: "success",
            text: "El objeto fue creado correctamente.",
          },
          { root: true }
        );
        return response;
      })
      .catch((err) => {
        dispatch(
          "layout/addToast",
          {
            toastState: "error",
            text: `No se pudo crear el objeto: ${JSON.stringify(
              err.response.data
            )}`,
          },
          { root: true }
        );
        throw err;
      });
  },
  delete({ dispatch }, { object, id }) {
    return services.programService
      .delete({ object, id })
      .then((response) => {
        dispatch(
          "layout/addToast",
          {
            toastState: "success",
            text: "El objeto fue eliminado correctamente.",
          },
          { root: true }
        );
        return response;
      })
      .catch((err) => {
        dispatch(
          "layout/addToast",
          {
            toastState: "error",
            text: `No se pudo eliminar el objeto: ${JSON.stringify(
              err.response.data
            )}`,
          },
          { root: true }
        );
        throw err;
      });
  },
  setCurrentPhase({ commit, state }, { phaseId }) {
    const phase = state.program.phases.find((x) => x.id == phaseId);
    commit("setCurrentPhase", { phase });
    return phase;
  },
  setCurrentPhaseDefault({ commit, dispatch, state }) {
    if (!state.program) {
      dispatch("getProgram", {}).then(() => {
        const phase = state.program.phases.find((x) => x.number == 0);
        commit("setCurrentPhase", { phase });
      });
    } else {
      const phase = state.program.phases.find((x) => x.number == 0);
      commit("setCurrentPhase", { phase });
    }
  },
  // eslint-disable-next-line
  getLectionaries({ commit }, { phaseId, classId }) {
    return services.programService
      .getLectionary({ phaseId, classId })
      .then((response) => {
        return response;
      });
  },
  // eslint-disable-next-line
  updateLectionaries({ commit, dispatch }, { body }) {
    return services.programService
      .updateLectionary({ body })
      .then((response) => {
        dispatch(
          "layout/addToast",
          {
            toastState: "success",
            text: `Leccionario actualizado correctamente.`,
          },
          { root: true }
        );
        return response;
      });
  },
  // eslint-disable-next-line
  createLectionaries({ commit }, { body }) {
    return services.programService
      .createLectionary({ body })
      .then((response) => {
        return response;
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
