<template>
  <!-- mixpanel on -->
  <responsive-div
    class="d-flex flex-column ms-auto me-auto"
    style="max-width:744px"
    :class="[
      activity.format.includes('rounded')
        ? 'rounded-lg ' + activity.format
        : '',
      activity.background != '' ? ' p-4' : '',
    ]"
    :style="
      activity.background != ''
        ? `background:${activity.background} !important`
        : ''
    "
  >
    <!-- iframe whenever possible -->
    <custom-dialog
      v-model="iframeActive"
      width="100% !important"
      extraStyles="margin: 0 !important padding: 0 !important;position:absolute;height:100vh;width:100vw;top:0;left:0"
      :fullScreen="true"
      closeIconStyles="padding-right:1px;position:absolute;right:5px;top:5px;width:26px;height:26px;background:white;border-radius:13px;max-width:26px"
      height="100vh !important"
      @close="postCompletedActivity"
    >
      <iframe
        id="iframeId"
        style="width:100%;height:100%"
        :src="buttonLink"
        title="resource"
      ></iframe>
    </custom-dialog>
    <!-- text -->
    <responsive-div
      :class="
        stage.format == 'inversed-background' ? 'color--neutral-100-t' : ''
      "
      class="mb-2"
    >
      <custom-text
        v-if="!readOnly"
        :class="readOnly ? '' : 'text-title mb-2'"
        style="color:#212529"
        :text="activity.title"
        :ignoreStyles="false"
      />
      <custom-text
        :class="readOnly ? '' : 'text-body'"
        v-if="!readOnly"
        :text="activity.description"
        :ignoreStyles="false"
      />
    </responsive-div>
    <!-- video, question or buttons -->
    <responsive-div>
      <responsive-div
        v-if="activity.media != '' && !readOnly"
        mobileStyle="margin-left: -16px !important;min-width:100vw !important;"
        desktopStyle=""
        class="bg--primary-70-t rounded"
      >
        <!--<responsive-div mobileOnly class="text-label"
          >Gira tu dispositivo móvil para ver el video.</responsive-div
        >-->
        <vimeo-player
          :options="{
            title: false,
            responsive: true,
            webkitallowfullscreen: true,
            mozallowfullscreen: true,
            allowfullscreen: true,
          }"
          ref="activityPlayer"
          :video-id="activity.media"
          @timeupdate="playing"
        />
        <!--<responsive-div class="italic text-right text-label"
          >*Debes terminar el video para continuar</responsive-div
        >-->
      </responsive-div>
      <div
        v-if="activity.btn_link"
        class="text-center h-100 d-flex align-items-center justify-content-center"
        style="min-height:100px"
      >
        <common-button
          secondaryColor="var(--tertiary)"
          :text="activity.btn_text"
          @click="openLink"
        />
      </div>
      <Question
        :readOnly="readOnly"
        v-for="(question, index) in activity.questions"
        :activity="computedActivity"
        :stage="stage"
        :key="question"
        :questionId="index"
        :question="question"
        class="mt-2"
      />
    </responsive-div>
  </responsive-div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import Question from "./Question.vue";
export default {
  name: "Activity",
  components: {
    Question,
  },
  mounted() {
    // watch for orientation change
    window.addEventListener("orientationchange", this.handleOrientationChange);
    this.$sort(this.activity.questions, "number", true);
    if (
      this.activity.questions.length == 0 &&
      !this.activity.media &&
      !this.activity.btn_link &&
      !this.$includes(this.activity.format, "simulator")
    ) {
      this.postCompletedActivity();
    }
    if (this.$includes(this.activity.format, "filtercareer")) {
      this.initFilterCareer();
    }
    if (this.$includes(this.activity.format, "simulator")) {
      this.initSimulator();
    }
    // new: if video, automatically continue
    if (this.activity.media.includes("vimeo")) {
      this.postCompletedActivity();
    }
  },
  data() {
    return {
      videoDuration: null,
      activityFinished: false,
      iframeActive: false,
      videoLandscape: false,
    };
  },
  methods: {
    getBaseQuestion(id) {
      return {
        btn_background: "",
        btn_color: "",
        btn_link: "",
        btn_text: "",
        media: "",
        question: id,
        unique: false,
        _max: "0",
        _min: "0",
      };
    },
    initFilterCareer() {
      console.log("Hi filter career");
    },
    initSimulator() {
      console.log("HI simulator");
    },
    checkUrlFrameOptions(apiurl) {
      return fetch(
        "https://header-inspector.repalash.workers.dev/?" +
          new URLSearchParams({
            apiurl: apiurl,
            headers: "x-frame-options",
          }),
        {
          method: "GET",
        }
      )
        .then((r) => r.json())
        .then((json) => {
          let xFrameOp = (json.headers["x-frame-options"] || "").toLowerCase();
          // deny all requests
          if (xFrameOp === "deny") return false;
          // deny if different origin
          if (xFrameOp === "sameorigin" && json.origin !== location.origin)
            return false;
          return true;
        });
    },
    checkOpenability(link) {
      var req = new XMLHttpRequest();
      req.open("GET", link, false);
      req.send(null);
      var headers = req.getAllResponseHeaders().toLowerCase();
      alert(headers);
    },
    handleOrientationChange() {
      const orientation = window.screen.orientation.type;
      const player = this.$refs.activityPlayer.player;
      if (orientation === "portrait-primary") {
        // portrait mode
        player.exitFullscreen();
      }
      if (orientation === "landscape-primary") {
        player.play().then(() => {
          player
            .requestFullscreen()
            .then(() => {
              console.log("");
            })
            .catch((err) => {
              console.log(err);
            });
        });
        // landscape mode
      }
    },
    openLink() {
      this.postCompletedActivity();
      window.open(this.activity.btn_link, "_blank");
      this.track({
        event: "phase-open-link",
        data: { link: this.activity.btn_link },
      });

      /*if (
        this.$includes(
          [
            "https://preicfes.net/",
            "https://proyectateherramienta.mineducacion.gov.co/MenVocOcup/Default.aspx?ReturnUrl=%2fMenVocOcup%2fSistema%2fAutoconocimiento1-05.aspx",
            "https://www.eligecarrera.cl/2019_aspx/test/test-de-interes.aspx#",
          ],
          this.activity.btn_link
        )
      ) {*/

      /*} else {
        this.checkUrlFrameOptions(this.activity.btn_link).then(
          (canBeOpenedInIframe) => {
            if (canBeOpenedInIframe) {
              this.iframeActive = true;
              this.postCompletedActivity();
            } else {
              window.open(this.activity.btn_link, "_blank");
              this.postCompletedActivity();
            }
          }
        );
      }*/
    },
    playing() {
      const player = this.$refs.activityPlayer.player;
      if (this.videoDuration == null) {
        player.getDuration().then((t) => {
          this.postCompletedActivity();
          this.videoDuration = t;
          this.track({
            event: "phase-click-video",
            data: { video: this.activity.media },
          });
        });
      }
      player.getCurrentTime().then((t) => {
        if (t / this.videoDuration > 0.9 && !this.activityFinished) {
          this.activityFinished = true;
          // this.postCompletedActivity(); no longer need to finish te video
        }
      });
    },
    postCompletedActivity() {
      this.track({
        event: "phase-end-activity",
        data: { activity: this.activity.id },
      });
      if (this.user.type == "student") {
        this.postCompleted({
          body: {
            type: "activity",
            id: this.activity.id,
          },
        });
      } else {
        this.addCompletedActivityTeacher({
          activity: this.activity,
          stage: this.stage,
          phase: this.phase,
        });
      }
    },
    ...mapActions({
      track: "authentication/track",
      postCompleted: "answer/postCompleted",
      addCompletedActivityTeacher: "program/addCompletedActivityTeacher",
    }),
  },
  computed: {
    buttonLink() {
      if (this.activity.btn_text == "Visitar Egresa") {
        return `${this.activity.btn_link}/${this.user.identifier}`;
      }
      return this.activity.btn_link;
    },
    ...mapGetters({
      mobile: "layout/isMobile",
      phase: "program/currentPhase",
      user: "authentication/user",
    }),
    computedActivity() {
      let activity = new Object();
      activity.id = this.activity.id;
      activity.questions = [];
      this.activity.questions.forEach((q) => {
        activity.questions.push({ id: q.id, format: q.format });
      });
      return activity;
    },
  },
  props: {
    activity: {
      required: true,
      type: Object,
    },
    stage: {
      required: true,
      type: Object,
    },
    readOnly: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
};
</script>

<style scoped>
.text-disclaimer {
  font-size: 0.6rem;
  font-style: italic;
  text-align: right;
}
</style>
