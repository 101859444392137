/* Useful store elements platform-wise. */
/* Sets the initial state of the app. Useful for startup and logout. */
import services from "../services";

const getDefaultState = () => ({
  variable: null,

});

const state = {
  ...getDefaultState(),
};

const getters = {
  variable: ({ variable }) => variable,
};

const mutations = {
  setVariable(state, { variable }) {
    state.variable = variable;
  },
};

const actions = {
  reset({ state }) {
    Object.assign(state, getDefaultState());
  },
  // eslint-disable-next-line
  getQualtricsProgress({}, {params}) {
    return services.qualtricsService
    .getQualtricsProgress({params})
    .then((response) => {
      return response.data
    })
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
