<template>
	<v-row
		class="bg--neutral-100-t relative"
		style="top:45px;margin-bottom:45px;"
	>
		<!-- title -->
		<v-row
			class=" ml-0 white--text text-left"
			:class="isMobile ? 'bg--primary' : 'title vh-100'"
			style="display: flex; align-items: flex-end; padding: 5rem 5%"
		>
			<p :class="isMobile ? 'w-100 text-title' : 'w-75 text-heading'">
				DFM al ser un programa digital, flexible y personalizado, ha logrado
				adaptarse a las necesidades particulares de cada estudiante y al
				currículum nacional de cada país.
			</p>
		</v-row>
		<v-row
			style="height: max-content!important"
			class=" ml-0 pl-0 pr-0 relative text-left"
			v-for="(row, index) in [
				{
					country: 'CHILE',
					title:
						'Durante los últimos dos años más de 900 establecimientos educacionales han sido parte de los pilotos que hemos implementado junto al Ministerio de Educación.',
					body:
						'Los resultados preliminares sugieren que los estudiantes ajustan sus expectativas sobre los beneficios asociados a la educación, aumentan su conocimiento sobre el funcionamiento del Sistema de Acceso a la educación superior y sobre los beneficios estudiantiles que ofrece el Estado para ayudar a financiar los costos asociados a los programas de educación superior.',
					imageUrl: 'row1.png',
				},
				{
					country: 'COLOMBIA',
					title:
						'En 2022, 150 instituciones educativas del Valle del Cauca, mayoritariamente ubicadas en zonas rurales, participaron del primer piloto de DFM en Colombia. ',
					body:
						'Gracias a esta oportunidad, más de 50 mil estudiantes, de 6° a 11° grado, tuvieron la oportunidad de acceder a contenido dinámico y personalizado sobre la importancia de la educación para construir su proyecto de vida. A partir de estos resultados y la buena recepción de las comunidades educativas, para el año 2023 se busca escalar DFM a lo largo del país.',
					imageUrl: 'row2.png',
				},
				{
					country: 'REPÚBLICA DOMINICANA',
					title: `En República Dominicana, 'Aprendiendo el Valor de la Educación' (AVE) se desarrolló en colaboración con el Ministerio de Educación a partir del año 2014. En los dos primeros años, se alcanzaron 200.000 estudiantes en 1.594 escuelas del país (el 26% de las escuelas públicas del país).`,
					body:
						'En 2021, 11 mil estudiantes, de 7° básico a IIIº medio, y más de 600 profesores de 101 establecimientos educacionales a lo largo del país cursaron el programa piloto. A partir de estos resultados, para el año 2022 se busca escalar DFM a lo largo del país.Los resultados preliminares del programa dan cuenta de una reducción en las cifras de abandono escolar en 2,5-3 puntos porcentuales y un aumento en los resultados de las pruebas nacionales. A partir de estos prometedores resultados, desde 2017, el Ministerio de Educación lleva a cabo AVE alcanzando a más 862 mil estudiantes en más de 5.600 escuelas.',
					imageUrl: 'row3.png',
				} /*
				{
					country: 'PERÚ',
					title:
						'En Perú, el programa DFM logró una disminución de la tasa de abandono escolar de casi un 20%. Los estudiantes y apoderados participantes cambiaron su percepción sobre los beneficios asociados de continuar sus estudios.',
					body:
						'El proyecto fue realizado entre 2015 y 2016, en un esfuerzo conjunto con MineduLAB, IPA y J-PAL LAC para replicar la experiencia de República Dominicana. La evaluación aleatoria demostró que el programa disminuyó en 1,8 puntos porcentuales la tasa de abandono escolar -equivalente a una disminución de la tasa de abandono de casi un 20% - a un costo inferior a 0,05 dólares por estudiante. Por ello, desde el 2018 el Ministerio de Educación de Perú ha buscado escalar el programa, incluyéndolo en el currículum nacional de las escuelas públicas de jornada extendida.',
					imageUrl: 'row4.png',
				},*/,
			]"
			:key="index"
		>
			<v-col
				:cols="isMobile ? 12 : 6"
				v-if="isMobile || index % 2 != 0"
				style=" padding: 5% 10% 2% 5%;height: max-content!important"
				><p class="h-25 bold mb-10" style="letter-spacing: 3px">
					{{ row.country }}
				</p>
				<p class="text-title mb-10 color--neutral-30-t">{{ row.title }}</p>
				<p class="text-body mb-10 color--neutral-50-t">{{ row.body }}</p>
			</v-col>
			<v-col :cols="isMobile ? 12 : 6" style="padding: 0">
				<img
					:src="require('@/assets/Home/Countries/' + row.imageUrl)"
					style="width:100%;"
					alt=""
				/>
			</v-col>
			<v-col
				:cols="isMobile ? 12 : 6"
				v-if="!isMobile && index % 2 == 0"
				style=" padding: 5% 10% 2% 5%;height: max-content!important"
				><p class="h-25 bold" style="letter-spacing: 3px">{{ row.country }}</p>
				<p class="text-title color--neutral-30-t">{{ row.title }}</p>
				<p class="text-body color--neutral-50-t">{{ row.body }}</p>
			</v-col>
		</v-row>
	</v-row>
</template>
<script>
	import { mapGetters, mapActions } from 'vuex';
	export default {
		data() {
			return {
				startTime: Date.now(),
			};
		},
		beforeDestroy() {
			const currentTime = Date.now();
			const timeSpent = (currentTime - this.startTime) / 1000;
			this.track({
				event: 'home-countries-time',
				data: { timeSpent: timeSpent },
			});
		},
		computed: {
			...mapGetters({
				isMobile: 'layout/isMobile',
			}),
		},
		methods: {
			...mapActions({
				track: "authentication/track",
			}),
		},
	};
</script>
<style scoped>
	.title {
		background-size: cover;
		background-position: center;
		background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
			url('../../assets/Home/Countries/background.png');
	}
</style>
