<template>
  <div class="bg--primary-70-t mb-2 p-2 pt-5 pb-5" style="border-radius:10px">
    <div
      v-if="simulationNumber != ''"
      class="color--primary-40-s text-left text-subtitle ps-2"
    >
      Selección {{ simulationNumber }}
    </div>
    <simulator-select
      :items="regions"
      v-model="region"
      placeholder="Selecciona la región"
      item-value="region"
      item-text="region"
      @change="getInstitutionTypes"
    ></simulator-select>
    <simulator-select
      :disabled="region == null"
      :items="institutionTypes"
      v-model="institutionType"
      placeholder="Selecciona el tipo de institución"
      @change="getModalities"
      item-value="institution_type"
      item-text="institution_type"
    ></simulator-select>
    <simulator-select
      :disabled="institutionType == null"
      :items="modalities"
      v-model="modality"
      placeholder="Selecciona el tipo de jornada"
      @change="getInstitutions"
      item-value="modality"
      item-text="modality"
    ></simulator-select>
    <simulator-select
      :disabled="modality == null"
      :items="institutions"
      v-model="institution"
      placeholder="Selecciona la institución"
      @change="getCareers"
      item-value="institution"
      item-text="institution"
    ></simulator-select>
    <simulator-select
      :disabled="institution == null"
      :items="careers"
      v-model="career"
      @change="getSimulation"
      placeholder="Selecciona la carrera"
      :background-color="institution == null ? '#bcbcbc' : ''"
      item-value="unique_code"
      item-text="career_name"
    >
    </simulator-select>
    <div
      v-if="simulationSet"
      ref="results"
      class="bg--primary-60-s text-left p-3 m-2 rounded color--neutral-100-t"
      style="border-radius:10px"
    >
      <div v-if="general">
        ¡Muy buena elección! Recuerda que las carreras tienen requisitos de
        admisión que pueden variar dependiendo, por ejemplo, del tipo de
        institución o incluso la modalidad. ¡Te invitamos a revisar toda la
        información de esta carrera en acceso.mineduc.cl!
      </div>
      <div v-else>
        <div
          v-if="
            $includes(
              ['Institutos Profesionales', 'Centros de Formación Técnica'],
              institutionType
            )
          "
        >
          <div>
            • Recuerda que las <b>carreras</b> que se imparten en
            <b
              >IP o CFT no tienen como requisito rendir la Prueba de Acceso a la
              Educación Superior (PAES)</b
            >, sin embargo,
            <b
              >podrían valorar tus notas de enseñanza media y/o tus aprendizajes
              previos.</b
            >
          </div>
          <div>
            • Te invitamos a revisar sus requisitos y fechas de admisión en
            acceso.mineduc.cl.
          </div>
        </div>
        <div v-else-if="directAdmission">
          <b
            >Esta carrera no solicita puntaje en la Prueba de Acceso a la
            Educación Superior (PAES).</b
          >
          Te invitamos a revisar sus requisitos y fechas de admisión en
          acceso.mineduc.cl
        </div>
        <div v-else>
          <div v-if="calculatedScore > thresholdScore && hasMedia">
            • De acuerdo a tus respuestas y considerando los resultados pasados
            de esta carrera,
            <b
              >creemos que vas por buen camino. ¡Sigue así para cumplir tus
              metas!</b
            >
          </div>
          <div v-else-if="hasMedia">
            • De acuerdo a tus respuestas y considerando los requisitos de esta
            carrera,
            <b
              >creemos que debes esforzarte más para alcanzar tus metas. ¡Tú
              también puedes lograrlo!</b
            >
          </div>
          <div v-if="highest.length > 0">
            • <span v-if="!hasMedia">¡Muy buena elección!</span> Para postular a
            esta carrera debes rendir las Pruebas de Acceso a la Educación
            Superior (PAES). Para aumentar tus probabilidades de ser admitido en
            esta carrera,
            <b>te recomendamos prepararte bien para la PAES de {{ tests }}</b>
          </div>
          <div v-if="nemRankingOver40">
            • Además, te contamos que esta carrera valora mucho las notas de
            enseñanza media, por lo que
            <b
              >te recomendamos poner especial atención a tu rendimiento
              académico.</b
            >
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="error"
      class="bg--primary-60-s text-left p-3 m-2 rounded color--neutral-100-t"
      style="border-radius:10px"
    >
      ¡Muy buena elección! Recuerda que las carreras tienen requisitos de
      admisión que pueden variar dependiendo, por ejemplo, del tipo de
      institución o incluso la modalidad. ¡Te invitamos a revisar toda la
      información de esta carrera en acceso.mineduc.cl!
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

import SimulatorSelect from "./SimulatorSelect.vue";
export default {
  name: "SimulatorSimulation",
  mounted() {
    this.getRegions();
  },
  computed: {
    ...mapGetters({
      variables: "authentication/variables",
    }),
    tests() {
      let tests = this.highest.map((x) => {
        return {
          mat: "Matemáticas",
          hist: "Historia",
          scie: "Ciencias",
          lang: "Lenguaje y Comunicación",
        }[x];
      });
      if (tests.length > 0) {
        let lastElement = tests.pop();
        if (tests.length > 0) {
          return `${tests.join(", ")} y ${lastElement}.`;
        }
        return lastElement;
      }
      return "";
    },
  },
  data() {
    return {
      error: false,
      thresholdScore: null,
      calculatedScore: null,
      highest: [],
      nemRankingOver40: false,
      general: false,
      directAdmission: false,
      hasMedia: false,
      simulationSet: false,
      region: null,
      institution: null,
      institutionType: null,
      career: null,
      careerCode: null,
      modality: null,
      modalities: [],
      regions: [],
      institutions: [],
      institutionTypes: [],
      careers: [],
    };
  },
  components: {
    SimulatorSelect,
  },
  props: {
    allPosition: {
      required: true,
    },
    matPosition: {
      required: true,
    },
    histPosition: {
      required: true,
    },
    langPosition: {
      required: true,
    },
    sciPosition: {
      required: true,
    },

    simulationNumber: {
      required: false,
      default: "",
    },
  },

  methods: {
    scroll() {
      setTimeout(() => {
        var element = this.$refs.results;
        this.$smoothScroll(
          {
            scrollTo: element,
            duration: 400,
            offset: -99,
          },
          200
        );
      });
    },
    ...mapActions({
      silentGet: "admin/silentGet",
      track: "authentication/track",
    }),
    fix(data) {
      // remove empty
      return data.filter((x) => {
        for (let key in x) {
          if (!x[key]) {
            return false;
          }
        }
        return true;
      });
    },
    resetInstitutionTypes() {
      this.institutionTypes = [];
      this.institutionType = null;
      this.resetModalities();
    },
    resetModalities() {
      this.modalities = [];
      this.modality = null;
      this.resetInstitutions();
    },
    resetInstitutions() {
      this.institutions = [];
      this.institution = null;
      this.resetCareers();
    },
    resetCareers() {
      this.careers = [];
      this.career = null;
      this.simulationSet = false;
    },
    get(params, variable) {
      this.silentGet({
        endpoint: "weightedcareer",
        params,
      }).then((response) => {
        this[variable] = this.fix(response.data);
      });
    },
    getSimulation() {
      this.track({
        event: "get-simulation",
        data: {},
      });
      if (
        !this.$includes(
          ["Institutos Profesionales", "Centros de Formación Técnica"],
          this.institutionType
        )
      ) {
        this.silentGet({
          endpoint: "simulate",
          params: {
            decil_mat: this.matPosition,
            decil_lang: this.langPosition,
            decil_sci: this.sciPosition,
            decil_hist: this.histPosition,
            decil_all: this.allPosition,
            unique_code: this.career,
          },
        })
          .then((response) => {
            this.error = false;
            let data = response.data;
            if (data.has_info) {
              if (data.direct_access) {
                this.directAdmission = true;
              } else {
                this.directAdmission = false;
              }
              this.general = data.general;
              this.thresholdScore = data.threshold_score;
              this.calculatedScore = data.weighted_score;

              this.hasMedia = data.has_media;

              this.highest = data.biggest_weight;
              this.nemRankingOver40 = data.ranking_and_nem_over_40;
            } else {
              this.error = true;
            }
            this.scroll();
            this.simulationSet = true;
          })
          .catch(() => {
            this.error = true;
            this.track({
              event: "get-simulation-error",
              data: {
                decil_mat: this.matPosition,
                decil_lang: this.langPosition,
                decil_sci: this.sciPosition,
                decil_hist: this.histPosition,
                decil_all: this.allPosition,
                unique_code: this.career,
              },
            });
          });
      } else {
        this.scroll();
        this.error = false;
        this.simulationSet = true;
      }

      // casos: rbd
    },
    getRegions() {
      this.get(
        {
          required_fields: "region",
          unique: true,
        },
        "regions"
      );
    },

    getInstitutionTypes() {
      this.resetInstitutionTypes();
      this.get(
        {
          required_fields: "institution_type",
          unique: true,
          region: this.region,
        },
        "institutionTypes"
      );
    },
    getModalities() {
      this.resetModalities();
      this.get(
        {
          required_fields: "modality",
          unique: true,
          region: this.region,
          institution_type: this.institutionType,
        },
        "modalities"
      );
    },
    getInstitutions() {
      this.resetInstitutions();
      this.get(
        {
          required_fields: "institution",
          unique: true,
          region: this.region,
          modality: this.modality,
          institution_type: this.institutionType,
        },
        "institutions"
      );
    },
    getCareers() {
      this.resetCareers();
      this.get(
        {
          required_fields: "career_name,unique_code",
          unique: true,
          modality: this.modality,

          region: this.region,
          institution_type: this.institutionType,
          institution: this.institution,
        },
        "careers"
      );
    },
  },
};
</script>
