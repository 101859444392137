<template>
  <!-- mixpanel on -->
  <div>
    <custom-dialog
      extra-classes="bg--error color--neutral-100-t"
      v-model="incorrectDialog"
    >
      <span>El email ingresado es inválido.</span>
      <common-button
        text="Cerrar"
        class="mt-5"
        @click="incorrectDialog = false"
      />
    </custom-dialog>
    <custom-dialog v-model="showDialog" @close="showDialog = false">
      <span class="text-body">Editar tu correo</span>
      <v-text-field v-model="email"></v-text-field>
      <common-button text="Guardar" @click="saveEmail" />
    </custom-dialog>
    <DashboardCard>
      <span class="text-subtitle"
        >{{ user.first_name }} {{ user.last_name }}</span
      >
      <div class="text-body d-flex">
        <span v-if="user.email != ''">{{ user.email }}</span>
        <span v-else class="color--neutral-50-t italic"
          >No tienes correo asociado.</span
        >
        <v-icon class="color--primary ms-5" @click="showDialog = true"
          >mdi-pencil</v-icon
        >
      </div>
    </DashboardCard>
  </div>
</template>
<script>
import DashboardCard from "../DashboardCard.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "DashboardProfile",
  components: { DashboardCard },
  methods: {
    ...mapActions({
      patchUser: "authentication/patchUser",
      track: "authentication/track",
    }),
    saveEmail() {
      if (!this.validEmail(this.email)) {
        this.incorrectDialog = true;
      } else {
        this.track({ event: "dashboard-change-email", data: {} });
        this.patchUser({
          body: {
            email: this.email,
          },
        }).then((response) => {
          if (response.status == 200) {
            this.showDialog = false;
          }
        });
      }
    },
    validEmail(email) {
      if (email === null) {
        return false;
      }
      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (email.match(validRegex)) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.email = this.user.email ?? "";
  },
  data() {
    return {
      showDialog: false,
      incorrectDialog: false,
      email: "",
    };
  },
  computed: {
    ...mapGetters({
      user: "authentication/user",
    }),
  },
};
</script>
