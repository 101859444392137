<template>
  <!-- mixpanel on -->
  <div style="max-height:100%;height:100%">
    <DashboardPhaseTemplate overflowY="overlay">
      <grid
        id="planning"
        gridTemplateRows="min-content min-content"
        gridTemplateColumns="1fr 1fr"
        v-if="phase.available"
      >
        <grid-element colStart="1" colEnd="2" rowStart="1" rowEnd="2"
          ><DashboardCard :title="$t('dashboard.planning-panel.summary-title')">
            {{ phase.summary }}
          </DashboardCard></grid-element
        >
        <grid-element colStart="2" colEnd="3" rowStart="1" rowEnd="2"
          ><DashboardCard
            :title="$t('dashboard.planning-panel.general-objective-title')"
            ><span style="color: #5669FB;" class="bold">
              <custom-text :text="mainObjective.text" :ignoreStyles="true" />
            </span>

            <div
              class="bold mb-2 mt-2"
              v-text="$t('dashboard.planning-panel.specific-objective-title')"/>
            <ol>
              <li v-for="(objective, index) in specificObjectives" :key="index">
                <custom-text :text="objective.text" :ignoreStyles="true" />
              </li></ol
          ></DashboardCard>
        </grid-element>
        <grid-element colStart="1" colEnd="3" rowStart="2" rowEnd="3">
          <div
            v-for="(lectionary, index) in phase.lectionaries"
            :key="index"
            :style="
              index === phase.lectionaries.length - 1
                ? ''
                : 'margin-bottom:20px'
            "
          >
            <DashboardCard :title="lectionary.name">
              <custom-text
                class="mt-2 mb-2"
                :text="lectionary.summary"
                :ignoreStyles="true"
              />
            </DashboardCard>
          </div>
        </grid-element>
      </grid>
      <grid
        id="planning"
        gridTemplateRows="1fr"
        gridTemplateColumns="1fr 0fr"
        v-else
      >
        <DashboardCard class="text-body bold italic color--primary">
          No hay ninguna etapa disponible por el momento.
        </DashboardCard>
      </grid>
    </DashboardPhaseTemplate>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import DashboardCard from "../DashboardCard.vue";
import Grid from "../../layout/Grid.vue";
import GridElement from "../../layout/GridElement.vue";
import DashboardPhaseTemplate from "../DashboardPhaseTemplate.vue";
export default {
  name: "DashboardPlanning",
  components: { DashboardPhaseTemplate, Grid, GridElement, DashboardCard },

  computed: {
    ...mapGetters({
      phase: "program/currentPhase",
    }),
    mainObjective() {
      return this.phase.objectives.length > 0
        ? this.phase.objectives.find((x) => x.type == "GN")
        : [];
    },
    specificObjectives() {
      return this.phase.objectives.length > 0
        ? this.phase.objectives.filter((x) => x.type == "SP")
        : [];
    },
  },
};
</script>
