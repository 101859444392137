<template>
  <div v-if="loaded">
    <div
      v-for="answer in question.answers"
      :key="answer"
      class="bg--neutral-100-t rounded mb-2 p-3"
    >
      <div
        class="d-flex bg--primary-80-t rounded-circle text-center align-items-center justify-content-center"
        style="width:30px;height:30px"
      >
        {{ answer.number + 1 }}
      </div>
      <br />
      {{ answer.text }}
    </div>
    <div class="d-flex w-100 pt-5">
      <div v-for="(ans, index) in question.answers" :key="index">
        <div
          class="d-flex flex-column text-center"
          :class="index % 2 == 1 ? 'pt-10' : ''"
        >
          <img :src="require('@/assets/' + ans.media)" style="max-width:100%" />
          <v-text-field
            :readonly="readOnly"
            dense
            hide-details
            label="N°"
            :value="answer[index] ? answer[index] : ''"
            @input="addAnswer(...arguments, index)"
            solo
            flat
            class="pt-2 ms-2 me-2  matching-text-field"
          ></v-text-field>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Matching",
  data() {
    return {
      answer: new Object(),
      loaded: false,
    };
  },
  mounted() {
    this.$sort(this.question.answers, "number", true);
    if (this.externalAnswer) {
      this.externalAnswer.split(",").forEach((value, index) => {
        this.answer[index] = value;
      });
      this.loaded = true;
    } else {
      this.loaded = true;
    }
  },
  methods: {
    addAnswer(text, index) {
      if (text == "" && index in this.answer) {
        delete this.answer[index];
      } else {
        this.answer[index] = text;
      }
      // check different answers
      let answers = [];
      Object.keys(this.answer).forEach((x) => {
        if (!this.$includes(answers, this.answer[x])) {
          answers.push(this.answer[x]);
        }
      });
      // if all answers different, send
      if (answers.length == this.question.answers.length) {
        let answer = "";
        Object.keys(this.answer)
          .sort()
          .forEach((x) => {
            answer = answer + this.answer[x] + ",";
          });
        answer = answer.slice(0, -1);
        this.$emit("setAnswer", true, answer);
      }
    },
  },
  props: {
    externalAnswer: {
      required: false,
    },
    question: {
      required: true,
      type: Object,
    },
    readOnly: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
};
</script>
<style>
.primary--text.v-input--is-focused.matching-text-field {
  background: transparent !important;
}
</style>
