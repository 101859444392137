<template>
	<div>
		<DropDown
			@setData="setSchool"
			@setInput="setInput"
			@debounceInput="search"
			:loading="searching"
			:options="schools"
			label="full_name"
		/>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	import DropDown from '../layout/DropDown.vue';
	export default {
		data() {
			return {
				currentSchool: '',
				previousSchool: '',
				searching: false,
				schools: [],
				formSchool: '',
				formSchoolCode: '',
				formPosition: '',
				contacting: false,
				formName: '',
				formEmail: '',
				sentMessage: false,
				showCalendlyModal: false,
				closedCalendly: false,
			};
		},
		components: { DropDown },
		computed: {
			...mapGetters({
				lang: 'layout/lang',
			}),
		},
		methods: {
			setInput(input) {
				this.currentSchool = input;
			},
			search() {
				if (this.currentSchool == this.previousSchool) {
					return;
				}
				this.searching = true;
				this.previousSchool = this.currentSchool;
				this.$store
					.dispatch('authentication/getSchools', {
						searchTerm: this.currentSchool,
					})
					.then((response) => {
						this.schools = response;
						this.schools.forEach((s) => {
							if (this.lang == 'es-CL') {
								s.full_name = `${s.campus_name} (RBD ${s.institution_code})`;
							} else if (this.lang == 'es-CO') {
								s.full_name = `${s.institution_name} - ${s.campus_name} (DINE ${s.campus_code})`;
							} else if (this.lang == 'es-PE') {
								s.full_name = `${s.campus_name} (Código Modular ${s.campus_code})`;
							} else if (this.lang == 'es-RD') {
								s.full_name = `${s.campus_name} (Código Centro ${s.institution_code})`;
							}
						});

						this.schools = this.schools.filter(
							((full_names) => ({ full_name }) =>
								!full_names.has(full_name) && full_names.add(full_name))(
								new Set()
							)
						);
						let schools = this.schools;
						this.schools = [];
						this.$nextTick(() => {
							this.schools = schools;
							this.searching = false;
							this.$emit('setSchools', this.schools);
						});
					})
					.catch(() => {
						this.searching = false;
					});
			},
			setSchool(school) {
				this.formSchool = school.campus_name;
				this.formSchoolCode = school.institution_code;
				this.$emit('setSchool', this.formSchool, this.formSchoolCode);
			},
			setPosition(position) {
				this.formPosition = position.position;
			},
		},
	};
</script>
