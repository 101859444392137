<template>
	<v-row class="relative" style="top:45px;margin-bottom:45px;">
		<!-- title -->
		<v-row
			justify="space-between"
			class=" ml-0 white--text text-left bg--primary "
			style="padding: 5% 5%"
		>
			<h2 :class="isMobile ? 'text-overline mt-15' : 'text-overline mt-10'">
				Resultados obtenidos
			</h2>
			<h1 class="text-giant bold">+ {{ startNumber }}</h1>
			<h2 class="text-title">escuelas interesadas en sumarse a DFM</h2>
			<v-col
				:cols="isMobile ? 12 : 3"
				v-for="(year, index) in dfmYears"
				:key="'year' + index"
			>
				<img
					style="transform-origin: left top;transform: scale(0.7)"
					:src="require('@/assets/Home/Results/' + year.svg)"
					:style="
						isMobile
							? 'transform-origin: left center;transform: rotate(90deg) scale(0.4)'
							: ''
					"
					alt=""
				/>
				<p :style="'color:' + year.color" :class="isMobile ? 'ml-2' : ''">
					{{ year.title }}
				</p>
				<p :class="isMobile ? 'ml-2' : ''">{{ year.body }}</p>
			</v-col>
		</v-row>
		<v-row
			justify="space-between"
			class="ml-0 text-left bg--quinary-30-t"
			style="padding: 5% 5%"
		>
			<h2 class="text-overline mt-10">
				Efectos prometedores a corto plazo sobre la deserción escolar
			</h2>
			<v-col
				style="position:relative"
				class="mb-10 mr-15"
				:cols="isMobile ? 12 : 4"
				v-for="(country, index) in countriesResults"
				:key="'country' + index"
			>
				<img
					:src="require('@/assets/Home/Results/' + country.background)"
					style="position:absolute;height:100%;z-index:1;left:50%;transform:translate(-50%)"
					alt=""
				/>
				<u class="bold mb-10" style="position:relative;z-index:2">{{
					country.country
				}}</u>
				<h2 class="text-title mb-4" style="position:relative;z-index:2">
					{{ country.title }}
				</h2>
				<p style="position:relative;z-index:2">{{ country.body }}</p>
			</v-col>
			<v-col
				class="mt-15 mr-15"
				:cols="isMobile ? 12 : 4"
				v-for="(result, index) in firstResults"
				:key="'result' + index"
			>
				<v-icon class="mb-5" color="#ff7497" x-large>{{ result.icon }}</v-icon>

				<p v-html="result.body"></p>
			</v-col>
		</v-row>
		<v-row
			justify="space-between"
			class="ml-0 text-left"
			style="padding: 5% 5%"
			:style="isMobile ? 'background: #F1F2F6' : ''"
		>
			<h2 class="text-overline bold mt-10">
				DFM ES IMPORTANTE Y NECESARIO
			</h2>
			<h2 class="text-overline mt-10">
				Estudiantes desconocen información clave para su futuro:
			</h2>
			<v-row class="ml-0 pa-0" style="background:#BCB8D1">
				<v-col
					:cols="isMobile ? 12 : 3"
					v-for="(singleStudentInformation, index) in studentsInformation1"
					:key="'info' + index"
					class="pt-11 pb-10 ps-5"
					:style="singleStudentInformation.style"
				>
					<h2 class="color--primary">{{ singleStudentInformation.title }}</h2>
					<p>{{ singleStudentInformation.body }}</p></v-col
				>
			</v-row>
			<h2 class="text-overline mt-10">
				Desertar es una opción probable para un número importante de
				estudiantes:
			</h2>
			<v-row class="ml-0 pa-0" style="background:#E9E8F0">
				<v-col
					:cols="isMobile ? 12 : 3"
					v-for="(singleStudentInformation, index) in studentsInformation2"
					:key="'info2' + index"
					class="pt-11 pb-10 ps-5"
					:style="singleStudentInformation.style"
				>
					<h2 class="color--primary">{{ singleStudentInformation.title }}</h2>
					<p>{{ singleStudentInformation.body }}</p></v-col
				>
			</v-row>
		</v-row>
		<!--
		<v-row
			justify="center"
			:class="isMobile ? 'ml-0 text-left' : 'ml-0 text-center'"
			style="padding: 5% 5%; background: #E9E8F0;"
			:style="isMobile ? 'background: #F1F2F6' : ''"
		>
			<h3 class="text-overline">
				LOS ESTUDIANTES AJUSTAN SUS CREENCIAS EN TEMAS CLAVES
			</h3>
			<v-col :cols="isMobile ? 12 : 4"
				><p :class="isMobile ? 'text-body' : 'text-label'">
					Dentro del programa DFM, los estudiantes se enfrentan a una serie de
					trivias que los ayudan a cuestionarse su futuro en relación a los
					niveles de educación. A través de videos que hacen referencia a las
					mismas problemáticas....
				</p></v-col
			>
		</v-row>
		-->
		<v-row
			justify="center"
			class="ml-0 text-left"
			style="padding: 5% 5%; background: #E9E8F0;"
			:style="isMobile ? 'background: #F1F2F6' : ''"
		>
			<v-col :cols="isMobile ? 12 : 6" align-self="end">
				<p>
					Como muestran los gráficos, después de ver el contenido las y los
					estudiantes se acercan más a los puntos de datos reales, lo que
					significa que internalizan y aprenden la información que se les está
					proporcionando a través de las diferentes actividades.
				</p>
				<br /><br />
				<p>Momentos:</p>
				<div style="position:relative">
					<canvas
						style="width:30px;height:30px;background:linear-gradient(180deg, #FF828F 0%, #FF537E 100%);"
					/>
					<span
						class="ml-3"
						style="position: absolute;top: 50%;transform: translateY(-50%);"
						><b>Antes</b> del programa</span
					>
				</div>
				<br />
				<div style="position:relative">
					<canvas
						style="width:30px;height:30px;background:linear-gradient(180deg, #FFBAD4 0%, #FF79AC 100%);"
					/>
					<span
						class="ml-3"
						style="position: absolute;top: 50%;transform: translateY(-50%);"
					></span>
				</div>
				<br />
				<div style="position:relative">
					<canvas
						style="width:30px;height:30px;background:linear-gradient(180deg, #27196B 0%, #20069C 100%);"
					/>
					<span
						class="ml-3"
						style="position: absolute;top: 50%;transform: translateY(-50%);"
						><b>Después</b> del programa</span
					>
				</div>
				<br />
			</v-col>
			<v-col :cols="isMobile ? 12 : 6" align-self="end">
				<img
					src="@/assets/Home/Results/graph_1.svg"
					alt=""
					style="width:100%"
				/>
			</v-col>
		</v-row>
		<v-row
			justify="center"
			class="ml-0 text-left"
			style="padding: 5% 5%; background: #E9E8F0;"
		>
			<h3 class="text-overline">
				DFM ESTÁ PRESENTE EN LAS COMUNIDADES QUE MÁS LO NECESITAN
			</h3>
			<div>
				<p :style="isMobile ? '' : 'width:50%'">
					La mayoría de los colegios que participaron en DFM, tienen una alta
					tasa de vulnerabilidad y son principalmente públicos, municipales y
					mixtos.
				</p>
			</div>
			<v-col :cols="isMobile ? 12 : 6" align-self="start">
				<img
					src="@/assets/Home/Results/graph_2.svg"
					alt=""
					style="width:100%"
				/>
			</v-col>
			<v-col :cols="isMobile ? 12 : 6" align-self="start">
				<img
					src="@/assets/Home/Results/graph_3.svg"
					alt=""
					style="width:100%"
				/>
			</v-col>
		</v-row>
		<v-row
			justify="center"
			class="ml-0 text-left"
			style="padding: 5% 5%; background: white;"
		>
			<h3 class="text-overline">DFM TIENE UNA ALTA TASa de retención</h3>
			<div>
				<p :style="isMobile ? '' : 'width:50%'">
					Nuestras actividades de corta duración, pero interactivas y
					personalizadas, mantienen motivados y conectados a los estudiantes
					hasta el final
				</p>
			</div>
			<v-col :cols="isMobile ? 12 : 6" align-self="start">
				<h3 class="text-title">2021</h3>
				<h1 class="text-giant color--primary">46%</h1>
				<p>
					de los estudiantes que empezó el programa, lo terminó (3k de 6,6k)
				</p>
			</v-col>
			<v-col :cols="isMobile ? 12 : 6" align-self="start">
				<h3 class="text-title">2022</h3>
				<h1 class="text-giant color--primary">60%</h1>
				<p>
					de los estudiantes que empezó el programa, lo terminó (11k de 18k)
				</p>
			</v-col>
			<v-col :cols="isMobile ? 12 : 6" align-self="start">
				<img
					src="@/assets/Home/Results/graph_4.svg"
					alt=""
					style="width:100%"
				/>
			</v-col>
			<v-col :cols="isMobile ? 12 : 6" align-self="start">
				<img
					src="@/assets/Home/Results/graph_5.svg"
					alt=""
					style="width:100%"
				/>
			</v-col>
		</v-row>
		<v-row
			justify="space-between"
			class="ml-0 text-left"
			style="padding: 5% 5%; background: white;"
		>
			<h3 class="text-overline">
				Los estudiantes valoran positivamente el programa
			</h3>
			<h3 class="text-overline color--neutral-80-t">
				¿QUÉ PUEDE EXPLICAR LAS BUENAS TASAS?
			</h3>

			<div>
				<p :style="isMobile ? '' : 'width:50%'">
					Nuestras actividades de corta duración, pero interactivas y
					personalizadas, mantienen motivados y conectados a los estudiantes
					hasta el final
				</p>
			</div>

			<v-col :cols="isMobile ? 12 : 6" align-self="start">
				<v-icon
					color="#FFB6CA"
					style="transform-origin: left center;transform:scale(2)"
					>mdi-chart-timeline-variant-shimmer</v-icon
				>
				<h1 class="text-giant color--primary">70%</h1>
				<p class="text-title">
					de los estudiantes califican el programa de buena manera, promediando
					4,1 de 5 estrellas.
				</p>
				<span v-for="index in 5" :key="'span' + index">
					<v-icon
						class=" mt-5 mr-5"
						:color="index == 5 ? '#ADADAD' : '#FF88A6'"
						style="transform-origin: left center;transform:scale(2)"
						>mdi-star</v-icon
					></span
				><br />
				<v-icon
					class="mt-15"
					color="#FF88A6"
					style="transform-origin: left center;transform:scale(2)"
					>mdi-account-group-outline</v-icon
				>
				<p class="text-title">
					Apoyo de las comunidades educativas aumenta la participación
				</p>
				<p class="text-body">
					Observamos que la mayoría de los estudiantes realizó las actividades
					en horario de clases.
				</p>
			</v-col>
			<v-col :cols="isMobile ? 12 : 6" align-self="start">
				<img
					src="@/assets/Home/Results/graph_6.svg"
					alt=""
					style="width:100%"
				/>
			</v-col>
		</v-row>
	</v-row>
</template>
<script>
	import { mapGetters, mapActions } from 'vuex';
	export default {
		data() {
			return {
				startNumber: 0,
				schoolsNumber: 1500,
				intervalId: -1,
				intervalWait: 0.1,
				dfmYears: [
					{
						title: 'Primer año',
						body: '+200 escuelas interesadas',
						color: '#FFD166',
						svg: 'arrow_yellow.svg',
					},
					{
						title: 'Segundo año',
						body: '+1300 escuelas interesadas',
						color: '#FFD166',
						svg: 'arrow_yellow.svg',
					},
					{
						title: 'Tercer año',
						body: '¡Vamos por más!',
						color: '#FFBAD4',
						svg: 'arrow_pink.svg',
					},
				],
				countriesResults: [
					{
						country: 'Perú',
						title: 'En casi un 20% disminuyó la exclusión',
						body:
							'en el grupo que accedió a la información, efecto que se vio potenciado en colegios rurales.',
						background: 'peru.png',
					},
					{
						country: 'Colombia',
						title: 'Reducción 10% de la deserción',
						body:
							'fueron los efectos tras la implementación del piloto realizado en el Valle del Cauca.',
						background: 'colombia.png',
					},
				],
				firstResults: [
					{
						body:
							'Perú y República Dominicana incluyeron el programa en el currículum nacional.',
						icon: 'mdi-text-box-plus-outline',
					},
					{
						body:
							'<b>En los años 2020 y 2023</b>, la intervención fue incluida en el listado de “Great Buys” del Banco Mundial, dada su costo efectividad en la reducción de la deserción escolar.',
						icon: 'mdi-creation-outline',
					},
				],
				studentsInformation1: [],
				studentsInformation2: [],
			};
		},
		mounted() {
			this.animateValue(600);
			this.studentsInformation1 = [
				{
					title: '45%-51%',
					body:
						'Identifica el dinero como la principal barrera para acceder a la educación superior.',
					style: {
						background: this.isMobile ? '#F1F2F6' : '#E9E8F0',
						borderRadius: this.isMobile ? '' : '0%',
						padding: '',
						borderBottom: this.isMobile ? '1px solid pink' : '',
					},
				},
				{
					title: '+ del 70%',
					body:
						'De los estudiantes no conoce las ayudas financieras disponibles para entrar a la educación superior.',
					style: {
						background: this.isMobile ? '#F1F2F6' : 'white',
						borderRadius: this.isMobile ? '' : '0%',
						borderBottom: this.isMobile ? '1px solid pink' : '',
					},
				},
				{
					title: '70%-96%',
					body: 'Desconocen los requisitos de gratuidad, becas y créditos.',
					style: {
						background: this.isMobile ? '#F1F2F6' : 'white',
						borderRadius: this.isMobile ? '' : '0% 30% 30% 0%',
						borderBottom: this.isMobile ? '1px solid pink' : '',
					},
				},
				{
					title: '80%',
					body:
						'De los estudiantes no conocen los requisitos de admisión para la educación superior.',
					style: {
						background: this.isMobile ? '#F1F2F6' : '#E9E8F0',
						borderRadius: this.isMobile ? '' : '30% 0% 0% 30%',
						borderBottom: this.isMobile ? '1px solid pink' : '',
					},
				},
			];
			this.studentsInformation2 = [
				{
					title: '27%',
					body: 'Reveló una probabilidad de desertar de 20% o más.',
					style: {
						background: this.isMobile ? '#F1F2F6' : 'white',
						borderRadius: this.isMobile ? '' : '100%',
						padding: '',
						borderBottom: this.isMobile ? '1px solid pink' : '',
					},
				},
				{
					title: '12%',
					body:
						'De los estudiantes cree que sus padres los dejarían desertar de la escuela.',
					style: {
						background: this.isMobile ? '#F1F2F6' : 'white',
						borderRadius: this.isMobile ? '' : '30% 0% 0% 30%',
						borderBottom: this.isMobile ? '1px solid pink' : '',
					},
				},
				{
					title: '33%',
					body:
						'De los estudiantes ha pensado muy poco o nada sobre su paso a la educación superior.',
					style: {
						background: this.isMobile ? '#F1F2F6' : 'white',
						borderRadius: this.isMobile ? '' : '0% 30% 30% 0%',
						borderBottom: this.isMobile ? '1px solid pink' : '',
					},
				},
				{
					title: '13%',
					body: 'Respondió que no asistirá a la educación superior.',
					style: {
						background: this.isMobile ? '#F1F2F6' : '',
						borderRadius: '',
						borderBottom: this.isMobile ? '1px solid pink' : '',
					},
				},
			];
		},
		computed: {
			...mapGetters({
				isMobile: 'layout/isMobile',
			}),
		},
		methods: {
			...mapActions({
				track: "authentication/track",
			}),
			animateValue(animationTime) {
				const easing = (t) => {
					let p = t * 2;
					const remainingDistance = 1 - t;
					const slowdownFactor = 1 + remainingDistance ** 2 * 2;
					return p < 1
						? 0.5 * p * p * slowdownFactor
						: -0.5 * (--p * (p - 2) - 1) * slowdownFactor;
				};
				const start = this.startNumber;
				const end = this.schoolsNumber;
				const range = end - start;
				let current = start;
				let elapsed = 0;
				const interval = 1; // milliseconds
				const update = () => {
					elapsed += interval;
					const progress = Math.min(1, elapsed / animationTime);
					const easedProgress = easing(progress);
					current = Math.floor(start + range * easedProgress);
					this.startNumber = current;
					if (progress >= 1) clearInterval(timer);
				};
				const timer = setInterval(update, interval);
			},
		},
	};
</script>
<style scoped>
	.star {
		width: 0;
		height: 0;
		border-right: 25px solid transparent;
		border-bottom: 17px solid #fd9bb7;
		border-left: 25px solid transparent;
		transform: rotate(35deg);
		margin: 20px;
	}
</style>
