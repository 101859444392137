<template>
	<v-row class="relative" style="top:45px;margin-bottom:45px;">
		<!-- title -->
		<v-row
			v-if="isMobile"
			class="ml-0 white--text text-left"
			:class="isMobile ? 'bg--primary' : 'title vh-100 '"
			style="display: flex; align-items: flex-end; padding: 5rem 5%"
		>
			<p :class="isMobile ? 'w-100 text-title' : 'w-50 text-heading'">
				La voz de nuestros participantes: descubre cómo DFM ha impactado a
				profesores y estudiantes de Chile y Colombia.
			</p>
		</v-row>
		<v-row
			style="height: max-content!important"
			class=" ml-0 pl-0 pr-0 relative text-left"
			v-for="(row, index) in [
				{
					mobileText: 'TESTIMONIOS',
					text: `TESTIMONIOS<br><br> La voz de nuestros participantes: descubre cómo DFM ha impactado a profesores y estudiantes de Chile y Colombia.`,
					imageUrl: 'Row1.png',
					backgroundText: 'bg--primary',
					backgroundImage: 'bg--primary-90-t',
					distribution: '50-50',
					color: 'white',
				},
				{
					text:
						'“Los estudiantes que aparecen en los videos lograron sus sueños y todo fue gracias a la motivación y esfuerzo que tuvieron. El aprendizaje que me llevo es que por imposible que las cosas parezcan, siempre con paciencia y dedicación se pueden lograr.” <br/><br/> — Estudiante de segundo medio en Lebu.',
					imageUrl: 'Row2.png',
					backgroundImage: 'bg--cuaternary',
					backgroundText: 'bg--neutral-100-t',
					distribution: '50-50',
					color: '',
				},
				{
					text:
						'Lo que aprendí fue que no es necesario irse del colegio ya que si sigues y terminas una carrera tendrás mejor futuro.” <br/><br/> — Estudiante de séptimo básico en la Florida.',
					imageUrl: 'Row3.png',
					backgroundText: 'bg--primary-90-t',
					backgroundImage: 'bg--primary-90-t',
					distribution: '40-60',
					color: '',
				},
				{
					text:
						'“Acercar este tipo de proyectos a niños de séptimo y octavo básico […] ya es importante [para] que comiencen a pensar lo que van hacer y tengan orientaciones más claras de lo que quieren en su futuro.” <br/><br/>— César Gómez, Orientador, Liceo Polimodal Juan Morales ',
					imageUrl: 'Row4.png',
					backgroundImage: 'bg--primary',
					backgroundText: 'bg--neutral-100-t',
					distribution: '50-50',
					color: '',
				},
				{
					text:
						'“El programa hace al alumno tomar conciencia de cuál es su proyecto de vida.” <br/><br/>— Fahime Castillo, profesor, Escuela Ejército de Salvación Arica.',
					imageUrl: 'Row5.png',
					backgroundText: 'bg--primary-90-t',
					backgroundImage: 'bg--cuaternary',
					distribution: '40-60',
					color: '',
				},
			]"
			:key="index"
		>
			<v-col
				:cols="isMobile ? 12 : row.distribution === '50-50' ? 6 : 5"
				v-if="(isMobile || index % 2 == 0) && !(isMobile && index == 0)"
				style="display: flex; align-items: flex-end; padding: 2% 15% 5% 5%"
				:style="
					isMobile
						? index == 0
							? 'padding: 60% 5% 10% 5%'
							: 'padding: 30% 5% 10% 5%'
						: ''
				"
				v-html="row.text"
				:class="row.backgroundText + ' ' + row.color + '--text '"
			>
			</v-col>
			<v-col
				:cols="isMobile ? 12 : row.distribution === '50-50' ? 6 : 7"
				:class="row.backgroundImage"
				style="padding: 0"
			>
				<img
					:src="require('@/assets/Home/Testimonies/' + row.imageUrl)"
					style="width:100%;"
					alt=""
				/>
			</v-col>
			<v-col
				:cols="isMobile ? 12 : row.distribution === '50-50' ? 6 : 5"
				v-html="row.text"
				v-if="!isMobile && index % 2 != 0"
				style="display: flex; align-items: flex-end; padding: 2% 15% 5% 5%"
				:style="isMobile ? 'padding: 30% 5% 10% 5%' : ''"
				:class="row.backgroundText + ' ' + row.color + '--text '"
			></v-col>
		</v-row>
	</v-row>
</template>
<script>
	import { mapGetters, mapActions } from 'vuex';
	export default {
		data() {
			return {
				startTime: Date.now(),
			};
		},
		beforeDestroy() {
			const currentTime = Date.now();
			const timeSpent = (currentTime - this.startTime) / 1000;
			this.track({
				event: 'home-testimonies-time',
				data: { timeSpent: timeSpent },
			});
		},
		computed: {
			...mapGetters({
				isMobile: 'layout/isMobile',
			}),
		},
		methods: {
			...mapActions({
				track: "authentication/track",
			}),
		},
	};
</script>
