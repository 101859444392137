<template>
  <div class="contact-form-form">
    <div>
      <div class="d-flex w-100 impact-container w-100">
        <responsive-div
          mobileClass="w-100"
          desktopClass="w-50"
          class="text-left d-flex flex-column"
        >
          <label
            class="contact-form-label"
            for="school"
            :style="mobile ? '  ' : 'margin-top:23px;margin-bottom:8px'"
          >
            {{ $t("contact-form.contact-form.school.label") }}
          </label>

          <DropDown
            :showSearch="true"
            marginTop="-8px !important"
            class="home-input contact-form-input"
            @setData="setSchool"
            @setInput="setInput"
            @debounceInput="search"
            @sendToParent="sendToParent"
            :initialValue="formSchool"
            fontsize="12px"
            style="height:58px;"
            :loading="searching"
            :options="schools"
            label="full_name"
          />
          <label
            class="contact-form-label"
            style="margin-top:10px"
            :class="mobile ? '' : 'mb-2'"
            for="name"
            >{{ $t("contact-form.contact-form.name.label") }}</label
          >
          <input
            v-model="formName"
            @input="sendToParent(...arguments, formName, 'formName')"
            class="home-input contact-form-input"
            type="text"
            id="name"
            :placeholder="$t('contact-form.contact-form.name.placeholder')"
          />
        </responsive-div>
        <responsive-div
          class="text-left d-flex flex-column"
          mobileClass="w-100"
          desktopClass="w-50"
        >
          <label
            class="contact-form-label"
            :class="mobile ? 'mt-4' : '  mt-4'"
            :style="mobile ? '' : 'margin-bottom:14px'"
            for="school"
            >{{ $t("contact-form.contact-form.email.label") }}</label
          >
          <input
            v-model="formEmail"
            @input="sendToParent(...arguments, formEmail, 'formEmail')"
            class="home-input contact-form-input"
            type="text"
            id="school"
            :placeholder="$t('contact-form.contact-form.email.placeholder')"
          />
          <label
            class="contact-form-label"
            :class="mobile ? 'mt-4' : '  mt-4'"
            :style="mobile ? '' : 'margin-bottom:14px'"
            for="cellphone"
            >{{ $t("contact-form.contact-form.phone.label") }}</label
          >
          <input
            v-model="formPhone"
            @input="sendToParent(...arguments, formPhone, 'formPhone')"
            class="home-input contact-form-input no-arrow"
            type="text"
            name="phone"
            id="cellphone"
            :placeholder="$t('contact-form.contact-form.phone.placeholder')"
          />
        </responsive-div>
      </div>
    </div>
    <div class="mt-4">
      <div class="d-flex flex-row mb-2">
        <b-form-group label-cols-sm="1" label="Cargo:">
          <b-form-radio-group
            style="display: flex;flex-direction:row;"
            id="radio-group-2"
            v-model="formPosition"
            @input="sendToParent(...arguments, formPosition, 'formPosition')"
            name="radio-options"
          >
            <b-form-radio
              class="mt-1"
              :value="$t('contact-form.contact-form.position')[0]"
            >
              {{ $t("contact-form.contact-form.position")[0] }}
            </b-form-radio>
            <div style="width:30px"></div>
            <b-form-radio
              class="mt-1"
              :value="$t('contact-form.contact-form.position')[1]"
              >{{ $t("contact-form.contact-form.position")[1] }}</b-form-radio
            >
          </b-form-radio-group>
          <input
            v-if="formPosition == 'Otro (especificar)'"
            v-model="formPosition2"
            @input="sendToParent(...arguments, formPosition2, 'formPosition')"
            class="home-input contact-form-input"
            placeholder="Especificar cargo..."
            type="text"
          />
        </b-form-group>
      </div>
    </div>
    <div>
      {{ $t("contact-form.contact-form.assign-in-charge-text") }}
    </div>
    <div class="mt-4">
      <div class="d-flex flex-row ">
        <b-form-checkbox
          id="checkbox-1"
          v-model="inCharge"
          @input="sendToParent(...arguments, inCharge, 'inCharge')"
          name="checkbox-1"
          :value="true"
          :unchecked-value="false"
        >
          {{ $t("contact-form.contact-form.assign-in-charge-instruction") }}
        </b-form-checkbox>
      </div>
    </div>
    <div v-if="inCharge">
      <div class="d-flex w-100 impact-container w-100">
        <responsive-div
          :class="mobile ? 'w-100' : 'w-50'"
          class="text-left d-flex flex-column"
        >
          <label
            class="contact-form-label"
            style="margin-top:10px"
            :class="mobile ? '' : 'mb-2'"
            for="name"
            >{{ $t("contact-form.contact-form.in-charge-name.label") }}</label
          >
          <input
            v-model="inChargeName"
            @input="sendToParent(...arguments, inChargeName, 'inChargeName')"
            class="home-input contact-form-input"
            type="text"
            id="name"
            :placeholder="
              $t('contact-form.contact-form.in-charge-name.placeholder')
            "
          />
          <label
            class="contact-form-label"
            style="margin-top:10px"
            :class="mobile ? 'mt-4' : 'mb-2'"
            for="name"
            >{{ $t("contact-form.contact-form.in-charge-email.label") }}</label
          >
          <input
            v-model="inChargeEmail"
            @input="sendToParent(...arguments, inChargeEmail, 'inChargeEmail')"
            class="home-input contact-form-input"
            type="text"
            id="name"
            :placeholder="
              $t('contact-form.contact-form.in-charge-email.placeholder')
            "
          />
        </responsive-div>
        <div
          class="text-left d-flex flex-column"
          mobileClass="w-100"
          desktopClass="w-50"
        >
          <label
            class="contact-form-label"
            :class="mobile ? 'mt-4' : ' '"
            :style="mobile ? '' : 'margin-bottom:14px'"
            for="school"
            >{{ $t("contact-form.contact-form.in-charge-role.label") }}</label
          >
          <input
            v-model="inChargePosition"
            @input="
              sendToParent(...arguments, inChargePosition, 'inChargePosition')
            "
            class="home-input contact-form-input"
            type="text"
            id="school"
            :placeholder="
              $t('contact-form.contact-form.in-charge-role.placeholder')
            "
          />
          <label
            class="contact-form-label"
            :class="mobile ? 'mt-4' : 'mt-3'"
            :style="mobile ? '' : 'margin-bottom:5px'"
            for="cellphone"
            >{{ $t("contact-form.contact-form.in-charge-phone.label") }}</label
          >
          <input
            v-model="inChargePhone"
            @input="sendToParent(...arguments, inChargePhone, 'inChargePhone')"
            class="home-input contact-form-input"
            type="text"
            id="cellphone"
            :placeholder="
              $t('contact-form.contact-form.in-charge-phone.placeholder')
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DropDown from "@/components/layout/DropDown.vue";
import { mapGetters } from "vuex";
export default {
  name: "Form",
  data() {
    return {
      formPosition2: "",
      formSchool: "",
      formSchoolCode: "",
      currentSchool: null,
      searching: false,
      previousSchool: "",
      schools: [],
      formName: "",
      formEmail: "",
      formPhone: "",
      formPosition: "",
      inCharge: false,
      inChargeName: "",
      inChargeEmail: "",
      inChargePosition: "",
      inChargePhone: "",
    };
  },
  mounted() {
    this.formSchool = this.external["formSchool"];
    this.formSchoolCode = this.external["formSchoolCode"];
    this.formName = this.external["formName"];
    this.formEmail = this.external["formEmail"];
    this.formPhone = this.external["formPhone"];
    this.formPosition = this.external["formPosition"];
    this.inCharge = this.external["inCharge"];
    this.inChargeName = this.external["inChargeName"];
    this.inChargeEmail = this.external["inChargeEmail"];
    this.inChargePosition = this.external["inChargePosition"];
    this.inChargePhone = this.external["inChargePhone"];
  },
  components: {
    DropDown,
  },
  props: {
    external: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters({
      mobile: "layout/isMobile",
      lang: "layout/lang",
    }),
  },
  methods: {
    setSchool(school) {
      this.formSchool = school.campus_name;
      this.formSchoolCode = school.institution_code;
      this.$emit("updateVariable", "formSchool", this.formSchool);
      this.$emit("updateVariable", "formSchoolCode", this.formSchoolCode);
    },
    setInput(input) {
      this.currentSchool = input;
    },
    search() {
      if (this.currentSchool == this.previousSchool) {
        return;
      }
      this.searching = true;
      this.previousSchool = this.currentSchool;
      this.$store
        .dispatch("authentication/getSchools", {
          searchTerm: this.currentSchool,
        })
        .then((response) => {
          this.schools = response;
          this.schools.forEach((s) => {
            if (this.lang == "es-CL") {
              s.full_name = `${s.campus_name} (RBD ${s.institution_code})`;
            } else if (this.lang == "es-CO") {
              s.full_name = `${s.institution_name} - ${s.campus_name} (DANE ${s.campus_code})`;
            } else if (this.lang == "es-PE") {
              s.full_name = `${s.campus_name} (Código Modular ${s.campus_code})`;
            } else if (this.lang == "es-RD") {
              s.full_name = `${s.campus_name} (Código Centro ${s.institution_code})`;
            }
          });
          this.schools = this.schools.filter(
            ((full_names) => ({ full_name }) =>
              !full_names.has(full_name) && full_names.add(full_name))(
              new Set()
            )
          );
          this.searching = false;
        })
        .catch(() => {
          this.searching = false;
        });
    },
    // eslint-disable-next-line
    sendToParent(args, value, variable) {
      this.$emit("updateVariable", variable, value);
    },
  },
};
</script>
<style scoped>
.contact-form-label {
  font-style: normal;
  font-weight: 400;
  color: #4f4f4f;
}
.no-arrow {
  -moz-appearance: textfield;
}
.no-arrow::-webkit-inner-spin-button {
  display: none;
}
.no-arrow::-webkit-outer-spin-button,
.no-arrow::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
