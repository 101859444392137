var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contact-form-form"},[_c('p',{staticClass:"bold"},[_vm._v(" "+_vm._s(_vm.$t("contact-form.quit-reason-question"))+" ")]),_c('b-form-group',[_c('b-form-checkbox-group',{staticClass:"mb-3",attrs:{"options":_vm.$t('contact-form.quit-reasons')},on:{"input":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.sendToParent.apply(void 0, argsArray.concat( [_vm.quitReasons], ['quitReasons'] ))}},model:{value:(_vm.quitReasons),callback:function ($$v) {_vm.quitReasons=$$v},expression:"quitReasons"}})],1),(_vm.$includes(_vm.quitReasons, 'other'))?_c('b-form-textarea',{staticClass:"mb-2 contact-form-form",attrs:{"id":"textarea","placeholder":_vm.$t('contact-form.quit-reason-other'),"rows":"3","max-rows":"6"},on:{"input":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.sendToParent.apply(void 0, argsArray.concat( [_vm.otherText], ['otherText'] ))}},model:{value:(_vm.otherText),callback:function ($$v) {_vm.otherText=$$v},expression:"otherText"}}):_vm._e(),_c('p',{staticClass:"bold"},[_vm._v(" "+_vm._s(_vm.$t("contact-form.interested"))+" ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.interestedEmail),expression:"interestedEmail"}],staticClass:"home-input contact-form-input",attrs:{"type":"text","id":"school","placeholder":_vm.$t('contact-form.interested-email-placeholder')},domProps:{"value":(_vm.interestedEmail)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.interestedEmail=$event.target.value},function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.sendToParent.apply(void 0, argsArray.concat( [_vm.interestedEmail], ['interestedEmail'] ))}]}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }