import { Integrations } from "@sentry/tracing";
// sentry
import * as Sentry from "@sentry/vue";
// axios HTTP client
import axios from "axios";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";
// bootstrap styling
import "bootstrap/dist/css/bootstrap.css";
// idle vue
import IdleVue from "idle-vue";
// mixpanel
import mixpanel from "mixpanel-browser";
import Vue from "vue";
// captcha
import { VueReCaptcha } from "vue-recaptcha-v3";
// youtube embed
import VueYoutube from "vue-youtube";
// smooth scroll
import VueSmoothScroll from "vue2-smooth-scroll";
import App from "./App.vue";
// router
import router from "./router";
// storage
import store from "./store";
// vimeo player
import vueVimeoPlayer from "vue-vimeo-player";
// vue select
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
// vue carousel
import VueCarousel from "vue-carousel";
// vue meta
import VueMeta from "vue-meta";
// styles
import "./styles/main.scss";
// vuetify
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css"; // Ensure you are using css-loader
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
// network graph
import { Network } from "vue-vis-network";
Vue.component("network", Network);
// global components
import * as VueGoogleMaps from "vue2-google-maps";
import GmapCluster from "vue2-google-maps/dist/components/cluster";
// NEW Global Components
//initialize vuephonenumber
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import services from "@/services";
// initialize mixpanel

mixpanel.init(process.env.VUE_APP_MIXPANEL_TOKEN, {
  debug: process.env.VUE_APP_MIXPANEL_DEBUG || false,
  ignore_dnt: true,
});

// initialize google maps
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GMAPS_KEY,
  },
});

// initialize Sentry
Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_KEY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.VUE_APP_ENVIRONMENT,
  tracesSampleRate: 1.0,
});

// create default URL
axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;

// axios config
Vue.prototype.$http = axios;
Vue.config.productionTip = false;
if (process.env.VUE_APP_ENVIRONMENT == "DEV") {
  Vue.config.devtools = false;
}

// checkers before route
router.beforeEach((to, from, next) => {
  // check for environment-available routes
  if (
    to.matched.some(
      (record) =>
        record.meta.environments &&
        !services.includes(
          record.meta.environments,
          process.env.VUE_APP_ENVIRONMENT
        )
    )
  ) {
    next({ name: "home" });
  } else {
    next();
  }

  // check for country-available routes
  store.dispatch("authentication/getCountry").then((country) => {
    if (to.matched.some((record) => record.meta.countries)) {
      const meta = to.matched[0].meta;
      if (
        meta &&
        meta.countries &&
        meta.countries != "*" &&
        !services.includes(meta.countries, country)
      ) {
        next({ name: "home" });
      } else {
        next();
      }
    }
  });

  // check for logged in/out available routes
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters["authentication/loggedIn"]) {
      next({ name: "login" });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.requiresLogged)) {
    if (store.getters["authentication/loggedIn"]) {
      next({ name: "dashboard" });
    } else {
      next();
    }
  } else {
    next();
  }
});

// idle vue config
const idleMinutes = 10;
const eventsHub = new Vue();

//
Vue.config.devtools = services.includes(
  ["DEV", "STAGING"],
  process.env.VUE_APP_ENVIRONMENT
)
  ? true
  : false;
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 60000 * idleMinutes,
  startAtIdle: false,
});

// global functions
// global includes. Only done bc DFM *NEEDED* To work on tablets with Chrome 40.x, that doesn't support includes().
Vue.prototype.$includes = function(array, object) {
  return array.indexOf(object) >= 0;
};

Vue.prototype.$log = function(text) {
  console.log(text);
};

// global sort
Vue.prototype.$sort = function(array, key, ascending) {
  if (ascending) {
    array.sort((a, b) => (a[key] > b[key] && 1) || -1);
  } else {
    array.sort((a, b) => (b[key] > a[key] && 1) || -1);
  }
  return array;
};

// find on array of objects by value of key
Vue.prototype.$find = function(array, key, value) {
  return array.find((o) => o[key] === value);
};

// remove object from array based on value of key
Vue.prototype.$remove = function(array, key, value) {
  return array.filter((obj) => obj[key] !== value);
};

// capitalize string
Vue.prototype.$capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// See if two arrays are the same
Vue.prototype.$arrayCompare = (arr1, arr2) => {
  return JSON.stringify(arr1) == JSON.stringify(arr2);
};

// see if two objects are equal
Vue.prototype.$objectEquals = (obj1, obj2) => {
  const obj1Length = Object.keys(obj1).length;
  const obj2Length = Object.keys(obj2).length;
  if (obj1Length === obj2Length) {
    return Object.keys(obj1).every(
      // eslint-disable-next-line
      (key) =>
        // eslint-disable-next-line
        obj2.hasOwnProperty(key) && String(obj2[key]) === String(obj1[key])
    );
  }
  return false;
};
// acccess token
const token = localStorage.getItem("access_token");
if (token) {
  axios.defaults.headers.common["Authorization"] = token;
}
axios
  .request({
    method: "GET",
    url: "healthcheck",
  })
  .then(() => {})
  .catch(() => {});
// Use packages

Vue.use(VueYoutube);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueSmoothScroll);
Vue.use(vueVimeoPlayer);
Vue.use(VueCarousel);
Vue.use(VueMeta);
Vue.use(Vuetify);
Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_KEY });

Vue.use(require("moment"));

Vue.component("v-select", vSelect);
Vue.component("GmapCluster", GmapCluster);
// Global components (old)
const requireComponentTwo = require.context(
  "@/components/layout",
  true,
  /[A-Z]\w+\.(vue|js)$/
);

requireComponentTwo.keys().forEach((fileName) => {
  let componentConfig = requireComponentTwo(fileName);
  let componentName = fileName
    .replace("./", "")
    .replace(".vue", "")
    .trim()
    .split(/\.?(?=[A-Z])/)
    .join("-")
    .toLowerCase();
  Vue.component(componentName, componentConfig.default || componentConfig);
});
Vue.component("vue-phone-number-input", VuePhoneNumberInput);

// New custom components
const requireComponent = require.context(
  "@/components/common",
  true,
  /[A-Z]\w+\.(vue|js)$/
);

requireComponent.keys().forEach((fileName) => {
  let componentConfig = requireComponent(fileName);

  let componentName = fileName
    .replace("./", "")
    .replace(".vue", "")
    .trim()
    .split(/\.?(?=[A-Z])/)
    .join("-")
    .toLowerCase();
  Vue.component(componentName, componentConfig.default || componentConfig);
});

export const app = new Vue({
  router,
  store,
  mixpanel,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
