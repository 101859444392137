<template>
  <div>
    <div style="max-height:100%;height:100%">
      <div v-for="(item, index) in termsAndConditions" :key="index">
        <div class="contact-form-title">{{ item.title }}</div>
        <div class="contact-form-tos">
          <div v-for="(content, indexTwo) in item.content" :key="indexTwo">
            <div
              :class="
                content.icon
                  ? 'contact-form-tos-icon'
                  : 'contact-form-tos-paragraph'
              "
            >
              <div>
                <img
                  v-if="content.icon"
                  :src="require('@/assets/ApplicationForm/' + content.icon)"
                  style="width:20px;height:20px;text-align:center;margin-right:10px;"
                />
              </div>
              <div v-html="content.text"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ProgramInformation",
  props: {
    termsAndConditions: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
<style scoped>
.contact-form-title {
  font-family: "Karla";
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  background: #5669fb;
  font-weight: bold;
  padding: 4px 24px 4px 24px;
}
.contact-form-tos {
  font-family: "Karla";
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #4a4b4f;
  padding: 24px;
  background-color: white;
}
.contact-form-tos-icon {
  display: flex;
  flex-direction: row;
  padding: 10px 0px 10px 0px;
}
</style>
