<template>
  <div
    class="dashboard-grid-element"
    :class="elevation ? 'elevation-3' : ''"
    :style="{
      '--row-start': mobile ? '' : rowStart,
      '--row-end': mobile ? '' : rowEnd,
      '--col-start': mobile ? '' : colStart,
      '--col-end': mobile ? '' : colEnd,
      '--bg-color': mobile ? '' : backgroundColor,
      'overflow-y': mobile ? 'visible' : overflowY,
    }"
  >
    <slot> </slot>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "GridElement",
  computed: {
    ...mapGetters({
      mobile: "layout/isMobile",
    }),
  },
  props: {
    overflowY: {
      required: false,
      type: String,
      default: "overlay",
    },
    backgroundColor: {
      type: String,
      required: false,
      default: "transparent",
    },
    rowStart: {
      required: true,
    },
    rowEnd: {
      required: true,
    },
    colStart: {
      required: true,
    },
    colEnd: {
      required: true,
    },
    elevation: {
      required: false,
      default: true,
      type: Boolean,
    },
  },
};
</script>
