<template>
  <div class="w-100 h-100 relative">
    <custom-dialog
      v-model="showTransitionModal"
      width="80%"
      v-if="showTransitionModal"
      @close="showTransitionModal = false"
    >
      <edit-table
        requiresPassword
        :panel="0"
        :withPanel="false"
        endpoint="stagetransition"
        :getParams="{
          stage_from__id: stageFrom,
          stage_to__id: stageTo,
        }"
        :keys="['id', 'stage_from', 'stage_to', 'feature_name', 'expected']"
        name="Transiciones"
      />
    </custom-dialog>
    <custom-dialog
      width="80%"
      v-model="showStageModal"
      v-if="showStageModal"
      @close="showStageModal = false"
    >
      <edit-table
        :panel="0"
        :withPanel="false"
        endpoint="stagetransitionweight"
        :getParams="{
          stage_from__id: stageFrom,
        }"
        :keys="['id', 'stage_from', 'feature_name', 'weight']"
        name="Pesos de salida"
      />
      <v-btn @click="showStage = true">Ver etapa</v-btn>

      <custom-dialog
        v-model="showStage"
        width="80%"
        v-if="showStage"
        @close="showStage = false"
      >
        <Stage
          :stage="stage"
          :class="stage.format == 'inversed-background' ? 'bg--primary' : ''"
        />
      </custom-dialog>
    </custom-dialog>
    <v-select
      :items="phases"
      solo
      flat
      hide-details
      item-text="name"
      item-value="id"
      v-model="phase"
      @input="setCurrentPhaseById"
    >
    </v-select>

    <network
      v-if="networkLoaded"
      ref="network"
      :nodes="nodes"
      :edges="edges"
      :options="options"
      class="w-100 h-100"
      :events="['click']"
      @click="clickNetwork"
    >
    </network>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import EditTable from "./ProgramEditor/EditTable.vue";
import Stage from "../../phase/Stage.vue";
export default {
  name: "DashboardAdminFlow",
  components: { EditTable, Stage },
  mounted() {
    this.setGraph();
  },
  data() {
    // https://visjs.github.io/vis-network/docs/network/
    return {
      showTransitionModal: false,
      showStageModal: false,
      stageFrom: null,
      stageTo: null,
      showStage: false,
      phase: null,
      networkLoaded: false,
      nodes: [],
      stage: null,
      edges: [],
      options: {
        interaction: {
          selectConnectedEdges: false,
        },
        clickToUse: false,
        nodes: {
          borderWidth: 4,
        },
        edges: {
          color: "lightgray",
          length: 200,
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      phases: "program/phases",
      currentPhase: "program/currentPhase",
    }),
  },
  watch: {
    currentPhase() {
      this.setGraph();
    },
  },

  methods: {
    ...mapActions({
      adminGet: "admin/get",
      setCurrentPhase: "program/setCurrentPhase",
    }),
    clickNetwork(event) {
      if (event.edges.length > 0) {
        this.selectEdge(event);
      } else if (event.nodes.length > 0) {
        this.selectNode(event);
      }
    },
    selectEdge(edge) {
      this.stageFrom = parseInt(edge.edges[0].split("-")[0]);

      this.stageTo = parseInt(edge.edges[0].split("-")[1]);

      this.showTransitionModal = true;
    },
    selectNode(node) {
      this.stageFrom = parseInt(node.nodes[0]);
      this.stage = this.currentPhase.stages.filter((s) => {
        return s.id == this.stageFrom;
      })[0];
      this.showStageModal = true;
    },
    setCurrentPhaseById(id) {
      this.setCurrentPhase({ phaseId: id });
    },
    setGraph() {
      this.phase = this.currentPhase;
      this.adminGet({
        endpoint: "stagetransition",
        params: { stage_to__phase: this.phase.id },
      }).then((results) => {
        let data = results.data;
        let nodes = new Set();
        let edges = new Set();
        this.nodes = [];
        this.edges = [];
        data.forEach((n) => {
          if (!nodes.has(n.stage_from)) {
            nodes.add(n.stage_from);
            this.addStage(n.stage_from);
          }
          if (!nodes.has(n.stage_to)) {
            nodes.add(n.stage_to);

            this.addStage(n.stage_to);
          }
          if (!edges.has(`${n.stage_from}-${n.stage_to}`)) {
            edges.add(`${n.stage_from}-${n.stage_to}`);
            this.addEdge(n);
          }
        });
        this.networkLoaded = true;
      });
    },

    addStage(stageId) {
      let s = this.phase.stages.filter((s) => s.id == stageId)[0];
      let newNode = {
        id: stageId,
        label: `${s.number} (ID${s.id})`,
        shape: "circle",
        title: s.name,
        borderWidth: s.has_feedback ? 4 : 1,
      };
      if (s.is_start) {
        newNode.color = "#C7F5EB";
      }
      if (s.is_end) {
        newNode.color = "#FFE5F4";
      }

      this.nodes.push(newNode);
    },
    addEdge(n) {
      this.edges.push({
        id: `${n.stage_from}-${n.stage_to}`,
        from: n.stage_from,
        to: n.stage_to,
        arrows: "to",
      });
    },
  },
};
</script>
