<template>
  <!-- mixpanel on -->
  <div>
    <DashboardPhaseTemplate>
      <DashboardCard
        class="h-100"
        style="min-height:100% !important;height: 100% !important;"
      >
        <div class="w-100">
          <v-btn-toggle
            v-model="currentButton"
            class="w-100 text-left d-flex flex-row"
            style="background-color:transparent"
            mandatory
          >
            <common-button
              rounded
              v-for="(button, index) in computedButtons"
              :key="index"
              class="me-2 mb-2"
              :class="getPhaseButtonClass(index)"
            >
              {{ button }}
            </common-button>
            <!--<v-btn
              rounded
              dense
              active-class="no-active"
              hideOverlay


              class="no-capitalize me-2 mb-2"
              :class="getPhaseButtonClass(index)"
              elevation="0"
              >{{ button }}</v-btn
            >-->
          </v-btn-toggle>
          <div class="w-100" style="overflow-y:overlay"></div>
        </div>
        <div
          class="w-100"
          style="overflow-y:overlay;height:100%;"
          v-if="currentButton == 0 && phase.available"
        >
          <div class="me-5">
            <div class="dashboard-tutorial-container">
              <span v-if="videoActivities.length == 0" class="italic"
                >En esta etapa no se presentan videos.</span
              >
              <div v-for="(video, index) in videoActivities" :key="index">
                <div>
                  <div style="max-width:100%;width:100%;" class="pb-2">
                    <vimeo-player
                      class="p-3"
                      :options="{ responsive: true }"
                      ref="player"
                      :video-id="video.media"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else-if="currentButton == 1 && phase.available"
          class="w-100 mt-1"
          style="overflow-y:auto"
        >
          <span v-if="linkActivities.length == 0" class="italic"
            >En esta etapa no se presentan recursos externos.</span
          >
          <div
            v-for="(activity, index) in linkActivities"
            :key="index"
            style="color:#5669FB"
          >
            <v-icon style="color:#5669FB" class="me-2"
              >mdi-file-document-outline</v-icon
            >
            <span
              class="clickable"
              @click="goTo(activity.btn_link, true)"
              style="text-decoration:underline"
              >{{ activity.btn_text }}</span
            >
          </div>
        </div>
        <div v-else class="w-100" style="overflow-y:overlay;height:100%;">
          <div class="me-5">
            <div class="dashboard-tutorial-container">
              <div v-for="(tut, index) in tutorials" :key="index" class="mb-5">
                <div>
                  <div style="max-width:100%;width:100%;" class="pb-2">
                    <vimeo-player
                      class="p-3"
                      :options="{ responsive: true }"
                      ref="player"
                      :video-id="tut.video_link"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DashboardCard>
    </DashboardPhaseTemplate>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import DashboardCard from "../DashboardCard.vue";
import DashboardPhaseTemplate from "../DashboardPhaseTemplate.vue";
export default {
  name: "DashboardResources",
  components: { DashboardPhaseTemplate, DashboardCard },

  data() {
    return {
      index: 0,
      currentButton: 0,
      allowVideo: true,
    };
  },

  methods: {
    ...mapActions({
      track: "authentication/track",
      getTutorials: "authentication/getTutorials",
    }),
    goTo(route, otherSite) {
      if (otherSite) {
        window.open(route, "_blank");
      } else {
        this.$router.push(route);
      }
      this.track({
        event: "dashboard-tutorial-goto",
        data: { route: route },
      });
    },
    getPhaseButtonClass(index) {
      if (index === this.currentButton) {
        return "dashboard-phase-button-selected";
      } else {
        return "dashboard-phase-button";
      }
    },
  },
  watch: {
    phase() {
      this.allowVideo = false;
      setTimeout(() => {
        this.allowVideo = true;
      }, 200);
    },
  },
  computed: {
    ...mapGetters({
      phase: "program/currentPhase",
      tutorials: "authentication/tutorials",
    }),
    computedButtons() {
      if (this.phase.available) {
        return this.$t("dashboard.tutorial-panel.buttons");
      } else {
        return this.$t("dashboard.tutorial-panel.buttons").filter((x) => {
          return x == "Tutoriales";
        });
      }
    },
    videoActivities() {
      if (!this.allowVideo) {
        return [];
      }
      let videoActivities = [];
      this.phase.stages.forEach((stage) => {
        videoActivities = videoActivities.concat(
          stage.activities.filter((x) => x.type == "media")
        );
      });

      return videoActivities;
    },
    linkActivities() {
      let linkActivities = [];
      this.phase.stages.forEach((stage) => {
        linkActivities = linkActivities.concat(
          stage.activities.filter((x) => x.btn_link != "")
        );
      });
      return linkActivities;
    },
  },
};
</script>

<style>
.dashboard-tutorial-container {
  height: 100%;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
}
.dashboard-tutorial-container > div {
  width: 50%;
  height: auto;
}
@media only screen and (max-width: 1000px) {
  .dashboard-tutorial-container > div {
    width: 100%;
  }
}
</style>
