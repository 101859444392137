<template>
  <div>
    <custom-dialog
      :width="mobile ? '95%' : '30%'"
      v-model="dialog"
      overlay-opacity="0.9"
      height="50vh"
      extraStyles="background:#FFD166;"
      style="z-index:1000;background:#FFD166;"
      closeIconStyles="display:none"
      @close="dialog = false"
      @hide="dialog = false"
    >
      <div class="text-center pt-2 pb-4">
        <v-icon class="clickable" large @click="dialog = false"
          >mdi-close</v-icon
        >
      </div>
      <div class="text-left">
        <custom-text class="text-subtitle" :text="answer.text" />
        <custom-text class="text-body" :text="answer.extra_text" />
      </div>
    </custom-dialog>
    <div class="blur-overlay" v-if="dialog"></div>
    <!--<v-expansion-panels
      flat
      v-if="answer.extra_text != '' && question.format != 'icon'"
      :value="selectedAnswer"
    >
      <v-expansion-panel class="p-0">
        <v-expansion-panel-header @click="setAnswer()">
          <custom-text class="bold" :text="answer.text"></custom-text>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="w-100">
            <img
              v-if="answer.extra_text.endsWith('.svg')"
              :src="require('@/assets/' + answer.extra_text)"
              style="max-width:100% !important;min-width:100% !important;width:100%"
            />
            <custom-text v-else :text="answer.extra_text"></custom-text>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>-->
    <div class="d-flex">
      <common-button
        :img="
          answer.media &&
          typeof answer.media === 'string' &&
          answer.media.includes('mdi')
            ? null
            : answer.media
        "
        v-if="!answer.btn_link"
        :read-only="readOnly"
        :prepend-icon="question.format == 'icon' ? answer.media : ''"
        class="rounded  text-body w-100 pt-2 pb-2"
        style="height: auto;
  line-height: normal !important;
  white-space: normal !important;"
        :class="
          selectedAnswer
            ? 'bg--secondary color--neutral-100-t'
            : 'bg--neutral-100-t color--neutral-80-s'
        "
        :style="{
          height: question.format == 'icon' ? '50px !important' : 'auto',
        }"
        :text="answer.text"
        @click="setAnswer()"
        :selected="selectedAnswer"
      ></common-button>
      <div
        v-else
        class="text-center h-100 d-flex align-items-center justify-content-center"
        style="min-height:50px"
      >
        <common-button
          secondaryColor="var(--tertiary)"
          :readOnly="readOnly"
          :text="answer.btn_text"
          @click="openLink"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ChoiceAnswer",
  computed: {
    ...mapGetters({
      mobile: "layout/isMobile",
    }),
  },
  methods: {
    setAnswer() {
      if (this.answer.extra_text != "") {
        this.dialog = true;
      }
      if (this.question.format == "icon") {
        this.dialog = true;
      }
      this.$emit("setAnswer", this.answer.number);
    },
    ...mapActions({
      track: "authentication/track",
    }),
    openLink() {
      this.track({
        event: "phase-open-link",
        data: { link: this.answer.btn_link },
      });
      window.open(this.answer.btn_link, "_blank");
      this.setAnswer();
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  props: {
    question: {
      required: false,
    },
    answer: {
      required: true,
    },
    selectedAnswer: {
      required: false,
      default: false,
    },
    readOnly: {
      default: false,
      required: false,
      type: Boolean,
    },
  },
};
</script>
<style>
.blur-overlay {
  backdrop-filter: blur(2px); /* Adjust the blur strength as needed */
  background-color: rgba(
    0,
    0,
    0,
    0.6
  ); /* Adjust the background color and opacity as needed */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999; /* Ensure the overlay is above other elements */
  pointer-events: auto; /* Enable interactions with the overlay */
}
</style>
