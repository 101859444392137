<template>
  <div></div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  mounted() {
    this.logout().then(() => {
      this.$router.push({ name: "login" });
    });
  },
  methods: {
    ...mapActions({
      logout: "authentication/logout",
    }),
  },
};
</script>
