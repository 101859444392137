<template>
  <DashboardCard v-if="loaded">
    <edit-generic
      :object="program"
      title="name"
      type="program"
      :keys="['description', 'name']"
      listName="programs"
      :parentId="program.id"
      :lists="[
        {
          name: 'phases',
          type: 'phase',
          title: 'title',
          lists: [
            {
              name: 'stages',
              type: 'stage',
              title: 'name',
              lists: [
                {
                  name: 'activities',
                  type: 'activity',
                  title: 'title',
                  lists: [
                    {
                      title: 'question',
                      type: 'question',
                      name: 'questions',
                      lists: [
                        {
                          title: 'answer',
                          type: 'answer',
                          name: 'answers',
                          lists: [],
                          keys: [
                            'id',
                            'question',
                            'text',
                            'number',
                            'media',
                            '_max',
                            '_min',
                            'extra_text',
                          ],
                        },
                      ],
                      keys: [
                        'id',
                        'activity',
                        'question',
                        'media',
                        'type',
                        'expected',
                        'format',
                        'number',
                      ],
                    },
                  ],
                  keys: [
                    'id',
                    'stage',
                    'title',
                    'description',
                    'media',
                    'type',
                    'format',
                    'number',
                    'btn_link',
                    'btn_text',
                    'expandable',
                    'background',
                  ],
                },
              ],
              keys: [
                'id',
                'phase',
                'number',
                'name',
                'title',
                'description',
                'has_feedback',
              ],
            },
            {
              name: 'lectionaries',
              title: 'name',
              lists: [],
              type: 'lectionary',
              keys: ['id', 'phase', 'name', 'summary', 'number'],
            },
            {
              name: 'objectives',
              type: 'objective',
              title: 'text',
              lists: [],
              keys: ['id', 'phase', 'text', 'type', 'number'],
            },
          ],
          keys: [
            'id',
            'program',
            'number',
            'name',
            'title',
            'description',
            'summary',
          ],
        },
      ]"
    />
  </DashboardCard>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import DashboardCard from "../DashboardCard.vue";
import EditGeneric from "./ProgramEditor/EditGeneric.vue";
export default {
  name: "DashboardAdminProgram",
  components: { DashboardCard, EditGeneric },
  data() {
    return {
      loaded: false,
    };
  },
  mounted() {
    this.getProgram({}).then(() => {
      this.loaded = true;
    });
  },
  methods: {
    ...mapActions({
      getProgram: "program/getProgram",
    }),
  },
  computed: {
    ...mapGetters({
      program: "program/program",
      programs: "program/programs",
      user: "authentication/user",
    }),
  },
};
</script>
