<template>
	<v-container
		ref="home"
		fluid
		class="fill-height color--neutral-20-t container fade-in-home"
	>
		<!--
	<v-dialog
			height="auto"
			width="auto"
			v-if="country == 'colombia'"
			@click:outside="hideDateExtension"
			v-model="showDateExtension"
		>
			<div class="relative" style="position:relative;overflow:hidden">
				<div>
					<div class="absolute-top-right mt-2 me-2 clickable">
						<v-icon class="color--neutral-100-t" @click="hideDateExtension"
							>mdi-close</v-icon
						>
					</div>
					<img
						src="https://cb-dfm.s3.amazonaws.com/documentos/extension_plazo.png"
						style="max-height:80vh;max-width:90vw;object-fit: contain; "
					/>
				</div>
			</div>
		</v-dialog>
		-->
		<!-- start video dialogs -->

		<v-dialog
			v-if="video1"
			:width="isMobile ? '95%' : '65%'"
			class="color--primary"
			v-model="video1"
		>
			<v-card class="pa-10 text-left" width="100%">
				<v-row class="mb-3" justify="center">
					<v-col :cols="isMobile ? 12 : 6">
						<h2 class="text-title ">Video tutorial parte 1/3</h2>
						<!--
						<p class="text-body">En este video se observará blabla</p>
						-->
					</v-col>
					<v-col
						:cols="isMobile ? 12 : 6"
						align-self="center"
						:class="isMobile ? 'text-left' : 'text-right'"
					>
						<common-button @click="nextVideo">Siguiente video</common-button>
					</v-col>
				</v-row>
				<div>
					<youtube
						style="width:100%"
						:video-id="
							videoId('https://www.youtube.com/watch?v=7cb8dEf3JCo&t=2s')
						"
						class="youtubeVideo"
					/>
				</div>
			</v-card>
		</v-dialog>
		<v-dialog
			v-if="video2"
			:width="isMobile ? '95%' : '65%'"
			class="color--primary"
			v-model="video2"
		>
			<v-card class="pa-10 text-left" width="100%">
				<v-row class="mb-3" justify="center">
					<v-col :cols="isMobile ? 12 : 6">
						<h2 class="text-title ">Video tutorial parte 2/3</h2>
						<!--
						<p class="text-body">En este video se observará blabla</p>
						-->
					</v-col>
					<v-col
						:cols="isMobile ? 12 : 6"
						align-self="center"
						:class="isMobile ? 'text-left' : 'text-right'"
					>
						<common-button @click="nextVideo">Siguiente video</common-button>
					</v-col>
				</v-row>
				<div>
					<youtube
						style="width:100%"
						:video-id="videoId('https://www.youtube.com/watch?v=G6p_76VzRtc')"
						class="youtubeVideo"
					/>
				</div>
			</v-card>
		</v-dialog>
		<v-dialog
			v-if="video3"
			:width="isMobile ? '95%' : '65%'"
			class="color--primary"
			v-model="video3"
		>
			<v-card class="pa-10 text-left" width="100%">
				<v-row class="mb-3">
					<v-col :cols="isMobile ? 12 : 6">
						<h2 class="text-title ">Video tutorial parte 3/3</h2>
						<!--
						<p class="text-body">En este video se observará blabla</p>
						-->
					</v-col>
				</v-row>
				<div>
					<youtube
						style="width:100%"
						:video-id="videoId('https://www.youtube.com/watch?v=Cy8iDY9qt40')"
						class="youtubeVideo"
					/>
				</div>
			</v-card>
		</v-dialog>
		<!-- end video dialogs -->

		<!-- start nav bar -->
		<v-row class="bg--white">
			<v-app-bar
				v-if="!isMobile"
				app
				color="white"
				elevate-on-scroll
				style="padding-left: 5%;padding-right: 5%;"
				
			>
				<!-- video bar -->
				<!--
				<v-row
					v-if="screenSelected === 'Home' && country == 'colombia'"
					style="height:60%;position:absolute;top:-30px;left:-5%;width:113%;padding-top:5px"
					class="bg--neutral-90-t d-flex text-body bold color--primary text-overline"
				>
					<p
						class="clickable"
						@click="
							video1 = true;
							track({ event: 'home-click-video-1', data: {} });
						"
					>
						¿Dudas? Revisa nuestro tutorial haciendo click aquí 👈🏼
					</p>
				</v-row>
				-->
				<!-- video bar -->
				<v-toolbar-title class="clickable" @click="changeScreen('Home')">
					<img
						src="@/assets/Home/NavBar/DFMLogo.svg"
						style="transform-origin: left center;transform: scale(0.85);"
						alt=""
					/>
				</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-toolbar-title
					class="text-label bold mr-2 ml-2 clickable color--neutral-20-t"
					v-for="(element, index) in [
						{
							screenName: 'About',
							text: 'Proyecto',
						},
						{
							screenName: 'Testimonies',
							text: 'Testimonios',
						},

						{ screenName: 'Content', text: 'Contenido' },
						/*
						{ screenName: 'Media', text: 'DFM en los medios' },
						*/
						{ screenName: 'Countries', text: 'Países' },
						{
							screenName: 'Team',
							text: 'Equipo',
						},
						{
							screenName: 'Results',
							text: 'Resultados',
						},
					]"
					:key="index"
					@click="changeScreen(element.screenName)"
				>
					{{ element.text }}
				</v-toolbar-title>
				<common-button
					@click="
						$router.push({ name: 'login' });
						track({ event: 'home-click-login', data: {} });
					"
					class=" ml-5"
					size="medium"
					primary
				>
					Ingresar al programa
				</common-button>
			</v-app-bar>
			<!-- start mobile version -->
			<v-app-bar
				v-if="isMobile"
				app
				color="white"
				elevate-on-scroll
				style="padding-left: 5%;padding-right: 5%;padding-top:0.7rem;min-height: 4.5rem"
				
			>
				<!-- start video bar -->
				<!--
				<v-row
					v-if="screenSelected === 'Home' && country == 'colombia'"
					style="height:80%;position:absolute;top:-40px;left:-5%;width:116%;padding-top:5px"
					class="bg--neutral-90-t d-flex text-body bold clickable color--primary text-overline"
				>
					<p
						@click="
							video1 = true;
							track({ event: 'home-click-video-1', data: {} });
						"
					>
						¿Dudas? Revisa nuestro tutorial haciendo click aquí 👈🏼
					</p>
				</v-row>
				-->
				<!-- end video bar-->
				<v-toolbar-title @click="changeScreen('Home')">
					<img src="@/assets/Home/NavBar/DFMLogoMobile.svg" alt="" />
				</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-app-bar-nav-icon
					@click="showNavigationDrawer = true; track({ event: 'home-click-drawer', data: {} });"
				></v-app-bar-nav-icon>
			</v-app-bar>
			<v-navigation-drawer
				v-model="showNavigationDrawer"
				:style="'top:' + drawerTop + 'px; z-index:101'"
				absolute
				temporary
			>
				<v-list dense>
					<v-list-item
						@click="changeScreen(element.screenName)"
						v-for="element in [
							{
								screenName: 'About',
								icon: 'mdi-information-outline',
								text: 'Proyecto',
							},
							{
								screenName: 'Testimonies',
								icon: 'mdi-information-outline',
								text: 'Testimonios',
							},
							{
								screenName: 'Content',
								icon: 'mdi-information-outline',
								text: 'Contenido',
							},

							{
								screenName: 'Countries',
								icon: 'mdi-information-outline',
								text: 'Países',
							},
							{
								screenName: 'Team',
								icon: 'mdi-information-outline',
								text: 'Equipo',
							},
							{
								screenName: 'Results',
								icon: 'mdi-information-outline',
								text: 'Resultados',
							},
						]"
						:key="element.text"
						link
						><!--
						<v-list-item-icon>
							<v-icon>{{ element.icon }}</v-icon>
						</v-list-item-icon>
						-->
						<v-list-item-content>
							<v-list-item-title>{{ element.text }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</v-navigation-drawer>
			<!-- end mobile version -->
		</v-row>
		<!-- end nav bar -->

		<!-- START HOME -->
		<!-- start description -->
		<transition name="fade-in">
			<v-row
				v-if="screenSelected == 'Home'"
				class="text-left fill-height description "
				style="padding: 7% 5% 8% 5%"
			>
				<v-row
					:style="isMobile ? '' : 'max-width:60%'"
					class="text-heading white--text"
					align="end"
				>
					<v-col>
						<p
							class="text-body white--text text-no-wrap"
							style="letter-spacing:2px"
						>
							DECIDIENDO PARA UN FUTURO MEJOR
						</p>
						Una herramienta para ayudar a las y los estudiantes a alcanzar sus
						metas personales
						<br />
						<common-button
							@click="
								$router.push({ name: 'login' });
								track({ event: 'home-click-login', data: {} });
							"
							:size="isMobile ? 'small' : 'large'"
							secondary
							class="mr-5"
						>
							Ingresar al programa
						</common-button>

						<common-button
							@click="
								openLink(
									'https://calendly.com/decidiendofuturomejor/socializacion-dfm-colombia?month=2023-04'
								)
							"
							:size="isMobile ? 'small' : 'large'"
							secondary
						>
							Agendar reunión
						</common-button>
						<!--
					<common-button :size="isMobile ? 'small' : 'large'" secondary>
						Cronograma
					</common-button>
				-->
					</v-col>
				</v-row>
			</v-row>
		</transition>
		<!-- end description -->

		<!-- start about -->
		<v-row
			v-if="screenSelected == 'Home'"
			class="bg--primary-90-t"
			style="padding:5% 5%"
		>
			<v-col :cols="isMobile ? '12' : '6'" class=" text-left ">
				<v-row :class="isMobile ? 'text-title' : 'text-heading'">
					<v-col
						>Reduciendo la exclusión escolar y abriendo las oportunidades de
						acceso a la educación superior.</v-col
					>
				</v-row>
				<v-row v-if="!isMobile" class="color--neutral-40-t">
					<v-col
						>Somos un programa de estudio digital y orientador virtual que
						entrega información personalizada a los estudiantes sobre los
						beneficios de la educación y las posibilidades que existen para
						alcanzar la educación superior.</v-col
					>
					<v-col
						>Nuestro objetivo a través de nuestro orientador virtual, es reducir
						la exclusión escolar y mostrar las oportunidades de acceso a la
						educación superior.
					</v-col>
				</v-row>
				<v-row v-if="isMobile" class="color--neutral-40-t">
					<v-col
						>Somos un programa de estudio digital y orientador virtual que
						entrega información personalizada a los estudiantes sobre los
						beneficios de la educación y las posibilidades que existen para
						alcanzar la educación superior. <br />
						<br />
						Nuestro objetivo a través de nuestro orientador virtual, es reducir
						la exclusión escolar y mostrar las oportunidades de acceso a la
						educación superior.
					</v-col>
				</v-row>
				<v-row v-if="!isMobile" class="color--secondary">
					<v-col
						>Decidiendo para un Futuro Mejor es un programa gratuito, flexible y
						ajustado al currículum nacional de educación para la asignatura de
						Orientación.</v-col
					>
				</v-row>
				<v-row>
					<v-col
						><common-button
							@click="changeScreen('About')"
							append="mdi-arrow-right"
							size="large"
							primary
						>
							Sobre el proyecto
						</common-button></v-col
					>
				</v-row>
			</v-col>
			<v-col :cols="isMobile ? '12' : '6'" class="text-center">
				<img
					src="../assets/Home/About.svg"
					style="object-fit: cover; width: 100%"
				/>
			</v-col>
			<v-row v-if="isMobile" class="text-left color--secondary">
				<v-col
					>Decidiendo para un Futuro Mejor es un programa gratuito, flexible y
					ajustado al currículum nacional de educación para la asignatura de
					Orientación.</v-col
				>
			</v-row>
		</v-row>
		<!-- end about -->

		<!-- start testimonies -->
		<v-row
			v-if="screenSelected == 'Home'"
			class="bg--primary-90-t pa-0 "
			:class="isMobile ? '' : 'fill-height'"
			style="min-height: 80%"
		>
			<v-col
				:cols="isMobile ? '12' : '6'"
				class="text-left fill-height bg--cuaternary"
				style="padding:5% 5% 5% 5%"
			>
				<v-row class="h-25"><v-col>TESTIMONIOS</v-col></v-row>
				<v-row align="start" class="text-title h-50 mb-10"
					><v-col
						>Lo que aprendí fue que no es necesario irse del colegio ya que si
						sigues y terminas una carrera tendrás mejor futuro.</v-col
					></v-row
				>
				<v-row align="end" class="h-25"
					><v-col
						><common-button
							class="mt-15"
							append="mdi-arrow-right"
							size="large"
							primary
							@click="changeScreen('Testimonies')"
						>
							Ver testimonios
						</common-button></v-col
					></v-row
				>
			</v-col>
			<transition name="fade">
				<v-col
					v-if="shownCountry"
					:cols="isMobile ? '12' : '6'"
					class="text-left white--text interventions fill-height"
					style="padding:5% 5%"
					:style="getCountryImage()"
				>
					<v-row class="h-25"><v-col>INTERVENCIONES</v-col></v-row>
					<v-row align="end" class="text-giant h-50 mb-10">
						<v-col>{{ shownCountry }}</v-col>
					</v-row>
					<v-row align="end" class="h-25"
						><v-col
							><common-button
								class="mt-15"
								append="mdi-arrow-right"
								size="large"
								secondary
								style="filter: grayscale(0%)!important;"
								@click="changeScreen('Countries')"
							>
								DFM países
							</common-button></v-col
						></v-row
					>
				</v-col>
			</transition>
		</v-row>
		<!-- end testimonies -->

		<!-- start calendar -->
		<v-row
			v-if="screenSelected == 'Home'"
			justify="center"
			class="bg--primary-90-t text-left"
			style=" padding:5% 5%"
		>
			<b class="text-overline">Calendario del programa</b>
			<h2 class="text-title bold mb-8">
				¡Cada etapa es fundamental para su éxito!
			</h2>
			<div
				class="text-left calendar-stage mr-9 mb-4 ml-6  relative"
				:class="isMobile ? 'ml-15' : ' w-25  mr-6'"
				:style="
					isMobile
						? ''
						: stage.stage == 2 || stage.stage == 4
						? '--margin-top:-10px' + ';--stage-color:' + stage.color
						: '--margin-bottom:-10px' + ';--stage-color:' + stage.color
				"
				v-for="(stage, index) in isMobile
					? sortedCalendarStages
					: calendarStages"
				:key="index"
			>
				<p
					v-if="!isMobile"
					class="text-overline bold bg--primary-100-t pt-1 pb-1 pl-6 pr-6"
					style="width: max-content; border-radius: 20px"
				>
					Etapa {{ stage.stage }}
				</p>
				<div
					class="absolute text-center bold text-body"
					style="background: white; border-radius: 50%; width:20px; height:20px; left:-10.5px;"
					v-if="isMobile"
					v-text="stage.stage"
				/>
				<p class="bold text-body" :class="isMobile ? 'ml-1' : ''">
					{{ stage.title }}
				</p>
				<p class="text-label" :class="isMobile ? 'ml-1' : ''">
					{{ stage.date }}
				</p>
				<p class="text-label" :class="isMobile ? 'ml-1 w-50' : ''">
					{{ stage.body }}
				</p>
			</div>
		</v-row>
		<!-- end calendar -->

		<!-- start reach -->
		<v-row
			v-if="screenSelected == 'Home'"
			justify="space-around"
			style="background:white; padding:5% 5%"
		>
			<v-col :cols="isMobile ? '12' : '3'" class="text-left">
				<v-row
					style="font-weight: 700; letter-spacing: 0.2rem;white-space:nowrap"
					><v-col>
						ALCANCE DEL PROYECTO
					</v-col></v-row
				>
				<img
					src="../assets/Home/Reach/america.png"
					style="max-width:120%"
					alt=""
				/>
				<v-row class="text-title "
					><v-col>1.100 escuelas en Chile y Colombia</v-col></v-row
				>
				<v-row class="text-body "
					><v-col
						>En sólo dos años, la plataforma web DFM se ha implantado en Chile y
						Colombia.
					</v-col></v-row
				>
			</v-col>
			<v-col :cols="isMobile ? '12' : '3'" class="text-left ">
				<v-row
					style="font-weight: 700; letter-spacing: 0.2rem; white-space:nowrap"
					><v-col>&#8205;</v-col></v-row
				>
				<img
					src="../assets/Home/Reach/interactions.png"
					style="max-width:120%"
					alt=""
				/>
				<v-row class="text-title "
					><v-col>Más de 8 millones de interacciones</v-col></v-row
				>
				<v-row class="text-body "
					><v-col
						>A través de nuestras diferentes y dinámicas actividades,
						herramientas y contenidos personalizados.
					</v-col></v-row
				>
			</v-col>
			<v-col :cols="isMobile ? '12' : '3'" class="text-left">
				<v-row style="font-weight: 700;"><v-col>&#8205;</v-col></v-row>
				<img
					src="../assets/Home/Reach/students.png"
					style="max-width:120%"
					alt=""
				/>
				<v-row class="text-title "
					><v-col>Los estudiantes valoraron el contenido</v-col></v-row
				>
				<v-row class="text-body "
					><v-col
						>El 70% de los estudiantes calificó el contenido del programa de
						"bueno" o "muy bueno".
					</v-col></v-row
				>
			</v-col>
		</v-row>
		<!-- end reach -->

		<!-- start logos -->
		<v-row
			v-if="screenSelected == 'Home'"
			style="letter-spacing:2px; padding:5% 5%; font-weight: 700"
		>
			<v-col class="text-left"
				><v-row>UNA INICIATIVA DE</v-row>
				<v-row class="text-left"
					><img
						:style="
							isMobile
								? 'transform-origin: left center;transform: scale(0.6);margin:0.5rem 0rem 1rem 0rem'
								: 'transform-origin: left center;transform: scale(0.2);margin:-1rem 0rem -1rem 0rem'
						"
						src="../assets/Home/Logos/CB.svg"
						alt=""/></v-row
				><v-row>EN ALIANZA CON</v-row>
				<v-row class="italic"> <br />INSTITUCIONES</v-row>
				<v-row
					><img
						:style="
							isMobile
								? 'transform-origin: left center;transform: scale(0.9);margin:1rem 0rem 1rem 0rem'
								: 'transform-origin: left center;transform: scale(0.5)'
						"
						src="../assets/Home/Logos/Institutions.svg"
						alt=""
				/></v-row>
				<v-row class="italic">GOBIERNOS</v-row>
				<v-row
					><img
						:style="
							isMobile
								? 'transform-origin: left center;transform: scale(0.9);margin:1rem 0rem 1rem 0rem'
								: 'transform-origin: left center;transform: scale(0.5)'
						"
						src="../assets/Home/Logos/Gobiernos.png"
						alt=""
				/></v-row>
			</v-col>
		</v-row>
		<!-- end logos -->

		<!-- start team -->
		<v-row
			v-if="screenSelected == 'Home'"
			class=" bg--primary "
			justify="center"
		>
			<v-col
				:cols="isMobile ? '12' : '3'"
				class="white--text "
				align-self="center"
				>Conoce nuestro equipo y de donde viene la iniciativa</v-col
			>
			<v-col :cols="isMobile ? '12' : '2'"
				><common-button
					@click="changeScreen('Team')"
					append="mdi-arrow-right"
					size="large"
					secondary
				>
					Equipo DFM
				</common-button></v-col
			>
		</v-row>
		<!-- end team -->

		<!-- start contact and news -->
		<v-row
			v-if="screenSelected == 'Home'"
			class="bg--primary-90-t"
			:style="isMobile ? '' : ''"
			style=" padding:5% 5%;"
		>
			<v-col
				:cols="isMobile ? '12' : '6'"
				class=" text-left mb-3"
				:class="isMobile ? '' : 'fill-height overflow-y-auto'"
			>
				<v-row
					><v-col class="bold">ACTUALIDAD</v-col>

					<v-row
						v-for="(newsObject, index) in [
							{
								imageUrl: 'One.png',
								title: 'Un ejemplo de innovación y escalamiento',
								text:
									'Francisco Gallego <br/>Profesor Economía UC<br/>Christopher Neilson<br/>Profesor Economía en Yale<br/><br/>Publicado en JPAL',
								link:
									'https://www.povertyactionlab.org/es/blog/1-28-22/facing-educational-covid-earthquake-example-scaling-and-innovating-chile',
							},
							{
								imageUrl: 'Two.png',
								title: 'Un futuro mejor',
								text:
									'Ignacio Sánchez D. <br/>Rector UC <br/><br/>Publicado en <i>La Segunda</i>',
								link:
									'https://drive.google.com/file/d/1EjiXWrf3NXMusmx85IKl7722g_kHeDNm/view?usp=sharing',
							},
							{
								imageUrl: 'Three.png',
								title: 'Un ejemplo de innovación y escalamiento',
								text:
									'Francisco Gallego <br/>Profesor Economía UC<br/>Christopher Neilson<br/>Profesor Economía en Yale<br/><br/>Publicado en <i>latercera.com</i>',
								link:
									'https://www.latercera.com/opinion/noticia/decidiendo-para-un-futuro-mejor-haciendo-frente-al-terremoto-educativo-desde-las-escuelas-y-liceos/WEKXUHN3JJF2HCV4CFEUK5AAVA/',
							},
						]"
						:key="index"
					>
						<v-col :cols="isMobile ? '12' : '6'"
							><img
								:src="require('@/assets/Home/News/' + newsObject.imageUrl)"
								style="min-width: 100%;max-width: 100%; height:230px; object-fit: cover;object-position: center;border-radius: 10px"
								alt="f"
						/></v-col>
						<v-col :cols="isMobile ? '12' : '6'">
							<v-row class="text-subtitle bold clickable "
								><v-col @click="openLink(newsObject.link)">{{
									newsObject.title
								}}</v-col></v-row
							>
							<v-row class="text-body color--neutral-40-t"
								><v-col v-html="newsObject.text" />
							</v-row>
						</v-col>
					</v-row>
				</v-row>
			</v-col>
			<v-col
				:cols="isMobile ? '12' : '6'"
				class="fill-height text-left"
				style="padding-left: 5%"
			>
				<v-row v-if="!sentMessage"
					><v-col class="bold">ÚNETE A DFM</v-col>
					<v-row
						><v-col class="text-body color--neutral-40-t"
							>Si quieres recibir más información acerca de DFM, regístrate
							aquí</v-col
						></v-row
					>

					<v-row style="margin-bottom:-25px"> <v-col>Nombre</v-col> </v-row
					><v-row>
						<v-col
							><v-text-field
								hide-details
								variant="underlined"
								v-model="formName"
						/></v-col>
					</v-row>

					<v-row style="margin-bottom:-25px">
						<v-col>Correo electrónico</v-col> </v-row
					><v-row>
						<v-col
							><v-text-field
								hide-details
								variant="underlined"
								v-model="formEmail"
						/></v-col>
					</v-row>
					<v-row style="margin-bottom:5px"> <v-col>Colegio</v-col> </v-row
					><v-row>
						<v-col
							><SchoolDropDown @setSchools="setSchools" @setSchool="setSchool"
						/></v-col>
					</v-row>
					<v-row style="margin-bottom:15px"> <v-col>Soy</v-col> </v-row
					><v-row>
						<v-col
							><common-dropdown
								hide-details
								v-model="formPosition"
								:items="['Orientador', 'Director']"
						/></v-col>
					</v-row>
					<v-row class="mt-10">
						<v-col
							><common-button
								@click="sendData"
								append="mdi-arrow-right"
								size="large"
								primary
							>
								Enviar
							</common-button></v-col
						>
					</v-row>
				</v-row>
				<v-row v-else
					><v-col class="bold">ÚNETE A DFM</v-col>
					<v-row
						><v-col class="text-body color--neutral-40-t"
							>Información enviada correctamente</v-col
						></v-row
					>
				</v-row>
			</v-col>
		</v-row>
		<!-- end contact and news -->
		<!-- END HOME -->
		<!-- About -->
		<transition name="fade-in">
			<About v-if="screenSelected === 'About'" />
		</transition>
		<!-- Testimonies -->
		<transition name="fade-in">
			<Testimonies v-if="screenSelected === 'Testimonies'" />
		</transition>

		<!-- Team -->
		<transition name="fade-in">
			<Team v-if="screenSelected === 'Team'" />
		</transition>

		<!-- Countries -->
		<transition name="fade-in">
			<Countries v-if="screenSelected === 'Countries'" />
		</transition>

		<!--Content -->
		<transition name="fade-in">
			<Content v-if="screenSelected === 'Content'" />
		</transition>
		<!-- Results -->
		<transition name="fade-in">
			<Results v-if="screenSelected === 'Results'" />
		</transition>
		<!-- Loading -->
		<div
			v-if="screenSelected === 'Loading'"
			style="height: 100vh!important"
		></div>

		<!-- start footer -->
		<v-row class="bg--primary-100-t bold " style="z-index:100" justify="center">
			<v-col :cols="isMobile ? '12' : '2'">¡Visítanos en nuestras redes!</v-col>
			<v-col :class="isMobile ? '' : 'd-flex'" :cols="isMobile ? '12' : '1'">
				<v-icon
					class="mr-3 color--primary"
					@click="openLink('https://www.instagram.com/programa.dfm/')"
				>
					mdi-instagram</v-icon
				><v-icon
					class="color--primary"
					@click="openLink('https://www.youtube.com/@DFM_Colombia')"
				>
					mdi-youtube</v-icon
				>
			</v-col>
		</v-row>
		<v-row
			class="bg--primary-90-t text-center pb-8"
			style="z-index:100"
			justify="center"
		>
			<v-col :cols="isMobile ? '12' : '4'"
				><v-row>
					<v-col
						><img
							src="@/assets/Home/Footer/DFMLogo.svg"
							alt="f"
							style="width:90%"/></v-col
				></v-row>
				<v-row>
					Reduciendo la exclusión escolar y abriendo las oportunidades de acceso
					a la educación superior.
				</v-row>
			</v-col>
		</v-row>
		<!-- end footer -->
	</v-container>
</template>
<script>
	import { mapGetters, mapActions } from 'vuex';
	import SchoolDropDown from '../components/home2/SchoolDropDown.vue';
	import About from '../components/home2/About.vue';
	import Testimonies from '../components/home2/Testimonies.vue';
	import Team from '../components/home2/Team.vue';
	import Countries from '../components/home2/Countries.vue';
	import Content from '../components/home2/Content.vue';
	import Results from '../components/home2/Results.vue';
	export default {
		data() {
			return {
				showDateExtension: false,
				screenSelected: 'Home',
				showNavigationDrawer: false,
				drawerTop: 0,
				formName: '',
				formEmail: '',
				formSchool: '',
				formSchoolCode: '',
				formPosition: '',
				schools: [],
				contacting: false,
				sentMessage: false,
				video1: false,
				video2: false,
				video3: false,
				shownCountry: 'Chile',
				countries: ['Chile', 'Colombia', 'República Domincana'],
				startTime: Date.now(),
				calendarStages: [
					{
						color: '#FFD066',
						stage: 1,
						title: 'Socialización e Inducción',
						date: '21/07 - 04/08',
						body:
							'Tendremos horarios AM y PM para resolver dudas y consultas sobre la implementación',
					},

					{
						color: '#FFA57D',
						stage: 3,
						title: 'Habilitación etapas',
						date: '08/08 - 29/09',
						body:
							'Los grados seleccionados podrán acceder a los 4 módulos del programa. Todas las sedes tendrán grados asignados. ',
					},
					{
						color: '#FF7B93',
						stage: 5,

						title: 'Resultados del programa',
						date: '20/11 - 24/11',
						body:
							'Las instituciones dispondrán de los resultados del programa.',
					},
					{
						color: '#FFBA72',
						stage: 2,

						title: 'Grados Asignados',
						date: '07/08',
						body:
							'Las instituciones recibirán los grados que fueron seleccionados para cada sede.',
					},
					{
						color: '#FF8E89',
						stage: 4,
						title: 'Encuesta Final',
						date: '02/10 - 31/10',
						body:
							'Todas las instituciones educativas recibirán un completo reporte con los resultados.',
					},
				],
			};
		},
		components: {
			About,
			Countries,
			Content,
			Testimonies,
			Team,
			SchoolDropDown,
			Results,
		},
		computed: {
			...mapGetters({
				isMobile: 'layout/isMobile',
				country: 'layout/country',
				dateExtensionSeen: 'authentication/dateExtensionSeen',
			}),
			sortedCalendarStages() {
				const sorted = this.calendarStages.slice();
				this.sortCalendarStages(sorted);
				return sorted;
			},
		},
		beforeDestroy() {
			const currentTime = Date.now();
			const timeSpent = (currentTime - this.startTime) / 1000;
			this.track({
				event: 'home-time',
				data: { timeSpent: timeSpent },
			});
		},
		mounted() {
			if (!this.dateExtensionSeen) {
				this.showDateExtension = true;
			}

			document.title = 'DFM | Home';
			this.animateDiv();
			setInterval(() => {
				if (this.shownCountry == 'Chile') {
					this.shownCountry = '';
					setTimeout(() => {
						this.shownCountry = 'Colombia';
					}, 1500);
				} else if (this.shownCountry == 'Colombia') {
					this.shownCountry = '';
					setTimeout(() => {
						this.shownCountry = 'República Dominicana';
					}, 1500);
				} else {
					this.shownCountry = '';
					setTimeout(() => {
						this.shownCountry = 'Chile';
					}, 1500);
				}
			}, 10000);
			this.track({ event: 'home-load', data: {} });
		},
		created() {
			// listen for scroll events and update the drawer top position
			window.addEventListener('scroll', this.updateDrawerTop);
		},
		destroyed() {
			// remove the scroll event listener when the component is destroyed
			window.removeEventListener('scroll', this.updateDrawerTop);
		},
		methods: {
			...mapActions({
				track: 'authentication/track',
				setDateExtensionSeen: 'authentication/setDateExtensionSeen',
			}),
			sortCalendarStages(stages) {
				stages.sort((a, b) => a.stage - b.stage);
			},
			hideDateExtension() {
				this.showDateExtension = false;
				this.setDateExtensionSeen({ dateExtensionSeen: true });
			},
			getCountryImage() {
				if (this.shownCountry === 'Chile')
					return {
						backgroundImage:
							'url(' +
							require('@/assets/Home/Testimonies/' + 'Chile.png') +
							')',
					};
				else if (this.shownCountry === 'Colombia') {
					return {
						backgroundImage:
							'url(' +
							require('@/assets/Home/Testimonies/' + 'Colombia.png') +
							')',
					};
				} else if (this.shownCountry === 'República Dominicana') {
					return {
						backgroundImage:
							'url(' + require('@/assets/Home/Testimonies/' + 'RD.png') + ')',
					};
				}
			},
			changeScreen(screenName) {
				this.showNavigationDrawer = false;
				let behaviorType = 'instant';
				if (this.screenSelected == screenName) {
					behaviorType = 'smooth';
				}
				this.screenSelected = 'Loading';
				setTimeout(() => {
					this.screenSelected = screenName;
				}, 50);
				setTimeout(() => {
					window.scrollTo({
						top: 0,
						behavior: behaviorType,
					});
				}, 50);

				this.track({
					event: 'home-change-screen',
					data: { screen: screenName },
				});
			},
			nextVideo() {
				if (this.video1) {
					this.video1 = false;
					this.$nextTick(() => {
						this.video2 = true;
					});
					this.track({ event: 'home-click-video-2', data: {} });
				}
				if (this.video2) {
					this.video2 = false;
					this.$nextTick(() => {
						this.video3 = true;
					});
					this.track({ event: 'home-click-video-3', data: {} });
				}
			},
			videoId(link) {
				return this.$youtube.getIdFromUrl(link);
			},
			updateDrawerTop() {
				this.drawerTop = window.pageYOffset;
			},
			sendData() {
				this.track({ event: 'home-contacting', data: {} });
				if (this.contacting) {
					return;
				}
				if (
					this.formName != '' &&
					this.formPosition != '' &&
					this.formEmail != ''
				) {
					if (
						this.formSchool == '' ||
						this.schools.filter((s) =>
							this.$includes(
								this.normalize(s.campus_name),
								this.normalize(this.formSchool)
							)
						).length == 0
					) {
						this.$store.state.success = false;
						this.$store.state.toasts.push('Favor elegir un colegio válido.');
					} else {
						this.contacting = true;

						this.$store
							.dispatch('authentication/sendContact', {
								position: this.formPosition,
								school_name: this.formSchool,
								school_code: this.formSchoolCode,
								contact_type: 'interested',
								email: this.formEmail,
								name: this.formName,
								country: this.country,
							})
							.then(() => {})
							.catch(() => {});
						setTimeout(() => {
							this.sentMessage = true;
						}, 300);
						this.contacting = false;
					}
				} else {
					this.$store.state.success = false;
					this.$store.state.toasts.push('Favor rellenar todos los campos.');
				}
			},
			normalize(str) {
				return str
					.toLowerCase()
					.normalize('NFD')
					.replace(/[\u0300-\u036f]/g, '');
			},
			setSchools(schools) {
				this.schools = schools;
			},
			setSchool(school, code) {
				this.formSchool = school;
				this.formSchoolCode = code;
			},
			openLink(link) {
				window.open(link, '_blank');
				this.track({ event: 'home-open-external-link', link: link });
			},
			animateDiv() {
				setTimeout(() => {
					this.$refs.home.classList.add('show');
				}, 300);
			},
		},
	};
</script>

<style scoped>
	.interventions {
		background-size: cover;
		background-position: center;
		background-color: rgb(150, 150, 150);
		background-blend-mode: luminosity;
		box-sizing: border-box !important;
		margin-left: 0px;
	}
	.description {
		background-size: cover;
		background-position: center;
		background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
			url('../assets/Home/boys.png');
	}
	.container::-webkit-scrollbar {
		background-color: #f7f4f9;
		border-radius: 5px;
		width: 0px !important;
	}
	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 1.5s ease;
	}
	.fade-enter {
		opacity: 0;
	}
	.fade-leave-to {
		opacity: 0;
		margin-left: -10px !important;
	}
	.fade-in-enter-active {
		transition: opacity 0.5s ease;
	}

	.fade-in-enter {
		opacity: 0;
	}
	.calendar-stage {
		position: relative;
		border-left: 1px solid #adadad62;
	}
	.calendar-stage::after {
		content: '';
		display: block;
		position: absolute;
		top: var(--margin-top);
		bottom: var(--margin-bottom);
		left: -3.5px;
		width: 6px;
		height: 6px;
		border-radius: 50%;
		background-color: var(--stage-color);
	}
</style>
