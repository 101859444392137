<template>
	<!-- todo: create again -->
	<div style="width:100%;margin-bottom:10px" class="dashboard-card">
		<vue-phone-number-input
			v-model="answer"
			@update="sendData"
			class="input"
			:border-radius="20"
			color="#cdc9ff"
			valid-color="#5669fb"
			error-color="red"
			:default-country-code="country"
			:translations="{
				countrySelectorLabel: 'Código de país',
				countrySelectorError: 'Elige un país',
				phoneNumberLabel: 'Número de teléfono',
				example: 'Ejemplo :',
			}"
		/>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	export default {
		name: 'IntegerInput',
		props: ['question', 'theme', 'readOnly', 'externalAnswer'],
		data() {
			return {
				answer: '',
				country: '',
			};
		},
		computed: {
			...mapGetters({
				mobile: 'layout/isMobile',
				lang: 'layout/lang',
			}),
		},
		components: {},
		methods: {
			sendData() {
				this.$emit('setAnswer', true, this.answer);
				if (this.mobile) {
					this.zoomOutMobile();
				}
			},
			zoomOutMobile() {
				var viewport = document.querySelector('meta[name="viewport"]');
				if (viewport) {
					viewport.content = 'initial-scale=1';
				}
			},
		},
		mounted() {
			this.answer = this.externalAnswer;
			this.country = this.lang.split('-')[1];
		},
	};
</script>
<style scoped>
	.input {
		font-family: 'Karla' !important;
		color: #5669fb !important;
	}
</style>
