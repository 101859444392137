<template>
  <div
    class="d-flex justify-content-center align-items-center bg--primary color--neutral-100-t"
    :class="mobile ? 'flex-column p-2' : 'p-3'"
    style="width:100vw;"
    :style="{ gap: mobile ? '10px' : '20px', width: width }"
  >
    <span
      :style="
        mobile
          ? 'font-size: 18px;line-height: 24px;'
          : 'font-size: 25px;line-height: 50px;'
      "
      >¡Visítanos en nuestras redes!</span
    >
    <div class="d-flex">
      <v-icon
        class="clickable home-icon-container"
        color="#5463FF"
        style="margin-right:20px"
        @click="openLink('https://www.instagram.com/programa.dfm/')"
        >mdi-instagram</v-icon
      >
      <v-icon
        class="clickable home-icon-container"
        color="#5463FF"
        style="margin-right:20px"
        @click="
          openLink(
            'https://www.linkedin.com/company/decidiendo-para-un-futuro-mejor/'
          )
        "
        >mdi-linkedin</v-icon
      >
      <v-icon
        class="clickable home-icon-container"
        color="#5463FF"
        @click="
          openLink(
            'https://www.facebook.com/Decidiendo-para-un-Futuro-Mejor-102067372541426'
          )
        "
        >mdi-facebook</v-icon
      >
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "Socials",
  props: {
    width: {
      default: "100vw",
      required: false,
      type: String,
    },
  },
  methods: {
    openLink(link) {
      window.open(link, "_blank");
    },
  },
  computed: {
    ...mapGetters({
      mobile: "layout/isMobile",
    }),
  },
};
</script>
