<template>
  <div :style="nodeStyle">
    <div :class="`item__element__${format}`">
      <!-- previous line -->
      <div :style="lineStyle" :class="getLineClass('before')" />

      <!-- end previous line -->
      <div :style="setColor(secondaryColor)" :class="getNodeClass()">
        <!-- node line if needed -->
        <div
          v-if="status == 2"
          :class="getNodeLineClass()"
          :style="setColor(primaryColor)"
        ></div>
        <!-- end node line if needed -->
        <!-- node if current -->
        <div
          v-if="status == 1"
          class="stacked center step-progress-node"
          :style="setColor(primaryColor)"
        >
          <span class="step-progress-node-text" :style="setColor(primaryColor)">
            {{ data }}
          </span>
        </div>
        <!-- end node if current -->
        <!-- node if not current -->
        <div
          v-if="status != 1"
          class="stacked node"
          :style="getSmallNodeStyle()"
        />
        <!-- end node if not current -->
      </div>
      <!-- next line -->
      <div :style="lineStyle" :class="getLineClass('after')"></div>
      <!-- end next line -->
    </div>
  </div>
</template>
<script>
export default {
  name: "StepProgressBarNode",
  props: {
    // content
    data: {
      required: true,
    },
    // orientation
    format: {
      required: true,
      type: String,
    },
    // incomplete (0), current (1) or complete (2)
    status: {
      required: true,
      type: Number,
    }, // position within parent array
    index: {
      required: true,
      type: Number,
    },
    primaryColor: { required: true, type: String },
    secondaryColor: { required: true, type: String },
  },
  data() {
    return {
      nodeStyle: "",
      childStyle: "",
      lineStyle: "",
      dataLength: 0,
    };
  },
  created() {
    window.addEventListener("resize", this.resize);
  },
  mounted() {
    this.dataLength = this.$parent.data.length;
    this.resize();
  },
  destroyed() {
    window.removeEventListener("resize", this.resize);
  },

  methods: {
    resize() {
      if (this.format == "v") {
        // get new height
        let height = this.$parent.$el.offsetHeight / this.dataLength;
        // style node based on height
        this.nodeStyle = `height:${height}px`;
        this.lineStyle = `height:${height / 2 - 10}px;--color:${
          this.primaryColor
        }`;
      } else {
        // get new width
        let width = this.$parent.$el.offsetWidth / this.dataLength;
        // style node based on width
        this.lineStyle = `width:${width / 2 - 10}px;--color:${
          this.primaryColor
        }`;
      }
    },
    getNodeClass() {
      let classes = ["node__base"];
      // if current node, change class to current
      if (this.status == 1) {
        classes.push("node-current");
      }
      return classes;
    },
    getLineClass(lineType) {
      // get class of line based on type and status.
      // if it's first segment or last segment, remove
      if (
        (this.index == 0 && lineType == "before") ||
        (this.index == this.dataLength - 1 && lineType == "after")
      ) {
        return `line line__${this.format} line__incomplete`;
      }
      // otherwise, we check wether to show or not based on if it's
      // previous line and the status.
      let offset = lineType == "before"; // 1 if line is before, 0 otherwise
      if (offset + this.status >= 2) {
        return `line line__${this.format} line__complete`;
      }
      return `line line__${this.format} line__incomplete`;
    },
    getNodeLineClass() {
      // get the line that crosses the node in the background.
      // useful for when the small node is visible.
      let classes = ["stacked", `stacked__${this.format}`];
      if (this.index == 0) {
        classes.push(`stacked__${this.format}__first`);
      } else if (this.index == this.dataLength - 1) {
        classes.push(`stacked__${this.format}__last`);
      }

      return classes.join(" ");
    },
    getSmallNodeStyle() {
      // change color of small node based on condition.
      if (this.status == 0) {
        // incomplete
        return `z-index:1;--color:white`;
      } else if (this.status == 1) {
        // current, shouldn't appear
        return `z-index:1;--color:${this.primaryColor}`;
      } else {
        // completed
        return `z-index:1;--color:${this.secondaryColor}`;
      }
    },
    setColor(color) {
      return `--color:${color}`;
    },
  },
};
</script>
