<template>
  <div>
    <div>
      <!-- header -->
      <div
        class="d-flex w-100 p-3 bg--neutral-100-t shadow-left"
        style="position:fixed;z-index: 99;top:0px"
      >
        <img
          class="clickable"
          @click="$router.push({ name: 'home' })"
          src="@/assets/Home/DFM.svg"
          style="height:20px;max-height:20px"
        />
      </div>
      <!-- end header -->

      <v-container
        class="lighten-5 bg--neutral-100-t"
        style="margin-top:40px;margin-right:0px !important;margin-left: 0px !important;width:100%;min-width:100%;position:relative;z-index:98"
      >
        <!-- welcome screen -->
        <v-row
          class="text-left h-100"
          style="height:100vh;min-height:100vh;;width:100%;min-width:100%"
        >
          <v-col cols="12" sm="1"></v-col>
          <v-col cols="12" sm="4" class="d-flex flex-column">
            <responsive-div
              desktopStyle="margin-top:40%"
              class="schedule-heading color--primary-70-s mb-3 relative"
            >
              <div
                class="bg--tertiary absolute"
                style="height:119px;width:119px;border-radius:60px;z-index:97;top:-60px"
              ></div>
              <span class="relative" style="z-index:98"
                >¡Bienvenidos/as a Decidiendo para un Futuro Mejor!</span
              >
            </responsive-div>
            <div class="mb-3 text-body schedule-line-height">
              ¿Eres parte de los más de 800 establecimientos educacionales que
              se inscribieron y quieres conocer cuáles son los próximos pasos?
            </div>
            <common-button
              class="mt-5"
              @click="scrollTo('step-1')"
              borderRadius="10px"
              text="¡Quiero conocer más información!"
            >
            </common-button>
          </v-col>
          <v-col
            cols="12"
            sm="7"
            class="d-flex justify-contents-center align-items-center h-100 flex-column"
          >
            <v-img max-width="100%" src="@/assets/Schedule/Main.png"></v-img>
          </v-col>
        </v-row>
        <!-- end welcome screen -->
        <!-- first step -->
        <v-row class="mt-15" ref="step-1">
          <v-col cols="12" sm="2"> </v-col>
          <v-col cols="12" class="relative" sm="5">
            <div
              class="bg--primary absolute"
              style="height:119px;width:119px;border-radius:60px;z-index:97;top:-60px;left:-60px;"
            ></div>
            <v-card
              class="text-left p-3 relative"
              style="z-index:98"
              elevation="5"
            >
              <div class="color--primary-70-s text-title bold mb-5">
                Paso 1: ¡Tiempo de encuesta!
              </div>
              <div class="mb-3 text-body schedule-line-height">
                A partir del próximo lunes 29 de Agosto y hasta el domingo 9 de
                Octubre, se llevará a cabo la Encuesta Inicial del programa.
                Esta encuesta tiene como propósito conocer las expectativas de
                las y los estudiantes, docentes y rectores sobre la educación y
                la información que manejan sobre las diferentes opciones que
                existen para acceder a la Educación Superior.
                <br />
                <br />
                Los datos de la encuesta serán analizados por el equipo de
                coordinación del programa, quienes elaborarán y enviarán a cada
                establecimiento un reporte con los principales resultados de su
                comunidad.
              </div>
              <div class="w-100 d-flex">
                <common-button
                  class="ms-auto mt-2"
                  @click="scrollTo('step-2')"
                  text="Siguiente paso"
                >
                </common-button>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" sm="1"> </v-col>
          <v-col cols="12" sm="3">
            <v-img
              max-width="100%"
              class="schedule-image"
              src="@/assets/Schedule/Step1.png"
            ></v-img>
          </v-col>
          <v-col cols="12" sm="1"> </v-col>
        </v-row>
        <!-- second step -->
        <v-row :class="mobile ? '' : 'mt-15'">
          <v-col cols="12" sm="1"> </v-col>
          <v-col cols="12" sm="5" v-if="!mobile">
            <v-img
              max-width="100%"
              class="schedule-image"
              src="@/assets/Schedule/Step2.png"
            ></v-img>
          </v-col>
          <v-col cols="12" sm="5" class="relative">
            <semi-circle
              width="118"
              class="color--secondary absolute"
              style="right:-40px;bottom:-70px"
              bottom
            ></semi-circle>
            <v-card
              flat
              class="bg--error d-flex p-3 color--neutral-100-t text-left mb-5"
              style="border-radius: 20px 0px 20px 0px"
            >
              <v-icon class="color--neutral-100-t" size="50"
                >mdi-alert-circle-outline</v-icon
              >
              <div class="ms-5" ref="step-2">
                Es importante señalar que la participación en esta etapa es
                obligatoria para todos los establecimientos inscritos. La
                encuesta debe ser respondida por el/la Director/a, docentes y
                estudiantes que participarán del programa.
              </div>
            </v-card>
            <v-card class="text-left p-3" elevation="5">
              <div class="color--primary-70-s text-title bold mb-5">
                Paso 2: ¿Fuiste seleccionado?
              </div>
              <div class="mb-3 text-body schedule-line-height">
                Dado que la postulación por sí sola no garantizará acceso al
                programa, debido a los recursos limitados con los que contamos,
                se seleccionarán aleatoriamente los establecimientos que
                recibirán las credenciales de acceso a la plataforma digital.
                Los resultados del proceso aleatorio de selección serán
                notificados y publicados por email el martes 11 de Octubre.
              </div>
              <div class="w-100 d-flex">
                <common-button
                  class="ms-auto mt-2"
                  @click="scrollTo('step-3')"
                  text="Siguiente paso"
                >
                </common-button>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" sm="5" v-if="mobile">
            <v-img
              max-width="100%"
              class="schedule-image"
              src="@/assets/Schedule/Step2.png"
            ></v-img>
          </v-col>
          <v-col cols="12" sm="1"> </v-col>
        </v-row>
        <!-- third step -->
        <v-row :class="mobile ? '' : 'mt-15'" ref="step-3">
          <v-col cols="12" sm="1"> </v-col>
          <v-col cols="12" sm="5" class="relative">
            <semi-circle
              width="118"
              class="color--primary absolute"
              left
              style="left:-70px;bottom:-20px"
            ></semi-circle>
            <v-card class="text-left p-3" elevation="5">
              <div class="color--primary-70-s text-title bold mb-5">
                Paso 3: Manos a la obra: ¡Aplicando DFM!
              </div>
              <div class="text-body schedule-line-height">
                Una vez seleccionado los establecimientos educacionales,
                nuestros equipos se contactarán con los encargados de la
                ejecución del programa en cada uno de los establecimientos, para
                dar inicio a la etapa de preparación e inducción. En esta etapa,
                las comunidades educativas recibirán toda la información
                necesaria para una correcta implementación del programa
                Decidiendo para un Futuro Mejor.
                <br />
                Posteriormente, daremos inicio a la etapa de aplicación del
                programa, donde se habilitará el acceso a la plataforma virtual
                que contiene el plan de estudio y todas las actividades que
                tenemos preparadas para este 2022.
                <br />
                <br />
                Etapa de preparación e inducción: 11 al 14 de Octubre.
                <br />
                <br />
                Etapa de implementación de DFM: 11 de Octubre al 11 de
                Noviembre.
              </div>
              <div class="w-100 d-flex">
                <common-button
                  class="ms-auto mt-2"
                  @click="scrollTo('step-4')"
                  text="Siguiente paso"
                >
                </common-button>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" sm="5">
            <v-img
              max-width="100%"
              class="schedule-image"
              src="@/assets/Schedule/Step3.png"
            ></v-img>
          </v-col>
          <v-col cols="12" sm="1"> </v-col>
        </v-row>
        <!-- fourth step -->
        <v-row :class="mobile ? '' : 'mt-15'">
          <v-col cols="12" sm="2"> </v-col>
          <v-col cols="12" sm="3">
            <v-img
              max-width="100%"
              class="schedule-image"
              src="@/assets/Schedule/Step4.png"
            ></v-img>
          </v-col>
          <v-col cols="12" sm="1"> </v-col>

          <v-col cols="12" sm="5" class="relative" ref="step-4">
            <v-card
              class="text-left p-3 relative"
              style="z-index:98"
              elevation="5"
            >
              <div class="color--primary-70-s text-title bold mb-5">
                Paso 4: ¡Tiempo de Encuesta!
              </div>
              <div class="text-body schedule-line-height">
                Por último, el equipo del programa se pondrá en contacto con
                todos los establecimientos educacionales que se inscribieron
                (independiente si fueron o no seleccionados para acceder a la
                plataforma digital) para facilitar la aplicación de una segunda
                y última encuesta que se llevará a cabo entre el 14 de Noviembre
                y 2 de Diciembre.
                <br />
                <br />
                Todos los establecimientos recibirán un reporte con el detalle
                de los resultados de sus comunidades.
              </div>
            </v-card>
            <div
              class="bg--tertiary absolute"
              style="height:119px;width:119px;border-radius:60px;z-index:97;right:-40px;bottom:-40px"
            ></div>
          </v-col>
          <v-col cols="12" sm="1"> </v-col>
        </v-row>
        <v-row
          class="schedule-heading color--primary-70-s text-center mt-15"
          style="margin-bottom:200px"
        >
          <v-col cols="12" sm="12">
            ¡Te esperamos!
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import mixpanel from "mixpanel-browser";
export default {
  name: "Schedule",
  methods: {
    scrollTo(section) {
      var element = this.$refs[section];
      this.$smoothScroll({
        scrollTo: element,
        duration: 400,
        offset: -99,
      });
      mixpanel.track(
        "schedule_scroll",
        { env: process.env.VUE_APP_ENVIRONMENT, section: section },
        () => {}
      );
    },
  },
  computed: {
    ...mapGetters({
      mobile: "layout/isMobile",
    }),
  },
};
</script>
<style>
.schedule-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 51px;
}
.schedule-line-height {
  line-height: 20px !important;
}
@media (max-width: 700px) {
  .schedule-image {
    max-width: 200px !important;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
