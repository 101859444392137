import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import authentication from "./authentication.module";
import program from "./program.module";
import student from "./student.module";
import _class from "./class.module";
import layout from "./layout.module";
import answer from "./answer.module";
import admin from "./admin.module";
import school from "./school.module";
import personalization from "./personalization.module";
import qualtrics from "./qualtrics.module"
Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState({ storage: window.sessionStorage })],
  state: {},
  mutations: {},
  actions: {},
  modules: {
    authentication,
    program,
    student,
    _class,
    layout,
    answer,
    admin,
    personalization,
    school,
    qualtrics,
  },
});
