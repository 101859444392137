<template>
  <div class="contact-form-container">
    <div class="d-flex flex-column  contact-form-text text-left">
      <h1 class="contact-form-header">
        {{ $t("contact-form.welcome-title") }}
      </h1>
      <p class="contact-form-span bold mt-2 mb-2">
        {{
          $t("contact-form.form-name", {
            country: $capitalize(country),
          })
        }}
      </p>
      <br />
      <p
        class="contact-form-span"
        v-for="(paragraph, paragraphIndex) in $t(
          'contact-form.welcome-paragraphs'
        )"
        :key="paragraphIndex"
        v-html="paragraph"
      ></p>
    </div>
    <p style="text-align:center;font-weight:bold;" class="contact-form-text">
      {{ $t("contact-form.thanks") }}
    </p>
    <button
      class="contact-form-button"
      style="width:223px"
      @click="setScreen('form')"
    >
      {{ $t("contact-form.continue") }}
    </button>
    <p
      style="text-decoration:underline;margin-top:10px;font-size: 14px;line-height: 16px;margin-bottom:20px"
      class="contact-form-text clickable"
      @click="goTo('/', true)"
    >
      {{ $t("contact-form.more-info") }}
    </p>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "Welcome",
  computed: {
    ...mapGetters({
      country: "layout/country",
    }),
  },
  methods: {
    setScreen(screen) {
      this.$emit("setScreen", screen);
    },
    goTo(route, otherSite) {
      this.$emit("goTo", route, otherSite);
    },
  },
};
</script>
<style scoped>
.contact-form-header {
  font-family: "Karla";
  font-style: normal;
  font-weight: 800;
  font-size: 26px;
  line-height: 30px;
  color: #eef0ff;
}
.contact-form-span {
  font-size: 16px;
  line-height: 20px;
  display: table;
}
.contact-form-text {
  font-family: "Karla";
  color: #eef0ff;
  flex: none;
  order: 0;
  flex-grow: 1;
}
.contact-form-container {
  padding: 101px 156px 0px 156px;
  background: #191f4b;
}
@media (max-width: 750px) {
  .contact-form-container {
    padding: 101px 32px 56px 32px;
  }
}
</style>
