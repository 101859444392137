<template>
  <div class="relative bg--primary-90-t">
    <!--<div
      class="contact-form-navbar"
      style="position:fixed;background-color:red;top:0px;width:100%;z-index:1000001;"
    >
      <div
        style="background:white;width: 100%;background: white;text-align: left;"
        class="text-left"
      >
        <img
          src="@/assets/Home/DFM.svg"
          class="m-3 clickable"
          @click="$router.push({ name: 'dashboard' })"
          style="height:17px"
        />
        Volver
      </div>
    </div>-->
    <div
      class="pt-5 pb-3 ps-5 pe-5 w-100 d-flex justify-content-center align-items-center  bg--neutral-100-t"
      style="position:fixed;z-index:100000"
    >
      <v-icon
        class="color--primary me-auto"
        style="position:relative:z-index:99999"
        @click="$router.push({ name: 'dashboard' })"
        >mdi-arrow-left</v-icon
      >
      <v-divider vertical class="bg--secondary ms-2 mr-2"></v-divider>
      <img src="@/assets/Home/DFM.svg" style="height:17px" />
    </div>
    <v-row>
      <v-col :cols="mobile ? 0 : 3"> </v-col>
      <v-col :cols="mobile ? 12 : 6">
        <div class=" p-5">
          <div class="pb-5 rounded mt-5 mb-2 text-left">
            <div>
              Estamos próximos a terminar el programa, pero antes, queremos
              <b>poner en práctica</b> lo que aprendimos y ayudarte a
              <b>planificar de mejor manera tu futuro.</b> Primero, te haremos
              unas preguntas iniciales:
            </div>

            <div>
              Imagina que dividimos a tu curso en 10 grupos, donde el
              <b>grupo 1 corresponde a los estudiantes de peor rendimiento</b> y
              el <b>grupo 10 a los de mejor rendimiento.</b>
            </div>
          </div>

          <div id="sliders"></div>
          <simulator-slider
            text="Si tuvieses que definir tu \bold{rendimiento de todas las asignaturas}, ¿en qué grupo te ubicarías?"
            v-model="allPosition"
          />

          <simulator-slider
            text="Si tuvieses que definir tu \bold{rendimiento en Matemática}, ¿en qué grupo te ubicarías?"
            v-model="matPosition"
          />
          <simulator-slider
            text="Si tuvieses que definir tu \bold{rendimiento en Lenguaje y Comunicación}, ¿en qué grupo te ubicarías?"
            v-model="langPosition"
          />
          <simulator-slider
            text="Si tuvieses que definir tu \bold{rendimiento en Historia}, ¿en qué grupo te ubicarías?"
            v-model="histPosition"
          />
          <simulator-slider
            text="Si tuvieses que definir tu \bold{rendimiento en Ciencias}, ¿en qué grupo te ubicarías?"
            v-model="sciPosition"
          />
          <div id="simulators" ref="simulators" v-if="showSimulators">
            <div class="rounded mt-5 mb-2 text-left">
              Ahora,
              <b
                >te invitamos a pensar y a elegir las 3 carreras que más te
                interesan.</b
              >
              Recuerda que puedes usar los filtros de región, tipo de
              institución y modalidad para simplificar el proceso.
            </div>
            <simulator-simulation
              simulationNumber="1"
              :allPosition="allPosition"
              :matPosition="matPosition"
              :histPosition="histPosition"
              :sciPosition="sciPosition"
              :langPosition="langPosition"
            />
            <simulator-simulation
              simulationNumber="2"
              :allPosition="allPosition"
              :matPosition="matPosition"
              :histPosition="histPosition"
              :sciPosition="sciPosition"
              :langPosition="langPosition"
            />
            <simulator-simulation
              simulationNumber="3"
              :allPosition="allPosition"
              :matPosition="matPosition"
              :histPosition="histPosition"
              :sciPosition="sciPosition"
              :langPosition="langPosition"
            />
          </div>
        </div>
      </v-col>
      <v-col :cols="mobile ? 0 : 3"> </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import SimulatorSimulation from "@/components/simulator/SimulatorSimulation.vue";

import SimulatorSlider from "@/components/simulator/SimulatorSlider.vue";
export default {
  name: "Simulator",
  watch: {
    showSimulators(newVal) {
      if (newVal) {
        this.scroll();
      }
    },
  },
  computed: {
    ...mapGetters({
      mobile: "layout/isMobile",
    }),
    showSimulators() {
      return (
        this.allPosition != null &&
        this.matPosition !== null &&
        this.langPosition !== null &&
        this.histPosition !== null &&
        this.sciPosition !== null
      );
    },
  },
  data() {
    return {
      allPosition: null,
      matPosition: null,
      langPosition: null,
      histPosition: null,
      sciPosition: null,
    };
  },
  mounted() {
    this.getRegions();
  },
  methods: {
    scroll() {
      setTimeout(() => {
        var element = this.$refs.simulators;
        this.$smoothScroll(
          {
            scrollTo: element,
            duration: 400,
            offset: -99,
          },
          200
        );
      });
    },
  },
  components: {
    SimulatorSimulation,
    SimulatorSlider,
  },
};
</script>
