import axios from "axios";

// create base api
const api = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT,
});
let storeAPI = null;

// access store
api.setStore = (store) => {
  storeAPI = store;
};

// set header from store on every request.
api.interceptors.request.use((config) => {
  const token = storeAPI.state.authentication.accessToken;
  // eslint-disable-next-line no-param-reassign
  /* if (config.data) {
    let formData = new FormData();
    Object.keys(config.data).forEach((k) => {
      formData.append(k, config.data[k]);
    });
    config.data = formData;
  }*/

  config.headers = {
    ...config.headers,
    Authorization: token,
    "Content-Type": "application/json",
  };
  return config;
});

export { api as default };
