var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"tool",staticClass:"dashboard-tool p-3 relative",staticStyle:{"flex":"1 1 0"},attrs:{"tabindex":"0"},on:{"focusout":function($event){_vm.showOptions = false},"click":function($event){return _vm.clickTool()}}},[_c('div',{staticStyle:{"position":"relative"}},[(_vm.showOptions)?_c('div',{staticClass:"absolute w-100 bg--primary-30-t color--primary-30-t my-menu",staticStyle:{"border-radius":"8px","margin-left":"-16px"},style:({
        'margin-top': _vm.toolHeight,
        width: _vm.toolWidth,
        'min-width': _vm.toolWidth,
        'max-width': _vm.toolWidth,
      })},[_c('div',{staticClass:"mt-3 mb-3",staticStyle:{"max-height":"50vh","overflow-y":"auto"}},[(_vm.options.length == 0)?_c('div',{staticClass:"italic bg--neutral-100-t mt-2 mb-2"},[_c('div',{staticClass:"pt-2 pb-2",domProps:{"textContent":_vm._s(_vm.$t('dashboard.no-notification'))}})]):_vm._e(),_vm._l((_vm.options),function(o,index){return _c('div',{key:index,staticClass:"bg--neutral-100-t",staticStyle:{"max-width":"100%"},on:{"click":[function($event){$event.stopPropagation();$event.preventDefault();},function($event){return _vm.selectOption(o.id)}]}},[(_vm.tool.name != 'notifications')?_c('div',{staticClass:"color--neutral-30-t pt-2 pb-2 bold"},[_vm._v(" "+_vm._s(o.text)+" ")]):_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"d-flex flex-column w-100 text-left p-2"},[_c('div',{staticClass:"text-label color--neutral-50-t"},[_vm._v(" "+_vm._s(o.header)+" ")]),_c('div',{staticClass:"color--neutral-30-t text-body"},[_vm._v(_vm._s(o.message))]),_c('div',{staticClass:"mt-1 text-label color--primray"},[_vm._v(" "+_vm._s(_vm.getDate(o.date_sent))+" ")])])]),(index < _vm.options.length - 1)?_c('div',{staticClass:"bg--neutral-80-t",staticStyle:{"height":"1px","width":"100%"}}):_vm._e()])})],2)]):_vm._e(),_c('div',{staticClass:"h-100 d-flex flex-column"},[_c('v-icon',{staticClass:"color--secondary mt-auto mb-auto",attrs:{"icon":"mdi-lock"}},[_vm._v(_vm._s(_vm.tool.icon)+" ")])],1),(
        _vm.tool.name == 'notifications' &&
          _vm.tool.options.filter(function (x) { return !x.seen; }).length > 0
      )?_c('div',{staticClass:"bg--error",staticStyle:{"position":"absolute","width":"10px","height":"10px","border-radius":"10px","top":"0","right":"0"}}):_vm._e()]),_c('div',{staticClass:"d-flex flex-column text-left ms-2 h-100 justify-content-center",style:({ '--color': _vm.getColor() })},[_c('span',{staticClass:"color--neutral-30-t bold text-body"},[_vm._v(_vm._s(_vm.tool.title))]),(!_vm.mobile)?_c('span',{staticClass:"text-small"},[_vm._v(_vm._s(_vm.tool.subtitle))]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }