<template>
  <v-select
    flat
    hide-details
    @input="setAnswer"
    :item-text="itemText"
    :item-value="itemValue"
    solo
    :disabled="disabled"
    v-model="answer"
    :items="question.answers"
  ></v-select>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "DropDown",
  mounted() {
    if (this.question.format == "institutiontype") {
      this.setInstitutionTypes();
    }
  },
  methods: {
    setAnswer(answer) {
      this.$emit("setAnswer", true, answer);
    },
    setInstitutionTypes() {
      let endpoint = "egresa/institution_type";
      this.get({ endpoint, params: {} }).then((response) => {
        let answers = response.data.map((answer, index) => {
          return {
            label: answer.label,
            number: index,
            value: answer.id,
          };
        });
        this.question.answers = answers;
        this.disabled = false;
      });
    },
    setInstitutions() {
      let endpoint = `egresa/institution?type=${this.institutionType}`;
      this.get({ endpoint, params: {} }).then((response) => {
        let answers = response.data.map((answer, index) => {
          return {
            label: answer.name,
            number: index,
            value: answer.id,
          };
        });
        this.question.answers = answers;
        this.disabled = false;
      });
    },
    setCareers() {
      let endpoint = `egresa/imparted_career?campus__institution=${this.institution}&career__type=${this.institutionType}`;
      this.get({ endpoint, params: {} }).then((response) => {
        let answers = response.data.map((answer, index) => {
          return {
            label: answer.name,
            number: index,
            value: answer.id,
          };
        });
        this.question.answers = answers;
        this.disabled = false;
      });
    },
    ...mapActions({
      get: "admin/silentGet",
    }),
  },
  watch: {
    institutionType() {
      if (this.question.format == "institution") {
        this.setInstitutions();
      }
    },
    institution() {
      if (this.question.format == "career") {
        this.setCareers();
      }
    },
  },
  computed: {
    institution() {
      let questionId = this.activity.questions.filter(
        (x) => x.format == "institution"
      )[0].id;
      if (questionId in this.answers) {
        return this.answers[questionId];
      }
      return null;
    },
    institutionType() {
      let questionId = this.activity.questions.filter(
        (x) => x.format == "institutiontype"
      )[0].id;
      if (questionId in this.answers) {
        return this.answers[questionId];
      }
      return null;
    },
    ...mapGetters({
      answers: "answer/answers",
    }),
  },
  data() {
    return {
      answer: "",
      itemText: "label",
      itemValue: "value",
      disabled: true,
    };
  },
  props: {
    activity: {
      required: true,
    },
    question: {
      required: true,
      type: Object,
    },
    externalAnswer: {
      required: false,
    },
  },
};
</script>
