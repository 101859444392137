import api from "../api";
export default {
  getPersonalizedStages({ phase, student }) {
    /* response is a list of Stage objects, to be used in Phase components. */
    return api.get("personalizedstage", { phase, student });
  },
  getNextStage({ stageId }) {
    /* response is a Stage object, to be added to current Stages. */
    return api.get(`personalizedstage/${stageId}`);
  },
};
