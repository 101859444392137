<template>
  <div class="w-100">
    <v-textarea
      v-if="question.format != 'small'"
      flat
      maxlength="249"
      solo
      :readonly="readOnly"
      hide-details
      auto-grow
      label="Escribe lo que quieras..."
      v-model="answer"
    ></v-textarea>
    <v-text-field
      flat
      maxlength="249"
      solo
      hide-spin-buttons
      :type="question.format == 'small' ? 'number' : 'text'"
      :readonly="readOnly"
      hide-details
      label="Escribe el número telefónico, o '1' si no tienes número telefónico."
      v-model="answer"
      v-else
    >
    </v-text-field>
    <div class="w-100 d-flex">
      <common-button
        v-if="!readOnly"
        text="Guardar"
        :extraClasses="
          hasValidAnswer() && !savedAnswer ? 'pulse-background' : ''
        "
        class="ms-auto mt-2 bg--secondary"
        :disabled="!hasValidAnswer()"
        @click="saveAnswer"
      ></common-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "Open",
  data() {
    return {
      answer: "",
      savedAnswer: false,
    };
  },
  mounted() {
    this.answer = this.externalAnswer;

    if (this.answer != "" && this.answer != undefined) {
      this.savedAnswer = true;
    }
  },
  methods: {
    hasValidAnswer() {
      if (this.answer.replace(/\s/g, "").length > 0) {
        return true;
      }
      return false;
    },
    saveAnswer() {
      this.$emit("setAnswer", true, this.answer);
      this.savedAnswer = true;
    },
  },
  props: {
    externalAnswer: {
      required: false,
    },
    question: {
      required: true,
      type: Object,
    },
    readOnly: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
};
</script>
<style scoped>
.pulse-background {
  transform: scale(1);
  animation: pulse-black 1.5s infinite;
}
@keyframes pulse-black {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
</style>
