<template>
  <div style="background:white;width:100vw;height:100vh">
    <div
      style="max-width:300px;"
      class="vh-100 ms-auto me-auto d-flex flex-column justify-content-center"
    >
      <v-dialog
        v-model="showIncorrectPasswordDialog"
        @click:outside="showIncorrectPasswordDialog = false"
        max-width="200px"
        height="100px"
      >
        <v-card
          style="min-height:100px"
          class="d-flex flex-row h-100 vertical-align-middle align-items-center text-center"
        >
          <div class="text-center w-100">{{ errorMsg }}</div>
        </v-card>
      </v-dialog>
      <form v-on:submit.prevent="recaptcha" class="w-100 p-3">
        <div style="width:50%;overflow:hidden" class="ms-auto me-auto">
          <video
            width="104%"
            style="transform:translate(-2%, -2%)"
            height="104%"
            muted
            autoplay
          >
            <source
              src="https://cb-dfm.s3.amazonaws.com/documentos/logo.mp4"
              type="video/mp4"
            />
            <!--<source src="@/assets/maps/logo.ogg" type="video/ogg" />-->
          </video>
        </div>

        <!--<gif-player
        src="@/assets/maps/logo.mp4"
        style="max-width:50%;width:50%"
      ></gif-player>-->
        <!--<img
        src="@/assets/maps/logo.gif"
        loop="false"
        style="max-width:50%;width:50%"
      />

      <img
        src="@/assets/maps/logo.svg"
        style="max-width:50%;width:50%"
        class="pb-5"
      />-->
        <custom-field label="Usuario" v-model="email" required hide-details>
        </custom-field>
        <custom-field
          class="mt-2"
          label="Password"
          type="password"
          v-model="password"
          hide-details
          required
        ></custom-field>
        <div class="w-100 text-center mt-4">
          <common-button text="Comenzar" size="x-large" type="submit" />
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "MapLogin",
  data() {
    return {
      showIncorrectPasswordDialog: false,
      email: "",
      password: "",
      errorMsg: "Password incorrecto.",
    };
  },
  methods: {
    ...mapActions({
      actionLogin: "authentication/login",
      get: "admin/silentGet",
    }),

    async recaptcha() {
      await this.$recaptchaLoaded();
      const captcha = await this.$recaptcha("login");
      this.trylogin(captcha);
    },
    async trylogin(captcha) {
      this.login(captcha);
    },
    login(captcha) {
      this.actionLogin({
        username: this.email,
        password: this.password,
        captcha: captcha,
      })
        .then((user) => {
          if (user) {
            this.get({ endpoint: "studentinformation", params: { limit: 1 } })
              .then(() => {
                this.$router.push({ name: `map` });
              })
              .catch(() => {
                this.errorMsg = "Usuario no tiene los permisos adecuados.";
                this.showIncorrectPasswordDialog = true;
              });
          } else {
            this.errorMsg = "Password incorrecto.";
            this.showIncorrectPasswordDialog = true;
          }
        })
        .catch(() => {
          this.errorMsg = "Password incorrecto.";

          this.showIncorrectPasswordDialog = true;
        });
    },
  },
};
</script>
