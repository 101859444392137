<template>
  <div
    :class="sliderFormat ? 'bg--neutral-100-t' : ''"
    class=" pt-4 ps-2 pe-2 rounded"
  >
    <v-slider
      v-if="sliderFormat"
      :max="max"
      :min="min"
      :readonly="readOnly"
      hide-details
      thumb-label="always"
      thumb-size="0"
      thumb-color="#FF7497"
      track-color="#FFC8DD"
      v-model="value"
      @change="setAnswer"
      color="#FF7497"
    >
      <template v-slot:thumb-label>
        <div style="color: black; font-size: 15px; margin-bottom: 13px">
          {{ format(value, "") }}
        </div>
      </template>
      <template v-slot:prepend>
        {{ format(min, "") }}
      </template>
      <template v-slot:append>
        {{ format(max, "max") }}
      </template>
    </v-slider>
    <div class="d-flex w-100" v-else>
      <div
        class="flex-grow-1 me-1 ms-1 text-center pt-3 pb-3 rounded elevation-2 clickable"
        :class="
          value == i
            ? 'bg--secondary color--neutral-100-t'
            : 'bg--neutral-100-t color--neutral-80-s'
        "
        @click="setValue(i)"
        v-for="i in range(this.max - this.min + 1, this.min)"
        :key="i"
      >
        {{ i }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Slider",

  computed: {
    min() {
      return this.question.answers[0]._min;
    },
    max() {
      return this.question.answers[0]._max;
    },
    sliderFormat() {
      if (this.question && this.question.answers.length > 0) {
        if (this.min <= 7 && this.max <= 7) {
          return false;
        }
      }
      return true;
    },
  },
  data() {
    return {
      value: 0,
    };
  },
  mounted() {
    this.value = this.externalAnswer;
  },
  props: {
    externalAnswer: {
      required: false,
    },
    question: {
      required: true,
      type: Object,
    },
    readOnly: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  methods: {
    range(size, startAt = 0) {
      return [...Array(size).keys()].map(
        (i) => parseInt(i) + parseInt(startAt)
      );
    },
    setValue(i) {
      this.value = i;
      this.setAnswer();
    },
    setAnswer() {
      this.$emit("setAnswer", true, this.value);
    },
    format(value, type) {
      if (this.question.format == "v-$") {
        var moneyFormatter = new Intl.NumberFormat();
        let x = moneyFormatter.format(value);
        if (type == "max") {
          return `$${x}+`;
        } else {
          return `$${x}`;
        }
      } else if (this.question.format == "%") {
        return `${value}%`;
      }
      return value;
    },
  },
};
</script>
<style>
div.v-slider__thumb-label.white {
  background: transparent !important;
  top: -20px !important;
}
</style>
