var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg--neutral-100-t vw-100 h-100 relative",staticStyle:{"background":"white"}},[_c('div',{staticClass:"d-flex w-100"},[_c('div',{staticClass:"pt-10 ms-10 mb-10 text-left"},[_c('img',{staticClass:"clickable",style:(_vm.mobile ? 'height:40px' : ''),attrs:{"src":require("@/assets/Dashboard/Logo.svg")},on:{"click":function($event){return _vm.$router.push({ name: 'home' })}}})]),(_vm.country != 'chile')?_c('div',{staticClass:"pt-10 me-10 ms-auto"},[_c('common-button',{staticClass:"ms-auto relative",staticStyle:{"background":"#F073D4","z-index":"100"},attrs:{"border-radius":"6px","text":_vm.country == 'colombia' ? 'Súmate' : 'Postula a DFM'},on:{"click":function($event){return _vm.$router.push({ name: 'apply' })}}})],1):_vm._e()]),_c('responsive-div',{staticClass:"text-left color--neutral-20-t relative bg--neutral-100-t",attrs:{"mobileStyle":"padding-left:10%;padding-right:10%","desktopStyle":"padding-left:20%;padding-right:20%","desktopClass":"pb-15"}},[_c('img',{staticClass:"absolute",style:(_vm.mobile
          ? 'top:-100px;right:-5px;width:24px;height:33px'
          : 'top:0;right:0;'),attrs:{"src":require("@/assets/Home/semicircle-left-blue.svg")}}),_c('img',{staticClass:"absolute",style:(_vm.mobile
          ? 'top:-100px;right:19px;width:24px;height:33px'
          : 'top:0;right:62px;'),attrs:{"src":require("@/assets/Home/semicircle-left-blue.svg")}}),_c('img',{staticClass:"absolute",style:(_vm.mobile
          ? 'top:100px;left:calc(20% - 106px);width:50px;height:50px'
          : 'top:100px;left:calc(20% - 106px);width:75px;height:75px'),attrs:{"src":require("@/assets/Home/square_pink.svg")}}),(!_vm.mobile)?_c('img',{staticClass:"absolute",staticStyle:{"top":"175px","left":"calc(20% - 138px)","width":"75px","height":"32px","object-fit":"cover"},attrs:{"src":require("@/assets/Home/square_pink.svg")}}):_vm._e(),(!_vm.mobile)?_c('img',{staticClass:"absolute",staticStyle:{"bottom":"-30px","left":"25%","object-fit":"cover"},attrs:{"src":require("@/assets/Home/triangle_left.svg")}}):_vm._e(),_c('responsive-div',{attrs:{"mobileClass":"text-center"}},[_c('span',{staticClass:"bold",class:_vm.mobile ? 'text-title' : 'text-heading'},[_vm._v(_vm._s(_vm.$t("content.title")))]),_c('br'),_c('span',{class:_vm.mobile ? 'text-title' : 'text-heading'},[_vm._v(_vm._s(_vm.$t("content.subtitle")))])]),(_vm.mobile)?_c('v-divider',{staticClass:"bg--primary"}):_vm._e(),_c('div',{staticClass:"d-flex mt-5",class:_vm.mobile ? 'flex-column' : ''},_vm._l((_vm.$t('content.paragraphs')),function(paragraph,paragraphIndex){return _c('p',{key:paragraphIndex,class:_vm.mobile ? '' : 'w-50'},[_vm._v(" "+_vm._s(paragraph)+" ")])}),0)],1),_c('responsive-div',{staticClass:"bg--neutral-100-t",staticStyle:{"padding-left":"10%","padding-right":"10%"},attrs:{"mobileStyle":"padding-bottom:160px","desktopStyle":"padding-bottom:400px","desktopClass":"mt-10 pt-10"}},[(_vm.mobile)?_c('v-divider',{staticClass:"color--primary",staticStyle:{"margin-top":"0px"}}):_vm._e(),_c('responsive-div',{staticClass:"bold mb-10",attrs:{"mobileClass":"text-title text-left","desktopClass":"text-heading"}},[_vm._v(" "+_vm._s(_vm.$t("content.phases-title"))+" ")]),_c('responsive-div',{staticClass:"d-flex",attrs:{"mobileClass":"flex-column"}},_vm._l((_vm.$t('content.phases')),function(phase,index){return _c('div',{key:index,staticClass:"text-left ms-5 mr-5",staticStyle:{"flex":"1"}},[_c('v-avatar',{class:[
            ("color--neutral-100-t bg--primary-" + (1 + index * 2) + "0-s"),
            _vm.mobile ? 'text-title' : 'text-heading' ],attrs:{"size":_vm.mobile ? 41 : 92}},[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('p',{class:_vm.mobile ? 'mt-2' : 'mt-5'},[_c('span',{class:("text-medium color--neutral-100-t highlight--primary-" + (1 +
                index * 2) + "0-s")},[_vm._v(_vm._s(phase.title))])]),_c('p',{staticClass:"text-body"},[_vm._v(_vm._s(phase.description))])],1)}),0)],1),_c('div',{staticClass:"relative d-flex justify-content-center align-items-center",staticStyle:{"height":"0"}},[_c('div',{staticClass:"absolute"},[_c('img',{staticClass:"absolute",staticStyle:{"transform":"translate(-50%, 0)"},style:(_vm.mobile
            ? 'width: 116px !important;z-index:1003;left:90px;top:-40px;border-radius:13px'
            : 'width: 240px !important;z-index:1003;left:400px;top:-270px;border-radius:25px'),attrs:{"src":"https://videoapi-muybridge.vimeocdn.com/animated-thumbnails/image/a0deb14e-8a94-4583-b657-e42cf2f069c5.gif?ClientID=vimeo-core-prod&Date=1657142707&Signature=03c29b3262e3eb3e45445e712ba82e8a74a788b6"}}),_c('img',{staticClass:"absolute",staticStyle:{"transform":"translate(-50%, 0)"},style:(_vm.mobile
            ? 'width: 331px !important;top:-160px;'
            : 'width: 691px !important;top:-360px;'),attrs:{"src":"https://videoapi-muybridge.vimeocdn.com/animated-thumbnails/image/0aa37e7b-b252-4887-9280-0038221b8842.gif?ClientID=vimeo-core-prod&Date=1657142611&Signature=521f81b8d4eeeb4068cfa4b73ee0fdfafe70e593"}})])]),_c('responsive-div',{staticClass:"bg--primary-60-s pb-15",attrs:{"mobileStyle":"padding-left:10%;padding-right:10%;padding-top:210px","desktopStyle":"padding-left:20%;padding-right:20%;padding-top:300px"}},[_c('responsive-div',{staticClass:"color--secondary-10-t mb-10",attrs:{"mobileClass":"text-left text-title","desktopClass":"text-heading","mobileStyle":"marging-right:20%"}},[_vm._v(" ¡Revisa lo que tenemos preparado para tu comunidad educativa! ")]),_c('responsive-div',{staticClass:"d-flex",staticStyle:{"gap":"20px"},attrs:{"mobileClass":"flex-column"}},[_c('div',{staticStyle:{"flex":"1 1 0"}},_vm._l((_vm.$t('content.benefitsLeft')),function(benefit,index){return _c('div',{key:index,staticClass:"d-flex"},[_c('div',{staticClass:"me-5"},[_c('img',{staticStyle:{"width":"9px"},attrs:{"src":require("@/assets/Home/semicircle-right-green.svg")}})]),_c('div',{staticClass:"text-left"},[_c('span',{staticClass:"color--secondary-10-t"},[_vm._v(_vm._s(benefit.title))]),_c('p',{staticClass:"color--neutral-100-t"},[_vm._v(_vm._s(benefit.description))])])])}),0),_c('div',{staticStyle:{"flex":"1 1 0"}},_vm._l((_vm.$t('content.benefitsRight')),function(benefit,index){return _c('div',{key:index,staticClass:"d-flex"},[_c('div',{staticClass:"me-5"},[_c('img',{staticStyle:{"width":"9px"},attrs:{"src":require("@/assets/Home/semicircle-right-green.svg")}})]),_c('div',{staticClass:"text-left"},[_c('span',{staticClass:"color--secondary-10-t"},[_vm._v(_vm._s(benefit.title))]),_c('p',{staticClass:"color--neutral-100-t"},[_vm._v(_vm._s(benefit.description))])])])}),0)])],1),_c('div',[_c('Socials')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }