<template>
  <DashboardCard>
    <edit-table
      endpoint="programnew"
      :keys="['id', 'name', 'description']"
      :ignoredKeys="['phases']"
      name="Programa"
      object="program"
      requiresPassword
    />
    <edit-table
      requiresPassword
      endpoint="phasenew"
      externalSearchableField="program"
      label="Filtrar por programa"
      :getParams="{ program: this.program.id }"
      :keys="[
        'id',
        'available',
        'program',
        'name',
        'number',
        'title',
        'description',
        'summary',
        'start_date',
        'end_message',
      ]"
      :ignoredKeys="['stages', 'lectionaries', 'objectives', 'transitions']"
      name="Etapa"
      object="phase"
    />
    <edit-table
      requiresPassword
      endpoint="stagenew"
      externalSearchableField="phase"
      label="Filtrar por Etapa"
      :getParams="{ phase__program: this.program.id }"
      :keys="[
        'id',
        'phase',
        'name',
        'number',
        'title',
        'description',
        'has_feedback',
        'is_start',
        'is_end',
        'format',
        'label',
      ]"
      :ignoredKeys="['activities']"
      name="Sub-Etapa (Pantalla)"
      object="stage"
    />

    <!-- :getParams="{ stage__phase__program: this.program.id }" -->
    <edit-table
      requiresPassword
      endpoint="activitynew"
      externalSearchableField="stage"
      label="Filtrar por Sub-Etapa"
      :keys="[
        'id',
        'stage',
        'title',
        'number',
        'description',
        'media',
        'type',
        'format',
        'btn_link',
        'btn_text',
        'expandable',
        'background',
      ]"
      :ignoredKeys="['questions']"
      name="Actividades"
      object="activity"
    />
    <edit-table
      requiresPassword
      :getParams="{ activity__stage__phase__program: this.program.id }"
      endpoint="questionnew"
      externalSearchableField="activity"
      label="Filtrar por Actividad"
      :keys="[
        'id',
        'activity',
        'question',
        'number',
        'media',
        'type',
        'text',
        'expected',
        'format',
        'maximum',
      ]"
      :ignoredKeys="['answers']"
      name="Preguntas"
      object="question"
    />
    <edit-table
      requiresPassword
      endpoint="answernew"
      externalSearchableField="question"
      label="Filtrar por Pregunta"
      :getParams="{
        question__activity__stage__phase__program: this.program.id,
      }"
      :keys="[
        'id',
        'question',
        'text',
        'number',
        'media',
        '_max',
        '_min',
        'btn_link',
        'btn_text',
        'unique',
        'extra_text',
      ]"
      name="Respuestas"
      object="answer"
    />
    <v-divider />
    <edit-table
      requiresPassword
      endpoint="lectionarynew"
      externalSearchableField="phase"
      label="Filtrar por etapa"
      :getParams="{
        phase__program: this.program.id,
      }"
      :keys="['id', 'number', 'phase', 'name', 'summary']"
      name="Leccionarios"
      object="lectionary"
    />
    <edit-table
      requiresPassword
      endpoint="objectivenew"
      externalSearchableField="phase"
      label="Filtrar por etapa"
      :getParams="{
        phase__program: this.program.id,
      }"
      :keys="['id', 'phase', 'number', 'text', 'type']"
      name="Objetivos por etapa"
      object="objective"
    />
    <v-divider />
    <edit-table
      requiresPassword
      endpoint="tutorial"
      externalSearchableField="id"
      label="Filtrar por id"
      :keys="['id', 'name', 'user_type', 'video_link', 'description']"
      name="Tutoriales"
      object="tutorial"
    />
  </DashboardCard>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import DashboardCard from "../DashboardCard.vue";
import EditTable from "./ProgramEditor/EditTable.vue";
export default {
  name: "DashboardAdminFeatures",
  components: { DashboardCard, EditTable },
  methods: {
    ...mapActions({
      getProgram: "program/getProgram",
    }),
  },
  computed: {
    ...mapGetters({
      program: "program/program",
    }),
  },
};
</script>
