var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loaded)?_c('DashboardCard',[_c('edit-generic',{attrs:{"object":_vm.program,"title":"name","type":"program","keys":['description', 'name'],"listName":"programs","parentId":_vm.program.id,"lists":[
      {
        name: 'phases',
        type: 'phase',
        title: 'title',
        lists: [
          {
            name: 'stages',
            type: 'stage',
            title: 'name',
            lists: [
              {
                name: 'activities',
                type: 'activity',
                title: 'title',
                lists: [
                  {
                    title: 'question',
                    type: 'question',
                    name: 'questions',
                    lists: [
                      {
                        title: 'answer',
                        type: 'answer',
                        name: 'answers',
                        lists: [],
                        keys: [
                          'id',
                          'question',
                          'text',
                          'number',
                          'media',
                          '_max',
                          '_min',
                          'extra_text' ],
                      } ],
                    keys: [
                      'id',
                      'activity',
                      'question',
                      'media',
                      'type',
                      'expected',
                      'format',
                      'number' ],
                  } ],
                keys: [
                  'id',
                  'stage',
                  'title',
                  'description',
                  'media',
                  'type',
                  'format',
                  'number',
                  'btn_link',
                  'btn_text',
                  'expandable',
                  'background' ],
              } ],
            keys: [
              'id',
              'phase',
              'number',
              'name',
              'title',
              'description',
              'has_feedback' ],
          },
          {
            name: 'lectionaries',
            title: 'name',
            lists: [],
            type: 'lectionary',
            keys: ['id', 'phase', 'name', 'summary', 'number'],
          },
          {
            name: 'objectives',
            type: 'objective',
            title: 'text',
            lists: [],
            keys: ['id', 'phase', 'text', 'type', 'number'],
          } ],
        keys: [
          'id',
          'program',
          'number',
          'name',
          'title',
          'description',
          'summary' ],
      } ]}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }