import services from "@/services";

const getDefaultState = () => ({
  rbd: null,
  schools: [],
});

const state = {
  ...getDefaultState(),
};

const getters = {
  schools: ({ schools }) => schools,
  rbd: ({ rbd }) => rbd,
  school: ({ rbd, schools }) => schools.find((s) => s.rbd == rbd),
};

const mutations = {
  setSchools(state, { schools }) {
    state.schools = schools;
  },
  setRbd(state, { rbd }) {
    state.rbd = rbd;
  },
};

const actions = {
  reset({ state }) {
    Object.assign(state, getDefaultState());
  },
  setRbd({ commit }, { rbd }) {
    commit("setRbd", { rbd });
  },
  getSchools({ commit }, { country }) {
    services.schoolService.getSchools({ country }).then((response) => {
      let schools = response.data;
      schools.map((s) => {
        let data = s;
        Object.keys(data.progress).forEach((phaseNumber) => {
          data[`Avance fase ${parseInt(phaseNumber) + 1}`] = `${Math.round(
            data.progress[phaseNumber]
          ) * 100}%`;
        });
        delete data["progress"];
        return data;
      });
      commit("setSchools", { schools });
    });
  },
  getSchoolClasses({ dispatch }, { schoolId }) {
    return services.schoolService
      .getSchoolClasses({ schoolId })
      .then((response) => {
        dispatch(
          "_class/setClasses",
          { classes: response.data },
          { root: true }
        );
        return response;
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
