var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DashboardCard',[_c('edit-table',{attrs:{"endpoint":"support","externalSearchableField":"id","label":"Filtrar por id","keys":[
      'email',
      'role',
      'assigned',
      'category',
      'text',
      'rbd',
      'date_sent',
      'completed' ],"name":"Soporte","object":"support"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }