<template>
  <v-btn
    @click="$emit('click')"
    class="text-none"
    flat
    :style="{
      color,
      background,
      border: inverted ? '1px solid #00405B !important' : '',
    }"
    depressed
    >{{ label }}</v-btn
  >
</template>
<script>
export default {
  name: "MapButton",
  props: {
    inverted: {
      required: false,
      default: false,
      type: Boolean,
    },
    label: {
      required: true,
    },
  },
  computed: {
    color() {
      if (this.inverted) {
        return "#00405B";
      }
      return "white";
    },
    background() {
      if (this.inverted) {
        return "white";
      }
      return "#00405B";
    },
  },
};
</script>
