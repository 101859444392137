<template>
  <v-app>
    <div id="app" style="height:100vh">
      <!-- <NavigationBar v-if="$route.name == 'dashboard'" /> -->

      <div
        class="bg--primary pt-1 pb-1 ps-2 pe-2"
        :style="userInterface ? 'opacity: 0.5' : 'opacity: 0'"
        style="z-index:10000;position:fixed;border-radius:5px;font-size:10px;bottom:0;right:0"
        v-if="env == 'DEV' || env == 'STAGING'"
      >
        <select
          v-if="userInterface"
          name="cars"
          id="cars"
          form="carform"
          @change="setCountry($event)"
        >
          <option value="es-CL" :selected="lang == 'es-CL'">chile</option>
          <option value="es-CO" :selected="lang == 'es-CO'">colombia</option>
          <option value="es-PE" :selected="lang == 'es-PE'">Perú</option>
          <option value="es-RD" :selected="lang == 'es-RD'"
            >República Dominicana</option
          >
        </select>
        <input
          @change="setUserInterface"
          v-model="showInterface"
          type="checkbox"
        />
      </div>

      <ToastNotification />
      <router-view /></div
  ></v-app>
</template>

<script>
// import NavigationBar from "./components/navbar/NavigationBar.vue";
import { mapActions, mapGetters } from "vuex";
import api from "./api";
import ToastNotification from "./components/layout/ToastNotification.vue";
export default {
  name: "App",
  metaInfo() {
    return {
      title: "DFM",
      meta: [
        {
          name: "description",
          content:
            "Decidiendo para un Futuro Mejor ayuda a las y los estudiantes a alcanzar sus metas personales y los orienta en las decisiones a tomar para llegar a la educación superior.",
        },
        { property: "og:title", content: "Decidiendo para un futuro mejor" },
        { property: "og:site_name", content: "DFM" },
        { property: "og:type", content: "website" },
        {
          property: "og:url",
          content: "https://www.decidiendofuturomejor.com/",
        },
        { property: "og:image", content: "https://imgur.com/a/egbJm9o" },
        {
          property: "og:description",
          content:
            "Decidiendo para un Futuro Mejor ayuda a las y los estudiantes a alcanzar sus metas personales y los orienta en las decisiones a tomar para llegar a la educación superior.",
        },
      ],
    };
  },
  components: {
    // NavigationBar,
    ToastNotification,
  },
  mounted() {
    /*if (this.loggedIn && this.user.type != "ambassador" && this.country == "colombia") {
      this.logout();
    }*/
    this.showInterface = this.userInterface;
    this.getPeruOptions();
    localStorage.removeItem("lang");
    this.getLang();
    this.hideRecaptchaBadge();
    api.setStore(this.$store);
  },
  data() {
    return {
      env: process.env.VUE_APP_ENVIRONMENT,
      showInterface: true,
      html: document.getElementById("html"),
    };
  },
  computed: {
    ...mapGetters({
      mobile: "layout/isMobile",
      lang: "layout/lang",
      peruRows: "authentication/peruRows",
      country: "layout/country",
      userInterface: "layout/showInterface",
      loggedIn: "authentication/loggedIn",
      user: "authentication/user",
    }),
    navbarHeight() {
      // navbar height depending on mobile or desktop view
      if (this.mobile) {
        return "50px";
      } else {
        return "137px";
      }
    },
  },
  created() {
    this.getCountry();
    window.addEventListener("resize", this.resizeHandler);
    this.resizeHandler();
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeHandler);
  },
  methods: {
    setUserInterface(event) {
      this.setInterface({ userInterface: event.target.checked });
    },
    setCountry(event) {
      this.setLang({ lang: event.target.value });
    },
    ...mapActions({
      getCountry: "authentication/getCountry",
      updateScreenWidth: "layout/updateScreenWidth",
      updateScreenHeight: "layout/updateScreenHeight",
      getLang: "layout/getLang",
      setLang: "layout/setLang",
      getPeruOptions: "authentication/getPeruOptions",
      setInterface: "layout/setInterface",
      logout: "authentication/logout",
    }),
    async hideRecaptchaBadge() {
      await this.$recaptchaLoaded();
      const recaptcha = this.$recaptchaInstance;
      recaptcha.hideBadge();
    },
    resizeHandler() {
      var w = document.documentElement.clientWidth;
      var h = document.documentElement.clientHeight;
      // this.$store.commit("updateScreenWidth", w);
      // this.$store.commit("updateScreenHeight", h);
      this.updateScreenWidth({ screenWidth: w });
      this.updateScreenHeight({ screenHeight: h });
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Karla");
@import url("https://fonts.googleapis.com/css?family=Montserrat");
@import url("https://fonts.googleapis.com/css?family=Satoshi");

#app {
  font-family: Satoshi, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: calc(100vh - 137px);
  padding: 0px;
  margin: 0px;
  background-color: #f7f4f9;
}
.hola {
  background-color: blue;
}
.chao {
  background-color: red;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right !important;
}
.icon {
  background-color: #f7f4f9;
  box-shadow: -3px 3px 10px #aaaaaa;
  margin-left: 10px;
}
.br {
  background-color: #f7f4f9;
  height: 2px;
  margin-top: 5px;
  margin-bottom: 8px;
}
.br-small {
  background-color: #f7f4f9;
  height: 1px;
  margin-top: 5px;
  margin-bottom: 8px;
}
.text {
  line-height: 18px;
}
.text-tiny {
  font-size: 12px;
  line-height: 14px;
}
.text-small {
  font-size: 14px;
  line-height: 18px;
}
.text-medium {
  font-size: 20px;
  line-height: 28px;
}
.text-big {
  font-size: 32px;
  line-height: 36px;
}
.text-button-panel {
  font-size: 12px;
  line-height: 14px;
}
@media (max-width: 768px) {
  .text-tiny {
    font-size: 10px;
    line-height: 12px;
  }
  .text-small {
    font-size: 12px;
    line-height: 16px;
  }
  .text-medium {
    font-size: 14px;
    line-height: 20px;
  }
  .text-big {
    font-size: 14px;
    line-height: 16px;
  }
  .text-button-panel {
    font-size: 12px;
    line-height: 14px;
  }
}
.shadow-right {
  box-shadow: 4px 4px 8px rgba(30, 30, 30, 0.2);
}
.shadow-left {
  box-shadow: -4px 4px 8px rgba(30, 30, 30, 0.2);
}
.inner-shadow-left {
  box-shadow: inset -5px 0 5px -5px rgba(0, 0, 0, 0.5);
}

.inner-shadow-right {
  box-shadow: inset 5px 0 5px -5px rgba(0, 0, 0, 0.5);
}

.inner-shadow-bottom {
  box-shadow: inset 0 -5px 5px -5px rgba(0, 0, 0, 0.5);
}
.shadow-left-light {
  box-shadow: -2px 2px 4px rgba(30, 30, 30, 0.2);
}
.text-11 {
  font-size: 11px;
}
body {
  height: calc(100vh - 137px);
}
html {
  height: calc(100vh - 137px);
  scroll-behavior: smooth;
}
/* Scrollbar */
::-webkit-scrollbar {
  background-color: #f7f4f9;
  border-radius: 5px;
  width: 10px;
}
::-webkit-scrollbar-track {
  border-radius: 5px;
}
::-webkit-scrollbar-thumb {
  background: var(--scrollbar-color, #dddadf);
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  margin-right: -10px;
  background: var(--scrollbar-bg-color, #b7b7b7);
}
.karla {
  font-family: Karla, Helvetica, Arial, sans-serif;
}

.text {
  line-height: 18px;
}
.text-tiny {
  font-size: 12px;
  line-height: 14px;
}
.text-small {
  font-size: 14px;
  line-height: 18px;
}
.text-medium {
  font-size: 20px;
  line-height: 28px;
}
.text-big {
  font-size: 32px;
  line-height: 36px;
}
.text-button-panel {
  font-size: 12px;
  line-height: 14px;
}
@media (max-width: 768px) {
  .text-tiny {
    font-size: 10px;
    line-height: 12px;
  }
  .text-small {
    font-size: 12px;
    line-height: 16px;
  }
  .text-medium {
    font-size: 14px;
    line-height: 20px;
  }
  .text-big {
    font-size: 14px;
    line-height: 16px;
  }
  .text-button-panel {
    font-size: 12px;
    line-height: 14px;
  }
}
.shadow-right {
  box-shadow: 4px 4px 8px rgba(30, 30, 30, 0.2);
}
.shadow-left {
  box-shadow: -4px 4px 8px rgba(30, 30, 30, 0.2);
}
.shadow-left-light {
  box-shadow: -2px 2px 4px rgba(30, 30, 30, 0.2);
}
.text-11 {
  font-size: 11px;
}
body {
  height: calc(100vh - 137px);
}
html {
  height: calc(100vh - 137px);
  scroll-behavior: smooth;
}
/* Scrollbar */
::-webkit-scrollbar {
  background-color: #f7f4f9;
  border-radius: 5px;
  width: 10px;
}
::-webkit-scrollbar-track {
  border-radius: 5px;
}
::-webkit-scrollbar-thumb {
  background: var(--scrollbar-color, #dddadf);
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  margin-right: -10px;
  background: var(--scrollbar-bg-color, #b7b7b7);
}
.karla {
  font-family: Karla, Helvetica, Arial, sans-serif;
}

.v-btn--active.no-active::before {
  opacity: 0 !important;
}
.v-btn {
  border: 0px !important;
}
.clickable {
  cursor: pointer;
}
.fade-in-home {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.fade-in-home.show {
  opacity: 1;
}
</style>
