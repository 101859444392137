<template>
	<div :class="getContainerClass()" style="height:100%">
		<StepProgressBarNode
			:class="getNodeClass()"
			v-for="(i, index) in data"
			:key="index"
			:data="i"
			:format="format"
			:status="getStatus(index)"
			:index="index"
			:primaryColor="primaryColor"
			:secondaryColor="secondaryColor"
		/>
	</div>
</template>
<script>
	import StepProgressBarNode from './StepProgressBarNode';
	export default {
		name: 'StepProgressBar',
		props: {
			data: { required: true, type: Array },
			current: { required: true, type: Number },
			format: { required: true, type: String },
			primaryColor: { required: true, type: String },
			secondaryColor: { required: true, type: String },
		},
		components: {
			StepProgressBarNode,
		},
		methods: {
			getContainerClass() {
				// return the class of the container
				// based on the orientation
				if (this.format == 'h') {
					return 'item-container';
				} else {
					return 'h-100';
				}
			},
			getNodeClass() {
				// returns the class of the items contained
				// based on the orientation
				let classes = [];
				if (this.format == 'h') {
					classes.push('item');
				}
				classes.push(`item__${this.format}`);
				return classes;
			},
			getStatus(index) {
				// returns the status of the node, either incomplete (0),
				// current (1) or completed (2)
				if (index > this.current) {
					return 0;
				}
				if (index == this.current) {
					return 1;
				}
				return 2;
			},
		},
	};
</script>
