<template>
  <div>
    <div
      v-if="loaded"
      :class="question.format == 'icon' ? 'd-flex w-100' : ''"
      style="flex-wrap: wrap !important;"
    >
      <div
        class="text-label bold mb-2"
        v-if="question.answers.filter((x) => x.extra_text != '').length == 0"
      >
        Marca solo una opción.
      </div>
      <ChoiceAnswer
        :style="
          question.format == 'icon'
            ? 'width:50%;padding-left:5px;padding-right:5px; height:50px'
            : ''
        "
        v-for="answer in question.answers"
        :key="answer"
        :answer="answer"
        :question="question"
        @setAnswer="setAnswer"
        :selectedAnswer="currentAnswer === answer.number"
        class="mb-3"
        :readOnly="readOnly"
      />
    </div>
  </div>
</template>
<script>
import ChoiceAnswer from "../answers/ChoiceAnswer.vue";
export default {
  name: "SingleChoice",

  props: {
    externalAnswer: {
      required: false,
    },
    question: {
      required: true,
      type: Object,
    },
    readOnly: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  mounted() {
    if (this.externalAnswer) {
      this.currentAnswer = parseInt(this.externalAnswer);
    }
    this.loaded = true;
  },
  methods: {
    setAnswer(answerNumber) {
      this.currentAnswer = answerNumber;
      this.$emit("setAnswer", true, answerNumber);
    },
  },
  data() {
    return {
      currentAnswer: null,
      loaded: false,
    };
  },
  components: { ChoiceAnswer },
};
</script>
