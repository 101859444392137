import programService from "./program.service";
import studentService from "./student.service";
import classService from "./class.service";
import answerService from "./answer.service";
import authenticationService from "./authentication.service";
import adminService from "./admin.service";
import personalizationService from "./personalization.service";
import schoolService from "./school.service";
import qualtricsService from "./qualtrics.service";
const services = {
  programService,
  studentService,
  classService,
  answerService,
  authenticationService,
  adminService,
  personalizationService,
  schoolService,
  qualtricsService,
  includes: (array, object) => {
    return array.indexOf(object) >= 0;
  },
  log: (text) => {
    if (["DEV", "STAGING"].indexOf(process.env.VUE_APP_ENVIRONMENT) >= 0) {
      console.log(text);
    }
  },
};

export default services;
