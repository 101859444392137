<template>
  <div
    :style="{
      '--n-columns': nCols,
      '--grid-template-rows': gridTemplateRows,
      '--grid-template-columns': gridTemplateColumns,
      '--background-color': backgroundColor,
      '--n-rows': nRows,
    }"
    class="dashboard-grid"
  >
    <slot> </slot>
  </div>
</template>
<script>
export default {
  name: "Grid",
  props: {
    nCols: {
      required: false,
      default: 2,
    },
    nRows: {
      required: false,
      default: 2,
    },
    gridTemplateRows: {
      required: false,
      default: null,
    },
    gridTemplateColumns: {
      required: false,
      default: null,
    },
    backgroundColor: {
      required: false,
      default: "transparent",
      type: String,
    },
  },
};
</script>
