<template>
  <!-- mixpanel on -->
  <div
    :id="questionId"
    class="rounded"
    :class="question.media == '' ? 'p-3 elevation-2' : ''"
    :style="'background: ' + background"
  >
    <custom-dialog
      v-model="correctDialog"
      extraClasses="bg--success color--success-80-s"
    >
      <div class="d-flex">
        <div class="d-flex flex-column text-subtitle ms-7">
          <div>¡Tu respuesta</div>
          <div>es correcta!</div>
        </div>
        <img src="@/assets/Phase/Good.svg" />
      </div>

      <common-button
        primaryClass="bg--success-80-s"
        class="mt-5"
        @click="correctDialog = false"
        text="Continuar"
      ></common-button>
    </custom-dialog>
    <custom-dialog
      v-model="incorrectDialog"
      extraClasses="bg--error color--error-80-s"
    >
      <div class="d-flex">
        <div class="d-flex flex-column text-subtitle ms-7 color--neutral-100-t">
          <div>Tu respuesta</div>
          <div>es incorrecta.</div>
        </div>
        <img src="@/assets/Phase/Bad.svg" />
      </div>

      <common-button
        primaryClass="bg--error-80-s"
        class="mt-5"
        @click="incorrectDialog = false"
        text="Intentarlo otra vez"
      ></common-button>
    </custom-dialog>
    <custom-text
      :text="
        $includes(question.format, 'specific')
          ? getQuestion
          : $includes(question.format, 'recommender-colombia')
          ? getQuestionCount
          : question.question
      "
      class="text-body color--primary-30-s mb-3"
      :style="'color: ' + fontColor"
    />

    <div
      :is="type"
      :question="question"
      @setAnswer="setAnswer"
      :externalAnswer="answer"
      :readOnly="readOnly"
      :activity="type == 'drop-down' ? activity : null"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Answer from "./Answer.vue";
import MultipleChoice from "./questions/MultipleChoice.vue";
import SingleChoice from "./questions/SingleChoice.vue";
import Slider from "./questions/Slider.vue";
import Matching from "./questions/Matching.vue";
import Open from "./questions/Open.vue";
import Graph from "./questions/Graph.vue";
import DropDown from "./questions/DropDown.vue";
import IntegerInput from "./questions/IntegerInput.vue";

export default {
  name: "Question",
  components: {
    IntegerInput,
    Answer,
    MultipleChoice,
    SingleChoice,
    Slider,
    Matching,
    Open,
    Graph,
    DropDown,
  },
  mounted() {
    if (this.$includes(this.question.format, "benefits")) {
      this.initBenefits();
    }
    if (this.$includes(this.question.format, "recommender")) {
      if (this.$includes(this.question.format, "recommender-colombia")) {
        this.initRecommenderColombia();
      } else {
        this.initRecommender();
      }
    }
    if (this.$includes(this.question.format, "simulator")) {
      this.initSimulator();
    }
  },
  data() {
    return {
      extraType: null,
      extraArea: null,
      replaceText: false,
      recommendedLoaded: false,
      correctDialog: false,
      incorrectDialog: false,
    };
  },
  methods: {
    getBaseQuestion() {
      return {
        btn_background: "",
        btn_color: "",
        btn_link: "",
        btn_text: "",
        media: "",
        question: this.question.id,
        unique: false,
        _max: "0",
        _min: "0",
      };
    },
    getBaseRecommenderAnswer(
      showIndex,
      index,
      elem,
      typeText,
      type,
      typeFirstYear,
      moneyFormatter
    ) {
      let text = `${index + 1}. ${elem.name}`;
      if (!showIndex) {
        text = elem.name;
      }
      return {
        ...this.getBaseAnswer(),
        text: text,
        number: index,
        extra_text:
          elem[typeFirstYear] == 0
            ? `${typeText[type]} no calculad${type == "salary" ? "o" : "a"}`
            : type == "employability"
            ? `${typeText[type]} de ${Math.round(elem[typeFirstYear], 2)}%`
            : `${typeText[type]} de $${moneyFormatter.format(
                elem[typeFirstYear]
              )}`,
      };
    },
    getBaseAnswer() {
      return {
        btn_background: "",
        btn_color: "",
        btn_link: "",
        btn_text: "",
        media: "",
        question: this.question.id,
        unique: false,
        _max: "0",
        _min: "0",
      };
    },
    initBenefits() {
      // first, get relevant answers
      this.question.answers = [];
      let p1 =
        "Si tuvieras que definir la situación económica de tu hogar, cuál de las siguientes frases la representa de mejor manera:";
      let p2 =
        "Si tuvieras que definir tu rendimiento académico, cuál de las siguientes frases la representa de mejor manera:";
      let area =
        "¿Cuál de las siguientes áreas de estudios es la que más te interesaría seguir?";
      let ies = "¿Cuál es el tipo de institución que más te interesa?";
      let p1_id = null;
      let p2_id = null;
      let area_id = null;
      let ies_id = null;
      let education = false;
      let university = true;
      this.program.phases.forEach((phase) => {
        phase.stages.forEach((stage) => {
          stage.activities.forEach((activity) => {
            activity.questions.forEach((question) => {
              if (question.question == p1) {
                p1_id = question.id;
              } else if (question.question == p2) {
                p2_id = question.id;
              } else if (question.question == area) {
                area_id = question.id;
              } else if (question.question == ies) {
                ies_id = question.id;
              }
            });
          });
        });
      });
      if (p1_id !== null) {
        p1 = parseInt(this.answers[p1_id]) + 1;
      }
      if (p2_id !== null) {
        p2 = parseInt(this.answers[p2_id]) + 1;
      }
      if (area_id !== null) {
        if (parseInt(this.answers[area_id]) == 6) {
          education = true;
        }
      }
      if (ies_id !== null) {
        ies = parseInt(this.answers[ies_id]); // cft, ip -> 0, 1 | universidad -> 2
        if (ies == 2) {
          university = true;
        } else {
          university = false;
        }
      }

      let gratuityAnswer = { ...this.getBaseQuestion(), text: "Gratuidad" };
      let scholarshipAnswer = { ...this.getBaseQuestion(), text: "Becas" };
      let creditsAnswer = { ...this.getBaseQuestion(), text: "Créditos" };

      let gratuityText =
        "Es un beneficio que está dirigido a las familias correspondientes al 60% de menores ingresos. Cubre el 100% del arancel y la matrícula en instituciones adscritas durante la duración oficial de la carrera.";
      if (p1 == 3 || p1 == 4 || p1 == 5) {
        gratuityText +=
          "\\n\\bold{Una buena parte de las personas que acceden a la educación superior estudian con gratuidad. Creemos que de acuerdo a tus respuestas… ¡Tú también puedes ser uno! }";
      }
      let scholarshipText =
        "Una beca es un apoyo económico que entrega el Estado para que puedas financiar tu carrera, cubriendo parte del arancel anual, y en algunos casos, la matrícula.";
      if (p1 > 1 && p2 < 3) {
        scholarshipText +=
          "\\n\\bold{Una buena parte de las personas que acceden a la educación superior estudian con becas. Creemos que de acuerdo a tus respuestas, tu podrías acceder a las siguientes becas:}";
        if (university) {
          scholarshipText +=
            "\\n• \\bold{Beca Bicentenario}: Financia el arancel de referencia anual de la carrera.";
        } else {
          scholarshipText +=
            "\\n• \\bold{Beca Nuevo Milenio}: Financia hasta $860.000 del arancel anual de la carrera.";
        }
        scholarshipText +=
          "\\n• \\bold{Beca Juan Gomez Milla}: Financia hasta $1.150.000 del arancel anual de la carrera.";
        if (university && education) {
          scholarshipText +=
            "\\n• \\bold{Beca Vocación de Profesor}: Financia la matrícula y la totalidad del arancel anual de la carrera.";
        }
        if (p2 == 1) {
          if (university) {
            scholarshipText +=
              "\\n• \\bold{Beca Excelencia Académica}: Financia hasta $1.150.000 del arancel anual de la carrera.";
          } else {
            scholarshipText +=
              "\\n• \\bold{Beca Excelencia Técnica}: Hasta $900.000 del arancel anual de la carrera.";
          }
          scholarshipText +=
            "\\n• \\bold{Beca Distinción a las Trayectorias Educativas}: Financia hasta $1.150.000 del arancel anual de la carrera.";
        }
      }
      let creditsText =
        "Los créditos estudiantiles te ayudan a cubrir parte del gasto asociado a tu carrera. Tendrás un plazo para pagarlo de entre 10 y 20 años, con pagos mensuales que comienzan a los 18 meses de egresar.\\n\\nActualmente existen dos créditos disponibles, el Fondo Solidario de Crédito Universitario y el Crédito con Garantía Estatal.";
      gratuityAnswer.extra_text = gratuityText;
      scholarshipAnswer.extra_text = scholarshipText;
      creditsAnswer.extra_text = creditsText;

      if (p1 >= 3) {
        // gratuity, scholarship, credits
        gratuityAnswer.number = 0;
        scholarshipAnswer.number = 1;
        creditsAnswer.number = 2;
        this.question.answers.push(gratuityAnswer);
        this.question.answers.push(scholarshipAnswer);
        this.question.answers.push(creditsAnswer);
      } else {
        // scholarships, credits, gratuity
        scholarshipAnswer.number = 0;
        creditsAnswer.number = 1;
        gratuityAnswer.number = 2;
        this.question.answers.push(scholarshipAnswer);
        this.question.answers.push(creditsAnswer);
        this.question.answers.push(gratuityAnswer);
      }
    },
    initRecommenderColombia() {
      var moneyFormatter = new Intl.NumberFormat();
      this.question.answers = [];
      let type = "";
      let typeFirstYear = "";
      if (this.$includes(this.question.question, "salario")) {
        type = "salary";
        typeFirstYear = "salary_first_year";
      }
      if (this.$includes(this.question.question, "empleabilidad")) {
        type = "employability";
        typeFirstYear = "employability_first_year";
      }
      let typeText = { salary: "Salario", employability: "Empleabilidad" };
      let extraParams = { order_by: `-${type}`, "x-tenant": this.tenant };
      // get all questions
      let t1;
      let t2;
      this.phase.stages.forEach((p) => {
        p.activities.forEach((a) => {
          a.questions.forEach((q) => {
            t1 = "¿Cuál es el tipo de institución que más te interesa?";
            t2 =
              "¿Cuál de las siguientes áreas de estudios es la que más te interesaría seguir?";
            if (q.question == t2) {
              this.extraArea = parseInt(this.answers[q.id]) + 1;
            }
            if (q.question == t1) {
              if (this.lang == "es-CO") {
                let answer = parseInt(this.answers[q.id]);
                this.extraType = { 0: 5, 1: 4, 2: 3 }[answer];
              } else {
                this.extraType = parseInt(this.answers[q.id]) + 1;
              }
            }
          });
        });
      });
      // by this point, we have the type and area.
      // first part: get random top 2 out of 10
      if (this.extraArea <= 10) {
        extraParams.area__code = this.extraArea;
      }
      if (this.extraType) {
        extraParams.type__id = this.extraType;
      }
      let endpoint = `egresa/recommender_colombia`;
      this.get({
        endpoint,
        params: extraParams,
      }).then((response) => {
        let answers = response.data.data.map((elem, index) => {
          return this.getBaseRecommenderAnswer(
            false,
            index,
            elem,
            typeText,
            type,
            typeFirstYear,
            moneyFormatter
          );
        });
        this.question.answers = answers;
        this.recommendedLoaded = true;
      });
    },
    initRecommender() {
      var moneyFormatter = new Intl.NumberFormat();
      this.question.answers = [];
      let type = "";
      let typeFirstYear = "";
      if (this.$includes(this.question.question, "salario")) {
        type = "salary";
        typeFirstYear = "salary_first_year";
      }
      if (this.$includes(this.question.question, "empleabilidad")) {
        type = "employability";
        typeFirstYear = "employability_first_year";
      }
      let typeText = { salary: "Salario", employability: "Empleabilidad" };
      let extraParams = "";
      // get all questions
      let t1;
      let t2;
      this.phase.stages.forEach((p) => {
        p.activities.forEach((a) => {
          a.questions.forEach((q) => {
            t1 = "¿Cuál es el tipo de institución que más te interesa?";
            t2 =
              "¿Cuál de las siguientes áreas de estudios es la que más te interesaría seguir?";
            if (q.question == t2) {
              this.extraArea = parseInt(this.answers[q.id]) + 1;
            }
            if (q.question == t1) {
              if (this.lang == "es-CO") {
                let answer = parseInt(this.answers[q.id]);
                this.extraType = { 0: 6, 1: 5, 2: 3 }[answer];
              } else {
                this.extraType = parseInt(this.answers[q.id]) + 1;
              }
            }
          });
        });
      });

      if (this.$includes(this.question.format, "specific")) {
        extraParams = `type__id=${this.extraType}&area__code=${this.extraArea}&`;
      }
      let endpoint = `egresa/career?${extraParams}order_by=-${type}&limit=5`;
      this.getWithHeaders({
        endpoint,
        params: { "x-tenant": this.tenant },
        headers: {},
      }).then((r) => {
        let newAnswers = r.data.map((elem, index) =>
          this.getBaseRecommenderAnswer(
            true,
            index,
            elem,
            typeText,
            type,
            typeFirstYear,
            moneyFormatter
          )
        );
        newAnswers = newAnswers.filter((x) => {
          return (
            x.extra_text != "Empleabilidad de 0%" &&
            x.extra_text != "Salario de $0"
          );
        });
        if (newAnswers.length == 0) {
          if (this.$includes(this.question.format, "specific")) {
            extraParams = `area__code=${this.extraArea}&`;
            endpoint = `egresa/career?${extraParams}order_by=-${type}&limit=5`;
            this.getWithHeaders({
              endpoint,
              params: { "x-tenant": this.tenant },
              headers: {},
            }).then((r) => {
              // second try
              let newAnswers = r.data.map((elem, index) =>
                this.getBaseRecommenderAnswer(
                  true,
                  index,
                  elem,
                  typeText,
                  type,
                  typeFirstYear,
                  moneyFormatter
                )
              );
              newAnswers = newAnswers.filter((x) => {
                return (
                  x.extra_text != "Empleabilidad de 0%" &&
                  x.extra_text != "Salario de $0"
                );
              });
              this.replaceText = true;
              this.question.answers = newAnswers;
              this.recommendedLoaded = true;
            });
          }
        } else {
          this.question.answers = newAnswers;
          this.recommendedLoaded = true;
        }
      });
    },
    ...mapActions({
      get: "admin/silentGet",
      getWithHeaders: "admin/silentGetWithHeaders",
      addCompletedQuestion: "program/addCompletedQuestion",
      addCompletedQuestionTeacher: "program/addCompletedQuestionTeacher",
      removeCompletedQuestion: "program/removeCompletedQuestion",
      postAnswer: "answer/postAnswer",
      track: "authentication/track",
    }),
    completeQuestion(answer) {
      //console.log("complete question");
      //let myDiv = document.getElementById(this.questionId);
      //console.log(myDiv)
      //myDiv.scrollIntoView({ behavior: 'smooth', block: 'start' });
      if (this.user.type == "student") {
        this.addCompletedQuestion({
          question: this.question,
          activity: this.activity,
          stage: this.stage,
          phase: this.phase,
          answer,
        });
        this.track({
          event: "phase-complete-question",
          data: { question: this.question.id },
        });
      } else {
        this.addCompletedQuestionTeacher({
          question: this.question,
          activity: this.activity,
          stage: this.stage,
          phase: this.phase,
        });
      }
    },
    setAnswer(answered, answer) {
      if (answered) {
        this.postAnswer({
          body: {
            question: this.question.id,
            answer: answer,
          },
        })
          .then((response) => {
            if (this.question.expected) {
              if (response.status == 200) {
                this.completeQuestion(answer);
                this.correctDialog = true;
                // right answer or doesn't matter
              } else {
                // wrong answer
                this.incorrectDialog = true;
              }
            } else {
              this.completeQuestion(answer);
            }
          })
          .catch(() => {
            if (this.question.expected) {
              this.incorrectDialog = true;
            }
          });
        this.track({
          event: "phase-answer-question",
          data: { question: this.question.id, answer: answer },
        });
      } else {
        this.removeCompletedQuestion({
          question: this.question,
          activity: this.activity,
          stage: this.stage,
        });
      }
    },
  },
  props: {
    question: {
      required: true,
      type: Object,
    },
    activity: {
      required: true,
      type: Object,
    },
    stage: {
      required: true,
      type: Object,
    },
    readOnly: {
      required: false,
      default: false,
      type: Boolean,
    },
    questionId: {
      required: false,
    },
  },
  computed: {
    tenant() {
      if (this.lang == "es-CL") {
        return "Chile";
      }
      return "Colombia";
    },
    getQuestionCount() {
      if (this.recommendedLoaded) {
        let newQuestion = this.question.question;
        newQuestion = newQuestion.replace("5", this.question.answers.length);
        return newQuestion;
      }

      return "";
    },
    getQuestion() {
      if (this.recommendedLoaded) {
        let newQuestion = this.question.question;
        newQuestion = newQuestion.replace(
          /(\\typename)/g,
          {
            1: "técnicas",
            2: "técnicas",
            3: "profesionales",
          }[this.extraType]
        );
        newQuestion = newQuestion.replace(
          /(\\areaname)/g,
          [
            "Administración y Comercio",
            "Agropecuaria",
            "Arte y Arquitectura",
            "Ciencias Básicas",
            "Ciencias Sociales",
            "Derecho",
            "Educación",
            "Humanidades",
            "Salud",
            "Tecnología",
          ][this.extraArea - 1]
        );
        if (this.replaceText) {
          newQuestion = newQuestion.replace("técnicas ", "");
          newQuestion = newQuestion.replace("profesionales ", "");
        }
        newQuestion = newQuestion.replace("5", this.question.answers.length);
        return newQuestion;
      }

      return "";
    },
    answer() {
      return this.answers[this.question.id] ?? null;
    },
    ...mapGetters({
      lang: "layout/lang",
      answers: "answer/answers",
      completedQuestions: "program/completedQuestions",
      phase: "program/currentPhase",
      program: "program/program",
      user: "authentication/user",
    }),
    type() {
      return {
        MC: "single-choice",
        OP: "open",
        SL: "slider",
        GR: "graph",
        MA: "matching",
        MS: "multiple-choice",
        II: "integer-input",
        DD: "drop-down",
        IN: "graph",
      }[this.question.type];
    },
    background() {
      if (this.question.media != "") {
        return "transparent";
      }
      if (this.question.format == "inversed-background")
        return "#6778FB !important";
      else return "#FFF8FB";
    },
    fontColor() {
      if (this.question.format == "inversed-background")
        return "white !important";
      else return "";
    },
  },
};
</script>
