<template>
  <div>
    <img
      v-if="question.media != ''"
      :src="require('@/assets/' + question.media)"
      class="w-100"
    />
  </div>
</template>
<script>
export default {
  name: "Matching",
  mounted() {
    if (!this.readOnly) {
      this.$emit("setAnswer", true, true);
    }
  },
  props: {
    readOnly: {
      required: false,
      default: false,
      type: Boolean,
    },
    externalAnswer: {
      required: false,
    },
    question: {
      required: true,
      type: Object,
    },
  },
};
</script>
