var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-stepper',{staticStyle:{"background-color":"transparent !important"},style:({
    '--primary-color': _vm.primaryColor,
    '--secondary-color': _vm.secondaryColor,
    '--border-color': _vm.borderColor,
    '--theme-purple:': 'red !important',
  }),attrs:{"alt-labels":"","elevation":"0"}},[_c('v-stepper-header',[_vm._l((_vm.total),function(n){return [_c('v-stepper-step',{key:(n + "-step"),staticClass:"relative",class:_vm.current >= n ? 'step-clickable' : '',staticStyle:{"z-index":"99"},attrs:{"color":"deep-purple","complete":_vm.current >= n,"step":"","complete-icon":"mdi-check"},on:{"click":function($event){return _vm.clickNode(n)}}}),(n !== _vm.total)?_c('v-divider',{key:n,staticStyle:{"opacity":"100%","margin-top":"11px"},style:({
          background:
            _vm.current >= n ? 'var(--secondary-color)' : 'var(--border-color)',
        })}):_vm._e()]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }