var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative",staticStyle:{"background":"white"},attrs:{"id":"map"}},[_c('v-dialog',{staticStyle:{"z-index":"1002"},attrs:{"id":"symbology","width":"600px"},on:{"click:outside":function($event){_vm.showInformation = false}},model:{value:(_vm.showInformation),callback:function ($$v) {_vm.showInformation=$$v},expression:"showInformation"}},[_c('v-card',{staticStyle:{"font-family":"Montserrat !important"}},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"d-flex"},[_c('div',{staticClass:"me-auto"},[_vm._v("Leyenda")]),_c('v-icon',{staticClass:"ms-auto",on:{"click":function($event){_vm.showInformation = false}}},[_vm._v("mdi-close")])],1)],1),_vm._l(([0, 1, 2]),function(i,index){return _c('v-row',{key:index,staticClass:"text-left"},[_c('v-col',[_c('div',{staticClass:"pt-1 ps-2"},[_c('img',{staticClass:"me-2",staticStyle:{"height":"17px","width":"24px"},attrs:{"src":require(("@/assets/maps/marker_group_" + (['big', 'medium', 'small'][index]) + ".svg"))}}),_vm._v(" "+_vm._s([ "Alta concentración", "Media concentración", "Baja concentración" ][index])+" ")])])],1)}),_c('v-divider'),_vm._l(([0, 1, 2, 3]),function(i,index){return _c('v-row',{key:index,staticClass:"text-left"},[_c('v-col',[_c('div',{staticClass:"pt-1 ps-2"},[_c('v-icon',{style:({
                  color: ['#00405B', '#EB5757', '#F2C94C', '#27AE60'][index],
                })},[_vm._v("mdi-circle")]),_vm._v(" "+_vm._s(_vm.filterNames[index])+" ")],1)])],1)})],2)],1)],1),(_vm.showDialog)?_c('v-dialog',{staticStyle:{"z-index":"1002"},attrs:{"id":"studentInfo","width":"600px"},on:{"click:outside":_vm.closeDialog},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',{staticClass:"text-left p-3",staticStyle:{"background":"white","line-height":"30px","font-family":"Montserrat !important","color":"#00405B !important"}},[_c('div',{staticClass:"w-100 d-flex"},[_c('h1',{staticClass:"bold"},[_vm._v(" Información ")]),_c('v-icon',{staticClass:"ms-auto mb-auto",attrs:{"color":"#00405B"},on:{"click":_vm.closeDialog}},[_vm._v("mdi-close")])],1),_c('h4',[_vm._v(_vm._s(_vm.studentInfo.name))]),(_vm.studentInfo.age)?_c('h6',{staticClass:"bold"},[_vm._v("Edad: "+_vm._s(_vm.studentInfo.age))]):_vm._e(),(_vm.studentInfo.last_school)?_c('div',[_vm._v(" Última institución educativa: "+_vm._s(_vm.studentInfo.last_school)+" ")]):_vm._e(),(_vm.studentInfo.phone)?_c('div',[_vm._v(" Número telefónico: "+_vm._s(_vm.studentInfo.phone)+" ")]):_vm._e(),_c('div',{staticClass:"d-flex"},[_c('v-icon',{style:({
            color: ['#00405B', '#EB5757', '#F2C94C', '#27AE60'][
              _vm.studentInfo.state
            ],
          })},[_vm._v("mdi-circle")]),_c('div',[_vm._v(_vm._s(_vm.filterNames[_vm.studentInfo.state]))])],1),_c('v-radio-group',{attrs:{"row":""},on:{"change":_vm.changeState},model:{value:(_vm.studentInfo.state),callback:function ($$v) {_vm.$set(_vm.studentInfo, "state", $$v)},expression:"studentInfo.state"}},_vm._l(([0, 1, 2]),function(n){return _c('v-radio',{key:n,staticStyle:{"color":"#00405B !important"},attrs:{"label":_vm.filterNames[n],"value":n}})}),1),_c('div',{staticClass:"w-100 d-flex vertical-align-middle mb-2"},[_c('img',{staticClass:"mt-1 me-2",staticStyle:{"height":"24px"},attrs:{"src":require("@/assets/maps/marker_blue.svg")}}),_c('div',{staticClass:"bold",staticStyle:{"color":"blue","cursor":"pointer","margin-top":"2px"},on:{"click":_vm.changeAddress}},[_vm._v(" Modificar ubicación en mapa ")])]),_c('map-input',{attrs:{"question":"Dirección","label":""},on:{"setValue":function($event){
          var i = arguments.length, argsArray = Array(i);
          while ( i-- ) argsArray[i] = arguments[i];
return _vm.setValue.apply(void 0, argsArray.concat( ['address'] ))}},model:{value:(_vm.studentInfo.address),callback:function ($$v) {_vm.$set(_vm.studentInfo, "address", $$v)},expression:"studentInfo.address"}}),_c('map-button',{staticClass:"text-center mt-4 p-4 w-100",attrs:{"label":"Ir a google maps"},on:{"click":function($event){return _vm.openGoogleMaps(_vm.studentInfo.lat, _vm.studentInfo.lng)}}},[_vm._v(" s")]),_c('map-button',{staticClass:"text-center mt-4 p-4 w-100",attrs:{"label":"Ir a google forms","rounded":""},on:{"click":function($event){return _vm.openGoogleForms()}}})],1)],1):_vm._e(),_c('v-container',{staticStyle:{"width":"100vw"},style:({ 'min-height': _vm.mobile ? '' : '64px' }),attrs:{"id":"navbar"}},[_c('div',{staticClass:"d-flex mt-auto mb-auto vertical-align-middle flex-row",style:({ height: _vm.mobile ? '40px !important' : '50px !important' })},[_c('div',{staticClass:"d-flex flex-column text-center vertical-align-middle align-items-center",style:({ height: _vm.mobile ? '40px !important' : '50px !important' })},[_c('v-img',{staticClass:"me-2",attrs:{"contain":"","src":require("@/assets/maps/logo.svg")}})],1),(!_vm.mobile)?_c('map-selector',{attrs:{"items":_vm.municipalities,"item-text":"name","item-value":"position","label":"Municipio"},model:{value:(_vm.municipality),callback:function ($$v) {_vm.municipality=$$v},expression:"municipality"}}):_vm._e(),_c('map-autocomplete',{style:({
          transform: _vm.mobile ? 'scale(0.8)' : '',
          'transform-origin': _vm.mobile ? 'center center' : '',
        }),attrs:{"items":_vm.students,"label":"Buscar por nombre"},on:{"input":_vm.selectStudent}}),_c('div',{staticClass:"ms-auto rounded d-flex justify-content-center text-center",staticStyle:{"height":"48px","width":"48px !important","max-width":"48px !important","background":"#00405B"},attrs:{"width":"48px","background":"red","flat":"","elevation":"0"}},[_c('v-icon',{staticClass:"ms-1",attrs:{"color":"white"},on:{"click":_vm.performLogout}},[_vm._v("mdi-logout")])],1)],1)]),(_vm.isChangingAddress)?_c('div',{staticClass:"absolute rounded",staticStyle:{"left":"10px","right":"10px","top":"90px","z-index":"3"},attrs:{"id":"changeAddressInformation"}},[_c('v-card',{staticClass:"w-100 text-center",attrs:{"background":"white"}},[_c('div',{staticClass:"d-flex p-2 text-center w-100"},[_vm._v(" Arrastra el pin a la ubicación deseada. Puedes cancelar la modificación presionando \"Cancelar\" o guardar la modificación presionando \"Aceptar\". ")])])],1):_vm._e(),(!_vm.isChangingAddress)?_c('div',{staticClass:"absolute",staticStyle:{"top":"calc(100vh - 50px)","right":"20px","z-index":"1001"},attrs:{"id":"information"}},[_c('v-btn',{attrs:{"fab":"","small":""},on:{"click":function($event){_vm.showInformation = true}}},[_c('v-icon',[_vm._v("mdi-help-circle")])],1)],1):_vm._e(),_c('div',{staticClass:"d-flex flex-column absolute",staticStyle:{"background":"green"},style:({
      top: _vm.mobile ? '70px !important' : '90px !important',
      left: _vm.mobile ? '5px !important' : '30px !important',
    })},[(_vm.mobile)?_c('map-selector',{staticStyle:{"min-height":"42px"},style:({
        transform: _vm.mobile ? 'scale(0.8)' : '',
        'transform-origin': _vm.mobile ? 'top left' : '',
      }),attrs:{"items":_vm.municipalities,"item-text":"name","item-value":"position","label":"Municipio"},model:{value:(_vm.municipality),callback:function ($$v) {_vm.municipality=$$v},expression:"municipality"}}):_vm._e(),(!_vm.isChangingAddress && !_vm.showDialog)?_c('div',{staticStyle:{"z-index":"1000"},style:({
        transform: _vm.mobile ? 'scale(0.8)' : '',
        'transform-origin': _vm.mobile ? 'top left' : '',
        'max-width': _vm.mobile ? '100px' : '',
      }),attrs:{"id":"filter-button"}},[_c('v-card',{staticStyle:{"color":"white","background":"#00405B"},on:{"click":function($event){_vm.showFilters = !_vm.showFilters}}},[_c('div',{staticClass:"p-2"},[_vm._v("Filtro "),_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-tune")])],1)])],1):_vm._e(),(_vm.showFilters)?_c('div',{staticStyle:{"z-index":"1000"},style:({
        transform: _vm.mobile ? 'scale(0.8)' : '',
        'transform-origin': _vm.mobile ? 'top left' : '',
      }),attrs:{"id":"filters"}},[_c('v-card',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}]},[_c('v-container',_vm._l((_vm.filters),function(i,index){return _c('v-row',{key:index},[_c('v-col',{staticClass:"d-flex flex-row text-left"},[_c('div',{staticClass:"pt-1 ps-2 me-2",staticStyle:{"width":"150px"}},[_c('v-icon',{style:({
                    color: ['#00405B', '#EB5757', '#F2C94C', '#27AE60'][
                      index
                    ],
                  })},[_vm._v("mdi-circle")]),_vm._v(" "+_vm._s(_vm.filterNames[index])+" ")],1),_c('label',{staticClass:"switch"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filters[index]),expression:"filters[index]"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.filters[index])?_vm._i(_vm.filters[index],null)>-1:(_vm.filters[index])},on:{"change":[function($event){var $$a=_vm.filters[index],$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.filters, index, $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.filters, index, $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.filters, index, $$c)}},function($event){
                  var i = arguments.length, argsArray = Array(i);
                  while ( i-- ) argsArray[i] = arguments[i];
return _vm.change.apply(void 0, argsArray.concat( [index] ))}]}}),_c('span',{staticClass:"slider round"})])])],1)}),1)],1)],1):_vm._e()],1),_c('GmapMap',{ref:"map",staticStyle:{"width":"100vw","height":"calc(100vh - 70px)"},attrs:{"center":_vm.center,"zoom":_vm.zoom,"options":{
      disableDefaultUI: true,
      // clickableIcons: false,
      mapTypeControl: false,
      styles: _vm.mapStyle,
      fullscreenControl: false,
      streetViewControl: false,
      // gestureHandling: 'greedy',
    }}},[(_vm.showMarkers)?_c('gmap-cluster',{attrs:{"styles":_vm.clusterStyles,"max-zoom":16,"minimumClusterSize":5,"zoomOnClick":true}},_vm._l((_vm.shownMarkers),function(m,index){return _c('gmap-marker',{key:index,attrs:{"icon":_vm.getIcon(m.state),"position":{ lat: m.lat, lng: m.lng },"clickable":true,"draggable":_vm.isChangingAddress},on:{"drag":_vm.dragMarker,"click":function($event){return _vm.clickStudent(m)}}})}),1):_vm._e()],1),(_vm.isChangingAddress)?_c('div',{staticClass:"absolute",staticStyle:{"bottom":"20px","right":"20px"}},[_c('v-card',{attrs:{"background":"white"}},[_c('div',{staticClass:"d-flex p-2"},[_c('map-button',{staticClass:"me-2",attrs:{"inverted":"","label":"Cancelar"},on:{"click":function($event){return _vm.setAddress(false)}}}),_c('map-button',{attrs:{"label":"Aceptar"},on:{"click":function($event){return _vm.setAddress(true)}}})],1)])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }