<template>
  <div class="bg--primary-70-t mb-3 p-3  mb-2 " style="border-radius:10px">
    <div class="text-left ">
      <custom-text :text="text" class="color--primary-40-s"></custom-text>
    </div>
    <Slider
      @setAnswer="setValue"
      class="m-2"
      externalAnswer=""
      :question="{ answers: [{ _min: 1, _max: 10 }] }"
    />
  </div>
</template>
<script>
import Slider from "@/components/phase/questions/Slider.vue";
export default {
  name: " SimulatorSlider",
  data() {
    return {
      value: "",
    };
  },
  components: {
    Slider,
  },
  props: {
    text: {
      required: false,
      default: "",
    },
  },
  methods: {
    // eslint-disable-next-line
    setValue(a, b) {
      this.computedValue = b;
    },
  },
  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
};
</script>
