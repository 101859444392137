<template>
  <!-- mixpanel on -->
  <v-navigation-drawer
    mini-variant-width="97"
    :value="drawer"
    v-model="computedValue"
    :mini-variant="mini"
    :absolute="mobile"
    floating
    :permanent="!mobile"
    :temporary="mobile"
    :class="mobile ? 'w-100' : 'd-flex flex-column h-100 relative'"
    style="border: 1px solid #DFE3EC;"
    :style="mobile ? 'z-index:1050' : 'border-radius:6px'"
  >
    <v-list-item
      :class="
        mobile
          ? 'text-center w-100 align-items-start pt-2'
          : 'text-center w-100 align-items-start pt-1'
      "
      style="height:173px"
    >
      <div v-if="mobile" class="w-100 d-flex">
        <v-img
          src="@/assets/Dashboard/Logo.svg"
          class="mt-4 ms-1 me-2"
          style="width:137px;min-width:137px;max-width:137px"
          contain
        />
        <v-icon
          v-if="mobile"
          class="ms-auto mb-auto mt-2 color--neutral"
          @click="computedValue = false"
          >mdi-close</v-icon
        >
      </div>
      <div v-else class="w-100">
        <v-img
          v-if="!mini"
          src="@/assets/Dashboard/Logo.svg"
          class="mt-4 ms-1 me-2"
          contain
        />
        <v-img
          src="@/assets/Home/DFM.svg"
          v-show="mini"
          class="mt-4 ms-3 mb-5 me-3"
          contain
        />
      </div>
    </v-list-item>

    <v-list dense>
      <v-list-item-group class="w-100" v-model="currentPanelButton" mandatory>
        <v-list-item
          :disabled="itemDisabled(item)"
          :class="mobile ? '' : 'mt-2 mb-2'"
          v-for="(item, index) in computedPanelButtons"
          :key="index"
          link
          active-class="color--neutral"
          @click="clickButton(item)"
        >
          <v-list-item-icon
            active-class="bg--neutral-80-t"
            :class="mini ? 'ms-0 me-0' : 'me-2'"
          >
            <v-icon
              :class="
                itemDisabled(item) ? 'color--neutral-80-t' : 'color--neutral'
              "
              >{{ item.icon }}</v-icon
            >
          </v-list-item-icon>

          <v-list-item-content class="text-left">
            <v-list-item-title
              class="text-label"
              :class="
                index === currentPanelButton
                  ? 'bold color--neutral'
                  : itemDisabled(item)
                  ? ''
                  : 'color--neutral'
              "
              >{{ item.name }}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>

    <v-list dense class="w-100">
      <v-list-item
        @click.native="clickButton(item)"
        v-for="item in computedPanelActions"
        :key="item.name"
        link
      >
        <v-list-item-icon>
          <v-icon>{{
            item.minimizedIcon && mini ? item.minimizedIcon : item.icon
          }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content class="text-left">
          <v-list-item-title class="text-label">{{
            item.name
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "DashboardButtonPanel.vue",
  components: {
    // DashboardButton,
  },
  data() {
    return {
      drawer: true,
      mini: false,

      currentPanelButton: 0,
      panelActions: [
        {
          name: this.$t("dashboard.navigation-drawer.actions.size-toggle"),
          tag: "size-toggle",
          icon: "mdi-arrow-left-circle-outline",
          minimizedIcon: "mdi-arrow-right-circle-outline",
          hideMobile: true,
        },
        {
          name: this.$t("dashboard.navigation-drawer.actions.logout"),
          tag: "logout",
          icon: "mdi-logout",
        },
        {
          name: this.$t("dashboard.navigation-drawer.actions.website"),
          tag: "website",
          icon: "mdi-attachment",
        },
      ],
      panelButtons: [
        {
          name: this.$t("dashboard.navigation-drawer.buttons.panel"),
          tag: "panel",
          icon: "mdi-home",
          roles: ["student", "teacher", "manager", "principal"],
        },
        {
          name: this.$t("dashboard.navigation-drawer.buttons.progress"),
          tag: "progress",
          icon: "mdi-chart-box",
          needsClasses: true,
          roles: ["teacher", "manager", "principal"],
        },
        // todo: change
        {
          name: this.$t("dashboard.navigation-drawer.buttons.students"),
          tag: "students",
          icon: "mdi-face-man",
          needsClasses: true,
          roles: ["teacher", "manager", "principal"],
        },
        {
          name: this.$t("dashboard.navigation-drawer.buttons.planning"),
          tag: "planning",
          icon: "mdi-note",
          needsClasses: true,
          roles: ["teacher", "manager", "principal", "admin"],
        },
        {
          name: this.$t("dashboard.navigation-drawer.buttons.lectionary"),
          tag: "lectionary",
          icon: "mdi-book-open-variant",
          needsClasses: true,
          roles: ["teacher"],
        },
        {
          name: this.$t("dashboard.navigation-drawer.buttons.schools"),
          tag: "schools",
          icon: "mdi-school",
          roles: ["admin"],
        },
        {
          name: this.$t("dashboard.navigation-drawer.buttons.resources"),
          tag: "resources",
          icon: "mdi-puzzle",
          needsClasses: true,
          roles: ["student", "teacher", "manager", "principal"],
        },
        {
          name: this.$t("dashboard.navigation-drawer.buttons.tutorial"),
          tag: "tutorial",
          icon: "mdi-magnify",
          needsClasses: true,
          roles: ["student", "teacher", "manager", "principal"],
        },
        {
          name: this.$t("dashboard.navigation-drawer.buttons.profile"),
          tag: "profile",
          icon: "mdi-account",
          roles: ["student", "admin"],
        },
        {
          name: "Soporte",
          tag: "admin-support",
          icon: "mdi-account",
          roles: ["admin"],
        },
        /*{
          name: "Estudiantes",
          tag: "students",
          icon: "mdi-account-school",
          needsClasses: true,
          roles: ["teacher", "manager", "principal", "admin"],
        },*/
        {
          name: this.$t("dashboard.navigation-drawer.buttons.help"),
          tag: "help",
          icon: "mdi-help-box",

          roles: ["student", "teacher", "manager", "principal"],
        },

        {
          name: this.$t(
            "dashboard.navigation-drawer.buttons.admin-program-table"
          ),
          tag: "admin-program-table",
          icon: "mdi-table-large",
          roles: ["admin"],
        },

        {
          name: this.$t("dashboard.navigation-drawer.buttons.admin-features"),
          tag: "admin-features",
          icon: "mdi-sitemap",
          roles: ["admin"],
        },
        {
          name: this.$t("dashboard.navigation-drawer.buttons.admin-flow"),
          tag: "admin-flow",
          icon: "mdi-graph-outline",
          roles: ["admin"],
        },
        {
          name: this.$t("dashboard.navigation-drawer.buttons.admin-progress"),
          tag: "admin-progress",
          icon: "mdi-chart-line",
          roles: ["admin"],
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      track: "authentication/track",
      logout: "authentication/logout",
      setDashboardSection: "layout/setDashboardSection",
    }),
    itemDisabled(item) {
      return (
        item.needsClasses &&
        this.user.type != "admin" &&
        this.classes.length === 0
      );
    },
    clickButton(button) {
      const tag = button.tag;
      this.track({ event: "dashboard-click-button", data: { button: tag } });
      if (tag == "size-toggle") {
        this.mini = !this.mini;
      } else if (tag === "website") {
        this.$router.push({ name: "home" });
      } else if (tag === "logout") {
        this.logout();
      } else {
        this.track({ event: "dashboard-goto-section", data: { section: tag } });
        this.setDashboardSection({ dashboardSection: tag });
        this.$emit("setPanel", button);
      }
    },
  },
  watch: {
    currentPanel(newPanel) {
      let index = 0;
      for (var i = 0; i < this.computedPanelButtons.length; i++) {
        let panel = this.computedPanelButtons[i];
        if (panel.tag == newPanel.tag) {
          this.currentPanelButton = index;
        }
      }
      /*const index = this.computedPanelButtons.findIndex(
        (p) => p.tag === newPanel.tag
      );
      this.currentPanelButton = index;*/
    },
  },
  computed: {
    computedPanelButtons() {
      let buttons = this.panelButtons.filter((x) =>
        this.$includes(x.roles, this.user.type)
      );
      /*if (this.lang == "es-CO") {
        buttons = buttons.filter((x) => x.tag != "planning");
      }*/
      return buttons;
    },
    computedPanelActions() {
      return this.panelActions.filter(
        (x) => x.hideMobile == undefined || (x.hideMobile && !this.mobile)
      );
    },
    ...mapGetters({
      classes: "_class/classes",
      mobile: "layout/isMobile",
      user: "authentication/user",
      lang: "layout/lang",
    }),
    computedValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
  props: {
    value: {
      required: true,
    },
    minimized: {
      required: true,
      type: Boolean,
    },
    currentPanel: {
      required: true,
      type: Object,
    },
  },
};
</script>
