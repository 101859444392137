<template>
  <!-- mixpanel on -->
  <div class="p-2">
    <v-select
      :items="items"
      class="dashboard-student-input"
      solo
      :label="placeholder"
      flat
      hide-details
      v-model="computedValue"
    ></v-select>
  </div>
</template>
<script>
export default {
  name: "DashboardStudentsSelect",
  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
  props: {
    items: {
      required: true,
      type: Array,
    },
    value: {
      required: true,
    },
    placeholder: {
      required: false,
      default: "",
    },
  },
};
</script>
<style>
.dashboard-student-input .v-input__slot {
  background: transparent !important;
  border: 1px solid var(--primary);
  border-style: dashed;
}

.v-input--is-focused {
  background: white !important;
}
</style>
