<template>
  <v-stepper
    alt-labels
    style="background-color:transparent !important;"
    :style="{
      '--primary-color': primaryColor,
      '--secondary-color': secondaryColor,
      '--border-color': borderColor,
      '--theme-purple:': 'red !important',
    }"
    elevation="0"
  >
    <v-stepper-header>
      <template v-for="n in total">
        <v-stepper-step
          class="relative"
          style="z-index:99;"
          color="deep-purple"
          :key="`${n}-step`"
          :class="current >= n ? 'step-clickable' : ''"
          :complete="current >= n"
          step=""
          complete-icon="mdi-check"
          @click="clickNode(n)"
        >
        </v-stepper-step>

        <v-divider
          style="opacity:100%;margin-top:11px"
          :style="{
            background:
              current >= n ? 'var(--secondary-color)' : 'var(--border-color)',
          }"
          v-if="n !== total"
          :key="n"
        ></v-divider>
      </template>
    </v-stepper-header>
  </v-stepper>
</template>
<script>
export default {
  name: " StepProgressBar",
  methods: {
    clickNode(node) {
      if (node <= this.current) {
        this.$emit("clickNode", node);
      }
    },
  },
  props: {
    primaryColor: {
      required: false,
      default: "white",
    },
    secondaryColor: {
      required: false,
      default: "#5669FB",
    },
    borderColor: {
      required: false,
      default: "#CBD0DC",
    },
    current: {
      required: true,
      type: Number,
    },
    total: {
      required: true,
      type: Number,
    },
  },
};
</script>
<style>
.v-stepper__step__step {
  background: var(--primary-color) !important;
  border: 1px solid var(--border-color);
}
.v-stepper__step {
  padding: 0 !important;
  width: 25px !important;
  max-width: 25px !important;
}
.v-stepper__header {
  margin-bottom: 0px !important;
  height: 25px !important;
  max-height: 25px !important;
}
.v-stepper__step__step.primary {
  margin-bottom: 0px !important;
  background-color: red !important;
  border-color: red !important;
}

.v-stepper__step__step.deep-purple > i.mdi {
  transform: scale(0.6);
}
.v-stepper__step__step.primary > .v-icon {
  height: 12px !important;
  width: 12px !important;
  font-size: 12px !important;
}
.step-clickable:hover {
  filter: brightness(150%);
  transition: 100ms ease-in-out;
  cursor: pointer;
}
.step-clickable:hover:active {
  filter: brightness(100%);
  transition: 100ms ease-in-out;
}
</style>
