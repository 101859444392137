<template>
  <v-autocomplete
    :items="items"
    hide-details
    no-data-text="Su búsqueda no produjo resultados."
    v-model="computedValue"
    class="map-autocomplete"
    @input="$emit('input')"
    color="rgba(0, 64, 91, 0.5)"
    solo
    flat
    style="max-width:220px;max-height:30px;z-index:1001"
  >
    <template slot="label">
      <div style="color:#00405b;opacity:0.5">
        <v-icon class="me-1" color="#00405b" style="opacity:0.5"
          >mdi-magnify</v-icon
        >{{ label }}
      </div>
    </template>
  </v-autocomplete>
</template>
<script>
export default {
  name: "MapAutocomplete",
  props: {
    value: {
      required: true,
    },
    label: {
      required: true,
    },
    items: {
      required: true,
    },
  },
  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
};
</script>
<style>
.map-autocomplete > .v-input__control {
  border: 2px solid #00405b !important;
  color: rgba(0, 64, 91, 0.5) !important;
}
</style>
