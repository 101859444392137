<template>
  <v-dialog v-model="computedValue" v-bind="$attrs">
    <v-card>
      <slot></slot>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "CommonPopUp",
  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
  props: {
    value: {
      required: true,
    },
  },
};
</script>
