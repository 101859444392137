<template>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <v-icon
        v-on="on"
        slot="activator"
        small
        class="mr-2"
        @click="$emit('click')"
      >
        mdi-{{ icon }}
      </v-icon>
    </template>
    <span>{{ tooltipText }}</span>
  </v-tooltip>
</template>
<script>
export default {
  name: "CustomIcon",
  props: {
    tooltipText: {
      required: false,
      default: "",
      type: String,
    },
    icon: {
      required: true,
      type: String,
    },
  },
};
</script>
