import api from "../api";
export default {
  /**
   * Gets the data about a program at a specific level.
   * @param program_id
   * @param id
   * @returns {Promise<AxiosResponse<any>>}
   */
  getStudents({ params }) {
    if (params) {
      return api.get("student", { params });
    } else {
      return api.get("student/?_class__has_dfm=1");
    }
  },
  getStudentsFromClass(classId) {
    return api.get(`student/?_class__id=${classId}`);
  },
  patchStudents(students, _class) {
    return api.patch("student/1/", {
      students: students,
      class: _class,
    });
  },
  uploadStudents(studentFile) {
    var formData = new FormData();
    formData.append("xlsx", studentFile);
    return api.post("student/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  deleteStudents() {
    return api.delete("student/1/");
  },
};
