<template>
  <!-- mixpanel on -->
  <div>
    <custom-dialog
      v-model="showDialog"
      @close="showDialog = false"
      :width="600"
    >
      <div class="d-flex flex-column text-left">
        <div class="color--neutral mb-2 bold">
          Para: {{ student.user.first_name }} {{ student.user.last_name }}
        </div>
        <v-textarea
          class="elevation-3"
          v-model="message"
          solo
          flat
          hide-details
        ></v-textarea>
        <div class="d-flex flex-row w-100 mt-2">
          <common-button
            class="ms-auto"
            text="Enviar"
            @click="sendMessage(student.user.id)"
          />
        </div>
      </div>
    </custom-dialog>
    <div class="w-100 d-flex flex-row elevation-3 bg--neutral-90-t p-3">
      <div>
        <div
          class="bg--neutral-100-t p-2 clickable me-4"
          style="border-radius:5px"
          @click="showDialog = true"
        >
          <v-icon class=" color--secondary">mdi-email-outline</v-icon>
        </div>
      </div>
      <div>
        <div class="text-body bold">
          {{ student.user.first_name }} {{ student.user.last_name }}
        </div>
        <div class="text-label">{{ getDate(student.user.last_login) }}</div>
        <div class="text-label clickable color--secondary bold">
          <div @click="toggleProgress">
            {{
              showProgress
                ? $t(
                    "dashboard.main-panel.individual-progress.student-progress.hide"
                  )
                : $t(
                    "dashboard.main-panel.individual-progress.student-progress.show"
                  )
            }}
            {{
              $t(
                "dashboard.main-panel.individual-progress.student-progress.detail"
              )
            }}
            <v-icon class="color--secondary">{{
              showProgress ? "mdi-chevron-up" : "mdi-chevron-down"
            }}</v-icon>
          </div>
        </div>
      </div>
      <div class="ms-auto">
        <div>
          <v-progress-circular
            class="color--primary"
            :rotate="270"
            :size="mobile ? 56 : 86"
            :width="mobile ? 10 : 15"
            :value="progress"
            >{{ progress }}%
          </v-progress-circular>
        </div>
      </div>
    </div>
    <div v-if="showProgress" style="border-radius: 0px 0px 5px 5px">
      <div v-for="(_phase, index) in program.phases" :key="_phase">
        <v-expansion-panels>
          <v-expansion-panel
            :disabled="!_phase.available"
            v-model="studentPhasePanels[_phase.id]"
          >
            <v-expansion-panel-header
              :class="
                _phase.available
                  ? 'color--neutral-20-t bold bg--secondary-80-t p-2'
                  : 'color--neutral-20-t bg--neutral-80-t p-2'
              "
            >
              {{
                $t(
                  "dashboard.main-panel.individual-progress.student-progress.activities.activity-phase"
                )
              }}
              {{ _phase.number + 1 }}
              {{ _phase.available ? "" : "(No disponible)" }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <!-- error here -->
              <div
                v-for="n in student.total[_phase.id]"
                :key="n"
                class="p-2 d-flex w-100"
                :class="index % 2 == 0 ? '' : 'bg--neutral-100-t'"
              >
                <div>
                  {{
                    $t(
                      "dashboard.main-panel.individual-progress.student-progress.activities.activity"
                    )
                  }}
                  {{ n }}
                </div>

                <div
                  v-if="n <= getStudentPhaseTotal(_phase.id)"
                  class="color--success bold ms-auto me-auto"
                  v-t="
                    $t(
                      'dashboard.main-panel.individual-progress.student-progress.activities.complete'
                    )
                  "
                />
                <div
                  v-else
                  class="color--error bold ms-auto me-auto"
                  v-t="
                    $t(
                      'dashboard.main-panel.individual-progress.student-progress.activities.incomplete'
                    )
                  "
                />
                <!--<div>
                  <v-icon
                    v-if="n <= studentTotal"
                    class="color--primary clickable"
                    >mdi-message-reply-text-outline</v-icon
                  >
                </div>-->
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  /**
   * Upon being clicked, should show % progress per phase.
   *  when phase expanded, should show Completed(related='stage').
   */
  name: "DashboardStudentProgress",

  computed: {
    progress() {
      let totalCompleted = this.student.progress.filter(
        (c) => c.related == "phase"
      ).length;
      let total = this.program.phases.length;
      let percentage = (totalCompleted * 100) / total;
      return Math.round(Math.min(percentage, 100));
    },
    studentTotal() {
      return this.student.progress.filter((completed) => {
        return completed.related_parent_id == this.phase.id;
      }).length;
    },
    ...mapGetters({
      mobile: "layout/isMobile",
      phase: "program/currentPhase",
      program: "program/program",
    }),
    sortedStages() {
      return [...this.currentPhase.stages].sort(
        ({ number: a }, { number: b }) => a - b
      );
    },
  },
  data() {
    return {
      studentPhasePanels: new Object(),
      showProgress: false,
      showDialog: false,
      message: "",
      completedStages: new Set(),
    };
  },
  mounted() {
    this.program.phases.forEach((phase) => {
      this.studentPhasePanels[phase] = null;
    });
  },
  methods: {
    ...mapActions({
      track: "authentication/track",
      postFeedback: "authentication/postFeedback",
    }),
    toggleProgress() {
      this.showProgress = !this.showProgress;
      this.track({
        event: "dashboard-student-progress-toggle",
        data: {},
      });
    },
    sendMessage(studentId) {
      this.track({
        event: "dashboard-send-feedback",
        data: { receiver: studentId, type: "general" },
      });
      this.postFeedback({
        feedback: {
          header: "Tu profesor te envió el siguiente mensaje:",
          message: this.message,
          receiver: studentId,
        },
      }).then(() => {
        this.message = "";
        this.showDialog = false;
      });
    },
    getDate(originalDate) {
      if (originalDate) {
        var date = new Date(originalDate);
        var formatOptions = {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        };
        var dateString = date.toLocaleDateString("en-UK", formatOptions);
        dateString =
          this.$t(
            "dashboard.main-panel.individual-progress.student-progress.last-login"
          ) +
          dateString
            .replace(",", "")
            .replace("PM", "p.m.")
            .replace("AM", "a.m.");

        return dateString;
      }
      return this.$t(
        "dashboard.main-panel.individual-progress.student-progress.no-login"
      );
    },
    getStudentPhaseTotal(phaseId) {
      return this.student.progress.filter((completed) => {
        return (
          completed.related_parent_id == phaseId && completed.related == "stage"
        );
      }).length;
    },
  },
  props: {
    student: {
      required: true,
      type: Object,
    },
    currentPhase: {
      required: true,
      type: Object,
    },
  },
};
</script>
