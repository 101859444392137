import services from "../services";

/* Useful store elements platform-wise. */
/* Sets the initial state of the app. Useful for startup and logout. */
const getDefaultState = () => ({
  personalizedPhases: Object(),
});

const state = {
  ...getDefaultState(),
};

const getters = {
  personalizedPhases: ({ personalizedPhases }) => personalizedPhases,
};

const mutations = {
  setPersonalizedStages(state, { stages, phaseId }) {
    state.personalizedPhases[phaseId] = stages;
  },
  addPersonalizedStage(state, { stage, phaseId }) {
    state.personalizedPhases[phaseId].push(stage);
  },
};

const actions = {
  reset({ state }) {
    Object.assign(state, getDefaultState());
  },
  //getClassProgress({ commit }, { phaseId, classId }) {
  /* given a specific class, get all their progress. */
  // ,
  getStudentPersonalizedStages({ commit }, { phaseId, student }) {
    /* given a specific student, get their perzonalized stages of this phase */

    services.personalizationService
      .getPersonalizedStages({ phase: phaseId, student })
      .then((response) => {
        commit("setPersonalizedStages", { stages: response.data, phaseId });
      });
  },
  getCurrentPersonalizedStages({ commit }, { phaseId }) {
    /* given my user, get my current stages of this phase */

    services.personalizationService
      .getPersonalizedStages({ phase: phaseId, student: null })
      .then((response) => {
        commit("setPersonalizedStages", { stages: response.data, phaseId });
      });
  },
  getNextPersonalizedStage({ commit }, { stage }) {
    /* given my current stage, get next personalized stage */
    const phaseId = stage.phase;
    services.personalizationService
      .getNextStage({ stageId: stage.id })
      .then((response) => {
        commit("addPerzonalizedStage", { stage: response.data, phaseId });
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
