import i18n from "@/i18n";
/* Useful store elements platform-wise. */
/* Sets the initial state of the app. Useful for startup and logout. */
const getDefaultState = () => ({
  dashboardSection: null,
  screenWidth: 0,
  toasts: [],
  success: null,
  showInterface: true,
  lang: "es-CO",
});

const state = {
  ...getDefaultState(),
};

const getters = {
  showInterface: ({ showInterface }) => showInterface,
  dashboardSection: ({ dashboardSection }) => dashboardSection,
  isMobile: ({ screenWidth }) => screenWidth <= 768,
  lang: ({ lang }) => lang,
  country: ({ lang }) => {
    return {
      "es-CO": "colombia",
      "es-CL": "chile",
      "es-PE": "peru",
      "es-RD": "dominicana",
    }[lang];
  },
};

const mutations = {
  setInterface(state, { userInterface }) {
    state.showInterface = userInterface;
  },

  setDashboardSection(state, { dashboardSection }) {
    state.dashboardSection = dashboardSection;
  },
  // mobile - desktop checker
  updateScreenWidth(state, { screenWidth }) {
    state.screenWidth = screenWidth;
  },
  updateScreenHeight(state, { screenHeight }) {
    state.screenHeight = screenHeight;
  },
  addToast(state, { toastState, text }) {
    state.success = toastState;
    state.toasts.push(text);
  },
  setLang(state, { lang }) {
    console.log("setting lang", lang);
    localStorage.setItem("lang", lang);
    localStorage.setItem(
      "country",
      {
        "es-CO": "colombia",
        "es-CL": "chile",
        "es-PE": "peru",
        "es-RD": "dominicana",
      }[lang]
    );
    i18n.locale = lang;
    state.lang = lang;
  },
};

const actions = {
  reset({ state }) {
    Object.assign(state, getDefaultState());
  },
  setInterface({ commit }, { userInterface }) {
    commit("setInterface", { userInterface });
  },

  setDashboardSection({ commit }, { dashboardSection }) {
    commit("setDashboardSection", { dashboardSection });
  },
  updateScreenWidth({ commit }, { screenWidth }) {
    commit("updateScreenWidth", { screenWidth });
  },
  updateScreenHeight({ commit }, { screenHeight }) {
    commit("updateScreenHeight", { screenHeight });
  },
  addToast({ commit }, { toastState, text }) {
    commit("addToast", { toastState, text });
  },
  getLang({ commit }) {
    // country name to 2 letter code map
    const countryToCode = {
      chile: "CL",
      colombia: "CO",
      peru: "PE",
      dominicana: "RD",
    };
    const countryToLang = {
      chile: "es-CL",
      colombia: "es-CO",
      peru: "es-PE",
      dominicana: "es-RD",
    };
    // if language is stored
    let lang = localStorage.getItem("lang");
    for (var i = 0; i < localStorage.length; i++) {
      let val = localStorage.getItem(localStorage.key(i));
      if (["chile", "colombia", "peru", "dominicana"].indexOf(val) >= 0) {
        lang = countryToLang[val];
      }
    }
    if (lang) {
      commit("setLang", { lang });
    } else {
      // first, check if we have country in domain
      // example: colombia.decidiendofuturomejor.com
      const host = window.location.host;
      let subdomain = host.split(".")[0];
      if (subdomain === "www") {
        subdomain = host.split(".")[1];
      }
      if (
        subdomain != "decidiendofuturomejor" &&
        !(subdomain.indexOf("localhost") >= 0) &&
        !(subdomain.indexOf("staging") >= 0) &&
        !(subdomain.indexOf("develop") >= 0)
      ) {
        // if we have it in subdomain
        commit("setLang", { lang: `es-${countryToCode[subdomain]}` });
      } else {
        // otherwise, try getting it from date.

        let country = new Date()
          .toString()
          .split("(")[1]
          .replace()
          .split(" ")[0]
          .toLowerCase();
        // test available countries
        /*let country2 = new Date()
          .toString()
          .split("(")[1]
          .replace(")", "")
          .split(" ");
        country2 = country2[country2.length - 1];*/

        // if outside of available countries, set default to chile.
        if (!["colombia", "chile"].indexOf(country) >= 0) {
          country = "colombia";
        }
        commit("setLang", { lang: `es-${countryToCode[country]}` });
      }
    }
  },
  setLang({ commit }, { lang }) {
    commit("setLang", { lang });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
