<template>
  <div class="contact-form-form">
    <div
      v-html="
        $t('contact-form.contact-form.declaration', {
          form_name: formName,
          form_school: formSchool,
          form_position: formPosition,
        })
      "
    />

    <b-form-group>
      <b-form-radio-group
        id="radio-group-2"
        v-model="consent"
        @input="sendToParent(...arguments, consent, 'consent')"
        name="radio-options-2"
      >
        <div style="width:10px;height:10px"></div>
        <b-form-radio class="mt-1 mb-1" :value="true">{{
          $t("contact-form.contact-form.accept")
        }}</b-form-radio>
        <div style="width:10px;height:10px"></div>
        <b-form-radio class="mt-1 mb-1" :value="false"
          >{{ $t("contact-form.contact-form.deny") }}
        </b-form-radio>
        <div style="width:10px;height:10px"></div
      ></b-form-radio-group>
    </b-form-group>
  </div>
</template>
<script>
export default {
  name: "Consent",
  data() {
    return {
      consent: null,
    };
  },
  props: {
    formName: {
      type: String,
    },
    formSchool: {
      type: String,
    },
    formPosition: {
      type: String,
    },
    externalConsent: {
      type: Boolean,
    },
  },
  mounted() {
    if (this.externalConsent != true || this.externalConsent != false) {
      this.consent = this.externalConsent;
    }
  },
  methods: {
    sendToParent(args, value, variable) {
      this.$emit("updateVariable", variable, value);
    },
  },
};
</script>
