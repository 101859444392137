<template>
  <div
    :style="{
      transform: transformation,
      width: `${computedWidth}px`,
      height: `${computedHeight}px`,
    }"
    style="transform-origin:center center;width:43px;height:53px"
  >
    <svg
      :width="computedWidth"
      :height="computedHeight"
      viewBox="0 0 43 53"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M43 53L0 0V53H43Z" />
    </svg>
  </div>
</template>
<script>
export default {
  name: "Triangle",
  props: {
    width: {
      required: false,
      default: 43,
      type: Number,
    },
    height: {
      required: false,
      default: 53,
      type: Number,
    },
    left: {
      required: false,
      default: false,
      type: Boolean,
    },
    right: {
      required: false,
      default: true,
      type: Boolean,
    },
    top: {
      required: false,
      default: false,
      type: Boolean,
    },
    bottom: {
      required: false,
      default: false,
      type: Boolean,
    },
    mirrored: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  computed: {
    transformation() {
      let transformation = "";
      if (this.bottom) {
        transformation = "rotate(90deg)";
      } else if (this.left) {
        transformation = "rotate(180deg)";
      } else if (this.top) {
        transformation = "rotate(270deg)";
      } else {
        transformation = "rotate(0)";
      }
      if (this.mirrored) {
        transformation = `${transformation} scaleX(-1) translateX(-100%) translate(100%, 0%)`;
      }
      return transformation;
    },
    computedHeight() {
      if (this.height != 53) {
        return this.height;
      }
      return (this.width * 53) / 43;
    },
    computedWidth() {
      if (this.width != 43) {
        return this.width;
      }
      return (this.height * 43) / 53;
    },
  },
};
</script>
