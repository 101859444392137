<template>
  <v-select
    :label="label"
    :items="items"
    :item-text="itemText"
    :item-value="itemValue"
    dark
    v-model="computedValue"
    background-color="#00405B"
    style="max-width:200px;max-height:30px;z-index:1001;"
    class="me-2 map-selector"
    hide-details
    solo
    flat
  >
  </v-select>
</template>
<script>
export default {
  name: "MapSelector",
  props: {
    label: {
      required: true,
    },
    value: {
      required: true,
    },
    items: {
      required: true,
    },
    itemText: {
      required: false,
    },
    itemValue: {
      required: false,
    },
  },
  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
};
</script>
<style></style>
