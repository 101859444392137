<template>
  <div class="bg--neutral-100-t vw-100 h-100 relative" style="background:white">
    <div class="d-flex w-100">
      <div class="pt-10 ms-10 mb-10 text-left">
        <img
          src="@/assets/Dashboard/Logo.svg"
          :style="mobile ? 'height:40px' : ''"
          class="clickable"
          @click="$router.push({ name: 'home' })"
        />
      </div>
      <div class="pt-10 me-10 ms-auto" v-if="country != 'chile'">
        <common-button
          class="ms-auto relative"
          border-radius="6px"
          style="background:#F073D4;z-index:100"
          @click="$router.push({ name: 'apply' })"
          :text="country == 'colombia' ? 'Súmate' : 'Postula a DFM'"
        ></common-button>
      </div>
    </div>

    <responsive-div
      mobileStyle="padding-left:10%;padding-right:10%"
      desktopStyle="padding-left:20%;padding-right:20%"
      desktopClass="pb-15"
      class="text-left color--neutral-20-t relative bg--neutral-100-t"
    >
      <!-- icons -->
      <img
        class="absolute"
        src="@/assets/Home/semicircle-left-blue.svg"
        :style="
          mobile
            ? 'top:-100px;right:-5px;width:24px;height:33px'
            : 'top:0;right:0;'
        "
      />
      <img
        class="absolute"
        src="@/assets/Home/semicircle-left-blue.svg"
        :style="
          mobile
            ? 'top:-100px;right:19px;width:24px;height:33px'
            : 'top:0;right:62px;'
        "
      />
      <img
        class="absolute"
        src="@/assets/Home/square_pink.svg"
        :style="
          mobile
            ? 'top:100px;left:calc(20% - 106px);width:50px;height:50px'
            : 'top:100px;left:calc(20% - 106px);width:75px;height:75px'
        "
      />
      <img
        class="absolute"
        v-if="!mobile"
        src="@/assets/Home/square_pink.svg"
        style="top:175px;left:calc(20% - 138px);width:75px;height:32px;object-fit:cover"
      />
      <img
        class="absolute"
        v-if="!mobile"
        src="@/assets/Home/triangle_left.svg"
        style="bottom:-30px;left:25%;object-fit:cover"
      />
      <responsive-div mobileClass="text-center">
        <span class="bold" :class="mobile ? 'text-title' : 'text-heading'">{{
          $t("content.title")
        }}</span>
        <br />
        <span :class="mobile ? 'text-title' : 'text-heading'">{{
          $t("content.subtitle")
        }}</span>
      </responsive-div>
      <v-divider v-if="mobile" class="bg--primary"></v-divider>
      <div class="d-flex mt-5" :class="mobile ? 'flex-column' : ''">
        <p
          :class="mobile ? '' : 'w-50'"
          v-for="(paragraph, paragraphIndex) in $t('content.paragraphs')"
          :key="paragraphIndex"
        >
          {{ paragraph }}
        </p>
      </div>
    </responsive-div>

    <responsive-div
      style="padding-left:10%;padding-right:10%;"
      mobileStyle="padding-bottom:160px"
      desktopStyle="padding-bottom:400px"
      class="bg--neutral-100-t"
      desktopClass="mt-10 pt-10"
    >
      <v-divider
        v-if="mobile"
        style="margin-top:0px"
        class="color--primary"
      ></v-divider>
      <responsive-div
        class="bold mb-10"
        mobileClass="text-title text-left"
        desktopClass="text-heading"
      >
        {{ $t("content.phases-title") }}
      </responsive-div>
      <responsive-div class="d-flex" mobileClass="flex-column">
        <div
          v-for="(phase, index) in $t('content.phases')"
          :key="index"
          class="text-left ms-5 mr-5"
          style="flex:1"
        >
          <v-avatar
            :class="[
              `color--neutral-100-t bg--primary-${1 + index * 2}0-s`,
              mobile ? 'text-title' : 'text-heading',
            ]"
            :size="mobile ? 41 : 92"
          >
            {{ index + 1 }}
          </v-avatar>

          <p :class="mobile ? 'mt-2' : 'mt-5'">
            <span
              :class="
                `text-medium color--neutral-100-t highlight--primary-${1 +
                  index * 2}0-s`
              "
              >{{ phase.title }}</span
            >
          </p>
          <p class="text-body">{{ phase.description }}</p>
        </div>
      </responsive-div>
    </responsive-div>
    <div
      class="relative d-flex justify-content-center align-items-center"
      style="height:0"
    >
      <div class="absolute">
        <img
          style="transform:translate(-50%, 0);"
          :style="
            mobile
              ? 'width: 116px !important;z-index:1003;left:90px;top:-40px;border-radius:13px'
              : 'width: 240px !important;z-index:1003;left:400px;top:-270px;border-radius:25px'
          "
          src="https://videoapi-muybridge.vimeocdn.com/animated-thumbnails/image/a0deb14e-8a94-4583-b657-e42cf2f069c5.gif?ClientID=vimeo-core-prod&Date=1657142707&Signature=03c29b3262e3eb3e45445e712ba82e8a74a788b6"
          class="absolute"
        />
        <!--  -->
        <img
          style="transform: translate(-50%, 0);"
          :style="
            mobile
              ? 'width: 331px !important;top:-160px;'
              : 'width: 691px !important;top:-360px;'
          "
          src="https://videoapi-muybridge.vimeocdn.com/animated-thumbnails/image/0aa37e7b-b252-4887-9280-0038221b8842.gif?ClientID=vimeo-core-prod&Date=1657142611&Signature=521f81b8d4eeeb4068cfa4b73ee0fdfafe70e593"
          class="absolute"
        />
      </div>
    </div>
    <responsive-div
      class="bg--primary-60-s pb-15"
      mobileStyle="padding-left:10%;padding-right:10%;padding-top:210px"
      desktopStyle="padding-left:20%;padding-right:20%;padding-top:300px"
    >
      <responsive-div
        class="color--secondary-10-t mb-10"
        mobileClass="text-left text-title"
        desktopClass="text-heading"
        mobileStyle="marging-right:20%"
      >
        ¡Revisa lo que tenemos preparado para tu comunidad educativa!
      </responsive-div>
      <responsive-div class="d-flex" style="gap:20px" mobileClass="flex-column">
        <div style="flex: 1 1 0">
          <div
            class="d-flex"
            v-for="(benefit, index) in $t('content.benefitsLeft')"
            :key="index"
          >
            <div class="me-5">
              <img
                style="width:9px"
                src="@/assets/Home/semicircle-right-green.svg"
              />
            </div>
            <div class="text-left">
              <span class="color--secondary-10-t">{{ benefit.title }}</span>
              <p class="color--neutral-100-t">{{ benefit.description }}</p>
            </div>
          </div>
        </div>
        <div style="flex: 1 1 0">
          <div
            class="d-flex"
            v-for="(benefit, index) in $t('content.benefitsRight')"
            :key="index"
          >
            <div class="me-5">
              <img
                style="width:9px"
                src="@/assets/Home/semicircle-right-green.svg"
              />
            </div>
            <div class="text-left">
              <span class="color--secondary-10-t">{{ benefit.title }}</span>
              <p class="color--neutral-100-t">{{ benefit.description }}</p>
            </div>
          </div>
        </div>
      </responsive-div>
    </responsive-div>
    <div>
      <Socials />
    </div>
  </div>
</template>
<script>
import Socials from "@/components/layout/Socials.vue";
import { mapGetters } from "vuex";
export default {
  name: "Details",
  components: { Socials },
  computed: {
    ...mapGetters({
      mobile: "layout/isMobile",
    }),
    country() {
      return this.$store.state.authentication.country;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
