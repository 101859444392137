<template>
  <v-textarea
    outlined
    dense
    v-model="computedValue"
    v-bind="$attrs"
    no-resize
  ></v-textarea>
</template>
<script>
export default {
  name: "CommonTextArea",
  data() {
    return {
      innerProps: {},
    };
  },

  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
  props: {
    value: {
      required: true,
    },
  },
};
</script>
