<template>
  <!-- mixpanel on -->
  <div class="p-2">
    <v-text-field
      :class="error ? 'bg--error' : ''"
      class="dashboard-student-input"
      solo
      flat
      hide-details="auto"
      v-model="computedValue"
      :rules="rules"
      :placeholder="placeholder"
    ></v-text-field>
  </div>
</template>
<script>
export default {
  name: "DashboardStudentsInput",
  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
  props: {
    rules: {
      required: false,
      default: [],
    },
    error: {
      required: false,
      default: false,
      type: Boolean,
    },
    value: {
      required: true,
    },
    placeholder: {
      required: false,
      default: "",
    },
  },
};
</script>
<style>
.dashboard-student-input .v-input__slot {
  background: transparent !important;
  border: 1px solid var(--primary);
  border-style: dashed;
}

.v-input--is-focused {
  background: white !important;
}
</style>
