<template>
  <div class="flex w-full  items-center justify-center text-center">
    <custom-dialog
      width="80%"
      extraClasses="text-left text-label"
      v-model="showErrorDialog"
      @close="showErrorDialog = false"
    >
      Existen los siguientes errores:
      <div style="max-height:60vh;height:60vh;overflow:auto;">
        <div
          v-for="(error, index) in errors"
          :key="index"
          v-html="error"
          style="background:#CCD2FE;border-radius:4px;margin:2px;"
        />
      </div>

      <common-button
        class="mb-3"
        text="OK"
        @click="showErrorDialog = false"
      ></common-button>
    </custom-dialog>
    <div
      class="p-12 bg-gray-100"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
    >
      <input
        type="file"
        name="fields[assetsFieldHandle][]"
        id="assetsFieldHandle"
        class="w-px h-px opacity-0 overflow-hidden absolute"
        style="max-width:150px"
        @change="onChange"
        ref="file"
        accept=".csv, .xlsx"
      />

      <label for="assetsFieldHandle" class="block cursor-pointer">
        <div class="d-flex flex-column">
          <common-button
            :text="text"
            :disabled="disabled"
            prependIcon="mdi-upload"
            @click.native="$refs.file.click()"
          />
        </div>
      </label>
    </div>
  </div>
</template>
<script>
import readXlsxFile from "read-excel-file";
export default {
  name: "CustomFileUpload",
  data() {
    return {
      filelist: [],
      file: null,
      showDialog: false,
      showErrorDialog: false,
      errors: [],
    };
  },
  props: {
    disabled: {
      required: false,
      default: false,
      type: Boolean,
    },
    text: {
      required: false,
      default: "Subir archivo",
      type: String,
    },
  },
  methods: {
    csvJSON(csv) {
      /* given a csv file content, returns json array */
      var lines = csv.split("\n");
      var result = [];
      var headers = lines[0].split(",");
      for (var i = 1; i < lines.length; i++) {
        var obj = {};
        var currentline = lines[i].split(",");

        for (var j = 0; j < headers.length; j++) {
          obj[headers[j]] = currentline[j];
        }
        result.push(obj);
      }
      return result;
    },
    transformToJsonArray(array) {
      /* given array of arrays, transforms to json array */

      let columns = array.shift();
      return array.map((subarray) => {
        let object = Object();
        subarray.forEach((value, index) => {
          object[columns[index]] = value;
        });
        return object;
      });
    },
    validateJsonArray(jsonArray) {
      /* given a json array, validates row per row  */
      this.errors = [];
      let showErrors = false;
      jsonArray.forEach((row, index) => {
        let result = new Promise((resolve) =>
          this.$emit("validateRow", row, resolve)
        );
        result.then((res) => {
          if (res !== true) {
            res = `Errores línea ${index + 1}:<br/>${res}`;
            this.errors.push(res);
            showErrors = true;
          }
          // upon last index, check
          if (index == jsonArray.length - 1) {
            if (!showErrors) {
              this.$emit("upload", jsonArray);
            } else {
              this.showErrorDialog = true;
            }
          }
        });
      });

      this.$refs.file.value = null;
      this.filelist = [];
    },
    onChange() {
      // get file
      this.filelist = [...this.$refs.file.files];
      let file = this.filelist[0];
      // get extension
      let extension = file.name.split(".").pop();
      // if csv, read csv and validate each row
      if (extension == "csv") {
        var reader = new FileReader();
        reader.readAsText(file, "UTF-8");
        reader.onload = (evt) => {
          let jsonArray = this.csvJSON(evt.target.result);

          this.validateJsonArray(jsonArray);
        };
        // if excel, read excel and validate each row
      } else {
        readXlsxFile(file)
          .then((rows) => {
            let jsonArray = this.transformToJsonArray(rows);
            this.validateJsonArray(jsonArray);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    emptyString(string) {
      if (string === null) {
        return true;
      }
      if (string.replace(/\s/g, "").length > 0) {
        return false;
      }
      return true;
    },
    dv(T) {
      var M = 0,
        S = 1;
      for (; T; T = Math.floor(T / 10))
        S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
      return S ? S - 1 : "k";
    },
    validEmail(email) {
      if (email === null) {
        return false;
      }
      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (email.match(validRegex)) {
        return true;
      } else {
        return false;
      }
    },
    validRUT(rutCompleto) {
      if (rutCompleto === null) {
        return false;
      }
      if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)) return false;
      var tmp = rutCompleto.split("-");
      var digv = tmp[1];
      var rut = tmp[0];
      if (digv == "K") digv = "k";
      return this.dv(rut) == digv;
    },
    uploadFile() {
      this.showDialog = false;
      this.$emit("fileUploaded", this.filelist[0]);
    },
    remove(i) {
      this.filelist.splice(i, 1);
    },
    dragover(event) {
      event.preventDefault();
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange();
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
  },
};
</script>
