var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"bg--neutral-100-t relative",staticStyle:{"top":"45px","margin-bottom":"45px"}},[_c('v-row',{staticClass:" ml-0 white--text text-left",class:_vm.isMobile ? 'bg--primary' : 'title vh-100',staticStyle:{"display":"flex","align-items":"flex-end","padding":"5rem 5%"}},[_c('p',{class:_vm.isMobile ? 'w-100 text-title' : 'w-75 text-heading'},[_vm._v(" DFM al ser un programa digital, flexible y personalizado, ha logrado adaptarse a las necesidades particulares de cada estudiante y al currículum nacional de cada país. ")])]),_vm._l(([
			{
				country: 'CHILE',
				title:
					'Durante los últimos dos años más de 900 establecimientos educacionales han sido parte de los pilotos que hemos implementado junto al Ministerio de Educación.',
				body:
					'Los resultados preliminares sugieren que los estudiantes ajustan sus expectativas sobre los beneficios asociados a la educación, aumentan su conocimiento sobre el funcionamiento del Sistema de Acceso a la educación superior y sobre los beneficios estudiantiles que ofrece el Estado para ayudar a financiar los costos asociados a los programas de educación superior.',
				imageUrl: 'row1.png',
			},
			{
				country: 'COLOMBIA',
				title:
					'En 2022, 150 instituciones educativas del Valle del Cauca, mayoritariamente ubicadas en zonas rurales, participaron del primer piloto de DFM en Colombia. ',
				body:
					'Gracias a esta oportunidad, más de 50 mil estudiantes, de 6° a 11° grado, tuvieron la oportunidad de acceder a contenido dinámico y personalizado sobre la importancia de la educación para construir su proyecto de vida. A partir de estos resultados y la buena recepción de las comunidades educativas, para el año 2023 se busca escalar DFM a lo largo del país.',
				imageUrl: 'row2.png',
			},
			{
				country: 'REPÚBLICA DOMINICANA',
				title: "En República Dominicana, 'Aprendiendo el Valor de la Educación' (AVE) se desarrolló en colaboración con el Ministerio de Educación a partir del año 2014. En los dos primeros años, se alcanzaron 200.000 estudiantes en 1.594 escuelas del país (el 26% de las escuelas públicas del país).",
				body:
					'En 2021, 11 mil estudiantes, de 7° básico a IIIº medio, y más de 600 profesores de 101 establecimientos educacionales a lo largo del país cursaron el programa piloto. A partir de estos resultados, para el año 2022 se busca escalar DFM a lo largo del país.Los resultados preliminares del programa dan cuenta de una reducción en las cifras de abandono escolar en 2,5-3 puntos porcentuales y un aumento en los resultados de las pruebas nacionales. A partir de estos prometedores resultados, desde 2017, el Ministerio de Educación lleva a cabo AVE alcanzando a más 862 mil estudiantes en más de 5.600 escuelas.',
				imageUrl: 'row3.png',
			} ]),function(row,index){return _c('v-row',{key:index,staticClass:" ml-0 pl-0 pr-0 relative text-left",staticStyle:{"height":"max-content!important"}},[(_vm.isMobile || index % 2 != 0)?_c('v-col',{staticStyle:{"padding":"5% 10% 2% 5%","height":"max-content!important"},attrs:{"cols":_vm.isMobile ? 12 : 6}},[_c('p',{staticClass:"h-25 bold mb-10",staticStyle:{"letter-spacing":"3px"}},[_vm._v(" "+_vm._s(row.country)+" ")]),_c('p',{staticClass:"text-title mb-10 color--neutral-30-t"},[_vm._v(_vm._s(row.title))]),_c('p',{staticClass:"text-body mb-10 color--neutral-50-t"},[_vm._v(_vm._s(row.body))])]):_vm._e(),_c('v-col',{staticStyle:{"padding":"0"},attrs:{"cols":_vm.isMobile ? 12 : 6}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require('@/assets/Home/Countries/' + row.imageUrl),"alt":""}})]),(!_vm.isMobile && index % 2 == 0)?_c('v-col',{staticStyle:{"padding":"5% 10% 2% 5%","height":"max-content!important"},attrs:{"cols":_vm.isMobile ? 12 : 6}},[_c('p',{staticClass:"h-25 bold",staticStyle:{"letter-spacing":"3px"}},[_vm._v(_vm._s(row.country))]),_c('p',{staticClass:"text-title color--neutral-30-t"},[_vm._v(_vm._s(row.title))]),_c('p',{staticClass:"text-body color--neutral-50-t"},[_vm._v(_vm._s(row.body))])]):_vm._e()],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }