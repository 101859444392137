<template>
	<div
		style="width:100% !important;margin-bottom:10px;z-index:99"
		class="dashboard-card"
	>
		<div style="display: flex; align-items: center;width:100%;">
			<b-container class="p-0 m-0" :style="{ 'margin-top': marginTop }">
				<b-row>
					<b-col>
						<div class="text-center" v-if="filterable">
							<b-dropdown-text
								block
								style="width:100%;height:44px;background:white"
								class="relative"
							>
								<b-spinner
									v-if="loading"
									style="position:absolute;right:15px;top:8px;height:24px;width:24px;"
								></b-spinner>
								<b-form-input
									:style="{ 'font-size': fontsize ? fontsize : 'auto' }"
									style="margin-left:-25px !important;margin-top:-4px;height:44px !important"
									ref="input"
									placeholder="Escribe el nombre de tu establecimiento..."
									v-model="value"
									@input="setValue"
									@keyup.native="() => debounce(300)"
								>
								</b-form-input>
							</b-dropdown-text>
							<div
								v-if="searchedValue !== ''"
								style="background:white;margin-top:10px;z-index:201; position: relative"
								:style="
									mobile
										? 'max-height:100px;overflow-y:auto;overflow-x:hidden'
										: 'max-height:150px;overflow-y:auto;overflow-x:hidden'
								"
							>
								<!-- check -->
								<b-dropdown-text
									v-if="filteredOptions.length == 0"
									:style="{
										'font-size': fontsize ? fontsize : 'auto',
										'padding-top': '17px !important',
									}"
									style="text-align:left;"
								>
									{{
										showSearchingMessage || loading
											? 'Buscando establecimiento...'
											: 'Colegio no encontrado.'
									}}
								</b-dropdown-text>
								<!-- end check -->

								<div
									ref="filterabledropdown"
									v-for="(answer, index) in filteredOptions"
									:key="index"
								>
									<b-dropdown-text
										:style="{
											'font-size': fontsize ? fontsize : 'auto',
											'padding-top': '17px !important',
										}"
										style="text-align:left;"
										@click="sendData(answer, answer[label])"
										>{{ answer[label] }}
									</b-dropdown-text>
									<b-dropdown-divider
										v-if="index != filteredOptions.length - 1"
									/>
								</div>
							</div>
						</div>
						<div class="text-center" style="height:39px;" v-else>
							<b-dropdown
								ref="nonfilterabledropdown"
								no-caret
								:text="dropdownText"
								block
								:style="cssVars"
								class="m-2 my-class"
								content-class="dropdown-toggle"
							>
								<template v-slot:button-content>
									<span
										:style="{ 'font-size': fontsize ? fontsize : 'auto' }"
										>{{ dropdownText }}</span
									>
								</template>
								<div
									style="background-color:white;margin-left:4px;"
									:style="
										mobile
											? 'max-height:200px;overflow-y:auto;overflow-x:hidden'
											: 'max-height:300px;overflow-y:auto;overflow-x:hidden'
									"
								>
									<div v-for="(answer, index) in filteredOptions" :key="index">
										<b-dropdown-text
											style="text-align:left"
											:style="{ 'font-size': fontsize ? fontsize : 'auto' }"
											@click="sendData(answer, answer[label])"
											>{{ answer[label] }}
										</b-dropdown-text>
										<b-dropdown-divider
											v-if="index != filteredOptions.length - 1"
										/>
									</div>
								</div>
							</b-dropdown>
						</div>
					</b-col>
				</b-row>
			</b-container>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	export default {
		name: 'DropDown',
		props: {
			showSearch: {
				required: false,
				default: false,
			},
			marginTop: {
				required: false,
				default: 0,
			},
			fontsize: {
				required: false,
			},
			loading: {
				required: false,
				default: false,
			},
			options: {
				required: true,
			},
			label: {
				required: true,
			},
			initialValue: {
				required: false,
				default: '',
			},
			filterable: {
				required: false,
				default: true,
			},
		},
		data() {
			return {
				showSearchingMessage: false,
				timeout: null,
				answer: '',
				pickedValue: false,
				firstSearch: false,
				searchedValue: '',
				dropdownText: 'Seleccionar',
				isLoaded: false,
				value: '',
				cssVars: {
					'--color': 'white',
				},
			};
		},
		components: {},
		computed: {
			...mapGetters({
				mobile: 'layout/isMobile',
			}),
			filteredOptions() {
				if (!this.filterable) {
					return this.options;
				}
				if (this.value.length > 0) {
					return this.options
						.filter((answer) =>
							this.$includes(
								this.normalize(answer[this.label]),
								this.normalize(this.value)
							)
						)
						.slice(0, 20);
				}
				return [];
			},
		},
		watch: {
			initialValue(newVal) {
				this.value = newVal;
			},
		},
		methods: {
			debounceInput() {
				this.$emit('debounceInput', this.searchedValue);
				this.showSearchingMessage = false;
			},
			debounce(wait) {
				if (this.showSearch) {
					this.showSearchingMessage = true;
				}
				clearTimeout(this.timeout);
				this.timeout = setTimeout(() => {
					this.debounceInput();
				}, wait);
			},
			setValue(value) {
				this.firstSearch = true;
				this.searchedValue = value;
				this.$emit('setInput', value);
			},
			sendData(answer, text) {
				this.value = text;
				this.$emit('sendToParent', null, this.value, 'formSchool');
				this.searchedValue = '';
				if (!this.filterable) {
					this.$refs.nonfilterabledropdown.hide(true);
				}
				this.answer = answer;
				if (text.length > 21) {
					this.dropdownText = text.slice(0, 18) + '...';
				} else {
					this.dropdownText = text;
				}
				this.$emit('setData', answer);
			},
			zoomOutMobile() {
				var viewport = document.querySelector('meta[name="viewport"]');
				if (viewport) {
					viewport.content = 'initial-scale=1';
				}
			},
			normalize(str) {
				return str
					.toLowerCase()
					.normalize('NFD')
					.replace(/[\u0300-\u036f]/g, '');
			},
		},
		created() {
			// sort options
			this.options.sort((a, b) => (a[this.label] > b[this.label] && 1) || -1);

			if (this.answer) {
				this.options.forEach((answer) => {
					if (answer.number == this.answer) {
						if (answer[this.label].length > 21) {
							this.dropdownText = answer[this.label].slice(0, 18) + '...';
						} else {
							this.dropdownText = answer[this.label];
						}
					}
				});
			}
			this.isLoaded = true;
		},
	};
</script>

<style scoped>
	/deep/ .dropdown-toggle {
		background-color: var(--color) !important;
		color: #4f4f4f;
	}
	li {
		list-style-type: none;
	}
	input {
		outline: none;
		border: none;
		-webkit-appearance: none;
	}
	button {
		outline: none;
		border: none;
		-webkit-appearance: none;
		width: 100% !important;
		min-width: 100% !important;
	}

	.dropdown.b-dropdown.m-2.my-class {
		padding: 0px !important;
		margin: 0px !important;
	}
	/deep/ button:focus {
		outline: none;
		box-shadow: none;
	}
	/deep/ ul {
		margin-left: -2%;
		margin-right: 1px;
	}
</style>
