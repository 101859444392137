<template>
  <div
    class="dashboard-card text-left"
    :style="{ '--card-background-color': backgroundColor }"
  >
    <div class="d-flex justify-content-center ">
      <v-icon class="me-2 color--secondary" v-if="icon">{{ icon }}</v-icon>
      <span class="dashboard-card-title mb-2" v-if="title"
        ><CustomText :text="title" style="color:#454545"
      /></span>
    </div>
    <span><CustomText :text="subtitle"/></span>
    <slot> </slot>
  </div>
</template>
<script>
import CustomText from "../../components/layout/CustomText.vue";
export default {
  name: "DashboardCard",
  components: { CustomText },
  props: {
    icon: {
      required: false,
      default: null,
    },
    title: {
      required: false,
      default: null,
    },
    subtitle: {
      required: false,
      default: null,
    },
    backgroundColor: {
      required: false,
      default: null,
    },
  },
};
</script>
<style scoped>
.dashboard-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 40px 16px 40px !important;
  border-radius: 6px;
  height: 100%;
}
.dashboard-card-title {
  font-family: "Karla";
  font-style: normal;
  font-weight: 700;
  font-size: 17.0808px;
  line-height: 27px;
  color: #4a4b4f;
}
</style>
