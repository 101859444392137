<template>
  <v-btn
    @click="$emit('click')"
    rounded
    v-bind="{ ...extraProps, ...$attrs }"
    :class="[extraClasses, primaryData ? ' bg--primary' : ' bg--secondary']"
    class="no-capitalize text-white"
    :style="{
      'pointer-events': $attrs['read-only'] ? 'none' : 'auto',
    }"
  >
    <v-img
      class="me-auto"
      max-height="72"
      max-width="72"
      v-if="img"
      :src="require('@/assets/' + img)"
    />
    <v-icon v-if="prepend" class="me-1">{{ prepend }}</v-icon>
    <custom-text v-if="text" :text="text" />
    <slot> </slot>
    <v-icon v-if="append" class="ms-1">{{ append }}</v-icon>
    <responsive-div
      desktop-only
      style="width:72px;height:72px"
      v-if="img"
      class="me-auto"
    ></responsive-div>
  </v-btn>
</template>
<script>
export default {
  name: "CommonButton",
  data() {
    return {
      primaryData: true,
    };
  },
  props: {
    img: {
      required: false,
      default: null,
    },
    text: {
      type: String,
      required: false,
      default: null,
    },
    textWrap: {
      type: Boolean,
      required: false,
      default: false,
    },
    append: {
      type: String,
      required: false,
    },
    prepend: {
      type: String,
      required: false,
    },
    size: {
      type: String,
      required: false,
      default: "default",
    },
    primary: {
      type: Boolean,
      required: false,
      default: true,
    },
    secondary: {
      type: Boolean,
      required: false,
      default: false,
    },
    extraClasses: {
      type: String,
      required: false,
      default: "",
    },
  },
  mounted() {
    if (this.secondary) {
      this.primaryData = false;
    }
  },
  computed: {
    extraProps() {
      if (this.size) {
        return { [this.size]: true };
      }
      return {};
    },
  },
};
</script>
