import api from "../api";
export default {
  /**
   * Gets the data about a program at a specific level.
   * @param program_id
   * @param id
   * @returns {Promise<AxiosResponse<any>>}
   */
  getProgram({ params }) {
    return api.get(`programnew/`, { params });
  },
  patch({ id, object, body }) {
    return api.patch(`${object}new/${id}/`, body);
  },
  create({ object, body }) {
    return api.post(`${object}new/`, body);
  },
  delete({ object, id }) {
    return api.delete(`${object}new/${id}`);
  },
  getLectionary({ phaseId, classId }) {
    return api.get(
      `lectionaryinstancenew?lectionary__phase=${phaseId}&program_instance___class=${classId}`
    );
  },
  updateLectionary({ body }) {
    return api.patch(`lectionaryinstancenew/1/`, body);
  },
  createLectionary({ body }) {
    return api.post(`lectionaryinstancenew/`, body);
  },
};
