<template>
	<div
		v-if="
			(!mobileOnly && !desktopOnly) ||
				(mobileOnly && mobile) ||
				(desktopOnly && !mobile)
		"
		:class="mobile ? mobileClass : desktopClass"
		:style="mobile ? mobileStyle : desktopStyle"
	>
		<slot> </slot>
	</div>
</template>
<script>
	import { mapGetters } from 'vuex';
	export default {
		name: 'ResponsiveDiv',
		computed: {
			...mapGetters({
				mobile: 'layout/isMobile',
			}),
		},
		props: {
			mobileClass: {
				/* html classes to be applied when in mobile layout */
				required: false,
				type: String,
				default: '',
			},
			desktopClass: {
				/* html classes to be applied when in desktop layout */
				required: false,
				type: String,
				default: '',
			},
			mobileStyle: {
				/* css styles to be applied when in mobile layout */
				required: false,
				type: String,
				default: '',
			},
			desktopStyle: {
				/* css styles to be applied when in desktop layout */
				required: false,
				type: String,
				default: '',
			},
			mobileOnly: {
				/* if true, the component will only be displayed in mobile layout */
				required: false,
				type: Boolean,
				default: false,
			},
			desktopOnly: {
				/* if true, the component will only be displayed in desktop layout */
				required: false,
				type: Boolean,
				default: false,
			},
		},
	};
</script>
