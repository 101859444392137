<template>
  <div class="flex w-full  items-center justify-center text-center">
    <custom-dialog v-model="showDialog" @close="showDialog = false">
      Subirás los siguientes cursos:

      <div
        v-for="(course, index) in courses"
        :key="index"
        style="background:#CCD2FE;border-radius:4px;margin:2px;"
      >
        {{ course }}
      </div>
      <br />
      ¿Continuar?
      <br />
      <common-button
        class="mb-3"
        text="Sí"
        @click="uploadFile()"
      ></common-button>

      <common-button
        style="width:100%;max-width:100%"
        text="No, quiero subir nuevamente"
        @click="showDialog = false"
      ></common-button>
    </custom-dialog>
    <custom-dialog
      width="80%"
      extraClasses="text-left text-label"
      v-model="showErrorDialog"
      @close="showErrorDialog = false"
    >
      Existen los siguientes errores:
      <div style="max-height:60vh;height:60vh;overflow:auto;">
        <div
          v-for="(error, index) in errors"
          :key="index"
          v-html="error"
          style="background:#CCD2FE;border-radius:4px;margin:2px;"
        />
      </div>

      <common-button
        class="mb-3"
        text="OK"
        @click="showErrorDialog = false"
      ></common-button>

      <common-button
        style="width:100%;max-width:100%"
        text="No, quiero subir nuevamente"
        @click="showDialog = false"
      ></common-button>
    </custom-dialog>
    <div
      class="p-12 bg-gray-100"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
    >
      <input
        type="file"
        name="fields[assetsFieldHandle][]"
        id="assetsFieldHandle"
        class="w-px h-px opacity-0 overflow-hidden absolute"
        @change="onChange"
        ref="file"
        accept=".csv, .xlsx"
      />

      <label
        for="assetsFieldHandle"
        class="block cursor-pointer p-2"
        style="border-style: dashed;background:white;border: 2px dashed #5669FB;border-radius:4px"
      >
        <div class="d-flex flex-column">
          <v-icon>mdi-upload</v-icon>
          <span>Arrastra el archivo y suéltalo dentro del cuadro</span>
          <span>ó</span>
          <span
            ><v-btn
              @click.native="$refs.file.click()"
              class="no-capitalize dashboard-phase-button-selected"
              rounded
              dense
              elevation="0"
            >
              Selecciona el archivo
              <v-icon right dark>
                mdi-upload
              </v-icon></v-btn
            ></span
          >
        </div>
      </label>
      <ul class="mt-4" v-if="this.filelist.length" v-cloak>
        <!--<li class="text-sm p-1" v-for="(file, index) in filelist" :key="index">
          {{ file.name
          }}<button
            class="ml-2"
            type="button"
            @click="remove(filelist.indexOf(file))"
            title="Remove file"
          >
            remove
          </button>
        </li>-->
      </ul>
    </div>
  </div>
</template>
<script>
import readXlsxFile from "read-excel-file";
export default {
  name: "FileUpload",
  data() {
    return {
      filelist: [],
      file: null,
      showDialog: false,
      showErrorDialog: false,
      courses: [],
      errors: [],
    };
  },
  methods: {
    transformToJsonArray(array) {
      let columns = array.shift();
      return array.map((subarray) => {
        let object = Object();
        subarray.forEach((value, index) => {
          object[columns[index]] = value;
        });
        return object;
      });
    },
    onChange() {
      this.filelist = [...this.$refs.file.files];
      let xlsxfile = this.filelist ? this.filelist[0] : null;
      readXlsxFile(xlsxfile).then((rows) => {
        const jsonArray = this.transformToJsonArray(rows);
        let showErrors = false;
        this.errors = [];
        jsonArray.forEach((row, index) => {
          let res = this.validateRow(row);
          if (res !== true) {
            res = `Errores línea ${index}:<br/>${res}`;
            this.errors.push(res);
            showErrors = true;
          }
        });
        if (!showErrors) {
          this.courses = [...new Set(jsonArray.map((item) => item.Curso))];
          this.showDialog = true;
        } else {
          this.showErrorDialog = true;
        }
      });
    },
    emptyString(string) {
      if (string === null) {
        return true;
      }
      if (string.replace(/\s/g, "").length > 0) {
        return false;
      }
      return true;
    },
    dv(T) {
      var M = 0,
        S = 1;
      for (; T; T = Math.floor(T / 10))
        S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
      return S ? S - 1 : "k";
    },
    validEmail(email) {
      if (email === null) {
        return false;
      }
      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (email.match(validRegex)) {
        return true;
      } else {
        return false;
      }
    },
    validRUT(rutCompleto) {
      if (rutCompleto === null) {
        return false;
      }
      if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)) return false;
      var tmp = rutCompleto.split("-");
      var digv = tmp[1];
      var rut = tmp[0];
      if (digv == "K") digv = "k";
      return this.dv(rut) == digv;
    },
    validateRow(row) {
      let error = "";
      Object.keys(row).forEach((key) => {
        if (this.emptyString(row[key])) {
          error += `Campo ${key} no puede estar vacío<br/>`;
        }
      });

      if (!this.validRUT(row["Rut Estudiante"])) {
        let rut = row["Rut Estudiante"];
        if (row["Rut Estudiante"] === null) {
          rut = "";
        }
        error += `R.U.T ${rut} inválido.<br/>`;
      }

      if (!this.validEmail(row["Correo Estudiante"])) {
        let correo = row["Correo Estudiante"];
        if (row["Correo Estudiante"] === null) {
          correo = "";
        }
        error += `Correo de estudiante ${correo} inválido.<br/>`;
      }

      if (!this.validEmail(row["Correo Profesor"])) {
        let correo = row["Correo Profesor"];
        if (row["Correo Profesor"] === null) {
          correo = "";
        }
        error += `Correo de profesor ${correo} inválido.<br/>`;
      }

      if (error == "") {
        return true;
      }
      return error;
    },
    uploadFile() {
      this.showDialog = false;
      this.$emit("fileUploaded", this.filelist[0]);
    },
    remove(i) {
      this.filelist.splice(i, 1);
    },
    dragover(event) {
      event.preventDefault();
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange();
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
  },
};
</script>
