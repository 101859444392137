<template>
  <!-- mixpanel on -->
  <div class="d-flex w-100 p-2 text-left text-label">
    <custom-dialog v-model="showModal" class="d-flex flex-column">
      <DashboardStudentsInput
        v-model="student.first_name"
        :placeholder="'Nombre'"
      />
      <DashboardStudentsInput
        v-model="student.last_name"
        :placeholder="'Apellido'"
      />
      <DashboardStudentsInput v-model="student.email" :placeholder="'Mail'" />
      a
      <DashboardStudentsInput
        v-model="student.identifier"
        :placeholder="lang == 'es-CL' ? 'Rut' : 'Identificador'"
        :rules="getRules()"
      />
      b
      <DashboardStudentSelect
        :items="['M', 'F', 'Otro']"
        v-model="gender"
        :placeholder="'Género'"
      />
      <div class="d-flex w-100">
        <common-button
          class="ms-auto"
          text="Guardar"
          @click.native="showModal = false"
        ></common-button>
      </div>
    </custom-dialog>
    <div class="d-flex w-100" v-if="!mobile">
      <div class="h-100 d-flex">
        <v-checkbox v-model="computedValue" hide-details></v-checkbox>
      </div>
      <div class="self-stretch">
        <DashboardStudentsInput v-model="student.first_name" />
      </div>
      <div class="self-stretch">
        <DashboardStudentsInput v-model="student.last_name" />
      </div>

      <div class="self-stretch">
        <DashboardStudentsInput
          v-model="student.email"
          :error="!validEmail(student.email)"
        />
      </div>
      <div class="self-stretch">
        <DashboardStudentsInput
          v-model="student.identifier"
          :rules="getRules()"
        />
      </div>

      <div class="self-stretch">
        <DashboardStudentSelect :items="['M', 'F', 'Otro']" v-model="gender" />
      </div>
    </div>
    <div v-else class=" text-body d-flex w-100">
      <div>
        <v-checkbox
          v-model="computedValue"
          hide-details
          style="margin-top: -2px"
        ></v-checkbox>
      </div>
      <div
        class="dashboard-student-text self-stretch ms-2 me-2"
        style="max-width:75%;width:75%;overflow-x:hidden"
      >
        <div>{{ student.first_name }} {{ student.last_name }}</div>
        <div>{{ student.email }}</div>
        <div>{{ student.identifier }}</div>
        <div>{{ gender }}</div>
      </div>

      <div>
        <v-icon class="color--primary" @click="showModal = true"
          >mdi-pencil</v-icon
        >
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import DashboardStudentsInput from "./DashboardStudentsInput";
import DashboardStudentSelect from "./DashboardStudentSelect.vue";
export default {
  name: "DashboardStudentsSingle",
  mounted() {
    this.gender = this.student.gender == "Masculino" ? "M" : "F";
    if (this.student.gender == "M") {
      this.gender = "M";
    }
    if (this.student.gender == "F") {
      this.gender = "F";
    }
    if (this.student.gender == "") {
      this.gender = "Otro";
    }
    if (this.student.gender == "Otro" || this.student.gender == "otro") {
      this.gender = "Otro";
    }
  },

  components: {
    DashboardStudentsInput,
    DashboardStudentSelect,
  },
  props: {
    student: {
      required: true,
      type: Object,
    },
    value: {
      required: true,
    },
    showAll: {
      required: true,
      type: Boolean,
    },
  },
  watch: {
    gender(newVal) {
      this.student.gender = newVal;
    },
    "student.identifier"(newVal) {
      if (
        this.$store.state.authentication.country == "chile" &&
        this.lang == "es-CL"
      ) {
        if (!this.validRUT(newVal)) {
          this.$emit("setRUTvalidation", this.student.id, false);
        } else {
          this.$emit("setRUTvalidation", this.student.id, true);
        }
      }
    },
  },
  data() {
    return {
      gender: null,
      showModal: false,
      validEdition: true,
    };
  },
  methods: {
    validEmail(email) {
      if (email == "") {
        return true;
      }
      var validRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      /// var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (email.match(validRegex)) {
        return true;
      } else {
        return false;
      }
    },
    /* */
    validRUT(rutCompleto) {
      if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)) return false;
      var tmp = rutCompleto.split("-");
      var digv = tmp[1];
      var rut = tmp[0];
      if (digv == "K") digv = "k";
      return this.dv(rut) == digv;
    },
    dv(T) {
      var M = 0,
        S = 1;
      for (; T; T = Math.floor(T / 10))
        S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
      return S ? S - 1 : "k";
    },
    getRules() {
      let country = this.$store.state.authentication.country;
      if (country == "chile" && this.lang == "es-CL") {
        return [
          (v) => {
            return this.validRUT(v) || "RUT Inválido.";
          },
        ];
      } else {
        return [];
      }
    },
  },
  computed: {
    ...mapGetters({
      mobile: "layout/isMobile",
      lang: "layout/lang",
    }),

    computedValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v, this.student.id);
      },
    },
  },
};
</script>
<style>
.dashboard-student-text {
  background: transparent !important;
  border: 1px solid var(--primary);
  border-style: dashed;
  border-radius: 4px;
  padding: 8px;
}
</style>
