<template>
  <!-- mixpanel on -->

  <div class="relative" v-if="user.type">
    <!-- pdf -->
    <div
      ref="document"
      id="pdfInfo"
      style="z-index:50;display:none"
      v-if="user.type != 'student' && this.currentClass"
    >
      <div class="w-100 text-left">
        <img src="@/assets/Home/DFM.svg" class="mr-auto" />
      </div>

      <div class="text-center text-title mt-4 mb-4">
        Reporte de avance {{ user.type == "teacher" ? currentClass.name : "" }}
      </div>
      <table class="table table-striped text-label">
        <thead>
          <tr>
            <th>Estudiante</th>
            <th>DNI</th>
            <th>Clase</th>
            <th>Inicio encuesta</th>
            <th>Término encuesta</th>
            <th v-for="(phase, index) in sortedPhases" :key="index">
              Avance etapa {{ index + 1 }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(user, index) in reportInformation" :key="index">
            <td>{{ user.name }}</td>
            <td>{{ user.identifier }}</td>

            <!--<td>
              <v-icon v-if="user.consent">mdi-check</v-icon>
              <v-icon v-else>mdi-close</v-icon>
            </td>-->
            <td>
              {{ user.class }}
            </td>
            <td>
              <v-icon v-if="user.baseline_start">mdi-check</v-icon>
              <v-icon v-else>mdi-close</v-icon>
            </td>
            <td>
              <v-icon v-if="user.baseline_end">mdi-check</v-icon>
              <v-icon v-else>mdi-close</v-icon>
            </td>
            <!--<td>
              <v-icon v-if="user.endline_start">mdi-check</v-icon>
              <v-icon v-else>mdi-close</v-icon>
            </td>
            <td>
              <v-icon v-if="user.endline_end">mdi-check</v-icon>
              <v-icon v-else>mdi-close</v-icon>
            </td>-->
            <td v-for="(phase, index) in sortedPhases" :key="index">
              <v-icon :color="user.phases.includes(index) ? 'green' : 'red'">
                {{ user.phases.includes(index) ? "mdi-check" : "mdi-close" }}
              </v-icon>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- main contents -->
    <!--<DashboardCard backgroundColor="#DDE1FE" class="d-flex h-100" v-if="false">
      <div
        style="z-index:101"
        class="w-100"
        :class="mobile ? 'd-flex' : ''"
        :style="mobile ? 'flex-grow:1' : ''"
      >
        <div
          :style="
            mobile
              ? 'display:flex;flex-grow:1;height:100%;flex-direction:column'
              : 'width:40%;margin-left:25%;'
          "
          v-if="!started"
        >
          <custom-text
            class="text-heading"
            text="\highlight{¡Bienvenido a Decidiendo para un futuro mejor!, #4A4B4F, white}"
          />
          <br v-if="env != 'PROD' && classes && classes.length > 0" />
          <span class="dashboard-panel-subtitle">
            Hemos detectado que no hay estudiantes registrados en su colegio. Le
            solicitamos se pueda poner en contacto con nosotros a través de
            nuestro centro de ayuda.
          </span>
          <br />
          <v-btn
            class="no-capitalize dashboard-phase-button-selected"
            rounded
            style="padding:30px;border-radius:105px !important;height:52px !important;width:200px !important;font-size:16px"
            dense
            @click.native="started = true"
            v-if="!mobile"
            elevation="0"
          >
            Comenzar
          </v-btn>
          <div
            v-if="mobile"
            class="text-center d-flex flex-column "
            style="flex-grow:1;"
          >
            <div class="mb-auto text-small clickable color--primary">
              ¿Necesitas ayuda?
            </div>
            <div
              class="text-small clickable"
              @click="$router.push({ name: 'home' })"
            >
              Visitar sitio web
            </div>
            <div class="text-small clickable" @click="logout()">
              Cerrar sesión
            </div>
          </div>
        </div>
        <div v-else class="w-100 h-100">
          Cargar lista de estudiantes
          <v-progress-linear
            :value="val"
            class="w-100 mt-2 mb-2"
            background-color="white"
            rounded
            height="9"
          ></v-progress-linear>
          <div class="d-flex">
            <div
              v-for="(step, index) in steps"
              :key="index"
              class="d-flex"
              style="flex: 1 1 0"
            >
              <v-icon
                :style="
                  currentStep >= index ? 'color: #5669FB; !important' : ''
                "
                >{{ step.icon }}</v-icon
              >
              <span
                :style="
                  currentStep >= index ? 'color:  #5669FB;font-weight:bold' : ''
                "
                style="white-space: nowrap;;overflow:hidden"
                >{{ step.text }}</span
              >
            </div>
          </div>
          <div>
            <div v-if="currentStep === 0" class="text-center">
              <youtube
                resize
                video-id="agjVXgcmsdc"
                :style="{
                  width: '100%',
                  'max-width': '100%',
                  height: 'calc(100vh - 245px)',
                  'min-height': 'calc(100vh - 245px)',
                  padding: '3px',
                }"
              ></youtube>
            </div>
            <div v-else-if="currentStep === 1">
              <div
                class="dashboard-panel-dataloader w-100"
                :style="
                  classes && classes.length > 0
                    ? getRemainingHeight(370)
                    : getRemainingHeight(280)
                "
              >
                <div v-if="!fileUploaded" class="d-flex w-100 h-100">
                  <div class="w-50 text-center">
                    <div
                      style="background:#5669FB;color:white"
                      class="dashboard-panel-dataloader-circle d-flex align-items-center justify-content-center ms-auto mr-auto mt-10"
                    >
                      1
                    </div>
                    <div class="mt-10 mb-10">
                      <v-icon color="#73767D" large
                        >mdi-cursor-default-click</v-icon
                      >
                    </div>
                    <div class="mt-10 mb-10">
                      Haz click en "Descargar plantilla" para cargar a tus
                      estudiantes
                    </div>
                    <v-btn
                      class="no-capitalize dashboard-phase-button-selected mt-2"
                      rounded
                      dense
                      @click.native="downloadTemplate"
                      elevation="0"
                    >
                      <a
                        href="https://drive.google.com/uc?export=download&id=1-P3I6kcaWYmbq9MxhG_4LOIWReFaejKV"
                        style="color:white;text-decoration:none"
                        >Descargar plantilla</a
                      >

                      <v-icon right dark>
                        mdi-download
                      </v-icon></v-btn
                    >
                  </div>
                  <div
                    class="text-center w-50"
                    style="min-width:50%; width:50%"
                    v-show="downloaded"
                  >
                    <div
                      style="background:#EE77D3;color:white"
                      class="d-flex align-items-center justify-content-center ms-auto mr-auto dashboard-panel-dataloader-circle mt-10"
                    >
                      2
                    </div>
                    <div class="text-body mt-4 mb-4" style="color:#73767D">
                      Sube tu archivo
                    </div>
                    <FileUpload @fileUploaded="processFile" />
                  </div>
                </div>
                <div v-else>
                  Estamos procesando sus datos...
                </div>
              </div>
            </div>
            <div v-else-if="currentStep === 2">
              <div
                class="dashboard-panel-dataloader h-100 w-100 mb-5"
                :style="
                  classes && classes.length > 0
                    ? getRemainingHeight(370)
                    : getRemainingHeight(210)
                "
              >
                <div class="text-center">
                  ¡Carga exitosa! A continuación veras las herramientas del
                  panel. ¡Está listo para comenzar a navegar!
                </div>
              </div>
            </div>
            <div class="d-flex w-100">
              <div
                class="clickable"
                v-if="currentStep == 1"
                @click="currentStep > 0 ? previousStep() : () => {}"
              >
                <div class="d-flex" style="flex: 1 1 0">
                  <v-icon style="color:#5669FB">mdi-chevron-left</v-icon>
                  <div
                    class="d-flex flex-column text-left ms-2  justify-content-center bold"
                    style="color:#5669FB"
                  >
                    Atrás
                  </div>
                </div>
              </div>

              <div
                class="ms-auto clickable"
                v-if="
                  currentStep == 0 ||
                    currentStep == 2 ||
                    (currentStep == 1 && fileProcessed)
                "
                @click="nextStep()"
              >
                <div class="d-flex" style="flex: 1 1 0">
                  <div
                    style="color:#5669FB"
                    class="d-flex flex-column text-left  justify-content-center bold"
                  >
                    Adelante
                  </div>
                  <v-icon style="color:#5669FB">mdi-chevron-right</v-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardCard>-->
    <div class="w-100 h-100">
      <grid
        gridTemplateRows="1fr"
        gridTemplateColumns="1fr 1fr"
        class="w-100 h-100"
      >
        <grid-element
          rowStart="1"
          rowEnd="2"
          colStart="1"
          :colEnd="user.type == 'student' ? '2' : '3'"
          ><DashboardCard>
            <div class="d-flex w-100 ">
              <!--<div
                v-if="$refs.rightprogress"
                :style="{
                  'max-height': `${$refs.rightprogress.clientHeight}px`,
                  height: `${$refs.rightprogress.clientHeight}px`,
                }"
                class=" w-25 me-2 bg--neutral-90-t d-flex align-items-center justify-content-center rounded"
              >
                <Building
                  style="max-height:80%"
                  :phase="storeCurrentPhase.number"
                />
              </div>-->
              <div class="w-100" ref="rightprogress">
                <div class="d-flex w-100">
                  <v-icon class="color--secondary me-2"
                    >mdi-chart-areaspline-variant</v-icon
                  >
                  <div class="dashboard-card-title">
                    {{
                      user.type == "student"
                        ? $t("dashboard.main-panel.progress-card.student-title")
                        : user.type == "teacher"
                        ? `${$t(
                            "dashboard.main-panel.progress-card.teacher-title"
                          )} (${currentClassStudents.length} estudiantes)`
                        : `Avance de sede ${school.name} (${storeStudents.length} estudiantes)`
                    }}
                  </div>
                  <div
                    v-if="!mobile && user.type == 'principal'"
                    class="text-right ms-auto d-flex "
                    :class="mobile ? 'flex-column' : ''"
                  >
                    <common-button
                      class="ms-auto bg--primary"
                      :class="mobile ? 'me-auto' : ''"
                      :loading="downloadingReportExcel"
                      @click="downloadReportExcel"
                      append="mdi-file-excel"
                      >Descargar Excel</common-button
                    >
                    <div v-if="mobile" style="height:10px"></div>
                    <common-button
                      class=" bg--primary"
                      :class="mobile ? 'me-auto ms-auto' : 'ms-2'"
                      :loading="downloadingReportPDF"
                      @click="downloadReport"
                      append="mdi-file-pdf-box"
                      >Descargar PDF</common-button
                    >
                  </div>

                  <!--<div class="ms-auto">
                    <v-tooltip
                      top
                      max-width="200px"
                      content-class="progress-tooltip"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="color--secondary"
                          v-bind="attrs"
                          v-on="on"
                          >mdi-help-circle-outline</v-icon
                        >
                      </template>
                      <span>{{ tooltipText }}</span>
                    </v-tooltip>
                  </div>-->
                </div>

                <!--<v-progress-linear
                  v-if="program && 'phases' in program"
                  :value="shownProgress[storeCurrentPhase.number]"
                  class="w-100 mt-2 mb-2"
                  background-color="#EDEDED"
                  rounded
                  height="30"
                >
                  <div class="w-100 text-right pe-1">
                    {{ shownProgress[storeCurrentPhase.number] }} %
                  </div>
                </v-progress-linear>-->
                <!--<div
                  class="w-100 d-flex justify-content-between"
                  v-if="currentPhase !== null"
                >
                  <div
                    style="flex: 1 1 0px"
                    @click="setCurrentPhase({ phaseId: phase.id })"
                    class="clickable ms-1 me-1 pt-1 pb-1 text-center justify-content-center align-items-center pt-auto pb-auto rounded text-label "
                    :class="
                      storeCurrentPhase.number == index
                        ? 'bg--primary color--neutral-100-t'
                        : 'bg--primary-80-t color--primary-40-t'
                    "
                    v-for="(phase, index) in sortedPhases"
                    :key="index"
                  >
                    {{ $t("dashboard.main-panel.progress-card.phase") }}
                    {{ phase.number + 1 }}
                  </div>
                </div>-->
              </div>
            </div>
            <!--             icon="mdi-tools"
            :title="
              user.type == 'student'
                ? $t('dashboard.main-panel.progress-card.student-title')
                : user.type == 'teacher'
                ? $t('dashboard.main-panel.progress-card.teacher-title')
                : 'Progreso de la sede'
            " -->

            <div
              v-if="!storeCurrentPhase"
              class="w-100 h-100 d-flex text-center"
            >
              <!-- check this one -->
              <v-progress-circular
                indeterminate
                class="m-auto"
                color="primary"
              ></v-progress-circular>
            </div>
            <DashboardCard
              v-if="storeCurrentPhase"
              class="w-100 d-flex flex-column mt-2 justify-content-center align-items-center"
              backgroundColor="#f4f4f4"
              :style="mobile ? '' : 'max-height:calc(100% - 150px);'"
            >
              <div v-if="user.type != 'student'" class="w-100 h-100">
                <div>
                  <div class="d-flex flex-column">
                    <div>
                      <div class="text-left">
                        Enc. inicial
                      </div>
                      <div
                        class="d-flex flex-row justify-content-end"
                        :style="mobile ? '' : 'max-width: 70%'"
                        style="min-width:200px"
                      >
                        <v-progress-linear
                          class="mt-1"
                          color="linear-gradient(#e66465, #9198e5)"
                          background-color="#e2e8f0"
                          height="20"
                          style="border-radius:15px;background:linear-gradient(90deg, #FFBAD4, #FF79AC)"
                          :value="shownProgress.baseline_start"
                        ></v-progress-linear>
                        <div style="width:100px;" class="text-subtitle ms-2">
                          {{ shownProgress.baseline_start }} %
                        </div>
                      </div>
                    </div>
                    <div
                      v-for="(phase, index) in sortedPhases"
                      :key="index"
                      class="mt-2"
                    >
                      <div class="text-left d-flex">
                        <div>Etapa {{ index + 1 }}</div>
                        <div
                          class="ms-5 text-label clickable"
                          :class="mobile ? 'mt-1' : ''"
                          @click="goToPhase(phase.id)"
                        >
                          Ir a etapa<v-icon class="ms-1" small
                            >mdi-arrow-right</v-icon
                          >
                        </div>
                      </div>
                      <div
                        class="d-flex flex-row justify-content-end"
                        :style="mobile ? '' : 'max-width: 70%'"
                        style="min-width:200px"
                      >
                        <v-progress-linear
                          class="mt-1"
                          color="linear-gradient(#e66465, #9198e5)"
                          background-color="#e2e8f0"
                          height="20"
                          style="border-radius:15px;background:linear-gradient(90deg, #FFBAD4, #FF79AC)"
                          :value="getStudentFinished(phase.id)"
                        ></v-progress-linear>
                        <div style="width:100px;" class="text-subtitle ms-2">
                          {{ getStudentFinished(phase.id) }} %
                        </div>
                      </div>
                      <!--<div style="width:20%" class="text-left">
                        Etapa {{ index + 1 }}
                      </div>
                      <div class="d-flex flex-row justify-content-end">
                        <div style="width:15%;">
                          {{ getStudentFinished(phase.id) }}%
                        </div>
                        <div style="width:64%;min-width:64%" class="ps-1 pe-1">
                          <div
                            class="bg--primary"
                            style="min-width:1px !important;height:20px"
                            :style="{
                              width: `${getStudentFinished(phase.id)}%`,
                            }"
                          ></div>
                        </div>
                      </div>-->
                    </div>
                  </div>
                </div>
                <div class="w-100">
                  <!--<div>
                    <i
                      >Descarga el reporte para conocer el avance de tu sede en
                      la <b>encuesta</b> y en las etapas:</i
                    >
                  </div>-->
                  <div
                    v-if="mobile"
                    class="w-100 d-flex mt-5"
                    :class="mobile ? 'flex-column' : ''"
                  >
                    <common-button
                      class="ms-auto bg--primary"
                      :class="mobile ? 'me-auto' : ''"
                      :loading="downloadingReportExcel"
                      @click="downloadReportExcel"
                      append="mdi-file-excel"
                      >Descargar Excel</common-button
                    >
                    <div v-if="mobile" style="height:10px"></div>
                    <common-button
                      class=" bg--primary"
                      :class="mobile ? 'me-auto ms-auto' : 'ms-2'"
                      :loading="downloadingReportPDF"
                      @click="downloadReport"
                      append="mdi-file-pdf-box"
                      >Descargar PDF</common-button
                    >
                  </div>
                </div>
              </div>
              <div v-else class="w-100 d-flex">
                <div class="w-50">
                  <custom-text
                    class="text-title"
                    :text="`\\highlight{${getText}, #4A4B4F, white}`"
                  />
                  <p v-t="getSubtext" />
                </div>
                <div class="w-50">
                  <Building :phase="completedPhases.length + 1" />
                </div>
              </div>
            </DashboardCard>
          </DashboardCard>
        </grid-element>
        <grid-element
          rowStart="1"
          rowEnd="2"
          colStart="2"
          colEnd="3"
          v-if="user.type == 'student'"
          ><DashboardCard
            icon="mdi-chart-pie"
            :title="
              user.type == 'student'
                ? 'Tu progreso por etapa'
                : user.type == 'teacher'
                ? 'Progreso individual'
                : `Progreso individual de sede (${students.length} estudiantes)`
            "
            class="bg--neutral-100-t"
          >
            <v-btn
              color="red"
              danger
              v-if="
                env != 'PROD' && classes && classes.length > 0 && showInterface
              "
              @click="deleteAllStudents()"
              >(testing) Eliminar datos</v-btn
            >
            <!-- principal, teacher and manager -->
            <div
              style="max-height:100%; overflow-y:auto"
              class="h-100 w-100"
              v-if="
                $includes(['principal', 'teacher', 'manager'], user.type) &&
                  students.length > 0
              "
            >
              <div>
                <div
                  v-for="(student, index) in students"
                  :key="index"
                  class="  bg--primary-90-t me-2"
                  style="border-radius:5px"
                >
                  <DashboardStudentProgress
                    class="w-100 mb-2"
                    :student="student"
                    :currentPhase="storeCurrentPhase"
                  />
                </div>
              </div>
            </div>
            <!-- end teacher, principal and manager -->
            <!-- student progress -->
            <!-- error here bug aqui -->
            <div
              v-else-if="
                sortedPhases.length > 0 && $includes(['student'], user.type)
              "
              class="w-100"
              style="height:100%;max-height:100%;overflow-y:overlay"
            >
              <v-expansion-panels flat>
                <v-expansion-panel
                  v-for="(phase, i) in sortedPhases"
                  :disabled="!phase.available"
                  :class="
                    phase.available ? 'bg--primary-80-t' : 'bg--neutral-80-t'
                  "
                  class="mt-2 "
                  :key="i"
                >
                  <v-expansion-panel-header class="color--primary text-body">
                    <flex-box
                      class="text-body ps-4 pe-4"
                      gap="16"
                      alwaysHorizontal
                    >
                      <div>Etapa {{ phase.number + 1 }}</div>
                      <!-- aqui el bug -->
                      <v-progress-linear
                        :value="getPhaseProgress(phase)"
                        class="self-stretch"
                        background-color="white"
                        rounded
                        height="9"
                      ></v-progress-linear>
                      <div>{{ getPhaseProgress(phase) }}%</div>
                    </flex-box>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="text-left text-body">
                    <flex-box gap="8" v-for="stage in phase.total" :key="stage">
                      <div
                        v-if="getCompletedStages(phase) < stage"
                        class="rounded-circle"
                        style="height:10px;width:10px;border: 2px solid var(--primary)"
                      ></div>
                      <div
                        v-else
                        class="rounded-circle bg--success"
                        style="height:10px;width:10px;border: 2px"
                      ></div>

                      <div class="text-body bold color--neutral-20-t">
                        Actividad {{ stage }}:
                        {{
                          getCompletedStages(phase) >= stage
                            ? "Completa"
                            : "Incompleta"
                        }}
                      </div>
                    </flex-box>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
            <!-- end student progress -->
            <!-- loading -->
            <div
              v-else-if="!loaded && students.length == 0"
              class="w-100 h-100 d-flex text-center"
            >
              <v-progress-circular
                indeterminate
                class="m-auto"
                color="primary"
              ></v-progress-circular>
            </div>
            <div v-else>
              No se encuentran estudiantes registrado en este curso.
            </div>
          </DashboardCard>
        </grid-element>
      </grid>
    </div>
    <!-- icons -->
    <!--<img
      v-if="classes && classes.length == 0"
      class="absolute-top-right me-5"
      src="@/assets/Dashboard/TopRight.svg"
    />
    <img
      v-if="classes && classes.length == 0"
      class="absolute-top-left"
      :style="
        mobile
          ? 'margin-left: 50%;margin-top:100px'
          : 'margin-left:300px;margin-top:100px'
      "
      src="@/assets/Dashboard/TopLeft.svg"
    />
    <img
      v-if="classes && classes.length == 0"
      class="absolute-bottom-right me-15 mb-5"
      src="@/assets/Dashboard/BottomRight.svg"
    />
    <img
      v-if="classes && classes.length == 0"
      class="absolute-bottom-left mb-10"
      src="@/assets/Dashboard/BottomLeft.svg"
    />-->
  </div>
</template>
<script>
import html2pdf from "html2pdf.js";
import Building from "../../dashboard/DashboardBuilding.vue";
import { mapActions, mapGetters } from "vuex";
// import FileUpload from "../../layout/FileUpload.vue";
import DashboardStudentProgress from "../DashboardStudentProgress.vue";
import DashboardCard from "../DashboardCard.vue";
import XLSX from "xlsx";
export default {
  name: "DashboardPanel",

  computed: {
    getText() {
      let length = this.completedPhases.length;
      return {
        0: "¡Estás comenzando a construir tu edificio!",
        1: "¡Ya iniciaste la construcción de tu edificio!",
        2: "¡Ya tienes la mitad del edificio construído!",
        3: "¡Estás a punto de terminar la construcción de tu edificio!",
        4: "¡Felicitaciones, ya terminaste de construir tu edificio!",
      }[length];
    },
    getSubtext() {
      let length = this.completedPhases.length;
      return {
        0: "Ahora te invitamos a revisar todos los recursos que hemos preparado para ti.",
        1: "Conociste el valor y la importancia de la educación, sigue adelante aún te quedan actividades por descubrir.",
        2: "Cada año de estudio es como un piso nuevo en el edificio de tu vida: te permite ver y explorar nuevos horizontes.",
        3: "Te invitamos a completar las actividades restantes que te ayudarán a terminar los últimos detalles para estrenar tu edificio.",
        4: "Ahora tienes todas las herramientas para construir tu proyecto de vida.",
      }[length];
    },
    rightProgressHeight() {
      if (this.$refs && this.$refs.rightprogress) {
        return this.$refs.rightprogress.clientHeight;
      }
      return 10;
    },
    tooltipText() {
      if (this.user.type == "student") {
        return "La barra de progreso indica la cantidad de actividades que has completado en la etapa seleccionada.";
      }
      if (this.user.type == "teacher") {
        return "La barra de progreso indica la cantidad de estudiantes del curso seleccionado que han finalizado la etapa seleccionada.";
      }
      if (this.user.type == "principal") {
        return "La barra de progreso indica la cantidad de estudiantes de la sede actual que han finalizado la etapa seleccionada.";
      }
      return "";
    },
    lastAvailable() {
      let availablePhases = this.phases.filter((x) => x.available);
      if (availablePhases.length == 0) {
        return 0;
      }
      var last_element = availablePhases[availablePhases.length - 1];
      return last_element.number;
    },
    sortedPhases() {
      if (this.phases) {
        return [...this.phases].sort(({ number: a }, { number: b }) => a - b);
      }
      return [];
    },
    latestPhase() {
      return Math.max(
        this.phases
          .filter((x) => this.$includes(this.completedPhases, x.id))
          .map((y) => y.number)
      );
    },
    ...mapGetters({
      rbd: "school/rbd",
      school: "school/school",
      showInterface: "layout/showInterface",
      completedStages: "program/completedStages",
      completedPhases: "program/completedPhases",
      currentClassStudents: "_class/currentClassStudents",
      storeStudents: "student/students",
      user: "authentication/user",
      program: "program/program",
      phases: "program/phases",
      classes: "_class/classes",
      currentClass: "_class/currentClass",
      mobile: "layout/isMobile",
      storeCurrentPhase: "program/currentPhase",
    }),
  },
  components: {
    DashboardCard,
    // FileUpload,
    DashboardStudentProgress,
    Building,
  }, // Building
  methods: {
    ...mapActions({
      track: "authentication/track",
      logout: "authentication/logout",
      uploadStudents: "student/uploadStudents",
      setUnavailableDialog: "authentication/setUnavailableDialog",
      getStudents: "student/getStudents",
      getClasses: "_class/getClasses",
      getClassStudents: "_class/getClassStudents",
      setCurrentPhase: "program/setCurrentPhase",
      deleteStudents: "student/deleteStudents",
      getCompleted: "answer/getCompleted",
      get: "admin/silentGet",
    }),
    goToPhase(phaseId) {
      this.track({
        event: "dashboard-main-go-to-phase",
        data: { phase: phaseId },
      });
      let phase = this.phases.find((x) => {
        return x.id == phaseId;
      });
      if (phase.available) {
        this.setCurrentPhase({ phaseId }).then(() => {
          this.$router.push({ name: "phase" });
        });
      } else {
        this.setUnavailableDialog({ dialog: true });
      }
    },
    setDownload(value) {
      this.downloadType = null;

      if (value.includes("PDF")) {
        this.downloadReport();
      } else if (value.includes("Excel")) {
        this.downloadReportExcel();
      }
    },
    getProgress() {
      this.get({ endpoint: `class_report/${this.currentClass.id}/` }).then(
        (response) => {
          // all students
          let students = response.data.data;

          // students that have finished
          let baseline_start_total = students.filter((s) => s.baseline_end)
            .length;

          // students that have finished * 100 / all students
          baseline_start_total = Math.round(
            (baseline_start_total * 100) / students.length
          );

          let progress = {};
          progress.baseline_start = isNaN(baseline_start_total)
            ? 0
            : baseline_start_total;

          progress.baseline_start = Math.min(progress.baseline_start, 100);
          // create object with progress per phase
          this.sortedPhases.forEach((p) => {
            progress[p.number] = 0;
          });
          // get total students for class or school
          let totalStudents = students.length;
          students.forEach((student) => {
            student.phases.forEach((phaseNumber) => {
              progress[phaseNumber] += 1;
            });
          });

          // set number to finished / total
          Object.entries(progress).forEach(([key, value]) => {
            if (key != "baseline_start") {
              progress[key] = Math.round((value * 100) / totalStudents);
            }
          });

          this.shownProgress = progress;
        }
      );
    },
    downloadReportExcel() {
      this.downloadingReportExcel = true;

      this.get({ endpoint: `class_report/${this.currentClass.id}/` })
        .then((response) => {
          let jsonData = response.data.data.map((user) => {
            let newStudent = {
              Estudiante: user.name,
              DNI: user.identifier,
              Clase: user.class,
              "Inicio Encuesta": user.baseline_start ? 1 : 0,
              "Término Encuesta": user.baseline_end ? 1 : 0,
            };
            this.sortedPhases.forEach(function(value, index) {
              newStudent[`Avance Etapa ${index + 1}`] = user.phases.includes(
                index
              )
                ? 1
                : 0;
            });
            return newStudent;
          });
          const worksheet = XLSX.utils.json_to_sheet(jsonData);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, "Reporte Avance");
          const excelBuffer = XLSX.write(workbook, {
            bookType: "xlsx",
            type: "array",
          });
          const data = new Blob([excelBuffer], {
            type: "text/plain;charset=utf-8",
          });
          const filename = "ReporteAvance.xlsx";
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(data, filename);
          } else {
            const url = window.URL.createObjectURL(data);
            const a = document.createElement("a");
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
          }
          this.downloadType = null;
          this.downloadingReportExcel = false;
        })
        .catch(() => {
          this.downloadingReportExcel = false;
        });
    },

    downloadReport() {
      this.downloadingReportPDF = true;
      this.get({ endpoint: `class_report/${this.currentClass.id}/` }).then(
        (response) => {
          this.reportInformation = response.data.data;
          this.pdf = true;
          this.$nextTick(() => {
            this.exportToPDF();
          });
        }
      );
    },
    exportToPDF() {
      this.downloadType = null;
      // Choose the element that our invoice is rendered in.
      const element = document.getElementById("pdfInfo");

      // clone the element
      var clonedElement = element.cloneNode(true);

      // change display of cloned element
      clonedElement.style.display = "block";
      // $(clonedElement).css("display", "block");

      // Choose the clonedElement and save the PDF for our user.
      html2pdf(clonedElement, {
        margin: 1,
        filename: "ReporteAvance.pdf",
        image: { type: "jpeg", quality: 0.98 },
        pagebreak: {
          mode: ["avoid-all", "css", "legacy"],
        },
        html2canvas: { dpi: 192, letterRendering: true },
        jsPDF: { unit: "in", format: "letter", orientation: "landscape" },
      })
        .then(() => {
          this.downloadingReportPDF = false;
        })
        .catch(() => {
          this.downloadingReportPDF = false;
        });

      // remove cloned element
      clonedElement.remove();
      this.downloadType = null;
    },
    getStudentFinishedTotal(phase) {
      let students = [];
      if (this.user.type == "teacher") {
        if (this.currentClassStudents) {
          students = this.currentClassStudents;
        }
      } else if (this.user.type == "principal") {
        students = this.storeStudents;
      }
      let finished = 0;
      students.forEach((s) => {
        let progress = s.progress.filter(
          (p) => p.related == "phase" && p.related_id == phase
        );

        if (progress.length > 0) {
          finished += 1;
        }
      });
      let value = Math.round(100 * (finished / 100));
      return isNaN(value) ? 0 : value;
    },
    getStudentFinished(phase) {
      let students = [];
      if (this.user.type == "teacher") {
        if (this.currentClassStudents) {
          students = this.currentClassStudents;
        }
      } else if (this.user.type == "principal") {
        students = this.storeStudents;
      }
      let length = students.length;
      let finished = 0;
      students.forEach((s) => {
        let progress = s.progress.filter(
          (p) => p.related == "phase" && p.related_id == phase
        );

        if (progress.length > 0) {
          finished += 1;
        }
      });
      let value = Math.round(100 * (finished / length));
      return isNaN(value) ? 0 : value;
    },
    setStudents(students) {
      this.students = students;
    },
    getPhaseProgress(phase) {
      let completed = 0;
      phase.stages.forEach((s) => {
        if (this.$includes(this.completedStages, s.id)) {
          completed += 1;
        }
      });
      let result = Math.round((completed * 100) / phase.total);
      if (result > 100) {
        result = 100;
      }
      return result;
    },
    getCompletedStages(phase) {
      let completed = 0;
      phase.stages.forEach((s) => {
        if (this.$includes(this.completedStages, s.id)) {
          completed += 1;
        }
      });
      return completed;
    },
    getPhaseButtonClass(index) {
      if (index <= this.currentPhase) {
        return "dashboard-phase-button-selected";
      } else {
        return "dashboard-phase-button";
      }
    },
    deleteAllStudents() {
      this.deleteStudents().then(() => {
        this.logout();
      });
    },
    downloadTemplate() {
      this.track({ event: "dashboard-download-template", data: {} });
      this.downloaded = true;
    },
    getRemainingHeight(margin) {
      return `min-height:calc(100vh - ${margin}px);height: calc(100vh - ${margin}px);max-height: calc(100vh - ${margin}px)`;
    },
    nextStep() {
      this.track({ event: "dashboard-panel-next-step", data: {} });
      if (this.currentStep == 2) {
        this.getStudents({ params: { _class__has_dfm: 1 } }).then(
          (students) => {
            if (this.user.type == "principal") {
              this.setStudents(students);
            }
            this.getClasses({ rbd: null }).then(() => {
              this.getClassStudents().then(() => {
                this.$emit("setPanel", { tag: "panel" });
              });
            });
          }
        );
      } else {
        this.currentStep += 1;
        this.val += 33;
      }
    },
    processFile(file) {
      this.fileUploaded = true;
      this.uploadStudents({ file }).then(() => {
        this.getStudents({ params: { _class__has_dfm: 1 } });
        this.fileProcessed = true;
        this.nextStep();
      });
    },
    previousStep() {
      this.track({ event: "dashboard-panel-previous-step", data: {} });
      this.currentStep -= 1;
      this.val -= 33;
    },
  },

  data() {
    return {
      progressTooltip: false,
      downloadingReportExcel: false,
      downloadingReportPDF: false,

      downloadType: null,

      currentRbd: null,
      reportInformation: [],
      pdf: false,
      shownProgress: { baseline_start: 0 },
      loaded: false,
      students: [],
      currentPhase: 0,
      env: process.env.VUE_APP_ENVIRONMENT,
      started: false, // TODO: change to false
      val: 34,
      downloaded: false, // TODO: Change to false
      fileUploaded: false,
      fileProcessed: false,
      currentStep: 0, // TODO: change to 0
      steps: [
        { text: "Ver tutorial", icon: "mdi-camera" },
        { text: "Descargar template csv", icon: "mdi-download" },
        { text: "Cargar lista de estudiantes", icon: "mdi-upload" },
      ],
    };
  },
  watch: {
    currentClass() {
      if (
        this.user.type == "teacher" ||
        (this.user.type == "principal" && this.currentRbd != this.rbd)
      ) {
        this.currentRbd = this.rbd;
        this.getProgress();
      }
    },
    storeStudents(newVal) {
      if (this.user.type == "principal") {
        /*let relevantClasses = this.classes
          .filter((c) => c.school == this.rbd)
          .map((c) => c.id);*/
        let students = newVal.filter((s) => s._class == this.currentClass.id);
        this.setStudents(students);
      }
    },
    currentClassStudents(newVal) {
      if (this.user.type == "teacher") {
        if (newVal && newVal.length > 0) {
          this.setStudents(newVal);
        } else {
          this.setStudents([]);
        }
      }
    },
    storeCurrentPhase(newVal, oldVal) {
      if (oldVal == null && newVal != null) {
        this.currentPhase = this.storeCurrentPhase.number - 1;
      }
    },
  },
  mounted() {
    if (this.rbd) {
      this.currentRbd = this.rbd;
    }
    if (!this.classes || this.classes.length == 0) {
      this.getClasses({ rbd: this.rbd })
        .then(() => {
          this.getClassStudents().then((students) => {
            this.setStudents(students);
            this.loaded = true;
          });
        })
        .catch(() => {
          this.loaded = true;
        });
    } else {
      if (this.students.length == 0) {
        if (this.currentClassStudents.length > 0) {
          this.setStudents(this.currentClassStudents);
          this.loaded = true;
        } else {
          this.getClassStudents().then((students) => {
            this.loaded = true;
            this.setStudents(students);
          });
        }
      } else {
        this.loaded = true;
      }
    }
    if (this.storeCurrentPhase) {
      this.currentPhase = this.storeCurrentPhase.number - 1;
    }
    // finally, get report if user is principal or teacher
    if (this.user.type == "teacher" || this.user.type == "principal") {
      this.getProgress();
    }
    if (this.user.type == "principal") {
      this.getStudents({
        params: { _class__school__rbd: this.rbd, _class__has_dfm: 1 },
      }).then((students) => {
        this.setStudents(students);
      });
    }
  },
};
</script>
<style>
.progress-tooltip {
  background-color: rgba(33, 33, 33, 1) !important;
}
.dashboard-panel-subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 37px;
  color: #4a4b4f;
}
.dashboard-panel-dataloader {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100%;
  align-items: center;
  padding: 24px;
  gap: 26px;
  background: #f6f8ff;
  border-radius: 6px;
}
.dashboard-panel-dataloader-title {
  font-family: "Karla";
  font-style: normal;
  font-weight: 500;
  font-size: 23.9131px;
  line-height: 34px;
  text-align: center;
  color: #4a4b4f;
}
.dashboard-panel-dataloader-circle {
  border-radius: 50px;
  background: red;
  width: 60px;
  height: 60px;
}
</style>
