<template>
  <v-card class="ma-4 p-4 text-left">
    <div class="text-heading">Playground</div>
    <!-- texts -->
    <div>
      <div class="text-title bold mt-6">Escala de textos</div>
      <div
        v-for="textClass in ['heading', 'title', 'subtitle', 'body', 'label']"
        :key="textClass"
      >
        <div class="ms-8" :class="`text-${textClass}`">{{ textClass }}</div>
      </div>
    </div>

    <!-- colors -->
    <div class="text-title bold mt-6">Colores</div>
    <div class="d-flex flex-column ms-8" style="gap: 10px">
      <div
        v-for="color in ['primary', 'secondary', 'tertiary', 'neutral']"
        :key="color"
      >
        <div>Escala de colores {{ color }}, hacia claridad (tinted)</div>
        <div class="d-flex">
          <div
            class="text-label"
            style="width:140px;height: 30px"
            :class="`bg--${color}-${i}0-t`"
            v-for="i in 10"
            :key="i"
          >
            bg--{{ color }}-{{ i }}0-t
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column ms-8" style="gap: 10px">
      <div
        v-for="color in ['primary', 'secondary', 'tertiary', 'neutral']"
        :key="color"
      >
        <div>Escala de colores {{ color }}, hacia oscuridad (shadow)</div>
        <div class="d-flex">
          <div
            class="text-label"
            style="width: 140px; height: 30px"
            :class="`bg--${color}-${i}0-s`"
            v-for="i in 10"
            :key="i"
          >
            bg--{{ color }}-{{ i }}0-s
          </div>
        </div>
      </div>
    </div>
    <span class="text-title">Botones</span>
    <div class="d-flex ms-8">
      <common-button
        v-for="size in ['x-small', 'small', 'medium', 'large', 'x-large']"
        :key="size"
        :size="size"
        primary
      >
        Botón de tamaño {{ size }}
      </common-button>
    </div>
    <div class="d-flex ms-8">
      <common-button
        v-for="size in ['x-small', 'small', 'medium', 'large', 'x-large']"
        :key="size"
        :size="size"
        secondary
      >
        Botón de tamaño {{ size }}
      </common-button>
    </div>
    <div class="d-flex ms-8">
      <common-button
        v-for="size in ['x-small', 'small', 'medium', 'large', 'x-large']"
        :key="size"
        :size="size"
        secondary
        text-wrap
        style="max-width:100px;height: auto;
  line-height: normal;
  white-space: normal;"
      >
        Botón de tamaño {{ size }} con un montón de texto para verificar que el
        text-wrap se está haciendo correctamente y no está teniendo problemas.
      </common-button>
    </div>
    <!-- Inputs -->
    <div>
      <div class="text-title">Inputs</div>
      <div class="ms-8">
        <div>Dropdown</div>
        <common-dropdown v-model="dropdown" :items="[1, 2, 3]" />
        <div>Autocomplete</div>
        <common-autocomplete v-model="autocomplete" :items="[1, 2, 3]" />
        <div>TextField</div>

        <common-text-field v-model="textfield" />
        <div>Text area</div>
        <common-text-area v-model="textarea" />
      </div>
    </div>
    <!-- pop ups -->
    <div>
      <common-pop-up v-model="popup">
        Hola! Somos DFM
      </common-pop-up>
      <div class="text-title">Pop ups</div>
      <div class="ms-8">
        <common-button @click="popup = !popup">Abrir pop up</common-button>
      </div>
    </div>
  </v-card>
</template>
<script>
export default {
  name: "Playground",

  data() {
    return {
      autocomplete: null,
      textfield: null,
      popup: false,
      textarea: null,
      dropdown: null,
    };
  },
};
</script>
